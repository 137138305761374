/**
 * Created by Ethan on 2017/9/6.
 */

const ja_JP = {

    APP_BRAND: "KABA FACE",
    APP_BRAND_COLLAPSED: "KABA",
    APP_COPYRIGHT: "KABA FACE Professional - 试用许可证",

    COMMON_BUTTON_OK: "确定",
    COMMON_BUTTON_ADD: "添加",
    COMMON_BUTTON_CREATE: "创建",
    COMMON_BUTTON_UPDATE: "更新",
    COMMON_BUTTON_SAVE: "保存",
    COMMON_BUTTON_SAVE_DRAFT: "保存草稿",
    COMMON_BUTTON_MODIFY: "修改",
    COMMON_BUTTON_EDIT: "编辑",
    COMMON_BUTTON_REMOVE: "移除",
    COMMON_BUTTON_DELETE: "删除",
    COMMON_BUTTON_RESTART: "重启",
    COMMON_BUTTON_SUBMIT: "提交",
    COMMON_BUTTON_CANCEL: "取消",
    COMMON_BUTTON_SEARCH: "查询",
    COMMON_BUTTON_SAVEANDCLOSE: "保存并关闭",
    COMMON_BUTTON_SEARCH_CLEAR: "清空查询",
    COMMON_BUTTON_SEARCH_ADVANCE: "高级查询",
    COMMON_BUTTON_DRAFT: "保存草稿",
    COMMON_BUTTON_EXPORT: "导出",
    COMMON_BUTTON_SELECT: "选择",
    COMMON_BUTTON_SELECTED: "已选择",
    COMMON_BUTTON_BACK: "返回",
    COMMON_BUTTON_UPLOAD: "上传",
    COMMON_BUTTON_IMPORT: "导入",
    COMMON_BUTTON_SCAN: "扫描",
    COMMON_BUTTON_STOP: "停止",
    COMMON_BUTTON_REFRESH: "刷新",
    COMMON_BUTTON_DETAIL: "详细",
    COMMON_BUTTON_CLOSE: "关闭",
    COMMON_BUTTON_COPY: "复制",
    COMMON_BUTTON_NEW: "新建",
    COMMON_BUTTON_DOWNLOAD: "下载",
    COMMON_BUTTON_DOWNLOAD_TEMPLATE: "下载模版",
    COMMON_BUTTON_ENABLE: "启用",
    COMMON_BUTTON_EMPTY: "清空",

    COMMON_FIELD_UPDATE_TIME: "更新时间",
    COMMON_FIELD_REMARKS: "备注",
    COMMON_FIELD_REMARKS_PLACEHOLDER: "请输入备注",
    COMMON_FIELD_ENABLED: "有效",
    COMMON_FIELD_DISABLED: "无效",
    COMMON_FIELD_SUCCESS: "成功",
    COMMON_FIELD_FAILED: "失败",
    COMMON_FIELD_ACTION: "操作",
    COMMON_FIELD_HAVE: "有",
    COMMON_FIELD_NOTHING: "无",
    COMMON_FIELD_PAGINATION_TOTAL: "共 {sum} 条",
    COMMON_FIELD_HEIGHT: "高",
    COMMON_FIELD_WIDTH: "宽",
    COMMON_FIELD_DEEP: "深",
    COMMON_FIELD_FLOOR: "层",
    COMMON_FIELD_NUMBER: "序号",
    COMMON_FIELD_UNIT_M: "米",
    COMMON_FIELD_UNIT_M2: "平方米",
    COMMON_FIELD_UNIT_T_M2: "吨/平方米",
    COMMON_PLACEHOLDER_FUZZY_QUERY: "模糊查询",
    COMMON_PLACEHOLDER_SELECT_DATE: "选择日期",

    COMMON_MSG_UPLOAD_CONTINUE: "上传中，请稍后。。。",
    COMMON_MSG_RESTART_SUCCESS: "重启设备成功",
    COMMON_MSG_RESTART_ERROR: "重启设备失败",
    COMMON_MSG_RESTART_TITLE: "重启确认",
    COMMON_MSG_RESTART_CONFIRM: "是否重启该设备？",
    COMMON_MSG_CREATE_SUCCESS: "创建已完成",
    COMMON_MSG_UPDATE_SUCCESS: "更新已完成",
    COMMON_MSG_DELETE_SUCCESS: "删除已完成",
    COMMON_MSG_DELETE_TITLE: "删除确认",
    COMMON_MSG_UPDATE_TITLE: "修改确认",
    COMMON_MSG_DELETE_CONFIRM: "删除后无法恢复，请确认是否删除？",
    COMMON_MSG_UPDATE_CONFIRM: "是否修改主联系人，请确认？",
    COMMON_MSG_REQUIRED: "不能为空",
    COMMON_MSG_BOTH_CHOOSE: "两项必填一项",
    COMMON_MSG_EXCEPTION: "未处理异常，请联系管理员",
    COMMON_MSG_CREATE_FAVORITES: "收藏成功",
    COMMON_FIELD_VALUE_UNKNOWN: "不详",
    COMMON_FIELD_VALUE_NO_LIMIT: "不限",

    // 账户管理
    ACCOUNT_ROUTER_MANAGEMENT: "账号管理",
    // 账户管理-角色
    ACCOUNT_USER_FIELD_ROLE: "角色",

    ACCOUNT_USER_ROUTE_LOGIN_PASSWORD: "密码登录",
    ACCOUNT_USER_ROUTE_LOGOUT: "退出登录",
    ACCOUNT_USER_ROUTE_REGISTER: "用户注册",
    ACCOUNT_USER_ROUTE_LIST: "账号一览",
    ACCOUNT_USER_ROUTE_CENTER: "个人中心",
    ACCOUNT_USER_ROUTE_PROFILE: "基本信息",
    ACCOUNT_USER_ROUTE_PASSWORD: "用户密码",
    ACCOUNT_USER_ROUTE_PASSWORD_MODIFY: "修改密码",
    ACCOUNT_USER_ROUTE_PASSWORD_FORGOT: "忘记密码",

    ACCOUNT_USER_TITLE_CREATE: "创建新用户",
    ACCOUNT_USER_TITLE_UPDATE: "更新用户",
    ACCOUNT_USER_TITLE_DELETE: "删除用户",
    ACCOUNT_USER_TITLE_PASSWORD_RESET: "密码重制",
    ACCOUNT_USER_FIELD_UID: "用户ID",
    ACCOUNT_USER_FIELD_UID_PLACEHOLDER: "用户ID/邮箱/手机号",
    ACCOUNT_USER_FIELD_PASSWORD_OLD: "旧密码",
    ACCOUNT_USER_FIELD_PASSWORD: "密码",
    ACCOUNT_USER_FIELD_PASSWORD_CONFIRM: "确认密码",
    ACCOUNT_USER_FIELD_EMAIL: "邮箱",
    ACCOUNT_USER_FIELD_EMAIL_VERIFY_CODE: "邮箱验证码",
    ACCOUNT_USER_FIELD_MOBILE: "手机号",
    ACCOUNT_USER_FIELD_FIRST_NAME: "名",
    ACCOUNT_USER_FIELD_LAST_NAME: "姓",
    ACCOUNT_USER_FIELD_FULL_NAME: "姓名",
    ACCOUNT_USER_FIELD_ROLE: "角色",
    ACCOUNT_USER_FIELD_DISABLED: "有效性",
    ACCOUNT_USER_FIELD_PORTRAIT: "头像",
    ACCOUNT_USER_BUTTON_SUBMIT: "登录",
    ACCOUNT_USER_BUTTON_REGISTER: "注册新用户",
    ACCOUNT_USER_BUTTON_EMAIL_VERIFY_CODE: "发送验证码",
    ACCOUNT_USER_BUTTON_PASSWORD_RESET: "重置密码",
    ACCOUNT_USER_MSG_UID_REQUIRED: "用户ID是不能为空",
    ACCOUNT_USER_MSG_PASSWORD_REQUIRED: "密码不能为空",
    ACCOUNT_USER_MSG_PASSWORD_WRONG: "密码错误",
    ACCOUNT_USER_MSG_OLD_PASSWORD_REQUIRED: "旧密码不能为空",
    ACCOUNT_USER_MSG_OLD_PASSWORD_WRONG: "旧密码错误",
    ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED: "密码长度不能少于{value}个字符",
    ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED: "密码必须包含字符，数字，特殊符号的组合",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_REQUIRED: "密码确认不能为空",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED: "两次密码不一致",
    ACCOUNT_USER_MSG_MOBILE_REQUIRED: "手机号不能为空",
    ACCOUNT_USER_MSG_MOBILE_FORMAT_WRONG: "手机号格式不正确",
    ACCOUNT_USER_MSG_EMAIL_REQUIRED: "邮箱不能为空",
    ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG: "邮箱格式不正确",
    ACCOUNT_USER_MSG_EMAIL_CODE_REQUIRED: "邮箱验证码不能为空",
    ACCOUNT_USER_MSG_EMAIL_CODE_SEND_SUCCESS: "邮箱验证码发送成功",
    ACCOUNT_USER_MSG_EMAIL_CODE_NOT_FOUND: "邮箱验证码不正确",
    ACCOUNT_USER_MSG_LAST_NAME_REQUIRED: "姓不能为空",
    ACCOUNT_USER_MSG_FIRST_NAME_REQUIRED: "名不能为空",
    ACCOUNT_USER_MSG_ROLE_REQUIRED: "角色不能为空",
    ACCOUNT_USER_MSG_AUTH_TOKEN_EXPIRED: "登录令牌过期",
    ACCOUNT_USER_MSG_AUTH_TOKEN_NON_PRINCIPAL: "登录令牌不存在",
    ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS: "密码重制成功",
    ACCOUNT_USER_MSG_PASSWORD_RESET_CONFIRM: "确定要重制该用户的密码吗？",
    ACCOUNT_USER_MSG_CREATE_SUCCESS: "用户创建成功",
    ACCOUNT_USER_MSG_UPDATE_SUCCESS: "用户更新成功",
    ACCOUNT_USER_MSG_DELETE_SUCCESS: "用户删除成功",
    ACCOUNT_USER_MSG_DELETE_CONFIRM: "用户删除确认吗？",
    ACCOUNT_USER_MSG_NOT_FOUND: "该用户不存在",
    ACCOUNT_USER_MSG_DISABLED: "该用户未激活",

    ACCOUNT_APP_MSG_ACCESS_FORBIDDEN: "该应用禁止访问",
    ACCOUNT_APP_MSG_ACCESS_NOT_FOUND: "该应用未正确注册",
    ACCOUNT_APP_MSG_ACCESS_DISABLED: "该应用未激活",

    // 组织管理-组织
    ORG_ROUTER_MANAGEMENT: "人员管理",
    ORG_ORGANIZATION_ROUTE_LIST: "组织一览",
    ORG_ORGANIZATION_TITLE_CREATE: "创建新组织",
    ORG_ORGANIZATION_TITLE_UPDATE: "更新组织",
    ORG_ORGANIZATION_TITLE_DELETE: "删除组织",
    ORG_ORGANIZATION_FIELD_PARENT: "上级组织",
    ORG_ORGANIZATION_FIELD_CODE: "编号",
    ORG_ORGANIZATION_FIELD_NAME: "名称",
    ORG_ORGANIZATION_FIELD_TYPE: "类型",
    ORG_ORGANIZATION_FIELD_TYPE_COMPANY: "公司",
    ORG_ORGANIZATION_FIELD_TYPE_DEPARTMENT: "部门",
    ORG_ORGANIZATION_FIELD_CONTACTS: "联系人",
    ORG_ORGANIZATION_FIELD_TELEPHONE: "联系电话",
    ORG_ORGANIZATION_FIELD_ADDRESS: "联系地址",
    ORG_ORGANIZATION_FIELD_DISABLED: "启用",
    ORG_ORGANIZATION_FIELD_TEMPORARY: "临时",
    ORG_ORGANIZATION_MSG_CODE_REQUIRED: "组织编号不能为空",
    ORG_ORGANIZATION_MSG_NAME_REQUIRED: "组织名不能为空",
    ORG_ORGANIZATION_MSG_TYPE_REQUIRED: "组织类型不能为空",
    ORG_ORGANIZATION_MSG_CREATE_SUCCESS: "组织创建成功",
    ORG_ORGANIZATION_MSG_UPDATE_SUCCESS: "组织更新成功",
    ORG_ORGANIZATION_MSG_DELETE_SUCCESS: "组织删除成功",
    ORG_ORGANIZATION_MSG_DELETE_CONFIRM: "组织删除确认吗？",
    ORG_ORGANIZATION_MSG_PARENT_DEAD_CYCLE: "不可设置父组织为自己",
    ORG_ORGANIZATION_MSG_HAVE_CHILDREN: "该组织下有子组织",
    ORG_ORGANIZATION_MSG_HAVE_EMPLOYEE: "该组织下有员工",

    // 组织管理-人员
    ORG_EMPLOYEE_ROUTE_LIST: "人员一览",
    ORG_EMPLOYEE_TITLE_CREATE: "人员创建",
    ORG_EMPLOYEE_TITLE_UPDATE: "人员更新",
    ORG_EMPLOYEE_TITLE_DELETE: "人员删除",
    ORG_EMPLOYEE_TITLE_BASIC_INFO: "基本信息",
    ORG_EMPLOYEE_TITLE_EXTEND_INFO: "扩展信息",
    ORG_EMPLOYEE_TITLE_IMPORT: "员工批量导入",
    ORG_EMPLOYEE_TITLE_SELECTOR_MODAL: "员工选择",
    ORG_EMPLOYEE_FIELD_CODE: "工号",
    ORG_EMPLOYEE_FIELD_TYPE: "类型",
    ORG_EMPLOYEE_FIELD_TYPE_INNER: "内部",
    ORG_EMPLOYEE_FIELD_TYPE_EXTERNAL: "外部",
    ORG_EMPLOYEE_FIELD_FULL_NAME: "姓名",
    ORG_EMPLOYEE_FIELD_MOBILE: "手机号",
    ORG_EMPLOYEE_FIELD_ORGANIZATION: "部门",
    ORG_EMPLOYEE_FIELD_LAST_NAME: "姓",
    ORG_EMPLOYEE_FIELD_FIRST_NAME: "名",
    ORG_EMPLOYEE_FIELD_EMAIL: "邮箱",
    ORG_EMPLOYEE_FIELD_EMAIL_VERIFY_CODE: "Verify Code",
    ORG_EMPLOYEE_FIELD_SEX: "性别",
    ORG_EMPLOYEE_FIELD_SEX_MALE: "男",
    ORG_EMPLOYEE_FIELD_SEX_FEMALE: "女",
    ORG_EMPLOYEE_FIELD_STATUS: "状态",
    ORG_EMPLOYEE_FIELD_STATUS_AT_POST: "在职",
    ORG_EMPLOYEE_FIELD_STATUS_DIMISSION: "离职",
    ORG_EMPLOYEE_FIELD_ENTRY_DATE: "入职日期",
    ORG_EMPLOYEE_FIELD_DIMISSION_DATE: "离职日期",
    ORG_EMPLOYEE_FIELD_PHOTO: "照片",
    ORG_EMPLOYEE_FIELD_POSITION: "所属组",
    ORG_EMPLOYEE_FIELD_ID_NUMBER: "身份证",
    ORG_EMPLOYEE_FIELD_SUPERVISOR: "上级",
    ORG_EMPLOYEE_BUTTON_SEND_VERIFY_EMAIL_CODE: "Send verify code",
    ORG_EMPLOYEE_BUTTON_REGISTER: "Register",
    ORG_EMPLOYEE_MSG_ORGANIZATION_ID_REQUIRED: "人员所属组织是必填项。",
    ORG_EMPLOYEE_MSG_LAST_NAME_REQUIRED: "人员姓氏是必填项",
    ORG_EMPLOYEE_MSG_FIRST_NAME_REQUIRED: "人员名字是必填项",
    ORG_EMPLOYEE_MSG_CODE_REQUIRED: "人员工号是必填项。",
    ORG_EMPLOYEE_MSG_CODE_DUPLICATE: "员工工号重复",
    ORG_EMPLOYEE_MSG_PHOTO_FILE_REQUIRED: "人员照片是必填项",
    ORG_EMPLOYEE_MSG_TYPE_REQUIRED: "人员类型是必填项",
    ORG_EMPLOYEE_MSG_EMAIL_REQUIRED: "人员邮箱是必填项。",
    ORG_EMPLOYEE_MSG_EMAIL_DUPLICATE: "该邮箱已被他人登记，请使用其他邮箱。",
    ORG_EMPLOYEE_MSG_EMAIL_FORMAT_WRONG: "人员邮箱格式错误。",
    ORG_EMPLOYEE_MSG_MOBILE_REQUIRED: "人员手机号是必填项。",
    ORG_EMPLOYEE_MSG_MOBILE_FORMAT_WRONG: "人员手机号格式错误",
    ORG_EMPLOYEE_MSG_MOBILE_DUPLICATE: "该手机号已被他人登记，请使用其他手机号。",
    ORG_EMPLOYEE_MSG_REGISTER_SUCCESS: "注册成功",
    ORG_EMPLOYEE_MSG_DELETE_CONFIRM: "人员删除确认吗？",
    // 岗位/用户组
    ORG_POSITION_ROUTE_LIST: "人员组",
    ORG_POSITION_TITLE_CREATE: "创建人员组",
    ORG_POSITION_TITLE_UPDATE: "更新人员组",
    ORG_POSITION_FIELD_CODE: "编号",
    ORG_POSITION_FIELD_NAME: "名称",
    ORG_POSITION_MSG_CODE_REQUIRED: "编号是必填项。",
    ORG_POSITION_MSG_NAME_REQUIRED: "名称是必填项。",

    // 文件管理
    FTP_FILE_ROUTE_LIST: "文件一览",
    FTP_FILE_FIELD_NAME: "文件名",
    FTP_FILE_FIELD_PATH: "文件路径",
    FTP_FILE_FIELD_LAST_MODIFIED: "最后修改日期",
    FTP_MSG_UPLOAD_SUCCEED: "上传成功",
    FTP_MSG_UPLOAD_FAILED: "上传失败",

    // 人脸设备
    FACE_DEVICE_MSG_RESPONSE_SUCCESS: "检查人脸图片成功",
    FACE_DEVICE_MSG_RESPONSE_KEY_ERROR: "参数有误:key 不能为空",
    FACE_DEVICE_MSG_RESPONSE_FACE_FEATURE_KEY_ERROR: "检查人脸图片失败:key 不正确",
    FACE_DEVICE_MSG_RESPONSE_FACE_FEATURE_LOADING: "检查人脸图片失败:设备未加载完毕，请稍后再试",
    FACE_DEVICE_MSG_RESPONSE_FACE_FEATURE_CHECKING: "检查人脸图片失败:正在检查，请稍后再试",
    FACE_DEVICE_MSG_RESPONSE_FACE_FEATURE_EXCEPTION: "检查人脸图片失败:图片数据异常",

    FACE_DEVICE_MSG_FACE_FEATURE_USABLE_OK: "可用",
    FACE_DEVICE_MSG_FACE_FEATURE_USABLE_DUPLICATE: "图片人脸过多",
    FACE_DEVICE_MSG_FACE_FEATURE_USABLE_LOW: "人脸质量过低",
    FACE_DEVICE_MSG_FACE_FEATURE_USABLE_FAILED: "无法检测到人脸",

    // 设备组管理
    ACS_DEVICE_GROUP_TITLE_CREATE: "创建设备组",
    ACS_DEVICE_GROUP_TITLE_UPDATE: "更新设备组",
    ACS_DEVICE_GROUP_TITLE_DELETE: "删除设备组",
    ACS_DEVICE_GROUP_FIELD_NAME: "名称",
    ACS_DEVICE_GROUP_FIELD_REMARKS: "说明",
    ACS_DEVICE_GROUP_MSG_NAME_REQUIRED: "设备组名称是必填项",

    // 设备管理
    VMS_VISIT_REQUEST_ROUTE_MANAGEMENT: "设备管理",
    VMS_VISIT_REQUEST_ROUTE_APPROVE_LIST: "设备一览",
    VMS_VISIT_REQUEST_ROUTE_HISTORY: "设备树",
    VMS_VISIT_REQUEST_ROUTE_CREATE: "设备组",
    ACS_DEVICE_TITLE_CREATE: "创建设备",
    ACS_DEVICE_TITLE_UPDATE: "更新设备",
    ACS_DEVICE_TITLE_DELETE: "删除设备",
    ACS_DEVICE_TITLE_BASIC_INFO: "基本信息",
    ACS_DEVICE_TITLE_STATUS_INFO: "状态信息",
    ACS_DEVICE_TITLE_PARAMS_INFO: "参数信息",
    ACS_DEVICE_BUTTON_CREATE: "添加设备",
    ACS_DEVICE_BUTTON_CREATE_GROUP: "添加设备组",
    ACS_DEVICE_FIELD_TREE: "设备树",
    ACS_DEVICE_FIELD_DEVICE_GROUP: "设备组",
    ACS_DEVICE_FIELD_NUMBER: "编号",
    ACS_DEVICE_FIELD_NAME: "名称",
    ACS_DEVICE_FIELD_IP: "IP地址",
    ACS_DEVICE_FIELD_PORT: "端口号",
    ACS_DEVICE_FIELD_SECRET_KEY: "密钥",
    ACS_DEVICE_FIELD_DEVICE_MODEL: "设备型号",
    ACS_DEVICE_FIELD_REMARKS: "备注",
    ACS_DEVICE_FIELD_ONLINE_FLAG: "联网状态",
    ACS_DEVICE_FIELD_ACTIVE_FLAG: "启用状态",
    ACS_DEVICE_FIELD_CPU_USAGE: "CPU使用率",
    ACS_DEVICE_FIELD_RAM_USAGE: "RAM使用率",
    ACS_DEVICE_FIELD_SERIAL_NUM: "序列号",
    ACS_DEVICE_FIELD_DEVICE_TIME: "设备时间",
    ACS_DEVICE_FIELD_AVAILABLE_STORAGE: "可用存储空间",
    ACS_DEVICE_FIELD_FIRMWARE_VERSION: "固件版本号",
    ACS_DEVICE_FIELD_ANDROID_VERSION: "安卓版本号",
    ACS_DEVICE_FIELD_APP_VERSION: "APP版本号",
    ACS_DEVICE_FIELD_HEARTBEAT_TIME: "心跳时间",
    ACS_DEVICE_FIELD_GROUP: "设备组",
    ACS_DEVICE_FIELD_POSITION: "位置",
    ACS_DEVICE_MSG_DEVICE_GROUP_REQUIRED: "设备组是必填项",
    ACS_DEVICE_MSG_NUMBER_REQUIRED: "设备编号是必填项",
    ACS_DEVICE_MSG_NAME_REQUIRED: "设备名称是必填项",
    ACS_DEVICE_MSG_IP_REQUIRED: "设备IP是必填项",
    ACS_DEVICE_MSG_IP_ADDRESS_UNREACHABLE: "设备的IP不通",
    ACS_DEVICE_MSG_PORT_REQUIRED: "设备端口号是必填项",
    ACS_DEVICE_MSG_SECRET_KEY_REQUIRED: "设备密钥是必填项",

    // 设备参数
    ACS_DEVICE_PARAM_FIELD_WIEGAND_PROTOCOL: "韦根协议",
    ACS_DEVICE_PARAM_FIELD_USE_ACS_LIVENESS: "活体检测",
    ACS_DEVICE_PARAM_FIELD_ACS_COMPARE_THRESHOLD: "比对阈值",
    ACS_DEVICE_PARAM_FIELD_ACS_DETECT_DISTANCE: "检测距离",
    ACS_DEVICE_PARAM_FIELD_VOLUME: "终端音量",
    ACS_DEVICE_PARAM_FIELD_RESTART_TIME: "重启设备时间",
    ACS_DEVICE_PARAM_FIELD_BRIGHTNESS_THRESHOLD: "亮度阈值",
    ACS_DEVICE_PARAM_FIELD_OPEN_DOOR_TYPE: "开门类型",
    ACS_DEVICE_PARAM_FIELD_SET_DEVICE_TIME: "设备时间",
    ACS_DEVICE_PARAM_FIELD_AUTO_OPEN_LOCK: "自动开锁",
    ACS_DEVICE_PARAM_FIELD_THRESHOLD_TEMPERATURE: "体温阈值",
    ACS_DEVICE_PARAM_FIELD_TEMPERATURE_COMPENSATE: "体温补偿值",
    ACS_DEVICE_PARAM_FIELD_WIEGAND_PARAMS: "韦根参数",
    ACS_DEVICE_PARAM_FIELD_VERIFY_PARAMS: "验证参数",
    ACS_DEVICE_PARAM_FIELD_FACE_CENTER: "人脸居中",
    ACS_DEVICE_PARAM_FIELD_PROMPT_MASK: "提示佩戴口罩",
    ACS_DEVICE_PARAM_FIELD_PASS_TEXT: "通过提示内容",
    ACS_DEVICE_PARAM_FIELD_FAIL_TEXT: "不通过提示内容",
    ACS_DEVICE_PARAM_FIELD_HEARTBEAT_PATH: "心跳请求地址",
    ACS_DEVICE_PARAM_FIELD_UPLOAD_FEATURE_PATH: "上传特征的地址",
    ACS_DEVICE_PARAM_FIELD_UPLOAD_RECORD_PATH: "上传记录的地址",
    ACS_DEVICE_PARAM_FIELD_GET_TIME_PATH: "获取时间的地址",

    ACS_DEVICE_PARAM_FIELD_BASIC: "其他设置",

    ACS_DEVICE_PARAM_FIELD_ONLY_FACE: "仅刷脸",
    ACS_DEVICE_PARAM_FIELD_FACE_AND_CARD: "刷脸+刷卡",
    ACS_DEVICE_PARAM_FIELD_WIEGAND_PROTOCOL_26: "26- bit",
    ACS_DEVICE_PARAM_FIELD_WIEGAND_PROTOCOL_34: "34- bit",

    // 权限管理
    VMS_VISITOR_ROUTE_MANAGEMENT: "权限管理",
    VMS_VISITOR_ROUTE_HISTORY: "权限组",
    ACS_DEVICE_ROUTE_PERSONNEL: "设备人员",
    ACS_PERMISSION_ROUTE_PERSON_PROFILE: "人员分组",

    // 权限组
    ACS_PERMISSION_PROFILE_TITLE_CREATE: "创建权限组",
    ACS_PERMISSION_PROFILE_TITLE_UPDATE: "更新权限组",
    ACS_PERMISSION_PROFILE_TITLE_DELETE: "删除权限组",
    ACS_PERMISSION_PROFILE_TITLE_SELECT_DEVICE_GROUP: "选择设备组",
    ACS_PERMISSION_PROFILE_FIELD_NAME: "名称",
    ACS_PERMISSION_PROFILE_FIELD_REMARKS: "说明",
    ACS_PERMISSION_PROFILE_FIELD_DEVICE_GROUPS: "已授权设备组",
    ACS_PERMISSION_PROFILE_BUTTON_DEVICE_GROUPS_SELECT: "选择设备组",
    ACS_PERMISSION_PROFILE_MSG_NAME_REQUIRED: "名称是必填项",

    ACS_PERMISSION_FIELD_DEVICE_TITLE_CREATE: "创建设备人员",
    ACS_PERMISSION_FIELD_DEVICE_TITLE_UPDATE: "更新设备人员",
    ACS_PERMISSION_FIELD_DEVICE_TITLE_DELETE: "删除设备人员",
    ACS_PERMISSION_FIELD_DEVICE_KEY: "密钥",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL: "类型",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_INSIDE: "内部员工",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_OUTSIDE: "外来访客",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_HAVE: "有",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_NO_HAVE: "无",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_DEFAULT: "默认",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_JOB_NUM: "工号",

    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_FIRST_NAME: "姓",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_LAST_NAME: "名",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_NAME: "姓名",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_HEADER_IMAGE: "头像",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_ID_CARD_NUM: "身份证号",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_WIEGAND_NUM: "韦根卡号",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_DEPARTMENT: "部门",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_COMPANY: "公司",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_EXPIRY_TIME: "有效期",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_FACE_FEATURE: "脸部特征值",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_FACE_FEATURE_IMG_PATH: "提取脸部特征的图片地址",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_FINGER_VEIN_FEATURE: "指静脉特征",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_HEADER_IMG_PATH: "头像图片路径",
    ACS_PERMISSION_MSG_PERSONNEL_ID_REQUIRED: "人员ID是必填项",
    ACS_PERMISSION_MSG_PERSONNEL_TYPE_REQUIRED: "人员类型是必填项",
    ACS_PERMISSION_MSG_PERSONNEL_NAME_REQUIRED: "姓名是必填项",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_MOBILE: "手机号",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_EMAIL: "邮箱",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_SEX: "性别",
    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_STATUS: "状态",

    ACS_PERMISSION_FIELD_DEVICE_PERSONNEL_IC_NUM: "卡号",
    ACS_DEVICE_PERMISSION_MSG_NO_DATA: "没有分配权限",
    ACS_DEVICE_PERMISSION_TITLE_DEVICE_GROUP: "选择设备组",

    // 人员权限组
    ACS_PERSON_PROFILE_TITLE_SELECT_PROFILES: "分配权限组",
    ACS_PERSON_PROFILE_FIELD_VALID_DATE_FROM: "有效起始日",
    ACS_PERSON_PROFILE_FIELD_VALID_DATE_TO: "有效结束日",
    ACS_PERSON_PROFILE_BUTTON_SELECT_PROFILES: "授权",
    ACS_PERSON_PROFILE_MSG_SYNC_PROFILES_SUCCESS: "授权成功",

    // 人员管理
    VMS_SYSTEM_ROUTE_LOCATION_LIST: "人员一览",
    VMS_VISITOR_ROUTE_IMPORT: "人员导入",
    ACS_PERSON_TITLE_CREATE: "人员创建",
    ACS_PERSON_TITLE_UPDATE: "人员更新",
    ACS_PERSON_FIELD_HEADER_IMAGE: "头像",
    ACS_PERSON_FIELD_PROFILE: "权限组",
    ACS_PERSON_FIELD_FACE_FEATURE_RESULT: "特征值结果",
    ACS_PERSON_FIELD_FACE_FEATURE_USABLE: "照片可用性",
    ACS_PERSON_FIELD_PERMISSION_RESULT: "授权结果",
    ACS_PERSON_BUTTON_PERMISSION: "授权",
    ACS_PERSON_MSG_HEADER_IMAGE_REQUIRED: "头像是必填项",
    ACS_PERSON_MSG_UPLOAD_FILE_REQUIRED: "请先选择要导入的文件",
    ACS_PERSON_MSG_PERMISSION_SUCCESS: "授权成功",

    // 通行管理
    VMS_SYSTEM_ROUTE_MANAGEMENT: "通行记录",
    VMS_SYSTEM_ROUTE_SITE_LIST: "记录一览",
    ACS_RECORD_ROUTE_DOWNLOAD: "记录下载",
    ACS_ACCESS_RECORD_FIELD_DEVICE_NUMBER: "设备编号",
    ACS_ACCESS_RECORD_FIELD_DEVICE_NAME: "设备名称",
    ACS_ACCESS_RECORD_FIELD_EMP_NUMBER: "人员编号",
    ACS_ACCESS_RECORD_FIELD_EMP_NAME: "人员姓名",
    ACS_ACCESS_RECORD_FIELD_DEPARTMENT: "部门",
    ACS_ACCESS_RECORD_FIELD_TIME: "通行时间",
    ACS_ACCESS_RECORD_FIELD_VERIFY_TYPE: "验证类型",
    ACS_ACCESS_RECORD_FIELD_FACE_IMAGE: "刷脸图片",
    ACS_ACCESS_RECORD_FIELD_IC_NUM: "IC卡号",
    ACS_ACCESS_RECORD_FIELD_QR_CODE: "二维码内容",
    ACS_ACCESS_RECORD_FIELD_TEMPERATURE: "体温",

    // ACS_ACCESS_RECORD_INTERNAL_FACE: "内部人脸",
    // ACS_ACCESS_RECORD_VISITORS_FACE: "访客人脸",
    // ACS_ACCESS_RECORD_STRANGE_FACE: "陌生人脸",
    // ACS_ACCESS_RECORD_INTERNAL_IC: "内部IC卡",
    // ACS_ACCESS_RECORD_VISITORS_IC: "访客IC卡",
    // ACS_ACCESS_RECORD_STRANGE_IC: "陌生IC卡",
    // ACS_ACCESS_RECORD_INTERNAL_QR: "内部二维码",
    // ACS_ACCESS_RECORD_VISITORS_QR: "访客二维码",
    // ACS_ACCESS_RECORD_STRANGE_QR: "陌生人二维码",
    // ACS_ACCESS_RECORD_VENA_DIGITALIS: "内部指静脉",

    ACS_ACCESS_RECORD_TITLE_DELETE: "通行记录删除",
    ACS_ACCESS_RECORD_DELETE_CONFIRM: "通行记录删除确认吗？",
};
export default ja_JP;