import React, {Component} from 'react';
import {DeleteOutlined, FormOutlined} from '@ant-design/icons';

import {injectIntl} from "react-intl";
import {connect} from "react-redux";

import {Button} from "antd";
import {Rnd} from "react-rnd";
import {Modal} from "antd/lib/index";
import {refreshSubjectContentItem} from "../../../actions/SubjectAction";
import RectangleParts from "./RectangleParts";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../../../constants/DateTimeFormats";
import moment from 'moment';
import './SubjectContentItemParts.css'
import {AttentionAssessmentSubjectType} from "../../../Enums";

class SubjectContentItemParts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            currentRectangleItem: {}
        }

    }

    _handleMoveItem(event, rectangle, index) {
        rectangle = Object.assign({}, rectangle);
        rectangle.x = event.x;
        rectangle.y = event.y;

        let contentItem = Object.assign({}, this.props.contentItem);
        let answerKeys = Object.assign([], contentItem.answerKeys);
        answerKeys[index] = rectangle;
        contentItem.answerKeys = answerKeys;
        this.props.dispatch(refreshSubjectContentItem(contentItem));
    }

    _handleResizeItem(style, rectangle, index) {
        rectangle = Object.assign({}, rectangle);
        rectangle.width = parseFloat(style.width.replace("px", ""));
        rectangle.height = parseFloat(style.height.replace("px", ""));

        let contentItem = Object.assign({}, this.props.contentItem);
        let answerKeys = Object.assign([], contentItem.answerKeys);
        answerKeys[index] = rectangle;
        contentItem.answerKeys = answerKeys;
        this.props.dispatch(refreshSubjectContentItem(contentItem));
    }

    _showDeleteConfirm(currentPageItem, index) {
        const self = this;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: "删除确认",
            content: '确认需要删除吗？',
            okText: "OK",
            cancelText: "取消",
            okType: 'danger',
            onOk() {
                self._handleDeleteItem(currentPageItem, index);
            }
        });
    }

    _handleDeleteItem(currentPageItem, index) {

        let contentItem = Object.assign({}, this.props.contentItem);
        let itemList = Object.assign([], contentItem.answerKeys);
        itemList.splice(index, 1);
        contentItem.answerKeys = itemList;
        this.props.dispatch(refreshSubjectContentItem(contentItem));
    }

    _handleAddItemItem() {

        let contentItem = Object.assign({}, this.props.contentItem);
        let itemList = Object.assign([], contentItem.answerKeys);
        let subject = this.props.subject;
        let item = {
            x: 5, y: 1,
            width: 46, height: 100,
        };
        if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT) {
            item.x = 5;
            item.y = 5;
            item.width = 40;
            item.height = 40;

        }if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL) {
            item.x = 5;
            item.y = 2;
            item.width = 45;
            item.height = 87;

        }
        item.key = "rectangle" + moment().format(FORMAT_DATE_TIME_FULL_SIMPLE);
        itemList.push(item);
        contentItem.answerKeys = itemList;
        this.props.dispatch(refreshSubjectContentItem(contentItem));
        this.setState({currentRectangleItem: contentItem})
    }

    render() {
        let contentItem = this.props.contentItem ? this.props.contentItem : {};
        let currentRectangleItem = this.state.currentRectangleItem ? this.state.currentRectangleItem : {};
        let subject = this.props.subject;


        let editOperation = null;
        console.log(contentItem);

        let currentPageStyle = {};
        if (contentItem.imagePath && contentItem.imagePath !== '') {
            currentPageStyle.backgroundImage = `url('${contentItem.imagePath }')`;

        }
        let imageElements = document.getElementsByClassName("content-item-image");

        let height = "107px";
        let width = "671px";
        // let height = "120px";
        if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT||
            subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL) {
            height = "132px";
            width = "167px";
            // let height = "12
        }else if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL) {
            height = "89px";
            width = "1090px";
            // let height = "12
        }
        let rectangleList = contentItem.answerKeys ? contentItem.answerKeys : [];
        return (<div><Button size="small" onClick={() => {
            this._handleAddItemItem();
        }}>添加答案区域</Button>
            <br/>
            <div className="content-item-show-penal" style={{height: height, width: width}}>

                <img src={contentItem.imagePath} className="content-item-image" style={{height: height, width: width}}>

                </img>
                <div className="answer-rectangle-content" style={{height: height, width: width}}>
                    {rectangleList.map((rectangle, index) => {
                        let operationParts = (
                            <div>{editOperation}
                                <Button size="small" style={{marginRight: "5px"}} onClick={(e) => {
                                    this._showDeleteConfirm(rectangle, index);
                                    e.stopPropagation();
                                }}>删除</Button>
                            </div>
                        );
                        let isCurrent = currentRectangleItem &&
                            ((currentRectangleItem.key && currentRectangleItem.key === rectangle.key));
                        return (<div key={index} onClick={(e) => {
                            this.setState({currentRectangleItem: rectangle});
                            e.stopPropagation();
                        }}>
                            <Rnd
                                size={{width: rectangle.width, height: rectangle.height}}
                                position={{x: rectangle.x, y: rectangle.y}}
                                onDragStop={(e, d) => {
                                    e.stopPropagation();
                                    this._handleMoveItem(d, rectangle, index)
                                }}
                                onResizeStop={(e, direction, ref, delta, position) => {
                                    e.stopPropagation();
                                    this._handleResizeItem(ref.style, rectangle, index);
                                }}
                                enableResizing={isCurrent}
                                disableDragging={!isCurrent}
                                className={isCurrent ? "select-box" : ""}
                            ><RectangleParts rectangleItem={rectangle} isCurrent={isCurrent}/>
                                {isCurrent ? operationParts : null}
                            </Rnd>
                        </div>)
                    })}
                </div>
            </div>
        </div>);
    }
}

function mapStateToProps(store) {
    return {
        subject: store.ExamSubjectReducer.subject,
        contentItem: store.ExamSubjectReducer.contentItem,


    }
}

export default connect(mapStateToProps)(injectIntl(SubjectContentItemParts));