import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Space, NavBar, Toast, Button, Form, Input} from 'antd-mobile';
import {getExamineeDetail} from "../../../../actions/ExamineeAction";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {LeftOutline} from "antd-mobile-icons";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            idNumber: ""
        }
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div>
                <NavBar
                    icon={<LeftOutline type="left"/>}
                    onBack={() => this._handleBack()}><FormattedMessage id="EXAM_EXAMINEE_TITLE_VERIFY"/></NavBar>
                <Form
                      layout='horizontal'
                      footer={
                          <Button block color='primary' onClick={() => this._handleSearch()}>
                              <FormattedMessage id="COMMON_BUTTON_SEARCH"/>
                          </Button>
                      }
                >
                    <Form.Item label={this.state.formatMessage({id: "EXAM_EXAMINEE_FIELD_ID_NUMBER"})}>
                        <Input value={this.state.idNumber} onChange={(value) => this.setState({idNumber: value})}
                               placeholder={this.state.formatMessage({id: "EXAM_EXAMINEE_FIELD_ID_NUMBER_PLACEHOLDER"})}/>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    _handleBack() {
        const examinationId = this.props.location.query.examinationId;
        this.props.router.push({
            pathname: RoutePath.EXAM_MOBILE_EXAMINATION.path,
            query: {id: examinationId}
        });
    }

    _handleSearch() {
        const examinationId = this.props.location.query.examinationId;
        if (this.state.idNumber) {
            this.props.dispatch(getExamineeDetail("", this.state.idNumber,
                (data) => {
                    this.props.router.push({
                        pathname: RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY_SUCCESS.path,
                        query: {examinationId: examinationId, examineeId: data.id}
                    })
                },
                (data) => {
                    this.props.router.push({
                        pathname: RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY_FAILED.path,
                        query: {examinationId: examinationId}
                    });
                }));
        }
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));