import moment from "moment";
import * as ActionTypes from '../ActionTypes';
import * as DateTimeFormats from "../../../constants/DateTimeFormats";
import {
    EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECT_SORT_LIST,
    EXAM_EXAMINATION_SUBJECT_TOGGLE_SUBJECT_SORT_MODAL
} from "../ActionTypes";

const initialState = {
    subjects: [],
    subject: {},
    searchCondition: {},
    pagination: {},
    showDetailModal: false,
    showImportModal: false,
    showSubjectSortModal: false,
    subjectSortList: [],
    isLoading: false
};

const ExaminationSubjectReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_TOGGLE_IMPORT_MODAL:
            return Object.assign({}, state, {
                showImportModal: action.showImportModal
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_TOGGLE_SUBJECT_SORT_MODAL:
            return Object.assign({}, state, {
                showSubjectSortModal: action.showSubjectSortModal
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_REFRESH_SUBJECT:
            return Object.assign({}, state, {
                subject: action.subject
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECTS:
            return Object.assign({}, state, {
                subjects: action.subjects,
                pagination: action.pagination
            });
        case ActionTypes.EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECT_SORT_LIST:
            return Object.assign({}, state, {
                subjectSortList: action.subjectSortList
            });
        default:
            return state
    }
};

export default ExaminationSubjectReducer;