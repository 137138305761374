/**
 * Created by Ethan on 2017/5/12.
 */

import {CACHE_ACCESS_TOKEN} from "../constants/Profile";
import {message, Modal} from 'antd';
import {HttpStatus} from "../constants/Enums";
import {RoutePath} from "../constants/RoutePath";

const HttpUtils = {

    /*
     * fetch简单封装
     * url: 请求的URL
     * successCallback: 请求成功回调
     * failCallback: 请求失败回调
     *
     * */
    get: (url, successCallback, failCallback, formatMessage) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === HttpStatus.SUCCESS) {
                    successCallback(responseJson);
                } else {
                    if (responseJson.status === HttpStatus.UNAUTHORIZED) {
                        message.warn(formatMessage({id: responseJson.data}));
                        if (window.location.href.indexOf("?redirect=") < 0) {
                            window.location.href = "/#" + RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path + "?redirect=" + window.location.href;
                        }
                    } else {
                        switch (responseJson.status){
                            case HttpStatus.EXCEPTION:
                                window.location.href = "/#" + RoutePath.COMMON_EXCEPTION_417.path;
                                break;
                            case HttpStatus.FORBIDDEN:
                                window.location.href = "/#" + RoutePath.COMMON_EXCEPTION_403.path;
                                break;
                            case HttpStatus.NOT_FOUND:
                                window.location.href = "/#" + RoutePath.COMMON_EXCEPTION_404.path;
                                break;
                            case HttpStatus.ERROR:
                                window.location.href = "/#" + RoutePath.COMMON_EXCEPTION_500.path;
                                break;
                        }
                        // 如果传入了格式化Message对象，则需要把返回的消息以Message弹出
                        if (formatMessage) {
                            let message = responseJson.data;
                            if (typeof(responseJson.data) !== "string") {
                                message = JSON.stringify(message);
                            } else {
                                message = formatMessage({id: message});
                            }
                            Modal.error({title: "ERROR", content: message, okText: "OK"});
                        }
                        failCallback(responseJson);
                    }
                }
            })
            .catch((error) => {
                message.error("" + error);
            });
    },


    /**
     * 在服务器新建一个资源
     * @param url 服务器资源URL
     * @param params 参数设置
     * @param successCallback 成功返回的方法
     * @param failCallback 失败返回的方法
     */
    post: (url, params, successCallback, failCallback, formatMessage) => {
        HttpUtils.executeFetch("POST", url, params, successCallback, failCallback, formatMessage);
    },

    /**
     * 在服务器更新资源（客户端提供改变后的完整资源）
     * @param url 服务器资源URL
     * @param params 参数设置
     * @param successCallback 成功返回的方法
     * @param failCallback 失败返回的方法
     */
    put: (url, params, successCallback, failCallback, formatMessage) => {
        HttpUtils.executeFetch("PUT", url, params, successCallback, failCallback, formatMessage);
    },

    /**
     * 在服务器删除一个资源
     * @param url 服务器资源URL
     * @param params 参数设置
     * @param successCallback 成功返回的方法
     * @param failCallback 失败返回的方法
     */
    delete: (url, params, successCallback, failCallback, formatMessage) => {
        HttpUtils.executeFetch("DELETE", url, params, successCallback, failCallback, formatMessage);
    },

    executeFetch: (method, url, params, successCallback, failCallback, formatMessage) => {
        fetch(url, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            body: JSON.stringify(params)
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === HttpStatus.SUCCESS) {
                    successCallback(responseJson);
                } else {
                    if (responseJson.status === HttpStatus.UNAUTHORIZED) {
                        if (window.location.href.indexOf("?redirect=") < 0) {
                            window.location.href = "/#" + RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path + "?redirect=" + window.location.href;
                        }
                    } else {
                        console.log(responseJson);
                        let message = responseJson.data;
                        if (typeof(message) !== "string") {
                            message = JSON.stringify(message);
                        } else {
                            message = formatMessage({id: message});
                        }
                        Modal.error({title: "ERROR", content: message, okText: "OK"});
                        failCallback(responseJson);
                    }
                }
            })
            .catch((error) => {
                failCallback(error);
                message.error("" + error);
            });
    },
    getFile: (url, fileName, successCallback, failCallback) => {
        fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                //'App-Key': APP_KEY,
                //'Secret-Key': Secret_Key,
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            }
        }).then((response) => {
            if (response.status === 200) {
                // let fileName = response.url.substr(response.url.lastIndexOf("/") + 1);
                response.blob().then(blob => {
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const aLink = document.createElement('a');
                        document.body.appendChild(aLink);
                        aLink.style.display = 'none';
                        const objectUrl = window.URL.createObjectURL(blob);
                        aLink.href = objectUrl;
                        aLink.download = fileName;
                        aLink.click();
                        document.body.removeChild(aLink);
                    } else {
                        // IE10+下载
                        if (fileName === undefined || fileName === "") {
                            const disposition = response.headers.map["content-disposition"];
                            fileName = disposition.substr(disposition.lastIndexOf("=") + 1);
                        }
                        navigator.msSaveBlob(blob, fileName);
                    }
                });
            } else {
                successCallback(response.json());
            }
        }).catch((error) => {
            console.log(error);
            Modal.error({
                title: "ERROR",
                content: " " + error,
                okText: "OK"
            });
        });
    },

    getQueryString: (param) => {
        let name, value;
        let query = window.location.href.substring(window.location.href.indexOf("?") + 1);
        let arr = query.split("&");
        for (let i = 0; i < arr.length; i++) {
            let num = arr[i].indexOf("=");
            if (num > 0) {
                name = arr[i].substring(0, num);
                value = arr[i].substr(num + 1);
                if (param === name) {
                    return value;
                }
            }
        }
    },

    addQueryString: (url, paramName, paramValue) => {
        if (url.indexOf("?") > 0) {
            // 存在参数，则直接加入
            url = url + "&" + paramName + "=" + paramValue;
        } else {
            url = url + "?" + paramName + "=" + paramValue;
        }
        return url;
    }
};

export default HttpUtils;