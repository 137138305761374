/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Form, Input, Button} from "antd";

import {resetPassword, refreshUser} from "../../../actions/UserAction";
import Validator from "../../../../../utils/Validator";
import {PASSWORD_STRENGTH_LEVEL} from "../../../../../constants/Profile";
import {sendMailVerifyCode} from "../../../../Mail/actions/TemplateAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            seconds: 60,
            canSendVerifyCode: true
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        // 如果存在this.timer，则使用clearTimeout清空。
        // 如果你使用多个timer，那么用多个变量，或者用个数组来保存引用，然后逐个clear
        this.timer && clearInterval(this.timer);
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        user[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshUser(user));
    }


    _handleSendEmailCode() {
        let messageMap = {};
        if (this.props.user.email === undefined || this.props.user.email === '') {
            messageMap["email"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_EMAIL_REQUIRED'});
        } else if (!Validator.isEmail(this.props.user.email)) {
            messageMap["email"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG'});
        }

        this.setState({messageMap: messageMap});

        if (Object.keys(messageMap).length > 0) {
            return;
        }

        this.props.dispatch(sendMailVerifyCode(this.props.user.email, this.state.formatMessage));

        this.timer = setInterval(() => {
            if (this.state.seconds > 0) {
                let seconds = this.state.seconds;
                this.setState({seconds: seconds - 1, canSendVerifyCode: false});
            } else {
                this.setState({seconds: 60, canSendVerifyCode: true});
                clearInterval(this.timer);
            }
        }, 1000);
    }

    _validate(user) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};

        if (user.email === undefined || user.email === "") {
            messageMap["email"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_REQUIRED"});
        } else if (!Validator.isEmail(user.email)) {
            messageMap["email"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG"});
        }
        if (user.emailVerifyCode === null || user.emailVerifyCode === "") {
            messageMap["emailVerifyCode"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_CODE_REQUIRED"});
        }
        if (user.password === undefined || user.password === "") {
            messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_REQUIRED"});
        } else if (user.passwordConfirm === undefined || user.passwordConfirm === "") {
            messageMap["passwordConfirm"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_PASSWORD_CONFIRM_REQUIRED'});
        } else if (user.password !== user.passwordConfirm) {
            messageMap["passwordConfirm"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED'});
        } else if (PASSWORD_STRENGTH_LEVEL >= 2) {
            if (user.password.length < 8) {
                messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED"}, {value: '8'});
            }
            if (PASSWORD_STRENGTH_LEVEL >= 3 && !Validator.isPasswordStrength(user.password)) {
                messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED"});
            }
        }
        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleSubmit() {
        const {dispatch, user} = this.props;
        if (this._validate(user)) {
            dispatch(resetPassword(user, this.state.formatMessage));
        }
    }

    render() {
        const {formatMessage, messageMap} = this.state;

        let verifyCodeAddon = <Button size="small" className="btn-default"
                                      onClick={() => this._handleSendEmailCode()}>
            <FormattedMessage id="ACCOUNT_USER_BUTTON_EMAIL_VERIFY_CODE"/>
        </Button>
        if (!this.state.canSendVerifyCode) {
            verifyCodeAddon = <span>{this.state.seconds}</span>;
        }

        return (
            <div style={{width: 750, margin: "auto auto", paddingTop: '100px'}}>
                <Card size='small'
                      headStyle={{
                          textAlign: 'center',
                          background: 'rgb(228, 0, 43)',
                          color: '#FFFFFF',
                          fontSize: '18px'
                      }}
                      title={formatMessage({id: "ACCOUNT_USER_ROUTE_PASSWORD_FORGOT"})}>
                    <Form>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_EMAIL"})} {...Label_6_Wrapper_18}
                                   required={true}
                                   validateStatus={messageMap["email"] ? "error" : ""}
                                   help={messageMap["email"]}>
                            <Input onChange={(value) => {
                                this._handleChange("email", value)
                            }}/>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({id: "ACCOUNT_USER_FIELD_EMAIL_VERIFY_CODE"})} {...Label_6_Wrapper_18}
                            required={true}
                            validateStatus={this.state.messageMap["emailVerifyCode"] ? "error" : ""}
                            help={messageMap["emailVerifyCode"]}>
                            <Input onChange={(value) => this._handleChange("emailVerifyCode", value)}
                                   addonAfter={verifyCodeAddon}/>
                        </Form.Item>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD"})} {...Label_6_Wrapper_18}
                                   required={true}
                                   validateStatus={messageMap["password"] ? "error" : ""}
                                   help={messageMap["password"]}>
                            <Input type="password"
                                   onChange={(value) => this._handleChange("password", value)}/>
                        </Form.Item>
                        <Form.Item
                            label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD_CONFIRM"})} {...Label_6_Wrapper_18}
                            required={true}
                            validateStatus={messageMap["passwordConfirm"] ? "error" : ""}
                            help={messageMap["passwordConfirm"]}>
                            <Input type="password"
                                   onChange={(value) => this._handleChange("passwordConfirm", value)}/>
                        </Form.Item>
                        <Form.Item className="text-center">
                            <Button type="primary" style={{width: '150px'}} onClick={() => this._handleSubmit()}>
                                <FormattedMessage id="COMMON_BUTTON_SUBMIT"/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        user: store.AccountUserReducer.user
    }
}

export default connect(mapStateToProps)(injectIntl(Index));