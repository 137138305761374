import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Modal, Select, Table} from "antd";
import {handInPaper} from "../../../actions/PaperAction";
import {AttentionAssessmentSubjectType, getEnumsLabel} from "../../../Enums";
import {
    changeLessonExamination,
    getExamExamineeLesson,
    refreshChangeLessonSubjectInfo, toggleChangeLessonSubjectModal
} from "../../../actions/ExamineeLessonAction";

class ChangeLessonSubjectModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedType: "",

        }

    }

    _getColumns(checkLevelItem) {


        let optionList = [];

        let columns = [
            {
                title: "课时名称",
                dataIndex: 'name',
                key: 'name',
                // render: (text, record) => {
                //     return getEnumsLabel(text, AttentionAssessmentSubjectType.Map, this.state.formatMessage)
                // }
            },
            {
                title: "原课程类型",
                dataIndex: 'subjectType',
                key: 'subjectType',
                render: (text, record) => {
                    return getEnumsLabel(text, AttentionAssessmentSubjectType.Map, this.state.formatMessage)
                }
            },
            {
                title: "可调整课程类型",
                dataIndex: 'newSubjectType',
                key: 'newSubjectType',
                render: (text, record, index) => {
                    if (checkLevelItem.isUpStandard) {
                        optionList = AttentionAssessmentSubjectType.TrainingChangeSubjectList[record.subjectType];
                    } else {
                        optionList = AttentionAssessmentSubjectType.TrainingChangeSubjectDownStandardList[record.subjectType];
                    }
                    return <Select
                        allowClear={true}
                        value={record.newSubjectType}
                        style={{width: '180px'}}
                        onChange={(value) => this._handleChangeSubjectType(value, index, checkLevelItem.subjectType)}>
                        {
                            optionList.map((item, thisIndex) => {
                                return <Select.Option key={thisIndex} value={item}>
                                    {getEnumsLabel(item, AttentionAssessmentSubjectType.Map, this.state.formatMessage)}
                                </Select.Option>
                            })
                        }
                    </Select>
                }
            }];
        return columns;
    }

    _handleChangeSubjectType(value, index, standardType) {
        let changeLessonSubjectInfo = Object.assign({}, this.props.changeLessonSubjectInfo);


        let examList = Object.assign([], changeLessonSubjectInfo[standardType]);
        let assign = Object.assign({}, examList[index]);
        assign.newSubjectType = value && value.target ? value.target.value : value;
        examList[index] = assign;
        changeLessonSubjectInfo[standardType] = examList;
        this.props.dispatch(refreshChangeLessonSubjectInfo(changeLessonSubjectInfo));

    }

    componentWillMount() {

    }

    _handleSubmit() {
        let examineeLesson = this.props.examineeLesson;
        let lessonId = examineeLesson.id;
        let changeValueInfo = [];
        const changeLessonSubjectInfo = Object.assign({}, this.props.changeLessonSubjectInfo);
        Object.keys(changeLessonSubjectInfo).forEach(subjectType => {
            let itemList = changeLessonSubjectInfo[subjectType];
            itemList.forEach(item=>{
                if (item.newSubjectType !== undefined && item.newSubjectType !== "") {
                    changeValueInfo.push(item)
                }
            })

        });
        console.log(changeValueInfo)
        this.props.dispatch(changeLessonExamination(lessonId, changeValueInfo, this.state.formatMessage, () => {
            this.props.dispatch(getExamExamineeLesson(lessonId, this.state.formatMessage, true));
            this._handleCancel();
        }));
    }

    _handleCancel() {
        this.props.dispatch(toggleChangeLessonSubjectModal(false));

    }

    render() {

        const formatMessage = this.state.formatMessage;

        let changeLessonSubjectInfo = this.props.changeLessonSubjectInfo ? this.props.changeLessonSubjectInfo : {};
        console.log(changeLessonSubjectInfo)

        let checkLevelList = this.props.checkLevelList ? this.props.checkLevelList : [];
        return (

            <Modal
                maskClosable={false}
                width={700}
                title={"调整课时"}
                visible={this.props.showChangeLessonSubjectModal}
                onOk={() => this._handleSubmit()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <span style={{color: 'red'}}>
                {checkLevelList.map(item => {
                    let message = null;
                    if (item.isUpStandard) {
                        message = <span
                            style={{color: 'red'}}>【{getEnumsLabel(item.subjectType, AttentionAssessmentSubjectType.Map, this.state.formatMessage)}】 已有分数超过记分标准</span>
                    }
                    if (item.isDownStandard) {
                        message = <span
                            style={{color: 'red'}}>【{getEnumsLabel(item.subjectType, AttentionAssessmentSubjectType.Map, this.state.formatMessage)}】还未达到标准分</span>
                    }
                    let examList = changeLessonSubjectInfo[item.subjectType];
                    return <div>
                        {message}<br/>
                        {examList ?
                            <Table rowKey="id" size="small" columns={this._getColumns(item)} dataSource={examList}
                                   pagination={false}/> : "无可调整的课程"}
                    </div>

                })}


                </span>
            </Modal>
        );
    }

}

function mapStateToProps(store) {
    return {
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        relatedExaminationList: store.ExamineeLessonReducer.relatedExaminationList,
        changeLessonSubjectInfo: store.ExamineeLessonReducer.changeLessonSubjectInfo,
        showChangeLessonSubjectModal: store.ExamineeLessonReducer.showChangeLessonSubjectModal,

    }
}

export default connect(mapStateToProps)(injectIntl(ChangeLessonSubjectModal));