import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {CACHE_ACCESS_TOKEN, CACHE_SESSION_USER} from '../../../constants/Profile';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';
import {RoutePath} from "../../../constants/RoutePath";
import {readFileById} from "../../FTP/WebService";

export function refreshUser(user) {
    return {
        type: ActionTypes.USER_REFRESH_USER,
        user: user
    }
}

export function refreshUsers(users) {
    return {
        type: ActionTypes.USER_REFRESH_USERS,
        users: users
    }
}

export function refreshSessionUser(sessionUser) {
    localStorage.setItem(CACHE_SESSION_USER, JSON.stringify(sessionUser));
    return {
        type: ActionTypes.USER_REFRESH_SESSION_USER,
        sessionUser: sessionUser
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.USER_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleDetailModal(showDetailModal) {
    return {
        type: ActionTypes.USER_TOGGLE_DETAIL_MODAL,
        showDetailModal: showDetailModal
    }
}

export function togglePasswordChangeModal(show) {
    return {
        type: ActionTypes.USER_TOGGLE_PASSWORD_CHANGE_MODAL,
        showPasswordChangeModal: show
    }
}

export function togglePasswordResetModal(show) {
    return {
        type: ActionTypes.USER_TOGGLE_PASSWORD_RESET_MODAL,
        showPasswordResetModal: show
    }
}

export function getUser(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        let requestURL = WebService.getUser.replace("{id}", id);

        HttpUtils.get(requestURL, (response) => {
            if (response.data.portraitFile !== undefined && response.data.portraitFile !== "") {
                HttpUtils.get(readFileById.replace("{id}", response.data.portraitFile), (ftpResponse) => {
                    response.data.portrait = ftpResponse.data;
                    dispatch(refreshUser(response.data));
                }, (response) => {
                }, formatMessage)
            } else {
                dispatch(refreshUser(response.data));
            }
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getSessionUser(formatMessage, router) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});
        HttpUtils.get(WebService.getSessionUser, (response) => {
            if (response.data.portraitFile !== undefined && response.data.portraitFile !== "") {
                HttpUtils.get(readFileById.replace("{id}", response.data.portraitFile), (ftpResponse) => {
                    response.data.portrait = ftpResponse.data;
                    dispatch(refreshSessionUser(response.data));
                }, (response) => {
                }, formatMessage)
            } else {
                dispatch(refreshSessionUser(response.data));
            }

            if (router) {
                // 获得重定向参数
                let redirect = HttpUtils.getQueryString("redirect");
                if (redirect !== undefined && redirect !== "") {
                    window.location.href = redirect;
                } else {
                    router.push(RoutePath.EXAM_BANK_LIST.path);
                }
            }

            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function searchUsers(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchUsers, condition, (response) => {
            dispatch({
                type: ActionTypes.USER_SEARCH,
                users: response.data,
                searchCondition: condition,
                pagination: response.pagination
            });
            dispatch(refreshSearchCondition(condition));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createUser(user, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.createUser, user, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(refreshUser({}));
            dispatch(searchUsers(condition, formatMessage));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function registerUser(user, router, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.registerUser, user, (response) => {
            dispatch(refreshUser({}));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_CREATE_SUCCESS"}));
            router.push(RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path);
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function resetPassword(user, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        let requestUrl = HttpUtils.addQueryString(WebService.resetPassword, "verifyType", "email");
        requestUrl = HttpUtils.addQueryString(requestUrl, "verifyCode", user.emailVerifyCode);
        HttpUtils.post(requestUrl, user, (response) => {
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function resetDefaultPassword(user, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        const requestUrl = WebService.resetDefaultPassword.replace("{id}", user.id);

        HttpUtils.put(requestUrl, user, (response) => {
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
            dispatch(togglePasswordResetModal(false));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateUser(user, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        let requestURL = WebService.updateUser.replace("{id}", user.id);

        HttpUtils.put(requestURL, user, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(refreshUser({}));
            dispatch(searchUsers(condition, formatMessage));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateSessionUser(user, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateSessionUser, user, (response) => {
            if (response.data.portraitFile !== undefined && response.data.portraitFile !== "") {
                HttpUtils.get(readFileById.replace("{id}", response.data.portraitFile), (ftpResponse) => {
                    response.data.portrait = ftpResponse.data;
                    dispatch(refreshUser(response.data));
                    dispatch(refreshSessionUser(response.data));
                }, (response) => {
                }, formatMessage)
            } else {
                dispatch(refreshUser(response.data));
                dispatch(refreshSessionUser(response.data));
            }
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateSessionUserPassword(user, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateSessionUserPassword, user, (response) => {
            dispatch(togglePasswordChangeModal(false));
            dispatch(refreshUser(response.data));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteUser(user, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});
        HttpUtils.post(WebService.deleteUser, user, (response) => {
            dispatch(searchUsers(condition, formatMessage));
            message.success(formatMessage({id: "ACCOUNT_USER_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}

export function login(user, router, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.USER_LOADING, isLoading: true});

        HttpUtils.post(WebService.createToken, user, (response) => {
            localStorage.setItem(CACHE_ACCESS_TOKEN, response.data.accessToken);
            dispatch(getSessionUser(formatMessage, router));
            // // 获得重定向参数
            // let redirect = HttpUtils.getQueryString("redirect");
            // if (redirect !== undefined && redirect !== "") {
            //     // redirect = HttpUtils.addQueryString(redirect, "token", response.data.accessToken);
            //     window.parent.location.href = redirect;
            // } else {
            //     router.push(RoutePath.CMS_SITE_LIST.path);
            // }
            dispatch({type: ActionTypes.USER_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function logout() {
    return dispatch => {
        localStorage.removeItem(CACHE_ACCESS_TOKEN);
    }
}