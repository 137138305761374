export function getEnumsLabel(code, map, formatMessage) {
    if (code && code !== '') {
        if (map[code]) {
            return formatMessage({id: map[code]});
        }
    }
    return "";
}


export const ExamineeGender = {
    List: [
        {value: "01", label: "ORG_EMPLOYEE_FIELD_SEX_MALE"},
        {value: "02", label: "ORG_EMPLOYEE_FIELD_SEX_FEMALE"}
    ],
    Map: {
        "01": "ORG_EMPLOYEE_FIELD_SEX_MALE",
        "02": "ORG_EMPLOYEE_FIELD_SEX_FEMALE"
    },
    Enum: {
        Male: "01",
        Female: "02",
    }
};
export const SubjectType = {
    List: [
        {value: "01", label: "EXAM_SUBJECT_FIELD_TYPE_SINGLE_CHOICE"},
        {value: "02", label: "EXAM_SUBJECT_FIELD_TYPE_MULTIPLE_CHOICE"},
        {value: "03", label: "EXAM_SUBJECT_FIELD_TYPE_JUDGMENT"},
        {value: "04", label: "EXAM_SUBJECT_FIELD_TYPE_QA"},
        {value: "05", label: "EXAM_SUBJECT_FIELD_TYPE_VIDEO"},
        {value: "21", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_GO_NO"},
        {value: "22", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_PAY_ATTENTION"},
        {value: "23", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF"},
        {value: "24", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT"},
        {value: "25", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL"},
        {value: "26", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL"},

    ],
    Map: {
        "01": "EXAM_SUBJECT_FIELD_TYPE_SINGLE_CHOICE",
        "02": "EXAM_SUBJECT_FIELD_TYPE_MULTIPLE_CHOICE",
        "03": "EXAM_SUBJECT_FIELD_TYPE_JUDGMENT",
        "04": "EXAM_SUBJECT_FIELD_TYPE_QA",
        "05": "EXAM_SUBJECT_FIELD_TYPE_VIDEO",
        "21": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_GO_NO",
        "22": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_PAY_ATTENTION",
        "23": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF",
        "24": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT",
        "25": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL",
        "26": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL",
        "31": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION",
        "32": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING",
        "33": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SHIP_FLANKER",
        "34": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_ARROW_FLANKER",
        "35": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT",

    },
    ShowList: [{value: "21", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_GO_NO"},
        {value: "22", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_PAY_ATTENTION"},
        {value: "23", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF"},
        {value: "24", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT"},],
    Enum: {
        SINGLE_CHOICE: "01",
        MULTIPLE_CHOICE: "02",
        JUDGMENT: "03",
        QA: "04",
        VIDEO: "05",
        ATTENTION_ASSESSMENT_GO_NO: "21",
        ATTENTION_ASSESSMENT_PAY_ATTENTION: "22",
        ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF: "23",
        ATTENTION_ASSESSMENT_FIND_IT: "24",
        ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL: "25",
    },
};


export const ExaminationStatus = {
    List: [
        {value: "00", label: "EXAM_EXAMINATION_STATUS_NEW"},
        {value: "01", label: "EXAM_EXAMINATION_STATUS_IN_PROGRESS"},
        {value: "02", label: "EXAM_EXAMINATION_STATUS_COMPLETED"},
        {value: "03", label: "EXAM_EXAMINATION_STATUS_CANCELED"},
    ],
    Map: {
        "00": "EXAM_EXAMINATION_STATUS_NEW",
        "01": "EXAM_EXAMINATION_STATUS_IN_PROGRESS",
        "02": "EXAM_EXAMINATION_STATUS_COMPLETED",
        "03": "EXAM_EXAMINATION_STATUS_CANCELED"
    },
    Enum: {
        New: "00",
        InProgress: "01",
        Completed: "02",
        Canceled: "03",
    },
};

export const PaperStatus = {
    List: [
        {value: "01", label: "EXAM_EXAMINATION_STATUS_NEW"},
        {value: "02", label: "EXAM_EXAMINATION_STATUS_IN_PROGRESS"},
    ],
    Map: {
        "01": "EXAM_EXAMINATION_STATUS_IN_PROGRESS",
        "02": "EXAM_EXAMINATION_STATUS_COMPLETED",
    },
    Enum: {
        New: "00",
        InProgress: "01",
        Completed: "02",
        Timeout: "03",
        Canceled: "04"
    },
};

export const ExamPaperType = {
    List: [
        {value: "01", label: "EXAM_EXAMINATION_TYPE_EVALUATION"},
        {value: "02", label: "EXAM_EXAMINATION_TYPE_TRAINING"},
    ],
    Map: {
        "01": "EXAM_EXAMINATION_TYPE_EVALUATION",
        "02": "EXAM_EXAMINATION_TYPE_TRAINING",
    },
    Enum: {
        Evaluation: "01",
        Training: "02"
    }
}

export const PaperSubjectStatus = {
    List: [
        {value: "00", label: "EXAM_PAPER_SUBJECT_STATUS_NEW"},
        {value: "01", label: "EXAM_PAPER_SUBJECT_STATUS_RIGHT"},
        {value: "02", label: "EXAM_PAPER_SUBJECT_STATUS_WRONG"},
        {value: "03", label: "EXAM_PAPER_SUBJECT_STATUS_MISS"},

    ],
    Map: {
        "00": "EXAM_PAPER_SUBJECT_STATUS_NEW",
        "01": "EXAM_PAPER_SUBJECT_STATUS_RIGHT",
        "02": "EXAM_PAPER_SUBJECT_STATUS_WRONG",
        "03": "EXAM_PAPER_SUBJECT_STATUS_MISS",
    },
    Enum: {
        New: "00",
        Right: "01",
        Wrong: "02",
        Miss: "03"
    },
};

export const ExamDataModelType = {
    List: [
        // {value: "00", label: "EXAM_EXAMINATION_STATUS_NEW"},
        {value: "11", label: "EXAM_DATA_MODEL_TYPE_ANT_1"},
        {value: "12", label: "EXAM_DATA_MODEL_TYPE_ANT_2"},
        {value: "13", label: "EXAM_DATA_MODEL_TYPE_ANT_3"},
        {value: "20", label: "EXAM_DATA_MODEL_TYPE_GO_NO_GO"},
        {value: "30", label: "EXAM_DATA_MODEL_TYPE_NUMBER"},
        {value: "40", label: "EXAM_DATA_MODEL_TYPE_FIND"},
    ],
    ShowMap: {
        "11": {
            value: "11",
            label: "EXAM_DATA_MODEL_TYPE_ANT_1",
            scoreLabel: "注意警觉",
            scoreInfo: "长时间保持在警觉状态，能够对面前出现的需要加以注意的信息进行快速判断。",
            scoreInfo2: "注意警觉能力好的儿童，能够在相对枯燥的上课或做题过程中，始终保持对重要信息的密切关注。相反，警觉能力弱的儿童，则容易在听课或解题过程出现松懈，而遗漏掉需要加以注意的内容。",

            score: "antScore1",
            scoreInModel: "antScore1InModel",
            isFinish: "antIsFinish"
        },
        "12": {
            value: "12",
            label: "EXAM_DATA_MODEL_TYPE_ANT_2",
            scoreLabel: "注意定向",
            scoreInfo: "在面前出现的来自四面八方的各种信息中，快速识别和确定重要信息所处的粗略位置，并对其进行注意和精细加工的能力。",
            scoreInfo2: "注意定向能力好的儿童在解题时，能从冗长的题目中快速确定与解题相关的重要信息所处的位置，为大脑对其进行进一步的精细加工创造了条件。相反，定向能力弱的儿童，则容易忽略掉重要的信息，出现拖沓、审题不仔细等情况。",

            score: "antScore2",
            scoreInModel: "antScore2InModel",
            isFinish: "antIsFinish"
        },
        "13": {
            value: "13",
            label: "EXAM_DATA_MODEL_TYPE_ANT_3",
            scoreLabel: "注意控制",
            scoreInfo: "完成任务时，根据任务要求进行自我控制并灵活调整自身行为的能力。",
            scoreInfo2: "注意控制能力好的儿童明确知道完成某项任务要做什么，以及不要做什么，能够很好地控制自己的行为。相反，控制能力弱的儿童在上课或做题时，会不由自主地做小动作，他们 “控制不住自己”，更容易分心走神，也更容易情绪失控，冲动发火。",
            score: "antScore3",
            scoreInModel: "antScore3InModel",
            isFinish: "antIsFinish"
        },
        "20": {
            value: "20",
            label: "EXAM_DATA_MODEL_TYPE_GO_NO_GO",
            scoreLabel: "抗干扰",
            scoreInfo: "在完成任务时，面对分心的事物和干扰的信息的抵抗能力。",
            scoreInfo2: "抗干扰能力好的儿童，不容易受到与他/她无关的事情干扰。相反，如果抗干扰能力弱，则很容易受外界信息干扰，窗外的鸟叫声、周围的说话声都有可能将注意力“带偏”，从而影响学习时的效率。",
            score: "goNoGoScore",
            scoreInModel: "goNoGoScoreInModel",
            isFinish: "goNoGoIsFinish"
        },
        "30": {
            value: "30",
            label: "EXAM_DATA_MODEL_TYPE_NUMBER",
            scoreLabel: "注意稳定性",
            scoreInfo: "将注意力长时间高度集中在某项活动或任务中的能力。",
            scoreInfo2: "注意稳定性好的儿童，能够长时间将注意力高度集中在一件事情上，从头到尾不分心。相反，稳定性弱的儿童则很难长时间全神贯注地集中在一件事上，他们的注意力状态时好时坏、断断续续，在上课或做题时容易错漏信息，分心走神。",
            score: "digitalScore",
            scoreInModel: "digitalScoreInModel",
            isFinish: "digitalIsFinish"
        },
        "40": {
            value: "40",
            label: "EXAM_DATA_MODEL_TYPE_FIND",
            scoreLabel: "注意选择性",
            scoreInfo: "当儿童面对外界输入的大量信息时，能够根据任务情境的需要灵活选择和切换注意的具体对象，而排除其他无关的信息。",
            scoreInfo2: "注意选择性好的儿童，能够高效地接受和处理重要的信息，上课时可以在老师、书本和黑板等目标对象间选择重要的信息加以注意。相反，注意选择性弱的儿童，上课时会不受控制地被外界各种无关信息所影响，无法做到在多个注意对象间进行正确选择和及时转换，上课效率较低。",
            score: "findItScore",
            scoreInModel: "findItScoreInModel",
            isFinish: "findItIsFinish"
        },
    },
    Map: {
        "11": "EXAM_DATA_MODEL_TYPE_ANT_1",
        "12": "EXAM_DATA_MODEL_TYPE_ANT_2",
        "13": "EXAM_DATA_MODEL_TYPE_ANT_3",
        "20": "EXAM_DATA_MODEL_TYPE_GO_NO_GO",
        "30": "EXAM_DATA_MODEL_TYPE_NUMBER",
        "40": "EXAM_DATA_MODEL_TYPE_FIND"

    },
    Enum: {
        ANT_1: "11",
        ANT_2: "12",
        ANT_3: "13",
        GO_NO_GO: "20",
        NUMBER: "30",
        FIND: "40",

    },
};

export const ExamDataModelGrade = {
    List: [
        {value: "01", label: "EXAM_DATA_MODEL_GRADE_1"},
        {value: "02", label: "EXAM_DATA_MODEL_GRADE_2"},
        {value: "03", label: "EXAM_DATA_MODEL_GRADE_3"},
        {value: "04", label: "EXAM_DATA_MODEL_GRADE_4"},
        {value: "05", label: "EXAM_DATA_MODEL_GRADE_5"},
    ],
    Map: {
        // "00": "EXAM_EXAMINATION_STATUS_NEW",
        "01": "EXAM_DATA_MODEL_GRADE_1",
        "02": "EXAM_DATA_MODEL_GRADE_2",
        "03": "EXAM_DATA_MODEL_GRADE_3",
        "04": "EXAM_DATA_MODEL_GRADE_4",
        "05": "EXAM_DATA_MODEL_GRADE_5"
    },
    Enum: {
        GRADE_1: "01",
        GRADE_2: "02",
        GRADE_3: "03",
        GRADE_4: "04",
        GRADE_5: "05",
    },
};

export const ANTClueType = {
    List: [
        {value: "00", label: "EXAM_ANT_CLUE_TYPE_NO"},
        {value: "01", label: "EXAM_ANT_CLUE_TYPE_SPATIAL"},
        {value: "02", label: "EXAM_ANT_CLUE_TYPE_MIDDLE"},

    ],
    Map: {
        "00": "EXAM_ANT_CLUE_TYPE_NO",
        "01": "EXAM_ANT_CLUE_TYPE_SPATIAL",
        "02": "EXAM_ANT_CLUE_TYPE_MIDDLE",

    },
    Enum: {
        No: "00",
        Spatial: "01",
        Middle: "02",

    },
};
export const AppearPosition = {
    List: [
        {value: "01", label: "EXAM_APPEAR_POSITION_TOP"},
        {value: "02", label: "EXAM_APPEAR_POSITION_CENTER"},

        {value: "03", label: "EXAM_APPEAR_POSITION_BOTTOM"},
    ],
    Map: {

        "01": "EXAM_APPEAR_POSITION_TOP",
        "02": "EXAM_APPEAR_POSITION_CENTER",
        "03": "EXAM_APPEAR_POSITION_BOTTOM"
    },
    Enum: {
        TOP: "01",
        CENTER: "02",
        BOTTOM: "02",

    },
};
export const MiddleDirection = {
    List: [
        {value: "01", label: "EXAM_MIDDLE_DIRECTION_LEFT"},
        {value: "02", label: "EXAM_MIDDLE_DIRECTION_RIGHT"},


    ],
    Map: {

        "01": "EXAM_MIDDLE_DIRECTION_LEFT",
        "02": "EXAM_MIDDLE_DIRECTION_RIGHT"
    },
    Enum: {
        LEFT: "01",
        RIGHT: "02",
    },
};
export const ANTMiddleSideConsistent = {
    List: [
        {value: "0", label: "EXAM_ANT_MIDDLE_SIDE_CONSISTENT_NO"},
        {value: "1", label: "EXAM_ANT_MIDDLE_SIDE_CONSISTENT_YES"},


    ],
    Map: {
        "0": "EXAM_ANT_MIDDLE_SIDE_CONSISTENT_NO",
        "1": "EXAM_ANT_MIDDLE_SIDE_CONSISTENT_YES",
    },
    Enum: {
        No: "0",
        YES: "1",
    },
};
export const SubjectImagePageIndex = {
    List: [
        {value: 1, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_1"},
        {value: 2, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_2"},
        {value: 3, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_3"},
        {value: 4, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_4"},
        {value: 5, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_5"},
    ],
    FindItList: [
        {value: 1, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_1"},
        {value: 2, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_2"},
        {value: 3, label: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_3"},
    ],
    Map: {
        1: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_1",
        2: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_2",
        3: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_3",
        4: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_4",
        5: "EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_5",

    },
    Enum: {
        PAGE1: 1,
        PAGE2: 2,
        PAGE3: 3,
        PAGE4: 4,
        PAGE5: 5,

    },
};
// 注意力评估工具的题目类型
//因为引用关系,多加了一个，和SubjectType一样。
export const AttentionAssessmentSubjectType = {
    List: [
        {value: "21", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_GO_NO"},
        {value: "22", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_PAY_ATTENTION"},
        {value: "23", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF"},
        {value: "24", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT"},
        {value: "25", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL"},
        {value: "26", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL"},

        {value: "31", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION"},
        {value: "32", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING"},
        {value: "33", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SHIP_FLANKER"},
        {value: "34", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_ARROW_FLANKER"},
        {value: "35", label: "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT"},

    ],
    Map: {
        "21": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_GO_NO",
        "22": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_PAY_ATTENTION",
        "23": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF",
        "24": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT",
        "25": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL",
        "26": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL",
        "31": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION",
        "32": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING",
        "33": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SHIP_FLANKER",
        "34": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_ARROW_FLANKER",
        "35": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT",
        "311": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION_1",
        "312": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION_2",
        "313": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION_3",
        "321": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING_1",
        "322": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING_2",
        "323": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING_3",
        "351": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT_1",
        "352": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT_2",
        "353": "EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT_3",
    },
    TrainingShowList: [
        "34",
        "351",
        "352",
        "353",
        "33",
        "311",
        "312",
        "313",
        "321",
        "322",
        "323",




    ],
    Enum: {
        ATTENTION_ASSESSMENT_GO_NO: "21",
        ATTENTION_ASSESSMENT_PAY_ATTENTION: "22",
        ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF: "23",
        ATTENTION_ASSESSMENT_FIND_IT: "24",
        ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL: "25",
        ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL: "26",
        ATTENTION_TRAINING_VISION: "31",
        ATTENTION_TRAINING_VISION1: "311",
        ATTENTION_TRAINING_VISION2: "312",
        ATTENTION_TRAINING_VISION3: "313",

        ATTENTION_TRAINING_HEARING: "32",
        ATTENTION_TRAINING_HEARING1: "321",
        ATTENTION_TRAINING_HEARING2: "322",
        ATTENTION_TRAINING_HEARING3: "323",
        ATTENTION_TRAINING_SHIP_FLANKER: "33",
        ATTENTION_TRAINING_ARROW_FLANKER: "34",
        ATTENTION_TRAINING_SQUIRREL_CPT: "35",
        ATTENTION_TRAINING_SQUIRREL_CPT1: "351",
        ATTENTION_TRAINING_SQUIRREL_CPT2: "352",
        ATTENTION_TRAINING_SQUIRREL_CPT3: "353",

    },
    DateModelTypeMap: {
        "21": {value: "21", modelDataTypeList: [ExamDataModelType.Enum.GO_NO_GO], label1: "抗干扰", label2: "注意抗干扰"},
        "22": {
            value: "22",
            modelDataTypeList: [ExamDataModelType.Enum.ANT_1, ExamDataModelType.Enum.ANT_2, ExamDataModelType.Enum.ANT_3],
            label1: "警觉、定向、控制", label2: "注意警觉",
        },
        "23": {
            value: "23", modelDataTypeList: [ExamDataModelType.Enum.NUMBER],
            label1: "稳定", label2: "注意稳定性",
        },
        "24": {
            value: "24", modelDataTypeList: [ExamDataModelType.Enum.FIND],
            label1: "选择性", label2: "注意选择性",
        },
        "25": {
            value: "25", modelDataTypeList: [ExamDataModelType.Enum.NUMBER],
            label1: "稳定", label2: "注意稳定性",
        },
        "26": {
            value: "26", modelDataTypeList: [ExamDataModelType.Enum.FIND],
            label1: "选择性", label2: "注意选择性",
        },
    },
    TrainingShowInfoMap: {
        "311": [ExamDataModelType.Enum.NUMBER],//注意稳定性
        "312": [ExamDataModelType.Enum.NUMBER],//注意稳定性
        "313": [ExamDataModelType.Enum.NUMBER],//注意稳定性
        "321": [ExamDataModelType.Enum.NUMBER],//注意稳定性
        "322": [ExamDataModelType.Enum.NUMBER],//注意稳定性
        "323": [ExamDataModelType.Enum.NUMBER],//注意稳定性
        "33": [ExamDataModelType.Enum.ANT_3, ExamDataModelType.Enum.GO_NO_GO, ExamDataModelType.Enum.NUMBER],//注意控制、注意抗干扰、注意稳定性、
        "34": [ExamDataModelType.Enum.ANT_3, ExamDataModelType.Enum.GO_NO_GO, ExamDataModelType.Enum.NUMBER],//注意控制、注意抗干扰、注意稳定性、
        "35": [ExamDataModelType.Enum.ANT_1],//注意警觉
        "351": [ExamDataModelType.Enum.ANT_1],//注意警觉
        "352": [ExamDataModelType.Enum.ANT_1],//注意警觉
        "353": [ExamDataModelType.Enum.ANT_1],//注意警觉


    },
    //检查分数是否完成达标
    TrainingCheckScoreLevel: {
        "33": {grade1: 75, grade2: 85},
        "34": {grade1: 75, grade2: 85},
        "311": {grade1: 75, grade2: 85},
        "312": {grade1: 75, grade2: 85},
        "313": {grade1: 65, grade2: 75},
        "321": {grade1: 75, grade2: 85},
        "322": {grade1: 75, grade2: 85},
        "323": {grade1: 65, grade2: 75},
        "351": {grade1: 80, grade2: 90},
        "352": {grade1: 80, grade2: 90},
        "353": {grade1: 75, grade2: 85},
    },
    //课程调整,上升级别
    TrainingChangeSubjectList: {
        "311": ["312", "313"],
        "312": ["313"],
        "321": ["322", "323"],
        "322": ["323"],
        "351": ["352", "353"],
        "352": ["353"],
    },
    //课程调整,下降级别
    TrainingChangeSubjectDownStandardList: {
        "313": ["311", "312"],
        "312": ["311"],
        "323": ["321", "322"],
        "322": ["321"],
        "353": ["351", "352"],
        "352": ["351"],
    }

};


export const ExaminationClassifyEnums = {
    List: [
        {value: "01", label: "EXAM_EXAMINATION_CLASSIFY_EVALUATION"},
        {value: "02", label: "EXAM_EXAMINATION_CLASSIFY_TRAINING"},
    ],
    Map: {
        "01": "EXAM_EXAMINATION_CLASSIFY_EVALUATION",
        "02": "EXAM_EXAMINATION_CLASSIFY_TRAINING",
    },
    Enum: {
        Evaluation: "01",
        Training: "02"
    }
}

export const KeyboardCode = {
    Enum: {
        Enter: "66",
        Space: '62',
        Left: '21',
        Right: '22',
        M: '41',
        Z: '54',
        Ctrl: '113'
    },
    Map: {
        "66": "{Enter}",
        "62": "{Space}",
        "21": "{←}",
        "22": "{→}",
        "41": "{M}",
        "54": "{Z}",
        "113": "{Ctrl}",

    },
};

