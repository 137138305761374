import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message, Modal} from 'antd';

export function toggleModal(isShow) {
    return {
        type: ActionTypes.EXAM_PAPER_SUBJECT_TOGGLE_MODAL,
        showModal: isShow
    }
}

export function refreshSubject(subject) {
    return {
        type: ActionTypes.EXAM_PAPER_SUBJECT_REFRESH_SUBJECT,
        subject: subject
    }
}

export function refreshSubjects(subjects) {
    return {
        type: ActionTypes.EXAM_PAPER_SUBJECT_REFRESH_SUBJECTS,
        subjects: subjects
    }
}

export function refreshCurrentIndex(index) {
    return {
        type: ActionTypes.EXAM_PAPER_SUBJECT_REFRESH_CURRENT_INDEX,
        currentIndex: index
    }
}

export function getSubject(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});

        const requestURL = HttpUtils.addQueryString(WebService.getSubject, "id", id);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshSubject(response.data));
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

//TODO：这不是在取ExaminationSubject吗？为什么在PaperSubjectAction中？？
export function getSubjects(examinationId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});

        let requestURL = WebService.getSubjects.replace("{examinationId}", examinationId);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshSubjects(response.data));
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createSubject(subject, examinationId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});

        let requestURL = WebService.createSubject.replace("{examinationId}", examinationId);
        HttpUtils.post(requestURL, subject, (response) => {
            dispatch(getSubjects(examinationId, formatMessage));
            message.success(formatMessage({id: "EXAM_PAPER_SUBJECT_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updatePaperSubject(subject, formatMessage,refreshFunction) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});

        let requestURL = WebService.updatePaperSubject;

        HttpUtils.post(requestURL, subject, (response) => {


            if(refreshFunction){
                refreshFunction.call();
            }
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteSubject(id, examinationId, formatMessage) {
    return dispatch => {
        let requestURL = WebService.deleteSubject.replace("{id}", id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(getSubjects(examinationId, formatMessage));
            message.success(formatMessage({id: "EXAM_PAPER_SUBJECT_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, formatMessage);
    }
}