export const ORG_PROPERTY_COMPANY_SEARCH = 'ORG_PROPERTY_COMPANY_SEARCH';

// 组织
export const ORG_ORGANIZATION_LOADING = 'ORG_ORGANIZATION_LOADING';
export const ORG_ORGANIZATION_TREE = 'ORG_ORGANIZATION_TREE';
export const ORG_ORGANIZATION_REFRESH_ID_MAP = 'ORG_ORGANIZATION_REFRESH_ID_MAP';
export const ORG_ORGANIZATION_TREE_SELECT = 'ORG_ORGANIZATION_TREE_SELECT';
export const ORG_ORGANIZATION_LIST_SELECT = 'ORG_ORGANIZATION_LIST_SELECT';
export const ORG_ORGANIZATION_SEARCH = "ORG_ORGANIZATION_SEARCH";
export const ORG_ORGANIZATION_TOGGLE_DETAIL_MODAL = "ORG_ORGANIZATION_TOGGLE_DETAIL_MODAL";
export const ORG_ORGANIZATION_REFRESH_ORGANIZATION = "ORG_ORGANIZATION_REFRESH_ORGANIZATION";
export const ORG_ORGANIZATION_REFRESH_SEARCH_CONDITION = "ORG_ORGANIZATION_REFRESH_SEARCH_CONDITION";
export const ORG_ORGANIZATION_REFRESH_EXPANDED_KEYS = "ORG_ORGANIZATION_REFRESH_EXPANDED_KEYS";

// 员工
export const ORG_EMPLOYEE_LOADING = 'ORG_EMPLOYEE_LOADING';
export const ORG_EMPLOYEE_REFRESH_SEARCH_CONDITION = "ORG_EMPLOYEE_REFRESH_SEARCH_CONDITION";
export const ORG_EMPLOYEE_REFRESH_EMPLOYEE = 'ORG_EMPLOYEE_REFRESH_EMPLOYEE';
export const ORG_EMPLOYEE_REFRESH_EMPLOYEE_LIST = 'ORG_EMPLOYEE_REFRESH_EMPLOYEE_LIST';
export const ORG_EMPLOYEE_SEARCH = 'ORG_EMPLOYEE_SEARCH';
export const ORG_EMPLOYEE_REFRESH_EMPLOYEE_SELECTOR_LIST = 'ORG_EMPLOYEE_REFRESH_EMPLOYEE_SELECTOR_LIST';
export const ORG_EMPLOYEE_TOGGLE_DETAIL_MODAL = "ORG_EMPLOYEE_TOGGLE_DETAIL_MODAL";
export const ORG_EMPLOYEE_TOGGLE_IMPORT_MODAL = "ORG_EMPLOYEE_TOGGLE_IMPORT_MODAL";

// 岗位
export const ORG_POSITION_LOADING = 'ORG_POSITION_LOADING';
export const ORG_POSITION_REFRESH_SEARCH_CONDITION = "ORG_POSITION_REFRESH_SEARCH_CONDITION";
export const ORG_POSITION_REFRESH_POSITION = 'ORG_POSITION_REFRESH_POSITION';
export const ORG_POSITION_SEARCH = 'ORG_POSITION_SEARCH';
export const ORG_POSITION_TOGGLE_DETAIL_MODAL = "ORG_POSITION_TOGGLE_DETAIL_MODAL";

