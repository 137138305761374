/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Form, Input, Button} from "antd";

import {updateSessionUserPassword, refreshUser} from "../../../actions/UserAction";
import Validator from "../../../../../utils/Validator";
import {PASSWORD_STRENGTH_LEVEL} from "../../../../../constants/Profile";
import {Label_4_Wrapper_10} from "../../../../../constants/FormItemLayout";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        user[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshUser(user));
    }

    _validate(user) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};

        if (user.oldPassword === undefined || user.oldPassword === "") {
            messageMap["oldPassword"] = formatMessage({id: "ACCOUNT_USER_MSG_OLD_PASSWORD_REQUIRED"});
        }

        if (user.password === undefined || user.password === "") {
            messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_REQUIRED"});
        } else if (user.passwordConfirm === undefined || user.passwordConfirm === "") {
            messageMap["passwordConfirm"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_PASSWORD_CONFIRM_REQUIRED'});
        } else if (user.password !== user.passwordConfirm) {
            messageMap["passwordConfirm"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED'});
        } else if (PASSWORD_STRENGTH_LEVEL >= 2) {
            if (user.password.length < 8) {
                messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED"}, {value: '8'});
            }
            if (PASSWORD_STRENGTH_LEVEL >= 3 && !Validator.isPasswordStrength(user.password)) {
                messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED"});
            }
        }
        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleSubmit() {
        const {dispatch, user} = this.props;
        if (this._validate(user)) {
            dispatch(updateSessionUserPassword(user, this.state.formatMessage));
        }
    }

    render() {
        const {formatMessage, messageMap} = this.state;
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 4,
                },
            },
        };
        return (
            <div >
                <Card>
                    <Form>
                        <Form.Item
                            label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD_OLD"})} {...Label_4_Wrapper_10}
                            required={true}
                            validateStatus={messageMap["oldPassword"] ? "error" : ""}
                            help={messageMap["oldPassword"]}>
                            <Input type="password"
                                   onChange={(value) => this._handleChange("oldPassword", value)}/>
                        </Form.Item>

                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD"})} {...Label_4_Wrapper_10}
                                   required={true}
                                   validateStatus={messageMap["password"] ? "error" : ""}
                                   help={messageMap["password"]}>
                            <Input type="password"
                                   onChange={(value) => this._handleChange("password", value)}/>
                        </Form.Item>

                        <Form.Item
                            label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD_CONFIRM"})} {...Label_4_Wrapper_10}
                            required={true}
                            validateStatus={messageMap["passwordConfirm"] ? "error" : ""}
                            help={messageMap["passwordConfirm"]}>
                            <Input type="password"
                                   onChange={(value) => this._handleChange("passwordConfirm", value)}/>
                        </Form.Item>

                        <Form.Item  {...tailFormItemLayout}>
                            <Button type="primary" onClick={() => this._handleSubmit()}>
                                <FormattedMessage id="COMMON_BUTTON_SUBMIT"/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        user: store.AccountUserReducer.user
    }
}

export default connect(mapStateToProps)(injectIntl(Index));