/**
 * Created by wangyixian on 2018/6/19.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Form, Row, Col, Button, Input, Select} from 'antd';

import EnableCheckGroup from "../../../components/EnableCheckGroup";

import {refreshSearchCondition, refreshUser, searchUsers, toggleDetailModal} from '../../../actions/UserAction';
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import DictItemCheckbox from "../../../../../components/EnumItemCheckbox";
import {RoleCode} from "../../../Enums";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch({});
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchUsers(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshUser({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {

        const formatMessage = this.state.formatMessage;

        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        {/*<Col span={8}>*/}
                        {/*<Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_UID"})} {...Label_6_Wrapper_18}>*/}
                        {/*<Input value={this.props.searchCondition.uid}*/}
                        {/*onChange={(value) => this._handleChange("uid", value)}/>*/}
                        {/*</Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_EMAIL"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.email}
                                       onChange={(value) => this._handleChange("email", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_MOBILE"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.mobile}
                                       onChange={(value) => this._handleChange("mobile", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "ACCOUNT_USER_FIELD_FULL_NAME"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.username}
                                       onChange={(value) => this._handleChange("username", value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/*<Row gutter={16}>*/}
                    {/*<Col span={8}>*/}
                    {/*<Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_ROLE"})} {...Label_6_Wrapper_18}>*/}
                    {/*<DictItemCheckbox data={this.props.roles.map((item, index) => {*/}
                    {/*return {value: item.id, label: item.name}*/}
                    {/*})} onChange={(value) => this._handleChange("roleIds", value)}/>*/}
                    {/*</Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col span={8}>*/}
                    {/*<Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_DISABLED"})} {...Label_6_Wrapper_18}>*/}
                    {/*<EnableCheckGroup defaultChecked={true}*/}
                    {/*onChange={(value) => this._handleChange("enableStatus", value)}/>*/}
                    {/*</Form.Item>*/}
                    {/*</Col>*/}
                    {/*</Row>*/}
                </Form>
                <div className="text-center">
                    <Button type="primary" icon="search" className="spacing-h"
                            onClick={() => this._handleSearch(this.props.searchCondition)}>
                        <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                    </Button>
                    <Button type="danger" icon="plus" onClick={() => this._handleShowCreateModal()}>
                        <FormattedMessage id='COMMON_BUTTON_ADD'/>
                    </Button>
                </div>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.AccountUserReducer.searchCondition,
        roles: store.AccountRoleReducer.roles
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));