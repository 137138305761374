/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import DetailModal from "./DetailModal";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import {searchRoles} from "../../../actions/RoleAction";
import {getDataGroupTree} from "../../../actions/DataGroupAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(searchRoles({}, this.state.formatMessage));
        this.props.dispatch(getDataGroupTree(this.state.formatMessage));
    }

    render() {
        return (
            <div >
                <DetailModal/>
                <SearchCondition/>
                <SearchResult/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));