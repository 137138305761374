import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";
import {message} from "antd/lib/index";

export function refreshRole(role) {
    return {
        type: ActionTypes.ACCOUNT_ROLE_REFRESH_ROLE,
        role: role
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ACCOUNT_ROLE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleDetailModal(showDetailModal) {
    return {
        type: ActionTypes.ACCOUNT_ROLE_TOGGLE_DETAIL_MODAL,
        showDetailModal: showDetailModal
    }
}

export function togglePermissionModal(show) {
    return {
        type: ActionTypes.ACCOUNT_ROLE_TOGGLE_PERMISSION_MODAL,
        showPermissionModal: show
    }
}

export function refreshSelectedRoles(selectedRoles) {
    return {
        type: ActionTypes.ACCOUNT_ROLE_REFRESH_SELECTED_ROLES,
        selectedRoles: selectedRoles
    }
}

export function getRole(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getRoleById, "id", id);
        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshRole(response.data));
            dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function searchRoles(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: true});

        console.log(condition);
        HttpUtils.post(WebService.searchRoles, condition, (response) => {
            dispatch({
                type: ActionTypes.ACCOUNT_ROLE_SEARCH,
                roles: response.data,
                pagination: response.pagination
            });
            dispatch(refreshSearchCondition(condition));
            dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createRole(role, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createRole, role, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchRoles(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateRole(role, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateRole, role, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(togglePermissionModal(false));
            dispatch(searchRoles(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteRole(role, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: true});
        HttpUtils.post(WebService.deleteRole, role, (response) => {
            dispatch(searchRoles(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.ACCOUNT_ROLE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}