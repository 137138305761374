import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Layout} from 'antd';

import TreeControl from '../../../../../components/TreeControl';
import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';
import DetailModal from "./DetailModal";
import {getDataGroupTree, refreshSearchCondition, searchDataGroups} from "../../../actions/DataGroupAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(getDataGroupTree(this.state.formatMessage));
    }

    _handleTreeNodeSelect(selectedKeys) {
        if (selectedKeys && selectedKeys.length > 0) {
            const searchCondition = Object.assign({}, this.props.searchCondition);
            searchCondition.parentIds = [];
            searchCondition.parentIds.push(selectedKeys[0]);
            this.props.dispatch(refreshSearchCondition(searchCondition));
            this.props.dispatch(searchDataGroups(searchCondition, this.state.formatMessage));
        }
    }

    render() {
        return (
            <div >
                <DetailModal/>
                <Layout style={{height: '100%'}}>
                    <Layout.Sider width={200}
                                  style={{background: '#fff', minHeight: '100%'}}>
                        <TreeControl onSelect={(selectedKeys) => this._handleTreeNodeSelect(selectedKeys)}
                                     treeData={this.props.dataGroupTree}/>
                    </Layout.Sider>
                    <Layout.Content style={{paddingLeft: '10px', minHeight: 200}}>
                        <SearchCondition/>
                        <SearchResult/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        dataGroupTree: store.AccountGroupReducer.dataGroupTree,
        searchCondition: store.AccountGroupReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(Index));