/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Layout} from 'antd';
import PageTitle from "../../../../../components/PageTitle";
import DictTypeTree from "./DictTypeTree";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";
import {getAllDictClass} from "../../../actions/DictClassAction";
import {getAllDictType} from "../../../actions/DictTypeAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(getAllDictType(this.state.formatMessage));
        this.props.dispatch(getAllDictClass(this.state.formatMessage));
    }

    render() {
        return (
            <div>
                <Layout style={{height: '100%'}}>
                    <Layout.Sider width={200}
                                  style={{background: '#fff', minHeight: '100%'}}>
                        <DictTypeTree/>
                    </Layout.Sider>
                    <Layout.Content style={{paddingLeft: '10px', minHeight: 200}}>
                        <SearchCondition/>
                        <SearchResult/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));