/**
 * Created by Ethan on 17/1/13.
 */

import {DICT_SERVER_HOST} from '../../constants/Profile';

// 字典类型
export const getDictTypes = DICT_SERVER_HOST + "/dict/type/query";
export const getDictType = DICT_SERVER_HOST + "/dict/type/detail";
export const createDictType = DICT_SERVER_HOST + "/dict/types";
export const updateDictType = DICT_SERVER_HOST + "/dict/types/{id}";
export const deleteDictType = DICT_SERVER_HOST + "/dict/types/{id}";

// 字典类
export const getDictClassItems = DICT_SERVER_HOST + "/openAPI/dict/classes/{code}/items";
export const searchDictClasses = DICT_SERVER_HOST + "/openAPI/dict/class/query";
export const getDictClass = DICT_SERVER_HOST + "/dict/classes/{id}";
export const createDictClass = DICT_SERVER_HOST + "/dict/classes";
export const updateDictClass = DICT_SERVER_HOST + "/dict/classes/{id}";
export const deleteDictClass = DICT_SERVER_HOST + "/dict/classes/{id}";

// 字典项
export const getDictItemDetail = DICT_SERVER_HOST + "/dict/item/detail";
export const getDictItemList = DICT_SERVER_HOST + "/dict/item/list";
export const createDictItem = DICT_SERVER_HOST + "/dict/item/create";
export const updateDictItem = DICT_SERVER_HOST + "/dict/item/update";
export const updateDictItems = DICT_SERVER_HOST + "/dict/item/batchUpdate";
export const deleteDictItem = DICT_SERVER_HOST + "/dict/item/delete";