export const HttpStatus = {
    "SUCCESS": 200,
    'NOT_FOUND': 404,
    'UNAUTHORIZED': 401,
    "FORBIDDEN": 403,
    'NOT_ACCEPTABLE': 406,
    "EXCEPTION": 417,
    "ERROR": 500
};

export const Language = {
    List: [
        {value: "zh-CN", label: "APP_MULTI_LANGUAGE_ZH"},
        {value: "en-US", label: "APP_MULTI_LANGUAGE_EN"},
        // {value: "ja-JP", label: "APP_LANGUAGE_JP"},
    ], Map: {
        "zh-CN": "APP_MULTI_LANGUAGE_ZH",
        "en-US": "APP_MULTI_LANGUAGE_EN",
        // "ja-JP": "APP_LANGUAGE_JP",
    }, Enum: {
        ZH: "zh-CN",
        EN: "en-US",
    }
};