import * as ActionTypes from '../ActionTypes';

const initialState = {
    examinee: {},
    examinees: [],
    searchCondition: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
    showImportModal: false,
    importResult: [],
    allExaminees:[]
};

const ExamineeReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_EXAMINEE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_EXAMINEE_REFRESH_EXAMINEE:
            return Object.assign({}, state, {
                examinee: action.examinee
            });
        case ActionTypes.EXAM_EXAMINEE_REFRESH_IMPORT_RESULT:
            return Object.assign({}, state, {
                importResult: action.importResult
            });
        case ActionTypes.EXAM_EXAMINEE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_EXAMINEE_TOGGLE_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.EXAM_EXAMINEE_TOGGLE_IMPORT_MODAL:
            return Object.assign({}, state, {
                showImportModal: action.showImportModal
            });
        case ActionTypes.EXAM_EXAMINEE_SEARCH_EXAMINEE:
            return Object.assign({}, state, {
                examinees: action.examinees,
                pagination: action.pagination
            });
        case ActionTypes.EXAM_EXAMINEE_SEARCH_EXAMINEE_ALL_LIST:
            return Object.assign({}, state, {
                allExaminees: action.allExaminees
            });
        default:
            return state
    }
};

export default ExamineeReducer;