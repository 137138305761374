/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Form, Input, Button} from "antd";

import {getSessionUser, getUser, refreshUser, updateSessionUser} from "../../../actions/UserAction";
import Validator from "../../../../../utils/Validator";
import {FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import {Label_4_Wrapper_10} from "../../../../../constants/FormItemLayout";
import FtpPortraitUpload from "../../../../FTP/components/FtpPortraitUpload";
import {CACHE_SESSION_USER} from "../../../../../constants/Profile";
import ParseUtils from "../../../../../utils/ParseUtils";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            seconds: 60,
            canSendVerifyCode: true
        }
    }

    componentWillMount() {
        this.props.dispatch(getUser(this.props.sessionUser.id, this.state.formatMessage));
    }

    componentWillUnmount() {
        // 如果存在this.timer，则使用clearTimeout清空。
        // 如果你使用多个timer，那么用多个变量，或者用个数组来保存引用，然后逐个clear
        this.timer && clearInterval(this.timer);
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        if (name === "portraitFile") {
            ParseUtils.blobToBase64(value.file.originFileObj, imageUrl => {
                // data:image/jpeg;base64, 的数据需要移除，否则提交请求会返回Bad Request
                user["portrait"] = imageUrl.split(",")[1];
                user["portraitFile"] = value.file.response.data.id;
                this.props.dispatch(refreshUser(user));
            });
        } else {
            user[name] = value.target ? value.target.value : value;
            this.props.dispatch(refreshUser(user));
        }
    }

    _handleSendEmailCode() {
        let messageMap = {};
        if (this.props.user.email === undefined || this.props.user.email === '') {
            messageMap["email"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_EMAIL_REQUIRED'});
        } else if (!Validator.isEmail(this.props.user.email)) {
            messageMap["email"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG'});
        }

        this.setState({messageMap: messageMap});

        if (Object.keys(messageMap).length > 0) {
            return;
        }

        // this.props.dispatch(sendMailVerifyCode(this.props.user.email, this.state.formatMessage));

        this.timer = setInterval(() => {
            if (this.state.seconds > 0) {
                let seconds = this.state.seconds;
                this.setState({seconds: seconds - 1, canSendVerifyCode: false});
            } else {
                this.setState({seconds: 60, canSendVerifyCode: true});
                clearInterval(this.timer);
            }
        }, 1000);
    }

    _validate(user) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};
        if (user.uid === undefined || user.uid === "") {
            messageMap["uid"] = formatMessage({id: "ACCOUNT_USER_MSG_UID_REQUIRED"});
        }
        if (user.mobile === undefined || user.mobile === "") {
            messageMap["mobile"] = formatMessage({id: "ACCOUNT_USER_MSG_MOBILE_REQUIRED"});
        }
        // else if (!Validator.isMobileNumber(user.mobile)) {
        //     messageMap["mobile"] = formatMessage({id: "ACCOUNT_USER_MSG_MOBILE_FORMAT_WRONG"});
        // }
        if (user.email === undefined || user.email === "") {
            messageMap["email"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_REQUIRED"});
        }
        // else if (!Validator.isEmail(user.email)) {
        //     messageMap["email"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG"});
        // }
        // if (user.emailVerifyCode === null || user.emailVerifyCode === "") {
        //     messageMap["emailVerifyCode"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_CODE_REQUIRED"});
        // }

        if (user.lastName === undefined || user.lastName === "") {
            messageMap["lastName"] = formatMessage({id: "ACCOUNT_USER_MSG_LAST_NAME_REQUIRED"});
        }
        if (user.firstName === undefined || user.firstName === "") {
            messageMap["firstName"] = formatMessage({id: "ACCOUNT_USER_MSG_FIRST_NAME_REQUIRED"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleSubmit() {
        const {dispatch, user} = this.props;
        if (this._validate(user)) {
            dispatch(updateSessionUser(user, this.state.formatMessage));
        }
    }

    render() {
        const {user} = this.props;
        const {formatMessage, messageMap} = this.state;

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 4,
                },
            },
        };

        let verifyCodeAddon = <Button size="small" className="btn-default"
                                      onClick={() => this._handleSendEmailCode()}>
            <FormattedMessage id="ACCOUNT_USER_BUTTON_EMAIL_VERIFY_CODE"/>
        </Button>
        if (!this.state.canSendVerifyCode) {
            verifyCodeAddon = <span>{this.state.seconds}</span>;
        }

        return (
            <div >
                <Card>
                    <Form>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_UID"})} {...Label_4_Wrapper_10}
                                   required={true}>
                            <Input disabled={true} value={user.uid}/>
                        </Form.Item>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_PORTRAIT"})} {...Label_4_Wrapper_10}>
                            {/*<PortraitUpload*/}
                                {/*value={user.portraitFile}*/}
                                {/*folder={"Account/Photos/" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE)}*/}
                                {/*onUpload={(value) => this._handleChange("portraitFile", value)}/>*/}
                            <FtpPortraitUpload
                                value={user.portrait}
                                folder={"upload/" + this.props.sessionUser.id + "/" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE)}
                                onUpload={(value) => this._handleChange("portraitFile", value)}/>
                        </Form.Item>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_MOBILE"})} {...Label_4_Wrapper_10}
                                   required={true}
                                   validateStatus={messageMap["mobile"] ? "error" : ""}
                                   help={messageMap["mobile"]}>
                            <Input value={user.mobile} onChange={(value) => {
                                this._handleChange("mobile", value)
                            }}/>
                        </Form.Item>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_EMAIL"})} {...Label_4_Wrapper_10}
                                   required={true}
                                   validateStatus={messageMap["email"] ? "error" : ""}
                                   help={messageMap["email"]}>
                            <Input value={user.email} onChange={(value) => {
                                this._handleChange("email", value)
                            }}/>
                        </Form.Item>

                        {/*<Form.Item*/}
                            {/*label={formatMessage({id: "ACCOUNT_USER_FIELD_EMAIL_VERIFY_CODE"})} {...Label_4_Wrapper_10}*/}
                            {/*required={true}*/}
                            {/*validateStatus={this.state.messageMap["emailVerifyCode"] ? "error" : ""}*/}
                            {/*help={messageMap["emailVerifyCode"]}>*/}
                            {/*<Input onChange={(value) => this._handleChange("emailVerifyCode", value)}*/}
                                   {/*addonAfter={verifyCodeAddon}/>*/}
                        {/*</Form.Item>*/}

                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_LAST_NAME"})} {...Label_4_Wrapper_10}
                                   required={true}
                                   validateStatus={this.state.messageMap["lastName"] ? "error" : ""}
                                   help={this.state.messageMap["lastName"]}>
                            <Input value={user.lastName}
                                   onChange={(value) => {
                                       this._handleChange("lastName", value)
                                   }}/>
                        </Form.Item>
                        <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_FIRST_NAME"})} {...Label_4_Wrapper_10}
                                   required={true}
                                   validateStatus={this.state.messageMap["firstName"] ? "error" : ""}
                                   help={this.state.messageMap["firstName"]}>
                            <Input value={user.firstName}
                                   onChange={(value) => {
                                       this._handleChange("firstName", value)
                                   }}/>
                        </Form.Item>
                        <Form.Item  {...tailFormItemLayout}>
                            <Button type="primary" onClick={() => this._handleSubmit()}>
                                <FormattedMessage id="COMMON_BUTTON_SUBMIT"/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        user: store.AccountUserReducer.user,
        sessionUser: store.AccountUserReducer.sessionUser
    }
}

export default connect(mapStateToProps)(injectIntl(Index));