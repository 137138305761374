import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Card, Space, NavBar, Icon, Button} from 'antd-mobile';

import {PaperStatus, PaperSubjectStatus} from "../../../../Enums";
import {getMyExaminationPaper} from "../../../../actions/PaperAction";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {refreshSubjects} from "../../../../actions/PaperSubjectAction";
import {LeftOutline} from "antd-mobile-icons";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.dispatch(getMyExaminationPaper(examinationId, examineeId, this.state.formatMessage));
    }

    componentWillUnmount() {
    }

    render() {
        const {paper} = this.props;
        let rightList = [];
        let wrongList = [];
        if (paper && paper.subjects) {
            for (let i = 0; i < paper.subjects.length; i++) {
                const subject = paper.subjects[i];
                if (subject.status === PaperSubjectStatus.Enum.Right) {
                    rightList.push(i + 1);
                } else {
                    wrongList.push(i + 1);
                }
            }
        }
        return (
            <div>
                <NavBar
                    mode="light"
                    icon={<LeftOutline/>}
                    onBack={() => this._handleResetPaper()}>{paper.title}</NavBar>
                <div className="p-5">
                    <Card>
                        <h3 className="text-center">答题结果</h3>
                        <p>正确数：{rightList.length} {rightList.length > 0 ? "(" + rightList.toString() + ")" : ""}</p>
                        <p>错题数：{wrongList.length} {wrongList.length > 0 ? "(" + wrongList.toString() + ")" : ""}</p>
                        <p>正确率：{((rightList.length / (rightList.length + wrongList.length)) * 100).toFixed(2)} %</p>
                        <p>及格分：{paper && paper.examination ? paper.examination.passScore : 0} </p>
                        <p>总得分：{paper.score} </p>
                    </Card>

                    {/*<Button type="primary"*/}
                    {/*onClick={() => this.props.router.push(RoutePath.KZKT_WORKSPACE.path)}>返回</Button>*/}

                    {/*<Space/>*/}

                    <br/>
                    <Button block color='primary'
                            onClick={() => this._handleResetPaper(paper)}>重新答题</Button>
                </div>
            </div>
        );
    }

    _handleResetPaper(paper) {

        // for (let i = 0; i < paper.subjects.length; i++) {
        //     const subject = paper.subjects[i];
        //     for (let j = 0; j < subject.options.length; j++) {
        //         const option = subject.options[j];
        //         option.selectFlag = 0;
        //     }
        // }
        // this.props.dispatch(refreshSubjects(paper.subjects));
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.router.push({
            pathname: RoutePath.EXAM_PAPER_DETAIL.path,
            query: {examinationId: examinationId, examineeId: examineeId}
        })
        // this.props.router.push(RoutePath.EXAM_PAPER_DETAIL.path.replace(":examinationId", paper.examinationId));
    }
}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper
    }
}

export default connect(mapStateToProps)(injectIntl(Index));