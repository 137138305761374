import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    users: [],
    user: {},
    sessionUser: {},
    pagination: {},
    searchCondition: {},
    showDetailModal: false,
    showPasswordChangeModal: false,
    showPasswordResetModal: false
};

const AccountUserReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.USER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.USER_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.USER_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.USER_TOGGLE_PASSWORD_CHANGE_MODAL:
            return Object.assign({}, state, {
                showPasswordChangeModal: action.showPasswordChangeModal
            });
        case ActionTypes.USER_TOGGLE_PASSWORD_RESET_MODAL:
            return Object.assign({}, state, {
                showPasswordResetModal: action.showPasswordResetModal
            });
        case ActionTypes.USER_REFRESH_USER:
            return Object.assign({}, state, {
                user: action.user
            });
        case ActionTypes.USER_REFRESH_SESSION_USER:
            return Object.assign({}, state, {
                sessionUser: action.sessionUser
            });
        case ActionTypes.USER_SEARCH:
            return Object.assign({}, state, {
                users: action.users,
                pagination: action.pagination
            });
        case ActionTypes.USER_REFRESH_USERS:
            return Object.assign({}, state, {
                users: action.users
            });
        default:
            return state
    }
};

export default AccountUserReducer;