import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    employees: [],
    employeeSelectorList: [],
    employee: {},
    pagination: {},
    searchCondition: {},
    showDetailModal: false,
    showImportModal: false
};

const EmployeeReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ORG_EMPLOYEE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ORG_EMPLOYEE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ORG_EMPLOYEE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ORG_EMPLOYEE_TOGGLE_IMPORT_MODAL:
            return Object.assign({}, state, {
                showImportModal: action.showImportModal
            });
        case ActionTypes.ORG_EMPLOYEE_REFRESH_EMPLOYEE:
            return Object.assign({}, state, {
                employee: action.employee
            });
        case ActionTypes.ORG_EMPLOYEE_REFRESH_EMPLOYEE_LIST:
            return Object.assign({}, state, {
                employees: action.employees
            });
        case ActionTypes.ORG_EMPLOYEE_REFRESH_EMPLOYEE_SELECTOR_LIST:
            return Object.assign({}, state, {
                employeeSelectorList: action.employeeSelectorList
            });
        case ActionTypes.ORG_EMPLOYEE_SEARCH:
            return Object.assign({}, state, {
                employees: action.employees,
                pagination: action.pagination
            });
        default:
            return state
    }
};

export default EmployeeReducer;