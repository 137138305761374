import * as ActionTypes from '../ActionTypes';

const initialState = {
    paper: {},
    papers: [],
    searchCondition: {},
    pagination: {},
    showDetailModal: false,
    isLoading: false
};

const PaperReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_PAPER_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_PAPER_REFRESH_PAPER:
            return Object.assign({}, state, {
                paper: action.paper
            });
        case ActionTypes.EXAM_PAPER_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_PAPER_TOGGLE_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.EXAM_PAPER_SEARCH_PAPER:
            return Object.assign({}, state, {
                papers: action.papers,
                pagination: action.pagination
            });
        case ActionTypes.EXAM_PAPER_REFRESH_RELATED_EXAM_SUBJECT_LIST:
            return Object.assign({}, state, {
                relatedExaminationSubjectList: action.relatedExaminationSubjectList
            });

        case ActionTypes.EXAM_PAPER_REFRESH_SUBJECT:
            return Object.assign({}, state, {
                paperSubject: action.paperSubject
            });
        case ActionTypes.EXAM_PAPER_TOGGLE_PAPER_SUBJECT_MODIFY_MODAL:
            return Object.assign({}, state, {
                showPaperSubjectModal: action.showPaperSubjectModal
            });
        default:
            return state
    }
};

export default PaperReducer;