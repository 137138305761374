import moment from "moment";
import * as ActionTypes from '../ActionTypes';
import * as DateTimeFormats from "../../../constants/DateTimeFormats";
import {ExaminationClassifyEnums, ExaminationStatus, ExamPaperType} from "../Enums";

const initialState = {
    examination: {
        code: moment().format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE),
        status: ExaminationStatus.Enum.New,
        classify:ExaminationClassifyEnums.Enum.Evaluation
    },
    examinations: [],
    pagination: {},
    searchCondition: {},

    isLoading: false,
    showDetailModal: false,
    showSubjectsModal: false,
    showPublishModal: false,


    examinationPeriodList: [],
    periodSearchCondition: {},
    periodPagination: {},
    examinationPeriod: {
        code: moment().format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE),
        status: ExaminationStatus.Enum.New,
        classify:ExaminationClassifyEnums.Enum.Training
    },
};

const ExaminationReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_EXAMINATION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_EXAMINATION_INIT_EXAMINATION:
            return Object.assign({}, state, {
                examination: initialState.examination
            });
        case ActionTypes.EXAM_EXAMINATION_INIT_EXAMINATION_PERIOD:
            return Object.assign({}, state, {
                examination: initialState.examinationPeriod
            });

        case ActionTypes.EXAM_EXAMINATION_REFRESH_EXAMINATION:
            return Object.assign({}, state, {
                examination: action.examination
            });
        case ActionTypes.EXAM_EXAMINATION_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.EXAM_EXAMINATION_TOGGLE_SUBJECTS_MODAL:
            return Object.assign({}, state, {
                showSubjectsModal: action.showSubjectsModal
            });
        case ActionTypes.EXAM_EXAMINATION_TOGGLE_PUBLISH_MODAL:
            return Object.assign({}, state, {
                showPublishModal: action.showPublishModal
            });
        case ActionTypes.EXAM_EXAMINATION_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_EXAMINATION_SEARCH_EXAMINATION:
            return Object.assign({}, state, {
                examinations: action.examinations,
                pagination: action.pagination
            });

        case ActionTypes.EXAM_EXAMINATION_SEARCH_EXAMINATION_PERIOD_LIST:
            return Object.assign({}, state, {
                examinationPeriodList: action.examinationPeriodList,
                periodPagination: action.periodPagination
            });

        case ActionTypes.EXAM_EXAMINATION_REFRESH_PERIOD_SEARCH_CONDITION:
            return Object.assign({}, state, {
                periodSearchCondition: action.periodSearchCondition,
            });
        default:
            return state
    }
};

export default ExaminationReducer;