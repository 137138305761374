import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Row, Col, Input, Button, Select} from "antd";

import {
    refreshDataGroup,
    refreshSearchCondition,
    searchDataGroups,
    toggleDetailModal
} from '../../../actions/DataGroupAction';
import TreeSelector from "../../../../../components/TreeSelector";

const formItemLayout = {labelCol: {span: 6}, wrapperCol: {span: 18}, style: {marginBottom: "3px"}};

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        this.props.dispatch(searchDataGroups(this.props.searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshDataGroup({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {dataGroupTree, searchCondition} = this.props;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ACCOUNT_DATA_GROUP_FIELD_PARENT"})} {...formItemLayout}>
                                <TreeSelector multiple={true}
                                              treeData={dataGroupTree}
                                              value={searchCondition.parentIds}
                                              onChange={(value) => this._handleChange("parentIds", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ACCOUNT_DATA_GROUP_FIELD_NAME"})} {...formItemLayout}>
                                <Input onChange={(value) => this._handleChange("groupName", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" icon="search" onClick={() => this._handleSearch()}
                                    className="spacing-h">
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            <Button type="danger" icon="plus" onClick={() => this._handleShowCreateModal()}>
                                <FormattedMessage id='COMMON_BUTTON_ADD'/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        dataGroupTree: store.AccountGroupReducer.dataGroupTree,
        searchCondition: store.AccountGroupReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));