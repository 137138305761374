import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';
import {CACHE_SITE_CODE, CACHE_SITE_ID} from "../../../constants/Profile";

export function toggleCreateModal(isShow) {
    return {
        type: ActionTypes.CMS_SITE_TOGGLE_CREATE_MODAL,
        showCreateModal: isShow
    }
}

export function toggleUpdateModal(isShow) {
    return {
        type: ActionTypes.CMS_SITE_TOGGLE_UPDATE_MODAL,
        showUpdateModal: isShow
    }
}

export function refreshSite(site) {
    return {
        type: ActionTypes.CMS_SITE_REFRESH_SITE,
        site: site
    }
}

export function getSites(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: true});

        HttpUtils.get(WebService.getSites, (response) => {
            dispatch({type: ActionTypes.CMS_SITE_LIST, sites: response.data});
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});

            // 站点内部都需要该站点ID，存入缓存
            if (!localStorage.getItem(CACHE_SITE_ID) || localStorage.getItem(CACHE_SITE_ID) === "") {
                localStorage.setItem(CACHE_SITE_ID, response.data[0].id);
            }
            if (!localStorage.getItem(CACHE_SITE_CODE) || localStorage.getItem(CACHE_SITE_CODE) === "") {
                localStorage.setItem(CACHE_SITE_CODE, response.data[0].code);
            }

        }, (response) => {
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getSite(siteId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: true});

        const requestUrl = WebService.getSite.replace("{id}", siteId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshSite(response.data));
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createSite(site, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createSite, site, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch(toggleCreateModal(false));
            dispatch(getSites(formatMessage));
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function copySite(siteId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: true});

        const requestUrl = WebService.copySite.replace("{id}", siteId);
        HttpUtils.post(requestUrl, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch(getSites(formatMessage));
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateSite(site, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: true});

        const requestUrl = WebService.updateSite.replace("{id}", site.id);
        HttpUtils.put(requestUrl, site, (response) => {
            dispatch(toggleUpdateModal(false));
            dispatch(getSites(formatMessage));
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteSite(siteId, formatMessage) {

    return dispatch => {
        let requestURL = WebService.deleteSite.replace("{id}", siteId);
        HttpUtils.delete(requestURL, {}, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getSites(formatMessage));
        }, (response) => {
            dispatch({type: ActionTypes.CMS_SITE_LOADING, isLoading: false});
        }, formatMessage);
    }
}
