import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Row, Col, Modal, Form, Input, InputNumber, Tabs, Upload, Button, Checkbox} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

import {createSubject, refreshSubject, toggleDetailModal, updateSubject} from "../../../actions/SubjectAction";

import {AttentionAssessmentSubjectType, SubjectType} from "../../../Enums";
import ListSelector from "../../../../../components/ListSelector";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import RichTextEditor from "../../../../../components/RichTextEditor";
import SubjectContentShow from "./SubjectContentShow";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            selectedTabKey: "rich"
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(subject) {
        let messageMap = {};
        if (!subject.bankId || subject.bankId === "") {
            messageMap["bankId"] = this.state.formatMessage({id: 'EXAM_SUBJECT_MSG_BANK_ID_REQUIRED'});
        }
        if (!subject.title || subject.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'EXAM_SUBJECT_MSG_TITLE_REQUIRED'});
        }
        if (!subject.type || subject.type === "") {
            messageMap["type"] = this.state.formatMessage({id: 'EXAM_SUBJECT_MSG_TYPE_REQUIRED'});
        }
        // if (!subject.limitTime || subject.limitTime === "") {
        //     messageMap["limitTime"] = this.state.formatMessage({id: 'EXAM_SUBJECT_MSG_LIMIT_TIME_REQUIRED'});
        // }
        if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO &&
            subject.difficultyLevel === undefined) {
            messageMap["difficultyLevel"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }
        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {subject, searchCondition} = this.props;
        const {formatMessage} = this.state;
        if (this._validate(subject)) {
            subject.content = JSON.stringify(subject.contentObject);

            if (subject.id && subject.id !== "") {

                this.props.dispatch(updateSubject(subject, searchCondition, formatMessage));
            } else {
                this.props.dispatch(createSubject(subject, searchCondition, formatMessage));
            }
            this.props.dispatch(toggleDetailModal(false));
        }
    }

    _handleChange(name, value) {
        const subject = Object.assign({}, this.props.subject);
        subject[name] = (value && value.target) ? value.target.value : value;
        if (name === 'type') {
            if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO &&
                subject.difficultyLevel === undefined) {
                subject.difficultyLevel = 15
            }
        }
        this.props.dispatch(refreshSubject(subject));
    }

    _handleChangeOption(index, name, value) {
        const subject = Object.assign({}, this.props.subject);
        const options = JSON.parse(subject.content);
        options[index][name] = value.target ? value.target.value : value;
        subject.content = JSON.stringify(options);
        this.props.dispatch(refreshSubject(subject));
    }

    _handleChangeFilePath(index, value) {
        const subject = Object.assign({}, this.props.subject);
        const filePaths = JSON.parse(subject.filePath);
        console.log(filePaths);
        filePaths[index] = value.target ? value.target.value : value;
        subject.filePath = JSON.stringify(filePaths);
        this.props.dispatch(refreshSubject(subject));
    }

    render() {
        const {subject, banks} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = formatMessage({id: 'EXAM_SUBJECT_TITLE_CREATE'});
        if (subject.id && subject.id !== "") {
            title = formatMessage({id: 'EXAM_SUBJECT_TITLE_UPDATE'});
        }
        let modalWidth = document.documentElement.clientWidth - 100;
        modalWidth = modalWidth > 1300 ? 1300 : modalWidth;

        return (
            <Modal
                maskClosable={false}
                title={title}
                width={modalWidth}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
            >
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={formatMessage({id: "EXAM_SUBJECT_TITLE_BASIC"})} key="1">
                        <Form>
                            <Form.Item
                                label={formatMessage({id: "EXAM_SUBJECT_FIELD_BANK"})} {...Label_6_Wrapper_18}
                                required={true}
                                validateStatus={this.state.messageMap["bankId"] ? "error" : ""}
                                help={this.state.messageMap["bankId"]}>
                                <ListSelector value={subject.bankId} data={banks}
                                              onChange={(value) => this._handleChange("bankId", value)}/>
                            </Form.Item>
                            <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_TYPE'})}
                                       {...Label_6_Wrapper_18}
                                       required={true}
                                       validateStatus={this.state.messageMap["type"] ? "error" : ""}
                                       help={this.state.messageMap["type"]}>
                                <EnumItemSelect value={subject.type}
                                                data={AttentionAssessmentSubjectType.List}
                                                onChange={(data) => {
                                                    this._handleChange("type", data)
                                                }}/>
                            </Form.Item>
                            <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_TITLE'})}
                                       {...Label_6_Wrapper_18} required={true}
                                       validateStatus={this.state.messageMap["title"] ? "error" : ""}
                                       help={this.state.messageMap["title"]}>
                                <Input.TextArea autoSize={{minRows: 2, maxRows: 6}} value={subject.title}
                                                onChange={(data) => {
                                                    this._handleChange("title", data)
                                                }}/>
                            </Form.Item>
                            {subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO?
                                <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_DIFFICULTY_LEVEL'})}
                                           {...Label_6_Wrapper_18} required={true}
                                           validateStatus={this.state.messageMap["difficultyLevel"] ? "error" : ""}
                                           help={this.state.messageMap["difficultyLevel"]}>
                                    <InputNumber max={50} min={0} value={subject.difficultyLevel}
                                                 onChange={(data) => {
                                                     this._handleChange("difficultyLevel", data)
                                                 }}/>
                                    <span>(0 ~ 50) {subject.difficultyLevel ? Math.round(subject.difficultyLevel * 100 / 50) + "%" : ''}</span>
                                </Form.Item>
                                : null}
                            {
                            subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING||
                            subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION||
                            subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT?
                                <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_DIFFICULTY_LEVEL'})}
                                           {...Label_6_Wrapper_18} required={true}
                                           validateStatus={this.state.messageMap["difficultyLevel"] ? "error" : ""}
                                           help={this.state.messageMap["difficultyLevel"]}>
                                    <InputNumber max={50} min={0} value={subject.difficultyLevel}
                                                 onChange={(data) => {
                                                     this._handleChange("difficultyLevel", data)
                                                 }}/>
                                    <span>(0 ~ 3)</span>
                                </Form.Item>
                                : null}


                            {/*<Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_SCORE'})}*/}
                            {/*{...Label_6_Wrapper_18}>*/}
                            {/*<InputNumber value={subject.score}*/}
                            {/*onChange={(data) => {*/}
                            {/*this._handleChange("score", data)*/}
                            {/*}}/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_LIMIT_TIME'})}*/}
                            {/*{...Label_6_Wrapper_18}*/}
                            {/*required={true}*/}
                            {/*validateStatus={this.state.messageMap["limitTime"] ? "error" : ""}*/}
                            {/*help={this.state.messageMap["limitTime"]}>*/}
                            {/*<InputNumber value={subject.limitTime}*/}
                            {/*onChange={(data) => {*/}
                            {/*this._handleChange("limitTime", data)*/}
                            {/*}}/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_SEQUENCE'})}
                                       {...Label_6_Wrapper_18}>
                                <InputNumber value={subject.sequence}
                                             onChange={(data) => {
                                                 this._handleChange("sequence", data)
                                             }}/>
                            </Form.Item>
                        </Form>
                    </Tabs.TabPane>
                    {/*<Tabs.TabPane tab={formatMessage({id: "EXAM_SUBJECT_TITLE_CONTENT"})} key="2">*/}
                    {/*{this._renderOptions(subject)}*/}
                    {/*</Tabs.TabPane>*/}
                    {this.props.showDetailModal ?
                        <Tabs.TabPane tab={formatMessage({id: "EXAM_SUBJECT_TITLE_SUMMARY"})} key="2">
                            <RichTextEditor value={subject.summary}
                                            onChange={(value) => this._handleChange("summary", value)}/>
                        </Tabs.TabPane>
                        : null}
                    <Tabs.TabPane tab={formatMessage({id: "EXAM_SUBJECT_TITLE_FILE"})} key="3">
                        {this._renderFiles(subject)}
                    </Tabs.TabPane>
                    {this.props.showDetailModal && (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF
                        || subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT ||
                        subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL||
                        subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL) ?
                        <Tabs.TabPane tab={formatMessage({id: "EXAM_SUBJECT_TITLE_IMAGE"})} key="4">
                            <SubjectContentShow/>
                        </Tabs.TabPane> : null
                    }

                </Tabs>
            </Modal>
        );
    }

    _renderFiles(subject) {
        const filePaths = subject.filePath ? JSON.parse(subject.filePath) : [];
        return (
            <Form>
                <Form.Item>
                    {
                        filePaths && filePaths.map((item, index) => {
                            return (
                                <Row key={index} gutter={16}>
                                    <Col span={20}>
                                        <Input value={item}
                                               onChange={(data) => {
                                                   this._handleChangeFilePath(index, data)
                                               }}/>
                                    </Col>
                                    <Col span={4}>
                                        <Button icon="close" size="small"
                                                onClick={() => this._handleRemoveFile(index)}>
                                        </Button>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Button icon="plus" size="small" onClick={() => this._handleAddFile()}><FormattedMessage
                        id="COMMON_BUTTON_ADD"/></Button>
                </Form.Item>
            </Form>
        )
    }

    _handleAddFile() {
        const subject = Object.assign({}, this.props.subject);
        let filePaths = [];
        if (subject.filePath) {
            filePaths = JSON.parse(subject.filePath);
        }
        filePaths.push("");
        subject.filePath = JSON.stringify(filePaths);
        this.props.dispatch(refreshSubject(subject));
    }

    _handleRemoveFile(index) {
        const subject = Object.assign({}, this.props.subject);
        const filePaths = JSON.parse(subject.filePath);
        filePaths.splice(index, 1);
        subject.filePath = JSON.stringify(filePaths);
        this.props.dispatch(refreshSubject(subject));
    }

    _renderOptions(subject) {
        if (subject.type === SubjectType.Enum.SINGLE_CHOICE || subject.type === SubjectType.Enum.MULTIPLE_CHOICE || subject.type === SubjectType.Enum.JUDGMENT) {
            const options = subject.content ? JSON.parse(subject.content) : [];
            return (
                <Form>
                    <Form.Item>
                        {
                            options && options.map((item, index) => {
                                return (
                                    <Row key={index} gutter={16}>
                                        <Col span={14}>
                                            <Input value={item.title}
                                                   onChange={(data) => {
                                                       this._handleChangeOption(index, "title", data)
                                                   }}/>
                                        </Col>
                                        <Col span={10}>
                                            <Checkbox checked={item.rightFlag === 1}
                                                      onChange={(data) => {
                                                          this._handleChangeOption(index, "rightFlag", data.target.checked ? 1 : 0)
                                                      }}>
                                                <FormattedMessage id="EXAM_SUBJECT_OPTION_FIELD_RIGHT_FLAG"/>
                                            </Checkbox>
                                            <Button icon="close" size="small"
                                                    onClick={() => this._handleRemoveOption(index)}>
                                            </Button>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                        <Button icon="plus" size="small" onClick={() => this._handleAddOption()}><FormattedMessage
                            id="EXAM_SUBJECT_BUTTON_OPTIONS"/></Button>
                    </Form.Item>
                </Form>
            )
        } else {
            return (
                <RichTextEditor value={subject.content} onChange={(value) => this._handleChange("content", value)}/>
            );
        }
    }

    _handleAddOption() {
        const subject = Object.assign({}, this.props.subject);
        let options = [];
        if (subject.content) {
            options = JSON.parse(subject.content);
        }
        options.push({});
        subject.content = JSON.stringify(options);
        this.props.dispatch(refreshSubject(subject));
    }

    _handleRemoveOption(index) {
        const subject = Object.assign({}, this.props.subject);
        const options = JSON.parse(subject.content);
        options.splice(index, 1);
        subject.content = JSON.stringify(options);
        this.props.dispatch(refreshSubject(subject));
    }
}

function mapStateToProps(store) {
    return {
        banks: store.ExamBankReducer.banks,
        searchCondition: store.ExamSubjectReducer.searchCondition,
        showDetailModal: store.ExamSubjectReducer.showDetailModal,
        subject: store.ExamSubjectReducer.subject
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));