import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Table, Divider, Popconfirm, Modal} from 'antd';

import PaginationBar from "../../../../components/PaginationBar";

import {
    getChannel,
    toggleDetailModal,
    deleteChannel,
    searchChannels,
    refreshSearchCondition,
    getChannelTree, refreshChannel
} from '../../actions/ChannelAction';
import {CACHE_SITE_ID} from "../../../../constants/Profile";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'CMS_CHANNEL_FIELD_CODE'}), dataIndex: 'code', key: 'code'},
                {title: formatMessage({id: 'CMS_CHANNEL_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'CMS_CHANNEL_FIELD_PARENT'}),
                    dataIndex: 'parent.title',
                    key: 'parent.title'
                },
                {title: formatMessage({id: 'CMS_CHANNEL_FIELD_SEQUENCE'}), dataIndex: 'sequence', key: 'sequence'},
                {
                    title: '',
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {
        // let siteId = localStorage.getItem(CACHE_SITE_ID);
        // this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
        // this.props.dispatch(searchChannels(siteId, searchCondition, this.state));
    }

    _handleDelete(record) {
        const {dispatch, searchCondition} = this.props;
        const {formatMessage} = this.state;
        dispatch(deleteChannel(record.siteId, record.id, searchCondition, formatMessage));
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getChannel(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleCopy(record) {
        record.id = "";
        this.props.dispatch(refreshChannel(record));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        return (
            <div className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>

                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.channels}
                       pagination={false}/>
            </div>
        )
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleCopy.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_COPY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handlePagination(page, pageSize) {

        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;

        this.props.dispatch(refreshSearchCondition(searchCondition));
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(searchChannels(siteId, searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        channels: store.CmsChannelReducer.channels,
        channelTree: store.CmsChannelReducer.channelTree,
        searchCondition: store.CmsChannelReducer.searchCondition,
        pagination: store.CmsChannelReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));