/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Select} from 'antd';

import {searchRoles} from '../actions/RoleAction';

class RoleSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(searchRoles({}, this.state.formatMessage));
    }

    _handleChange(values) {
        this.props.onChange && this.props.onChange.call(this, values);
    }

    render() {
        return (
            <Select
                mode={this.props.mode}
                value={this.props.value}
                onChange={(value) => this._handleChange(value)}>
                {
                    this.props.roles.map((item, i) => {
                        return (
                            <Select.Option key={i} value={item.id}>{item.name}</Select.Option>
                        )
                    })
                }
            </Select>
        );
    }
}

function mapStateToProps(store) {
    return {
        roles: store.AccountRoleReducer.roles
    }
}

export default connect(mapStateToProps)(injectIntl(RoleSelector));