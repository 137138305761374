import * as ActionTypes from '../ActionTypes';

const initialState = {
    searchCondition: {},
    pagination: {
        pageSize: 15,
        currentPage: 1,
        recordSum: 0
    },
    propertyCompanies: [],
    isLoading: false,
    organizationsTree: [],
    organizationIdMap: {},
    organizations: [],
    organization: {},
    showDetailModal: false,
    expandedKeys: []
};

const OrganizationReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ORG_ORGANIZATION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ORG_PROPERTY_COMPANY_SEARCH:
            return Object.assign({}, state, {
                propertyCompanies: action.propertyCompanies
            });
        case ActionTypes.ORG_ORGANIZATION_TREE:
            return Object.assign({}, state, {
                organizationsTree: action.organizationsTree
            });
        case ActionTypes.ORG_ORGANIZATION_REFRESH_ID_MAP:
            return Object.assign({}, state, {
                organizationIdMap: action.organizationIdMap
            });
        case ActionTypes.ORG_ORGANIZATION_SEARCH:
            return Object.assign({}, state, {
                organizations: action.organizations,
                pagination: action.pagination
            });
        case ActionTypes.ORG_ORGANIZATION_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ORG_ORGANIZATION_REFRESH_ORGANIZATION:
            return Object.assign({}, state, {
                organization: action.organization
            });
        case ActionTypes.ORG_ORGANIZATION_REFRESH_EXPANDED_KEYS:
            return Object.assign({}, state, {
                expandedKeys: action.expandedKeys
            });
        case ActionTypes.ORG_ORGANIZATION_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        default:
            return state
    }
};

export default OrganizationReducer;