import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Row, Col, Modal, Form, Input, InputNumber, Select, Upload, Button, Checkbox} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

import {
    createExaminationSubject, importExaminationSubjectFromBank,
    refreshExaminationSubject,
    toggleDetailModal, toggleImportModal,
    updateExaminationSubject
} from "../../../actions/ExaminationSubjectAction";

import {SubjectType} from "../../../Enums";
import ListSelector from "../../../../../components/ListSelector";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {searchBanks} from "../../../actions/BankAction";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            bankId: "",
            messageMap: {}
        }
    }

    componentWillMount() {
        this.props.dispatch(searchBanks({paginationFlag: false}, this.state.formatMessage));
    }

    componentDidMount() {
    }

    _validate() {
        let messageMap = {};
        if (!this.state.bankId || this.state.bankId === "") {
            messageMap["bankId"] = this.state.formatMessage({id: 'EXAM_EXAMINATION_SUBJECT_MSG_BANK_ID_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleImportModal(false));
    }

    _handleOk() {
        const {examination, searchCondition} = this.props;
        const {bankId, formatMessage} = this.state;
        if (this._validate()) {
            this.props.dispatch(importExaminationSubjectFromBank(examination.id, bankId, searchCondition, formatMessage));
        }
    }

    _handleChange(name, value) {
        const newState = {};
        newState[name] = (value && value.target) ? value.target.value : value;
        this.setState(newState);
    }

    render() {
        const {banks} = this.props;
        const {formatMessage, bankId} = this.state;

        return (
            <Modal
                maskClosable={false}
                title={formatMessage({id: "EXAM_EXAMINATION_SUBJECT_TITLE_IMPORT"})}
                visible={this.props.showImportModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
            >
                <Form>
                    <Form.Item
                        label={formatMessage({id: "EXAM_EXAMINATION_SUBJECT_FIELD_BANK"})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={this.state.messageMap["bankId"] ? "error" : ""}
                        help={this.state.messageMap["bankId"]}>
                        <ListSelector value={bankId} data={banks}
                                      onChange={(value) => this._handleChange("bankId", value)}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        banks: store.ExamBankReducer.banks,
        examination: store.ExamExaminationReducer.examination,
        searchCondition: store.ExamExaminationSubjectReducer.searchCondition,
        showImportModal: store.ExamExaminationSubjectReducer.showImportModal
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));