import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.ACCOUNT_DATA_GROUP_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshDataGroup(dataGroup) {
    return {
        type: ActionTypes.ACCOUNT_DATA_GROUP_REFRESH_DATA_GROUP,
        dataGroup: dataGroup
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.ACCOUNT_DATA_GROUP_SEARCH_CONDITION,
        searchCondition: condition
    }
}

export function searchDataGroups(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchDataGroups, condition, (response) => {
            dispatch({
                type: ActionTypes.ACCOUNT_DATA_GROUP_SEARCH,
                dataGroups: response.data,
                pagination: response.pagination
            });
            dispatch(refreshSearchCondition(condition));
            dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getDataGroupTree(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: true});

        HttpUtils.get(WebService.getDataGroupTree, (response) => {
            dispatch({
                type: ActionTypes.ACCOUNT_DATA_GROUP_TREE,
                dataGroupTree: response.data
            });

            dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getDataGroupById(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getDataGroupById, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshDataGroup(response.data));
            dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createDataGroup(dataGroup, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: true});

        HttpUtils.post(WebService.createDataGroup, dataGroup, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch(getDataGroupTree(formatMessage));
            dispatch(searchDataGroups(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: false});

        }, (response) => {
        }, formatMessage)
    }
}

export function updateDataGroup(dataGroup, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateDataGroup, dataGroup, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch(getDataGroupTree(formatMessage));
            dispatch(searchDataGroups(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteDataGroup(dataGroup, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: true});

        HttpUtils.post(WebService.deleteDataGroup, dataGroup, (response) => {
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch(getDataGroupTree(formatMessage));
            dispatch(searchDataGroups(condition, formatMessage));
            dispatch({type: ActionTypes.ACCOUNT_DATA_GROUP_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}


