import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

import {refreshSubjects} from "../../../../actions/PaperSubjectAction";

import {PaperStatus, PaperSubjectStatus, SubjectType} from "../../../../Enums";

import "./PaperSubjectOptions.css";
import {Input} from "antd";

class PaperSubjectOptions extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentWillMount() {
    }

    _handleClick(item, index) {

        const subjects = Object.assign([], this.props.subjects);
        const paperSubject = subjects[this.props.subjectIndex];

        // 选择题的回答是一个选项数组，如果没有回答过，则先初始化成数组
        if (!paperSubject.answer) {
            paperSubject.answer = [];
        } else {
            paperSubject.answer = JSON.parse(paperSubject.answer);
        }

        // 单选题或者判断题，则直接把当前选中的index，作为答案
        // 多选题需要做累计
        if (paperSubject.subject.type === SubjectType.Enum.MULTIPLE_CHOICE) {
            if (paperSubject.answer.includes(index.toString())) {
                paperSubject.answer.splice(paperSubject.answer.indexOf(index.toString()), 1);
            } else {
                paperSubject.answer.push(index.toString());
            }
        } else {
            paperSubject.answer = [];
            paperSubject.answer.push(index.toString());
        }

        // 回答作为字符串存储
        paperSubject.answer = JSON.stringify(paperSubject.answer);
        paperSubject.status = PaperSubjectStatus.Enum.Completed;
        this.props.dispatch(refreshSubjects(subjects));
    }

    _handleChange(name, value) {
        const subjects = Object.assign([], this.props.subjects);
        const paperSubject = subjects[this.props.subjectIndex];
        paperSubject[name] = (value && value.target) ? value.target.value : value;
        if (paperSubject[name] && paperSubject[name] !== "") {
            paperSubject.status = PaperSubjectStatus.Enum.Completed;
        } else {
            paperSubject.status = PaperSubjectStatus.Enum.New;
        }


        this.props.dispatch(refreshSubjects(subjects));
    }

    _handleText(paperSubject) {

        return <Input.TextArea value={paperSubject.answer}
                               onChange={(data) => {
                                   this._handleChange("answer", data)
                               }}/>
    }

    _handleChoice(subjects, paperSubject) {
        const options = paperSubject && paperSubject.subject ? JSON.parse(paperSubject.subject.content) : [];
        const answers = paperSubject && paperSubject.answer ? JSON.parse(subjects[this.props.subjectIndex].answer) : [];

        return <div>
            {
                options && options.map((item, index) => {
                    const className = (answers.includes(index.toString())) ? "selected" : "default";
                    return <div key={index}
                                className={className}
                                onClick={() => this._handleClick(item, index)}>{String.fromCharCode(index + 65)}. {item.title}</div>
                })
            }
        </div>

    }

    render() {
        const {subjects, subjectIndex} = this.props;
        const paperSubject = subjects[this.props.subjectIndex] ? subjects[this.props.subjectIndex] : {};
        const subject = paperSubject.subject ? paperSubject.subject : {};

        return (
            <div>
                {
                    subject.type !== SubjectType.Enum.QA ?
                        this._handleChoice(subjects, paperSubject) :
                        this._handleText(paperSubject)
                }
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        subjects: store.ExamPaperSubjectReducer.subjects
    }
}

export default connect(mapStateToProps)(injectIntl(PaperSubjectOptions));