import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false
};

const FileTransferReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.FTP_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        default:
            return state
    }
};

export default FileTransferReducer;