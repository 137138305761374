import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Col, Row,Form, Modal, Spin, Upload} from "antd";

import moment from "moment";
import {FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {aliOSSPolicyUpload} from "../../../../FTP/actions/FileTransferAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import SubjectContentItemParts from "./SubjectContentItemParts";
import {refreshSubject, refreshSubjectContentItem, toggleContentItemEditModal} from "../../../actions/SubjectAction";
import FieldSelect from "../../../../../components/FieldSelect";
import {SubjectImagePageIndex} from "../../../Enums";

class SubjectContentEditModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            imagePath: "",
            fileList: [],
            spinning: false
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _handleOk() {
        let contentItem = this.props.contentItem;
        let subject = Object.assign({}, this.props.subject);
        let contentObject = Object.assign({}, subject.contentObject);
        let itemList = Object.assign([], contentObject.items);
        let currentIndex = -1;
        itemList.forEach((item, index) => {
            if (item.key === contentItem.key) {
                currentIndex = index;
            }
        });
        if (currentIndex >= 0) {
            itemList[currentIndex] = contentItem;
        } else {
            itemList.push(contentItem);
        }
        contentObject.items = itemList;
        subject.contentObject = contentObject;
        this.props.dispatch(refreshSubject(subject));
        this._handleCancel();
    };

    _handleCancel() {
        this.props.dispatch(toggleContentItemEditModal(false));
        this.setState({fileList: [], spinning: false, imagePath: ""});

    };

    //
    // _handleOk() {
    //     const {selectedZonePhoto, selectedZonePhotoIndex} = this.props;
    //     const zone = Object.assign({}, this.props.zone);
    //
    //
    //     if (this.state.spinning) {
    //         const formatMessage = this.state.formatMessage;
    //         message.error(formatMessage({id: "COMMON_MSG_UPLOAD_CONTINUE"}));
    //     } else {
    //
    //         if (this._validate(selectedZonePhoto)) {
    //
    //           let photoFiles = Object.assign([], zone.photoFiles);
    //             if (selectedZonePhotoIndex < 0) {
    //                 // 说明是新增
    //                 photoFiles.push(selectedZonePhoto);
    //             } else {
    //                 photoFiles[selectedZonePhotoIndex] = selectedZonePhoto;
    //             }
    //             zone.photoFiles = photoFiles;
    //             this.props.dispatch(refreshZone(zone));
    //             this.props.dispatch(toggleZonePhotoModal(false));
    //         }
    //     }
    // }

    _handleChange(name, value) {



        let contentItem = Object.assign({}, this.props.contentItem);
        contentItem[name] = value && value.target ? value.target.value : value;

        this.props.dispatch(refreshSubjectContentItem(contentItem));
    }

    render() {
        const formatMessage = this.state.formatMessage;

        const self = this;

        let contentItem = this.props.contentItem? this.props.contentItem:{};
        const policyUploadProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: (file, fileList) => {
                self.setState({spinning: true});
                self.props.dispatch(aliOSSPolicyUpload(file, "mianhuatang/Subject/" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE),
                    self.state.formatMessage, (url) => {
                        let contentItem = Object.assign({}, this.props.contentItem);
                        contentItem.imagePath =url;

                        this.props.dispatch(refreshSubjectContentItem(contentItem));
                        self.setState({fileList: fileList, spinning: false, imagePath: url});
                    }));
                return false;
            },
        };

        const uploadButton = (
            <Button>
                {/*{this.state.spinning ? <LoadingOutlined/> : <PlusOutlined/>}*/}
                <div className="ant-upload-text"><FormattedMessage id="COMMON_BUTTON_UPLOAD"/></div>
            </Button>
        );
        let modalWidth = document.documentElement.clientWidth - 100;
        modalWidth = modalWidth > 1300 ? 1300 : modalWidth;
        return (
            <Modal
                title={"题目编辑"}
                width={modalWidth}
                visible={this.props.showContentItemEditModal} maskClosable={false}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_SAVE"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Spin spinning={this.state.spinning} tip={formatMessage({id: "COMMON_MSG_UPLOAD_CONTINUE"})}>
                       <Row> <Col span={12}><Form.Item label={"图片"} {...Label_6_Wrapper_18}>
                            <Upload
                                fileList={this.state.fileList}
                                {...policyUploadProps}>
                                {uploadButton}
                            </Upload>
                       </Form.Item></Col>
                           <Col span={12}>
                               <Form.Item label={"所属页"} {...Label_6_Wrapper_18}>
                               <FieldSelect data={SubjectImagePageIndex.List} value={contentItem.pageIndex}    style={{width: 200}}
                                            onChange={(value) => this._handleChange("pageIndex", value)}/> </Form.Item>
                       </Col></Row>

                        <SubjectContentItemParts/>
                    </Spin>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        contentItem: store.ExamSubjectReducer.contentItem,
        subject: store.ExamSubjectReducer.subject,
        showContentItemEditModal: store.ExamSubjectReducer.showContentItemEditModal,

    }
}

export default connect(mapStateToProps)(injectIntl(SubjectContentEditModal));
