import React, {Component} from 'react';
import {TreeSelect} from 'antd';
import {Language} from "../constants/Enums";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

class TreeSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    componentWillMount() {
    }

    // onSelect = (value) => {
    //     console.log(value);
    //     this.props.dispatch(selectListItem(value));
    //     this.props.onSelect && this.props.onSelect.call(this, value);
    // };

    onChange = (value) => {
        // this.setState({ value });
        // this.props.dispatch(selectListItem(value));
        if (!value){
            value = "";
        }
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <div>
                <TreeSelect
                    allowClear
                    multiple={this.props.multiple}
                    value={this.props.value}
                    treeDefaultExpandAll
                    // onSelect={this.onSelect}
                    onChange={this.onChange}>
                    {
                        this.props.treeData.map((item, i) => {
                            const title = item.title || item.name || (this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn);
                            return (
                                <TreeSelect.TreeNode value={item.id} title={title} key={item.id}
                                                     disabled={item.disabled === 1}>
                                    {this.renderChildrenlTreeNode(item)}
                                </TreeSelect.TreeNode>
                            )
                        })
                    }
                </TreeSelect>
            </div>
        );
    }

    renderChildrenlTreeNode(item) {
        if (item.children && item.children.length > 0) {
            return (
                item.children.map((item, i) => {
                    const title = item.title || item.name || (this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn);
                    return (
                        <TreeSelect.TreeNode value={item.id} title={title} key={item.id}
                                             disabled={item.disabled === 1}>
                            {this.renderChildrenlTreeNode(item)}
                        </TreeSelect.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage
    }
}

export default connect(mapStateToProps)(injectIntl(TreeSelector));