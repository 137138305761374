import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

import {
    createExaminationSubject,
    refreshExaminationSubject,
    toggleDetailModal,
    updateExaminationSubject
} from "../../../actions/ExaminationSubjectAction";

import {AttentionAssessmentSubjectType, SubjectType} from "../../../Enums";
import SubjectSelect from "../../Subject/components/SubjectSelect";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
    }

    _validate(subject) {
        let messageMap = {};
        if (!subject.title || subject.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'EXAM_SUBJECT_MSG_TITLE_REQUIRED'});
        }
        if (!subject.type || subject.type === "") {
            messageMap["type"] = this.state.formatMessage({id: 'EXAM_SUBJECT_MSG_TYPE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {subject, searchCondition, examination} = this.props;
        const {formatMessage} = this.state;
        if (this._validate(subject)) {
            if (subject.id && subject.id !== "") {
                this.props.dispatch(updateExaminationSubject(subject, searchCondition, formatMessage));
            } else {
                subject.examinationId = examination.id;
                this.props.dispatch(createExaminationSubject(subject, searchCondition, formatMessage));
            }
            this.props.dispatch(toggleDetailModal(false));
        }
    }

    _handleChange(name, value) {
        const examinationSubject = Object.assign({}, this.props.subject);
        examinationSubject[name] = (value && value.target) ? value.target.value : value;
        if (name === 'subjectId') {
            const allSubjectList = this.props.allSubjectList;

            let filter = allSubjectList.filter(item => item.id === examinationSubject.subjectId);
            if (filter.length > 0) {
                let subject = filter[0];
                examinationSubject.type = subject.type;
                examinationSubject.title = subject.title;

                if(subject.type ===AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION||
                    subject.type ===AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING||
                    subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT ){
                    examinationSubject.difficultyLevel = subject.difficultyLevel;

                }
            }
        }
        this.props.dispatch(refreshExaminationSubject(examinationSubject));
    }

    _handleChangeOption(index, name, value) {
        const subject = Object.assign({}, this.props.subject);
        const options = JSON.parse(subject.content);
        options[index][name] = value.target ? value.target.value : value;
        subject.content = JSON.stringify(options);

        this.props.dispatch(refreshExaminationSubject(subject));
    }

    render() {
        const {subject} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = formatMessage({id: 'EXAM_SUBJECT_TITLE_CREATE'});
        let isUpdateFlag = subject.id && subject.id !== "";
        if (isUpdateFlag) {
            title = formatMessage({id: 'EXAM_SUBJECT_TITLE_UPDATE'});
        }
        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
            >
                <Form>
                    <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_SUBJECT_SELECT'})}
                               {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["type"] ? "error" : ""}
                               help={this.state.messageMap["type"]}>

                         <SubjectSelect  value={subject.subjectId}  disabled={isUpdateFlag} onChange={(data) => {
                             this._handleChange("subjectId", data)
                         }}/>

                    </Form.Item>
                    {/*<Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_TYPE'})}*/}
                               {/*{...Label_6_Wrapper_18}*/}
                               {/*required={true}*/}
                               {/*validateStatus={this.state.messageMap["type"] ? "error" : ""}*/}
                               {/*help={this.state.messageMap["type"]}>*/}
                        {/*<EnumItemSelect value={subject.type} disabled={true}*/}
                                        {/*data={AttentionAssessmentSubjectType.List}*/}
                                        {/*onChange={(data) => {*/}
                                            {/*this._handleChange("type", data)*/}
                                        {/*}}/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_TITLE'})}
                               {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["title"] ? "error" : ""}
                               help={this.state.messageMap["title"]}>
                        <Input.TextArea autosize={{minRows: 2, maxRows: 6}} value={subject.title}
                                        onChange={(data) => {
                                            this._handleChange("title", data)
                                        }}/>
                    </Form.Item>

                    {subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO ?
                        <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_DIFFICULTY_LEVEL'})}
                                   {...Label_6_Wrapper_18} required={true}
                                   validateStatus={this.state.messageMap["difficultyLevel"] ? "error" : ""}
                                   help={this.state.messageMap["difficultyLevel"]}>
                            NoGo <InputNumber max={50} min={0} value={subject.difficultyLevel}
                                              onChange={(data) => {
                                                  this._handleChange("difficultyLevel", data)
                                              }}/>
                            <span> 题，GO <span
                                style={{textDecoration: 'underline'}}>{50 - subject.difficultyLevel}</span> 题 , 系数{subject.difficultyLevel ? Math.round(subject.difficultyLevel * 100 / 50) + "%" : ''}</span>
                        </Form.Item>
                        : null}

                    {subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING||
                    subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION||
                    subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT ?
                        <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_DIFFICULTY_LEVEL'})}
                                   {...Label_6_Wrapper_18} required={true}
                                   validateStatus={this.state.messageMap["difficultyLevel"] ? "error" : ""}
                                   help={this.state.messageMap["difficultyLevel"]}>
                            <InputNumber max={3} min={1} value={subject.difficultyLevel}
                                         onChange={(data) => {
                                             this._handleChange("difficultyLevel", data)
                                         }}/>
                            <span>(1 ~ 3) </span>
                        </Form.Item>
                        : null}
                    <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_SEQUENCE'})}
                               {...Label_6_Wrapper_18}>
                        <InputNumber value={subject.sequence}
                                     onChange={(data) => {
                                         this._handleChange("sequence", data)
                                     }}/>
                    </Form.Item>
                </Form>
                {/*{this._renderOptions(subject)}*/}
            </Modal>
        );
    }

    _renderOptions() {
        const {subject} = this.props;
        const formatMessage = this.state.formatMessage;
        if (subject.type === SubjectType.Enum.SINGLE_CHOICE || subject.type === SubjectType.Enum.MULTIPLE_CHOICE || subject.type === SubjectType.Enum.JUDGMENT) {
            const options = subject.content ? JSON.parse(subject.content) : [];
            return (
                <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_OPTION'})}
                           {...Label_6_Wrapper_18}>
                    {
                        options && options.map((item, index) => {
                            return (
                                <Row key={index} gutter={16}>
                                    <Col span={14}>
                                        <Input value={item.title}
                                               onChange={(data) => {
                                                   this._handleChangeOption(index, "title", data)
                                               }}/>
                                    </Col>
                                    <Col span={10}>
                                        <Checkbox checked={item.rightFlag === 1}
                                                  onChange={(data) => {
                                                      this._handleChangeOption(index, "rightFlag", data.target.checked ? 1 : 0)
                                                  }}>
                                            <FormattedMessage id="EXAM_SUBJECT_OPTION_FIELD_RIGHT_FLAG"/>
                                        </Checkbox>
                                        <Button icon="close" size="small"
                                                onClick={() => this._handleRemoveOption(index)}>
                                        </Button>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Button icon="plus" size="small" onClick={() => this._handleAddOption()}><FormattedMessage
                        id="EXAM_SUBJECT_BUTTON_OPTIONS"/></Button>
                </Form.Item>
            )
        } else {
            return (
                <Form.Item label={formatMessage({id: 'EXAM_SUBJECT_FIELD_CONTENT'})}
                           {...Label_6_Wrapper_18}>
                    <Input.TextArea autosize={{minRows: 2, maxRows: 6}} value={subject.content}
                                    onChange={(data) => {
                                        this._handleChange("content", data)
                                    }}/>
                </Form.Item>
            );
        }
    }

    _handleAddOption() {
        const subject = Object.assign({}, this.props.subject);
        let options = [];
        if (subject.content) {
            options = JSON.parse(subject.content);
        }
        options.push({});
        subject.content = JSON.stringify(options);
        this.props.dispatch(refreshExaminationSubject(subject));
    }

    _handleRemoveOption(index) {
        const subject = Object.assign({}, this.props.subject);
        const options = JSON.parse(subject.content);
        options.splice(index, 1);
        subject.content = JSON.stringify(options);
        this.props.dispatch(refreshExaminationSubject(subject));
    }
}

function mapStateToProps(store) {
    return {
        examination: store.ExamExaminationReducer.examination,
        searchCondition: store.ExamExaminationSubjectReducer.searchCondition,
        showDetailModal: store.ExamExaminationSubjectReducer.showDetailModal,
        subject: store.ExamExaminationSubjectReducer.subject,
        allSubjectList: store.ExamSubjectReducer.allSubjectList,

    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));