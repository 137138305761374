import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {HttpStatus} from '../../../constants/Enums';
import HttpUtils from '../../../utils/HttpUtils';
import {message, Modal} from 'antd';

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.CMS_CHANNEL_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshChannel(channel) {
    return {
        type: ActionTypes.CMS_CHANNEL_REFRESH_CHANNEL,
        channel: channel
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.CMS_CHANNEL_REFRESH_SEARCH_CONDITION,
        searchCondition: condition
    }
}

export function selectTreeNode(selectedKey) {
    return {
        type: ActionTypes.CMS_CHANNEL_TREE_SELECT,
        selectTreeKey: selectedKey
    }
}

export function selectListItem(selectItemKey) {
    return {
        type: ActionTypes.CMS_CHANNEL_LIST_SELECT,
        selectItemKey: selectItemKey
    }
}

export function searchChannels(siteId, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: true});

        const requestUrl = WebService.searchChannels.replace("{siteId}", siteId);
        HttpUtils.post(requestUrl, condition, (response) => {
            dispatch({
                type: ActionTypes.CMS_CHANNEL_SEARCH,
                channels: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchSiteRootChannels(siteId, state) {
    return dispatch => {
        dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: true});

        const requestUrl = WebService.getChannelTree.replace("{siteId}", siteId);
        HttpUtils.get(requestUrl, (response) => {
            if (response.status === HttpStatus.SUCCESS) {
                dispatch({
                    type: ActionTypes.CMS_CHANNEL_SEARCH,
                    channels: response.data,
                    pagination: {}
                });
            } else {
                Modal.error({
                    title: "ERROR",
                    content: state.formatMessage({id: response.data}),
                    okText: "OK"
                });
            }

            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        })
    }
}

export function getChannelTree(siteId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: true});

        const requestURL = WebService.getChannelTree.replace("{siteId}", siteId);

        HttpUtils.get(requestURL, (response) => {
            // // 计算所有的展开节点
            // let expandedKeys = [];
            // expandedKeys.push(siteId);
            // for (let i = 0; i < response.data.length; i++) {
            //     const item = response.data[i];
            //     expandedKeys.push(item.id);
            //     pushChildrenExpandedKeys(expandedKeys, item.children);
            // }

            dispatch({type: ActionTypes.CMS_CHANNEL_TREE, channelTree: response.data});

            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

//
// function pushChildrenExpandedKeys(expandedKeys, children) {
//     if (children !== null && children.length > 0) {
//         for (let i = 0; i < children.length; i++) {
//             const item = children[i];
//             expandedKeys.push(item.id);
//             pushChildrenExpandedKeys(expandedKeys, item.children);
//         }
//     }
// }

export function getChannel(channelId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: true});

        const requestUrl = WebService.getChannel.replace("{id}", channelId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshChannel(response.data));
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, (error) => {
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createChannel(siteId, channel, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: true});

        const params = Object.assign({}, channel);

        const requestURL = WebService.createChannel.replace("{siteId}", siteId);
        console.log(requestURL);
        HttpUtils.post(requestURL, params, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getChannelTree(siteId, formatMessage));
            dispatch(searchChannels(siteId, condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));

            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, (error) => {
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateChannel(siteId, channel, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: true});

        const params = Object.assign({}, channel);

        const requestURL = WebService.updateChannel.replace("{id}", channel.id);
        HttpUtils.put(requestURL, params, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getChannelTree(siteId, formatMessage));
            dispatch(searchChannels(siteId, condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));

            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, (error) => {
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteChannel(siteId, channelId, condition, formatMessage) {

    return dispatch => {
        let requestURL = WebService.deleteChannel.replace("{id}", channelId);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(getChannelTree(siteId, formatMessage));
            dispatch(searchChannels(siteId, condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (error) => {
            dispatch({type: ActionTypes.CMS_CHANNEL_LOADING, isLoading: false});
        }, formatMessage);
    }
}




