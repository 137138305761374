/**
 * Created by Ethan on 16/12/23.
 * 根Reducer，把所有的Reducer合并返回
 */

import {combineReducers} from 'redux';

// Account
import AppReducer from './AppReducer';
import AccountUserReducer from '../modules/Account/reducers/UserReducer';
import AccountRoleReducer from '../modules/Account/reducers/RoleReducer';
import AccountResourceReducer from "../modules/Account/reducers/ResourceReducer";
import AccountGroupReducer from '../modules/Account/reducers/DataGroupReducer';

// 字典项
import DictTypeReducer from "../modules/Dict/reducers/DictTypeReducer";
import DictClassReducer from "../modules/Dict/reducers/DictClassReducer";
import DictItemReducer from "../modules/Dict/reducers/DictItemReducer";

// 组织管理
import OrgOrganizationReducer from "../modules/ORG/reduers/OrganizationReducer";
import OrgEmployeeReducer from "../modules/ORG/reduers/EmployeeReducer";
import OrgPositionReducer from "../modules/ORG/reduers/PositionReducer";

import FtpFileTransferReducer from "../modules/FTP/reducers/FileTransferReducer";

// 邮件模版
import MailPropertiesReducer from "../modules/Mail/reducers/PropertiesReducer";
import MailTemplateReducer from "../modules/Mail/reducers/TemplateReducer";

// 内容管理
import CmsSiteReducer from '../modules/CMS/reducers/SiteReducer';
import CmsChannelReducer from '../modules/CMS/reducers/ChannelReducer';
import CmsContentReducer from '../modules/CMS/reducers/ContentReducer';
import CmsContentFileReducer from '../modules/CMS/reducers/ContentFileReducer';
//课程管理
import LessonTemplateReducer from "../modules/Exam/reducers/LessonTemplateReducer";
import ExamineeLessonReducer from "../modules/Exam/reducers/ExamineeLessonReducer";
// 测评系统
import ExamBankReducer from "../modules/Exam/reducers/BankReducer";
import ExamSubjectReducer from '../modules/Exam/reducers/SubjectReducer';
import ExamExamineeReducer from '../modules/Exam/reducers/ExamineeReducer';
import ExamExaminationReducer from '../modules/Exam/reducers/ExaminationReducer';
import ExamExaminationSubjectReducer from "../modules/Exam/reducers/ExaminationSubjectReducer";
// import ExamExaminationBankReducer from "../modules/Exam/reducers/ExaminationBankReducer";
import ExamPaperReducer from '../modules/Exam/reducers/PaperReducer';
import ExamPaperSubjectReducer from '../modules/Exam/reducers/PaperSubjectReducer';
import ExamDataModelReducer from "../modules/Exam/reducers/DataModelReducer";

const RootReducer = combineReducers({
    AppReducer,

    DictTypeReducer,
    DictClassReducer,
    DictItemReducer,

    AccountUserReducer,
    AccountRoleReducer,
    AccountResourceReducer,
    AccountGroupReducer,

    // 组织管理
    OrgOrganizationReducer,
    OrgEmployeeReducer,
    OrgPositionReducer,

    FtpFileTransferReducer,

    MailPropertiesReducer,
    MailTemplateReducer,

    // 内容管理
    CmsSiteReducer,
    CmsChannelReducer,
    CmsContentReducer,
    CmsContentFileReducer,

    // 测评系统
    ExamBankReducer,
    ExamSubjectReducer,
    ExamExamineeReducer,
    ExamExaminationReducer,
    // ExamExaminationBankReducer,
    ExamExaminationSubjectReducer,
    ExamPaperReducer,
    ExamPaperSubjectReducer,
   ExamDataModelReducer,

    //课程管理
    LessonTemplateReducer,
    //课时
    ExamineeLessonReducer

});

export default RootReducer;