import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col} from "antd";


import {Label_4_Wrapper_20, Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {searchDataModels,refreshSearchCondition} from "../../../actions/ExamDataModelAction";
import FieldRadioGroup from "../../../../../components/FieldRadioGroup";
import {ExamDataModelGrade, ExamDataModelType} from "../../../Enums";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        const {searchCondition} = this.props;

        this.props.dispatch(searchDataModels(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
        this.props.dispatch(searchDataModels(searchCondition, this.state.formatMessage));
    }

    // _handleShowCreateModal() {
    //     this.props.dispatch(initExamination());
    //     this.props.dispatch(toggleDetailModal(true));
    // }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_DATA_MODEL_FILED_TYPE"})} {...Label_4_Wrapper_20}>
                                <FieldRadioGroup value={searchCondition.type}
                                                 data={ExamDataModelType.List}
                                                 onChange={(value) => this._handleChange("type", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_DATA_MODEL_FILED_GRADE"})} {...Label_4_Wrapper_20}>
                                <FieldRadioGroup value={searchCondition.grade}
                                                 data={ExamDataModelGrade.List}
                                                 onChange={(value) => this._handleChange("grade", value)}/>
                            </Form.Item>
                        </Col>
                        {/*<Col span={8}>*/}
                            {/*<Form.Item>*/}
                                {/*<Button type="primary" icon="search" className="spacing-h"*/}
                                        {/*onClick={() => this._handleSearch()}>*/}
                                    {/*<FormattedMessage id='COMMON_BUTTON_SEARCH'/>*/}
                                {/*</Button>*/}
                             {/**/}
                            {/*</Form.Item>*/}
                        {/*</Col>*/}
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.ExamDataModelReducer.searchCondition,
        pagination: store.ExamDataModelReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));