import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {NavBar} from 'antd-mobile';

import PaperSubject from "./PaperSubject";
import {getMyExaminationPaper} from "../../../../actions/PaperAction";
import {LeftOutline} from "antd-mobile-icons";
import {RoutePath} from "../../../../../../constants/RoutePath";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.dispatch(getMyExaminationPaper(examinationId, examineeId, this.state.formatMessage));
    }

    componentWillUnmount() {
    }

    _handleBack() {
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.router.push({
            pathname: RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY_SUCCESS.path,
            query: {examinationId: examinationId, examineeId: examineeId}
        })
    }

    render() {
        const {paper, subjects, currentIndex} = this.props;
        return (
            <div>
                <NavBar
                    icon={<LeftOutline/>}
                    onBack={() => this._handleBack()}>{paper.title}</NavBar>
                <PaperSubject index={currentIndex} paperSubject={subjects[currentIndex]}
                              sum={subjects.length} {...this.props}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper,
        subjects: store.ExamPaperSubjectReducer.subjects,
        currentIndex: store.ExamPaperSubjectReducer.currentIndex
    }
}

export default connect(mapStateToProps)(injectIntl(Index));