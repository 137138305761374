/**
 * Created by Ethan on 2017/9/8.
 */

import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
    currentLanguage: '',
    menuItems: [],
    menuItemMap: {}
};

const AppReducer = (state = initialState, action = 'none') => {

    switch (action.type) {
        case ActionTypes.APP_CHANGE_LANGUAGE:
            return Object.assign({}, state, {
                currentLanguage: action.currentLanguage
            });
        case ActionTypes.APP_GET_MAIN_MENU:
            return Object.assign({}, state, {
                menuItems: action.menuItems,
                menuItemMap: action.menuItemMap
            });
        default:
            return state
    }
};

export default AppReducer;