import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, Collapse, Row, Col, Checkbox, Button, Icon} from "antd";
import {refreshPaper, toggleDetailModal} from "../../../actions/PaperAction";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {PaperSubjectStatus, SubjectType} from "../../../Enums";
import RichTextEditor from "../../../../../components/RichTextEditor";
import {Card, Space} from "antd-mobile";
import ReactMarkdown from "react-markdown";
import PaperSubjectVideo from "../../Mobile/Paper/Detail/PaperSubjectVideo";
import PaperOptions from "../../Mobile/Paper/Detail/PaperSubjectOptions";
import {refreshSubjects} from "../../../actions/PaperSubjectAction";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    render() {
        const {paper} = this.props;
        const {formatMessage} = this.state;
        const subjects = Object.assign([], paper.subjects);

        return (
            <Modal
                title={formatMessage({id: 'EXAM_PAPER_TITLE_DETAIL'})}
                maskClosable={false}
                width={1000}
                visible={this.props.showDetailModal}
                onCancel={() => this._handleCancel()}
                footer={[
                    <Button type="primary" key="id" onClick={() => this._handleCancel()}>
                        <FormattedMessage id="COMMON_BUTTON_CLOSE"/>
                    </Button>
                ]}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Collapse defaultActiveKey={[]}>
                    {
                        subjects.map((item, subjectIndex) => {
                            let subject = item.subject ? item.subject : {};

                            let extra = <Icon type="close-circle" style={{fontSize: 20, color: "#f81d22"}}/>
                            if (subject.type === SubjectType.Enum.VIDEO) {
                                extra = <Icon type="check-circle" style={{fontSize: 20, color: "#52c41a"}}/>
                            } else if (subject.type === SubjectType.Enum.QA) {
                                extra = <Icon type="question-circle" style={{fontSize: 20, color: "#1890ff"}}/>
                            } else if (item.answer === undefined || item.answer === "") {
                                extra = <Icon type="exclamation-circle" style={{fontSize: 20, color: "#faad14"}}/>
                            } else if (item.finalScore > 0) {
                                extra = <Icon type="check-circle" style={{fontSize: 20, color: "#52c41a"}}/>
                            }

                            return (
                                <Collapse.Panel header={subject.title} key={subjectIndex}
                                                expandIconPosition="left"
                                                extra={extra}>
                                    {
                                        this._renderChoice(item, subject)
                                    }
                                </Collapse.Panel>
                            )
                        })
                    }

                </Collapse>
            </Modal>
        );
    }

    _renderVideo() {
        return <div>{this.state.formatMessage({id: "EXAM_SUBJECT_FIELD_TYPE_VIDEO"})}</div>
    }

    _renderChoice(paperSubject, subject) {
        if (subject.type === SubjectType.Enum.VIDEO) {
            return <div>{this.state.formatMessage({id: "EXAM_SUBJECT_FIELD_TYPE_VIDEO"})}</div>
        } else if (subject.type === SubjectType.Enum.QA) {
            return <div>{paperSubject.answer}</div>
        } else {
            const options = subject.content ? JSON.parse(subject.content) : [];
            const answers = paperSubject.answer ? JSON.parse(paperSubject.answer) : [];

            return <div>
                {
                    options && options.map((item, index) => {
                        const className = (answers.includes(index.toString())) ? "selected" : "default";
                        return <div key={index}
                                    className={className}>{String.fromCharCode(index + 65)}. {item.title}</div>
                    })
                }
            </div>
        }

    }
}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper,
        showDetailModal: store.ExamPaperReducer.showDetailModal
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));