import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Tabs} from "antd";
import {getExamExamineeLesson} from "../../../actions/ExamineeLessonAction";
import LessonExaminationPaperShowParts from "./LessonExaminationPaperShowParts";
import LessonSubjectShowParts from "./LessonSubjectShowParts";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        const lessonId = this.props.location.query.lessonId;
        this.props.dispatch(getExamExamineeLesson(lessonId, this.state.formatMessage, true));


    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="3">
                    <Tabs.TabPane tab={this.state.formatMessage({id: 'EXAM_EXAMINEE_LESSON_FIELD_DETAILS'})} key="1">
                        <LessonExaminationPaperShowParts/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"反馈报告"} key="2">
                        <LessonSubjectShowParts/>
                    </Tabs.TabPane>

                </Tabs>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));