import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Row, Col, Input, Button} from "antd";

import {refreshChannel, toggleDetailModal, refreshSearchCondition, searchChannels} from '../../actions/ChannelAction';
import {Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";
import {CACHE_SITE_ID} from "../../../../constants/Profile";
import ChannelSelect from "../../components/ChannelSelect";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(searchChannels(siteId, this.props.searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {

        this.props.dispatch(refreshChannel({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "CMS_CHANNEL_FIELD_PARENT"})} {...Label_6_Wrapper_18}>
                                <ChannelSelect multiple={true}
                                               channelTree={this.props.channelTree}
                                               value={searchCondition.channelIds}
                                               onChange={(value) => this._handleChange("channelIds", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "CMS_CONTENT_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.title}
                                       onChange={(value) => this._handleChange("title", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" onClick={() => this._handleSearch()} className="spacing-h">
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            <Button onClick={() => this._handleShowCreateModal()}>
                                <FormattedMessage id='COMMON_BUTTON_CREATE'/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.CmsChannelReducer.searchCondition,
        pagination: store.CmsChannelReducer.pagination,
        channelId: store.CmsChannelReducer.selectTreeKey
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));