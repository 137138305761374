import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, DatePicker} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {
    createExaminee,
    refreshExaminee,
    updateExaminee,
    toggleDetailModal,
    searchExaminees
} from "../../../actions/ExamineeAction";
import {FORMAT_DATE_SIMPLE} from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import FieldSelect from "../../../../../components/FieldSelect";
import {ExamDataModelGrade, ExamineeGender} from "../../../Enums";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(examinee) {
        let messageMap = {};
        if (examinee.lastName === undefined || examinee.lastName === "") {
            messageMap["lastName"] = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_LAST_NAME_REQUIRED'});
        }
        // if (examinee.firstName === undefined || examinee.firstName === "") {
        //     messageMap["firstName"] = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_FIRST_NAME_REQUIRED'});
        // }
        if (examinee.mobile === undefined || examinee.mobile === "") {
            messageMap["mobile"] = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_MOBILE_REQUIRED'});
        }
        if (examinee.birthday === undefined || examinee.birthday === "") {
            messageMap["birthday"] = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_BIRTHDAY_REQUIRED'});
        }
        if (examinee.grade === undefined || examinee.grade === "") {
            messageMap["grade"] = this.state.formatMessage({id: 'COMMON_MSG_BIRTHDAY_REQUIRED'});
        }
        if (examinee.gender === undefined || examinee.gender === "") {
            messageMap["gender"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }
        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {examinee, searchCondition} = this.props;
        const {formatMessage} = this.state;
        if (this._validate(examinee)) {
            if (examinee.id === undefined || examinee.id === "") {
                this.props.dispatch(createExaminee(examinee, formatMessage,
                    () => {
                        if (this.props.refreshFunction) {
                            this.props.refreshFunction.call();
                        } else {
                            this.props.dispatch(searchExaminees(searchCondition, formatMessage));
                        }
                        this.props.dispatch(toggleDetailModal(false));
                    }));
            } else {
                this.props.dispatch(updateExaminee(examinee, formatMessage
                    , () => {
                        if (this.props.refreshFunction) {
                            this.props.refreshFunction.call();
                        } else {
                            this.props.dispatch(searchExaminees(searchCondition, formatMessage));
                        }
                        this.props.dispatch(toggleDetailModal(false));
                    }));
            }

        }
    }

    _handleChange(name, value) {

        const examinee = Object.assign({}, this.props.examinee);
        examinee[name] = value && value.target ? value.target.value : value;

        this.props.dispatch(refreshExaminee(examinee));
    }

    render() {
        const {examinee} = this.props;
        const {formatMessage, messageMap} = this.state;

        let title = formatMessage({id: 'EXAM_EXAMINEE_TITLE_CREATE'});
        if (examinee.id !== undefined && examinee.id !== "") {
            title = formatMessage({id: 'EXAM_EXAMINEE_TITLE_UPDATE'});
        }
        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_NAME'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["lastName"] ? "error" : ""}
                               help={messageMap["lastName"]}>
                        <Input value={examinee.lastName}
                               onChange={(data) => {
                                   this._handleChange("lastName", data)
                               }}/>
                    </Form.Item>

                    {/*<Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_FIRST_NAME'})} {...Label_6_Wrapper_18}*/}
                               {/*required={true}*/}
                               {/*validateStatus={this.state.messageMap["firstName"] ? "error" : ""}*/}
                               {/*help={messageMap["firstName"]}>*/}
                        {/*<Input value={examinee.firstName}*/}
                               {/*onChange={(data) => {*/}
                                   {/*this._handleChange("firstName", data)*/}
                               {/*}}/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_MOBILE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["mobile"] ? "error" : ""}
                               help={messageMap["mobile"]}>
                        <Input value={examinee.mobile}
                               onChange={(data) => {
                                   this._handleChange("mobile", data)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_BIRTHDAY'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["birthday"] ? "error" : ""}
                               help={messageMap["birthday"]}>
                        <DatePicker
                            allowClear
                            defaultValue={moment()}
                            format="YYYY-MM-DD"
                            value={examinee.birthday ? moment(examinee.birthday, FORMAT_DATE_SIMPLE) : null}
                            onChange={(value, dateString) => this._handleChange("birthday", value ? value.format(FORMAT_DATE_SIMPLE) : "")}
                        />
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_GRADE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["grade"] ? "error" : ""}
                               help={messageMap["grade"]}>
                        <FieldSelect value={examinee.grade} data={ExamDataModelGrade.List}
                                     onChange={(data) => {
                                         this._handleChange("grade", data)
                                     }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_GENDER'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["gender"] ? "error" : ""}
                               help={messageMap["gender"]}>
                        <FieldSelect value={examinee.gender} data ={ExamineeGender.List}
                                     onChange={(data) => {
                                         this._handleChange("gender", data)
                                     }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_ID_NUMBER'})} {...Label_6_Wrapper_18}>
                        <Input value={examinee.idNumber}
                               onChange={(data) => {
                                   this._handleChange("idNumber", data)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINEE_FIELD_EMAIL'})} {...Label_6_Wrapper_18}>
                        <Input value={examinee.email}
                               onChange={(data) => {
                                   this._handleChange("email", data)
                               }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        examinee: store.ExamExamineeReducer.examinee,
        showDetailModal: store.ExamExamineeReducer.showDetailModal,
        searchCondition: store.ExamExamineeReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));