const en_US = {

    APP_BRAND: "Zhuo Li Examination System",
    APP_BRAND_COLLAPSED: "EXAM",
    APP_COPYRIGHT: "EXAM Professional",
    APP_MULTI_LANGUAGE: "Please select language",
    APP_MULTI_LANGUAGE_ZH: "中文",
    APP_MULTI_LANGUAGE_EN: "English",

    COMMON_BUTTON_OK: "OK",
    COMMON_BUTTON_ADD: "Add",
    COMMON_BUTTON_CREATE: "Create",
    COMMON_BUTTON_UPDATE: "Update",
    COMMON_BUTTON_SAVE: "Save",
    COMMON_BUTTON_SAVE_DRAFT: "Save As Draft",
    COMMON_BUTTON_MODIFY: "Modify",
    COMMON_BUTTON_EDIT: "Edit",
    COMMON_BUTTON_REMOVE: "Remove",
    COMMON_BUTTON_DELETE: "Delete",
    COMMON_BUTTON_RESTART: "Restart",
    COMMON_BUTTON_SUBMIT: "Submit",
    COMMON_BUTTON_CANCEL: "Cancel",
    COMMON_BUTTON_SEARCH: "Search",
    COMMON_BUTTON_SAVEANDCLOSE: "Save And Close",
    COMMON_BUTTON_SEARCH_CLEAR: "Clear Search",
    COMMON_BUTTON_SEARCH_ADVANCE: "Advance Search",
    COMMON_BUTTON_EXPORT: "Export",
    COMMON_BUTTON_SELECT: "Select",
    COMMON_BUTTON_SELECTED: "Selected",
    COMMON_BUTTON_BACK: "Back",
    COMMON_BUTTON_UPLOAD: "Upload",
    COMMON_BUTTON_UPGRADE: "Upgrade",
    COMMON_BUTTON_IMPORT: "Import",
    COMMON_BUTTON_SCAN: "Scan",
    COMMON_BUTTON_STOP: "Stop",
    COMMON_BUTTON_REFRESH: "Refresh",
    COMMON_BUTTON_DETAIL: "Detail",
    COMMON_BUTTON_CLOSE: "Close",
    COMMON_BUTTON_COPY: "Copy",
    COMMON_BUTTON_NEW: "New",
    COMMON_BUTTON_DOWNLOAD: "Download",
    COMMON_BUTTON_DOWNLOAD_TEMPLATE: "Download Template",
    COMMON_BUTTON_ENABLE: "Enable",
    COMMON_BUTTON_EMPTY: "Empty",
    COMMON_BUTTON_PARAMS: "Params",
    COMMON_BUTTON_NEXT: "Next",
    COMMON_BUTTON_NEXT_STEP: "Next Step",
    COMMON_BUTTON_PREVIOUS: "Previous",
    COMMON_BUTTON_PRINT: "Print",
    COMMON_BUTTON_CLEAR: "Clear",
    COMMON_BUTTON_SORT: "Sort",
    COMMON_BUTTON_SAVE_ORDER: "Save Order",
    COMMON_BUTTON_HOME: "HOME",

    COMMON_STATUS_WAITING: "Waiting",
    COMMON_STATUS_APPROVING: "Pending Approval",
    COMMON_STATUS_APPROVED: "Approved",
    COMMON_STATUS_APPROVED_PART: "Part Approved",
    COMMON_STATUS_REJECT: "Rejected",
    COMMON_STATUS_DONE: "Done",

    COMMON_FIELD_UPDATE_TIME: "Update Time",
    COMMON_FIELD_REMARKS: "Remarks",
    COMMON_FIELD_REMARKS_PLACEHOLDER: "Please input remarks",
    COMMON_FIELD_ENABLED: "Enabled",
    COMMON_FIELD_DISABLED: "Disabled",
    COMMON_FIELD_SUCCESS: "Success",
    COMMON_FIELD_FAILED: "Failed",
    COMMON_FIELD_ACTION: "Action",
    COMMON_FIELD_HAVE: "Have",
    COMMON_FIELD_NOTHING: "None",
    COMMON_FIELD_YES: "Yes",
    COMMON_FIELD_NO: "No",
    COMMON_FIELD_PAGINATION_TOTAL: "Total {sum} items",
    COMMON_FIELD_HEIGHT: "Height",
    COMMON_FIELD_WIDTH: "Width",
    COMMON_FIELD_DEEP: "Deep",
    COMMON_FIELD_FLOOR: "Floor",
    COMMON_FIELD_NUMBER: "Number",
    COMMON_FIELD_UNIT_M: "m",
    COMMON_FIELD_UNIT_M2: "m2",
    COMMON_FIELD_UNIT_T_M2: "t/m2",
    COMMON_FIELD_STATUS: "Status",
    COMMON_TITLE_TEXT_EDITOR_MARKDOWN: "Markdown",
    COMMON_FIELD_EMAIL_VERIFY_CODE: "Email Verify Code",
    COMMON_TITLE_TEXT_EDITOR_RICH: "Editor Rich",
    COMMON_PLACEHOLDER_FUZZY_QUERY: "Fuzzy Query",
    COMMON_PLACEHOLDER_SELECT_DATE: "Select Date",
    COMMON_PLACEHOLDER_SELECT_TIME: "Select Time",

    COMMON_MSG_UPLOAD_CONTINUE: "Uploading, Please waiting...",
    COMMON_MSG_RESTART_SUCCESS: "Restart Success",
    COMMON_MSG_RESTART_ERROR: "Restart Failed",
    COMMON_MSG_RESTART_TITLE: "Restart Confirm",
    COMMON_MSG_RESTART_CONFIRM: "Please confirm restart?",
    COMMON_MSG_IMPORT_SUCCESS: "Import Success",
    COMMON_MSG_CREATE_SUCCESS: "Create Success",
    COMMON_MSG_UPDATE_SUCCESS: "Update Success",
    COMMON_MSG_DELETE_SUCCESS: "Delete Success",
    COMMON_MSG_DELETE_TITLE: "Delete Confirm",
    COMMON_MSG_UPDATE_TITLE: "Update Confirm",
    COMMON_MSG_DELETE_CONFIRM: "Please confirm delete this item?",
    COMMON_MSG_UPDATE_CONFIRM: "Please confirm update this item?",
    COMMON_MSG_REQUIRED: "This item is required",
    COMMON_MSG_EXCEPTION: "Unexpected Exception",
    COMMON_MSG_EXCEPTION_403: "Sorry, you are not authorized to access this page.",
    COMMON_MSG_EXCEPTION_404: "Sorry, the page you visited does not exist.",
    COMMON_MSG_EXCEPTION_500: "Sorry, the server is wrong.",
    COMMON_MSG_CREATE_FAVORITES: "Favorite Success",
    COMMON_FIELD_VALUE_UNKNOWN: "Unknown",
    COMMON_FIELD_VALUE_NO_LIMIT: "No Limit",

    // 字典类型
    DICT_TYPE_TITLE_CREATE: "Create Dictionary Type",
    DICT_TYPE_TITLE_UPDATE: "Update Dictionary Type",
    DICT_TYPE_FIELD_CODE: "Code",
    DICT_TYPE_FIELD_NAME: "Name",
    DICT_TYPE_FIELD_SEQUENCE: "Sequence",
    DICT_TYPE_MSG_CODE_REQUIRED: "Code is required",
    DICT_TYPE_MSG_NAME_REQUIRED: "Title is required",
    DICT_TYPE_MSG_DELETE_FAILED_WHIT_CLASSES: "Delete failed with classes",

    // 字典类
    DICT_CLASS_TITLE_CREATE: "Create Dictionary Class",
    DICT_CLASS_TITLE_UPDATE: "Update Dictionary Class",
    DICT_CLASS_FIELD_TYPE: "Type",
    DICT_CLASS_FIELD_CODE: "Code",
    DICT_CLASS_FIELD_NAME: "Name",
    DICT_CLASS_FIELD_SEQUENCE: "Sequence",
    DICT_CLASS_MSG_TYPE_REQUIRED: "Type is required",
    DICT_CLASS_MSG_CODE_REQUIRED: "Code is required",
    DICT_CLASS_MSG_NAME_REQUIRED: "Title is required",
    DICT_CLASS_MSG_DELETE_FAILED_WHIT_ITEMS: "Delete failed with items",

    // 字典项
    DICT_ITEM_ROUTE_LIST: "Dictionary Item",
    DICT_ITEM_TITLE_CREATE: "Create Dictionary Item",
    DICT_ITEM_TITLE_UPDATE: "Update Dictionary Item",
    DICT_ITEM_FIELD_CLASS: "Class",
    DICT_ITEM_FIELD_CODE: "Code",
    DICT_ITEM_FIELD_NAME_ZH: "Chinese Name",
    DICT_ITEM_FIELD_NAME_EN: "English Name",
    DICT_ITEM_FIELD_VALUE: "Value",
    DICT_ITEM_FIELD_SEQUENCE: "Sequence",
    DICT_ITEM_FIELD_REMARKS: "Remarks",
    DICT_ITEM_MSG_CLASS_REQUIRED: "Class is required",
    DICT_ITEM_MSG_CODE_REQUIRED: "Code is required",
    DICT_ITEM_MSG_TITLE_REQUIRED: "Title is required",

    // 账户管理
    ACCOUNT_ROUTER_MANAGEMENT: "Account",
    // 账户权限管理-资源
    ACCOUNT_RESOURCE_ROUTE_LIST: "Resource List",
    ACCOUNT_RESOURCE_TITLE_CREATE: "Create Resource",
    ACCOUNT_RESOURCE_TITLE_UPDATE: "Update Resource",
    ACCOUNT_RESOURCE_FIELD_PARENT: "Parent",
    ACCOUNT_RESOURCE_FIELD_CODE: "Code",
    ACCOUNT_RESOURCE_FIELD_NAME: "Name",
    ACCOUNT_RESOURCE_FIELD_NAME_ZH: "Chinese Name",
    ACCOUNT_RESOURCE_FIELD_NAME_EN: "English Name",
    ACCOUNT_RESOURCE_FIELD_PATH: "Path",
    ACCOUNT_RESOURCE_FIELD_SEQUENCE: "Sequence",
    ACCOUNT_RESOURCE_FIELD_TYPE: "Type",
    ACCOUNT_RESOURCE_FIELD_TYPE_APP: "App",
    ACCOUNT_RESOURCE_FIELD_TYPE_MENU: "Menu",
    ACCOUNT_RESOURCE_FIELD_TYPE_ACTION: "Action",
    ACCOUNT_RESOURCE_FIELD_METHOD: "Method",
    ACCOUNT_RESOURCE_FIELD_HIDDEN_FLAG: "Hidden",
    ACCOUNT_RESOURCE_FIELD_ICON: "Icon",
    ACCOUNT_RESOURCE_MSG_CODE_REQUIRED: "Resource code is required",
    ACCOUNT_RESOURCE_MSG_NAME_REQUIRED: "Resource name is required",
    ACCOUNT_RESOURCE_MSG_PATH_REQUIRED: "Resource path is required",
    ACCOUNT_RESOURCE_MSG_TYPE_REQUIRED: "Resource type is required",
    // 账户管理-角色
    ACCOUNT_ROLE_ROUTE_LIST: "Role List",
    ACCOUNT_ROLE_TITLE_CREATE: "Create Role",
    ACCOUNT_ROLE_TITLE_UPDATE: "Update Role",
    ACCOUNT_ROLE_TITLE_PERMISSION: "Role Permission",
    ACCOUNT_ROLE_FIELD_CODE: "Code",
    ACCOUNT_ROLE_FIELD_NAME: "Name",
    ACCOUNT_ROLE_FIELD_SEQUENCE: "Sequence",
    ACCOUNT_ROLE_BUTTON_PERMISSION: "Permission",
    ACCOUNT_ROLE_MSG_CODE_REQUIRED: "Role code is required",
    ACCOUNT_ROLE_MSG_NAME_REQUIRED: "Role name is required",
    // 账户权限管理-角色
    ACCOUNT_USER_ROUTE_LOGIN_PASSWORD: "Login by Password",
    ACCOUNT_USER_ROUTE_LOGOUT: "Logout",
    ACCOUNT_USER_ROUTE_REGISTER: "User Register",
    ACCOUNT_USER_ROUTE_LIST: "Account List",
    ACCOUNT_USER_ROUTE_CENTER: "User Center",
    ACCOUNT_USER_ROUTE_PROFILE: "User Profile",
    ACCOUNT_USER_ROUTE_PASSWORD: "User Password",
    ACCOUNT_USER_ROUTE_PASSWORD_MODIFY: "Modify Password",
    ACCOUNT_USER_ROUTE_PASSWORD_FORGOT: "Forgot Password",

    ACCOUNT_USER_TITLE_CREATE: "Create User",
    ACCOUNT_USER_TITLE_UPDATE: "Update User",
    ACCOUNT_USER_TITLE_DELETE: "Delete User",
    ACCOUNT_USER_TITLE_PASSWORD_RESET: "Reset Password",
    ACCOUNT_USER_TITLE_USER_SEARCHER_MODAL: "Search User",
    ACCOUNT_USER_FIELD_UID: "User ID",
    ACCOUNT_USER_FIELD_UID_PLACEHOLDER: "User ID",
    ACCOUNT_USER_FIELD_PASSWORD_OLD: "Old Password",
    ACCOUNT_USER_FIELD_PASSWORD: "VMS Password",
    ACCOUNT_USER_FIELD_PASSWORD_CONFIRM: "Confirm VMS Password",
    ACCOUNT_USER_FIELD_EMAIL: "Email",
    ACCOUNT_USER_FIELD_EMAIL_VERIFY_CODE: "Email Verify Code",
    ORG_EMPLOYEE_FIELD_EMAIL_CONFIRM: "Confirm Email",
    ACCOUNT_USER_FIELD_MOBILE: "Mobile",
    ACCOUNT_USER_FIELD_FIRST_NAME: "First Name",
    ACCOUNT_USER_FIELD_LAST_NAME: "Last Name",
    ACCOUNT_USER_FIELD_FULL_NAME: "Name",
    ACCOUNT_USER_FIELD_ROLE: "Role",
    ACCOUNT_USER_FIELD_DISABLED: "Disabled",
    ACCOUNT_USER_FIELD_PORTRAIT: "Portrait",
    ACCOUNT_USER_FIELD_DATA_GROUP: "Data Group",
    ACCOUNT_USER_FIELD_AUTH_TYPE_PASSWORD: "Username Password",
    ACCOUNT_USER_FIELD_AUTH_TYPE_LDAP: "LDAP",
    ACCOUNT_USER_BUTTON_SUBMIT: "Login",
    ACCOUNT_USER_BUTTON_GO_LOGIN: "Go Login",
    ACCOUNT_USER_BUTTON_REGISTER: "Register New User",
    ACCOUNT_USER_BUTTON_EMAIL_VERIFY_CODE: "Send Email Verify Code",
    ACCOUNT_USER_BUTTON_PASSWORD_RESET: "Reset Password",
    ACCOUNT_USER_BUTTON_SELECT_ME: "Select Me",
    ACCOUNT_USER_MSG_UID_REQUIRED: "User ID is required",
    ACCOUNT_USER_MSG_PASSWORD_REQUIRED: "Password is required",
    ACCOUNT_USER_MSG_PASSWORD_WRONG: "Password wrong",
    ACCOUNT_USER_MSG_OLD_PASSWORD_REQUIRED: "Old password is required",
    ACCOUNT_USER_MSG_OLD_PASSWORD_WRONG: "Old password wrong",
    ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED: "Password not less than {value} character",
    ACCOUNT_USER_MSG_PASSWORD_SPECIAL_CHARACTER_REQUIRED: "Special Character required",
    ACCOUNT_USER_MSG_PASSWORD_NUMBER_REQUIRED: "Number required",
    ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED: "Password must contain character, number, special symbol",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_REQUIRED: "Password confirm is required",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED: "Two password not same",
    ACCOUNT_USER_MSG_MOBILE_REQUIRED: "Mobile is required",
    ACCOUNT_USER_MSG_MOBILE_FORMAT_WRONG: "Mobile format wrong",
    ACCOUNT_USER_MSG_EMAIL_REQUIRED: "Email is required",
    ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG: "Email format wrong",
    ACCOUNT_USER_MSG_EMAIL_CODE_REQUIRED: "Email verify code is required",
    ACCOUNT_USER_MSG_EMAIL_CODE_SEND_SUCCESS: "Email verify code is required",
    ACCOUNT_USER_MSG_EMAIL_CODE_NOT_FOUND: "Email verify code is wrong",
    ACCOUNT_USER_MSG_EMAIL_CONFIRM_REQUIRED: "Email confirm is required",
    ACCOUNT_USER_MSG_EMAIL_CONFIRM_EQUAL_REQUIRED: "Two email not same",
    ACCOUNT_USER_MSG_LAST_NAME_REQUIRED: "Last name is required",
    ACCOUNT_USER_MSG_FIRST_NAME_REQUIRED: "First name is required",
    ACCOUNT_USER_MSG_ROLE_REQUIRED: "Role is required",
    ACCOUNT_USER_MSG_AUTH_TOKEN_EXPIRED: "Access token is expired",
    ACCOUNT_USER_MSG_AUTH_TOKEN_NON_PRINCIPAL: "Access token not exist",
    ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS: "Password reset success",
    ACCOUNT_USER_MSG_PASSWORD_RESET_CONFIRM: "Please confirm reset the user password",
    ACCOUNT_USER_MSG_RESULT_LOGIN: "Result login",
    ACCOUNT_USER_MSG_CREATE_SUCCESS: "User create success",
    ACCOUNT_USER_MSG_UPDATE_SUCCESS: "User update success",
    ACCOUNT_USER_MSG_DELETE_SUCCESS: "User delete success",
    ACCOUNT_USER_MSG_DELETE_CONFIRM: "Please confirm delete user",
    ACCOUNT_USER_MSG_NOT_FOUND: "User not found",
    ACCOUNT_USER_MSG_DISABLED: "User disabled",

    // 用户管理-数据组
    ACCOUNT_DATA_GROUP_ROUTE_LIST: "Data Group",
    ACCOUNT_DATA_GROUP_TITLE_CREATE: "Create Data Group",
    ACCOUNT_DATA_GROUP_TITLE_UPDATE: "Update Data Group",
    ACCOUNT_DATA_GROUP_FIELD_CODE: "Code",
    ACCOUNT_DATA_GROUP_FIELD_NAME: "Name",
    ACCOUNT_DATA_GROUP_FIELD_SEQUENCE: "Sequence",
    ACCOUNT_DATA_GROUP_FIELD_PARENT: "Parent",
    ACCOUNT_DATA_GROUP_MSG_CODE_REQUIRED: "Data group code is required.",
    ACCOUNT_DATA_GROUP_MSG_NAME_REQUIRED: "Data group name is required.",

    // 组织管理-组织
    ORG_ROUTER_MANAGEMENT: "Organization",
    ORG_ORGANIZATION_ROUTE_LIST: "Organization List",
    ORG_ORGANIZATION_TITLE_CREATE: "Create Organization",
    ORG_ORGANIZATION_TITLE_UPDATE: "Update Organization",
    ORG_ORGANIZATION_TITLE_DELETE: "Delete Organization",
    ORG_ORGANIZATION_FIELD_PARENT: "Parent",
    ORG_ORGANIZATION_FIELD_CODE: "Code",
    ORG_ORGANIZATION_FIELD_NAME: "Name",
    ORG_ORGANIZATION_FIELD_TYPE: "Type",
    ORG_ORGANIZATION_FIELD_TYPE_COMPANY: "Company",
    ORG_ORGANIZATION_FIELD_TYPE_DEPARTMENT: "Department",
    ORG_ORGANIZATION_FIELD_CONTACTS: "Contacts",
    ORG_ORGANIZATION_FIELD_TELEPHONE: "Telephone",
    ORG_ORGANIZATION_FIELD_ADDRESS: "Address",
    ORG_ORGANIZATION_FIELD_DISABLED: "Disabled",
    ORG_ORGANIZATION_FIELD_TEMPORARY: "Temporary",
    ORG_ORGANIZATION_FIELD_DATA_GROUP: "Data Group",
    ORG_ORGANIZATION_MSG_CODE_REQUIRED: "Organization code is required",
    ORG_ORGANIZATION_MSG_NAME_REQUIRED: "Organization name is required",
    ORG_ORGANIZATION_MSG_TYPE_REQUIRED: "Organization type is required",
    ORG_ORGANIZATION_MSG_CREATE_SUCCESS: "Create organization success",
    ORG_ORGANIZATION_MSG_UPDATE_SUCCESS: "Update organization success",
    ORG_ORGANIZATION_MSG_DELETE_SUCCESS: "Delete organization success",
    ORG_ORGANIZATION_MSG_DELETE_CONFIRM: "Please confirm to delete organization",
    ORG_ORGANIZATION_MSG_PARENT_DEAD_CYCLE: "Could not set self as parent",
    ORG_ORGANIZATION_MSG_HAVE_CHILDREN: "Organization have children",
    ORG_ORGANIZATION_MSG_HAVE_EMPLOYEE: "The organization have employee",
    ORG_ORGANIZATION_MSG_NOT_FOUND: "Organization not found",

    // 组织管理-人员
    ORG_EMPLOYEE_ROUTE_LIST: "Employee List",
    ORG_EMPLOYEE_ROUTE_PROFILE: "User Profile",
    ORG_EMPLOYEE_TITLE_CREATE: "Create Employee",
    ORG_EMPLOYEE_TITLE_UPDATE: "Update Employee",
    ORG_EMPLOYEE_TITLE_DELETE: "Delete Employee",
    ORG_EMPLOYEE_TITLE_BASIC_INFO: "Basic Info",
    ORG_EMPLOYEE_TITLE_EXTEND_INFO: "Extend Info",
    ORG_EMPLOYEE_TITLE_IMPORT: "Import Employee",
    ORG_EMPLOYEE_TITLE_SELECTOR_MODAL: "Employee Selector",
    ORG_EMPLOYEE_FIELD_CODE: "Code",
    ORG_EMPLOYEE_FIELD_TYPE: "Type",
    ORG_EMPLOYEE_FIELD_TYPE_INNER: "contract",
    ORG_EMPLOYEE_FIELD_TYPE_EXTERNAL: "leasing",
    ORG_EMPLOYEE_FIELD_FULL_NAME: "Name",
    ORG_EMPLOYEE_FIELD_MOBILE: "Mobile",
    ORG_EMPLOYEE_FIELD_ORGANIZATION: "Organization",
    ORG_EMPLOYEE_FIELD_LAST_NAME: "Last Name",
    ORG_EMPLOYEE_FIELD_FIRST_NAME: "First Name",
    ORG_EMPLOYEE_FIELD_EMAIL: "Email",
    ORG_EMPLOYEE_FIELD_EMAIL_VERIFY_CODE: "Verify Code",
    ORG_EMPLOYEE_FIELD_SEX: "Sex",
    ORG_EMPLOYEE_FIELD_SEX_MALE: "Male",
    ORG_EMPLOYEE_FIELD_SEX_FEMALE: "Female",
    ORG_EMPLOYEE_FIELD_STATUS: "Status",
    ORG_EMPLOYEE_FIELD_STATUS_AT_POST: "on duty",
    ORG_EMPLOYEE_FIELD_STATUS_DIMISSION: "Dimission",
    ORG_EMPLOYEE_FIELD_ENTRY_DATE: "Entry Date",
    ORG_EMPLOYEE_FIELD_DIMISSION_DATE: "Dimission Date",
    ORG_EMPLOYEE_FIELD_PHOTO: "Photo",
    ORG_EMPLOYEE_FIELD_POSITION: "Position",
    ORG_EMPLOYEE_FIELD_ID_NUMBER: "ID Number",
    ORG_EMPLOYEE_FIELD_DATA_GROUP: "Data Group",
    ORG_EMPLOYEE_FIELD_SUPERVISOR: "Supervisor",
    ORG_EMPLOYEE_BUTTON_SEND_VERIFY_EMAIL_CODE: "Send verify code",
    ORG_EMPLOYEE_BUTTON_REGISTER: "Register",
    ORG_EMPLOYEE_MSG_ORGANIZATION_ID_REQUIRED: "Organization is required",
    ORG_EMPLOYEE_MSG_LAST_NAME_REQUIRED: "Last name is required",
    ORG_EMPLOYEE_MSG_FIRST_NAME_REQUIRED: "First name is required",
    ORG_EMPLOYEE_MSG_CODE_REQUIRED: "Employee code is required",
    ORG_EMPLOYEE_MSG_CODE_UNIQUE: "Employee code is unique required",
    ORG_EMPLOYEE_MSG_PHOTO_FILE_REQUIRED: "Employee photo is required",
    ORG_EMPLOYEE_MSG_TYPE_REQUIRED: "Employee type is required",
    ORG_EMPLOYEE_MSG_EMAIL_REQUIRED: "Email is required",
    ORG_EMPLOYEE_MSG_EMAIL_UNIQUE: "Email is unique required",
    ORG_EMPLOYEE_MSG_EMAIL_FORMAT_WRONG: "Email format wrong",
    ORG_EMPLOYEE_MSG_MOBILE_REQUIRED: "Mobile required",
    ORG_EMPLOYEE_MSG_MOBILE_FORMAT_WRONG: "Mobile format wrong",
    ORG_EMPLOYEE_MSG_MOBILE_UNIQUE: "Mobile unique required",
    ORG_EMPLOYEE_MSG_REGISTER_SUCCESS: "Register success",
    ORG_EMPLOYEE_MSG_DELETE_CONFIRM: "Please confirm delete employee",
    ORG_EMPLOYEE_MSG_NOT_FOUND: "Employee not found",
    ORG_EMPLOYEE_MSG_EMAIL_VERIFY_CODE_NOT_FOUND: "Email verify code not found",

    // 岗位/用户组
    ORG_POSITION_ROUTE_LIST: "Position List",
    ORG_POSITION_TITLE_CREATE: "Create Position",
    ORG_POSITION_TITLE_UPDATE: "Update Position",
    ORG_POSITION_FIELD_CODE: "Code",
    ORG_POSITION_FIELD_NAME: "Name",
    ORG_POSITION_MSG_CODE_REQUIRED: "Code is required",
    ORG_POSITION_MSG_NAME_REQUIRED: "Name is required",

    // 文件管理
    FTP_FILE_ROUTE_LIST: "File List",
    FTP_FILE_FIELD_NAME: "File Name",
    FTP_FILE_FIELD_PATH: "File Path",
    FTP_FILE_FIELD_LIST: "File List",
    FTP_FILE_FIELD_SEARCH: "Click to view",
    FTP_FILE_FIELD_UPLOAD_FILE: "Upload File",
    FTP_FILE_FIELD_FILE_UPLOAD: "File Upload",
    FTP_FILE_FIELD_LAST_MODIFIED: "Last modified",
    FTP_MSG_UPLOAD_SUCCEED: "Upload success",
    FTP_MSG_UPLOAD_FAILED: "Upload failed",
    FTP_MSG_DOWNLOAD_FAILED: "Download failed",
    FTP_MSG_DELETE_SUCCEED: "Delete success",
    FTP_MSG_DELETE_FAILED: "Delete failed",
    FTP_MSG_UPLOAD_FILE_TYPE_LIMIT: "Upload file type limit {type}!",
    FTP_MSG_UPLOAD_FILE_SIZE_LIMIT: "Upload file size limit {size}!",

    // 邮件管理
    // 邮件配置
    MAIL_PROPERTIES_ROUTE_LIST: "Mail Properties",
    MAIL_PROPERTIES_TITLE_CREATE: "Create Mail Properties",
    MAIL_PROPERTIES_TITLE_UPDATE: "Update Mail Properties",
    MAIL_PROPERTIES_FIELD_USERNAME: "Username",
    MAIL_PROPERTIES_FIELD_PASSWORD: "Password",
    MAIL_PROPERTIES_FIELD_MAIL_HOST: "Host",
    MAIL_PROPERTIES_FIELD_MAIL_FROM: "Mail From",
    MAIL_PROPERTIES_FIELD_MAIL_PORT: "Port",
    MAIL_PROPERTIES_FIELD_SMTP_AUTH: "Auth",
    MAIL_PROPERTIES_FIELD_SMTP_SSL: "SSL",
    MAIL_PROPERTIES_MSG_USERNAME_REQUIRED: "Mail properties username required",
    MAIL_PROPERTIES_MSG_MAIL_HOST_REQUIRED: "Mail properties host required",
    MAIL_PROPERTIES_MSG_MAIL_FROM_REQUIRED: "Mail properties mail from required",
    MAIL_PROPERTIES_MSG_MAIL_PORT_REQUIRED: "Mail properties port required",
    // 邮件模版
    MAIL_TEMPLATE_ROUTE_LIST: "Mail Template",
    MAIL_TEMPLATE_TITLE_CREATE: "Create Mail Template",
    MAIL_TEMPLATE_TITLE_UPDATE: "Update Mail Template",
    MAIL_TEMPLATE_FIELD_CODE: "Code",
    MAIL_TEMPLATE_FIELD_NAME: "Name",
    MAIL_TEMPLATE_FIELD_MAIL_FROM: "From",
    MAIL_TEMPLATE_FIELD_MAIL_TO: "To",
    MAIL_TEMPLATE_FIELD_MAIL_CC: "CC",
    MAIL_TEMPLATE_FIELD_MAIL_BCC: "BCC",
    MAIL_TEMPLATE_FIELD_MAIL_TITLE: "Title",
    MAIL_TEMPLATE_FIELD_MAIL_CONTENT: "Content",
    MAIL_TEMPLATE_MSG_CODE_REQUIRED: "Mail template code is required",
    MAIL_TEMPLATE_MSG_NAME_REQUIRED: "Mail template name is required",
    MAIL_TEMPLATE_MSG_MAIL_FROM_REQUIRED: "Mail template from is required",
    MAIL_TEMPLATE_MSG_MAIL_TO_REQUIRED: "Mail template mail to is required",
    MAIL_TEMPLATE_MSG_VERIFY_CODE_SEND_SUCCESS: "Send verify code success",

    // 审批流
    WORKFLOW_PROCESS_BUTTON_PASS: 'Pass',
    WORKFLOW_PROCESS_BUTTON_REJECT: 'Reject',
    WORKFLOW_PROCESS_MSG_APPROVE_PASS_SUCCESS: "Approve pass succeed.",
    WORKFLOW_PROCESS_MSG_APPROVE_PASS_CONFIRM: "Do you confirm to approve pass this request?",
    WORKFLOW_PROCESS_MSG_APPROVE_REJECT_SUCCESS: "Approve reject succeed.",
    WORKFLOW_PROCESS_MSG_APPROVE_REJECT_CONFIRM: "Do you confirm to approve reject this request?",
    // 审批代理人
    WORKFLOW_DEPUTY_ROUTE_LIST: "Approve Deputy",
    WORKFLOW_DEPUTY_TITLE_DEPUTY: "Deputy",
    WORKFLOW_DEPUTY_TITLE_DEPUTY_SETTING_MODAL: "Deputy Setting",
    WORKFLOW_DEPUTY_TITLE_DELETE_MODAL: "Deputy Delete",
    WORKFLOW_DEPUTY_FIELD_AGENT_NAME: "Agent Name",
    WORKFLOW_DEPUTY_FIELD_AGENT_EMAIL: "Agent Email",
    WORKFLOW_DEPUTY_FIELD_AGENT_MOBILE: "Agent Mobile",
    WORKFLOW_DEPUTY_FIELD_START_TIME: "Start Time",
    WORKFLOW_DEPUTY_FIELD_END_TIME: "End Time",
    WORKFLOW_DEPUTY_MSG_AGENT_NAME_REQUIRED: "Agent name is required",
    WORKFLOW_DEPUTY_MSG_AGENT_EMAIL_REQUIRED: "Agent email is required",
    WORKFLOW_DEPUTY_MSG_AGENT_MOBILE_REQUIRED: "Agent mobile is required",
    WORKFLOW_DEPUTY_MSG_START_TIME_REQUIRED: "Start time is required",
    WORKFLOW_DEPUTY_MSG_END_TIME_REQUIRED: "End time is required",

    // 站点
    CMS_SITE_ROUTE_SITE_LIST: "站点管理",
    CMS_SITE_CREATE_MODAL_TITLE: "创建新站点",
    CMS_SITE_UPDATE_MODAL_TITLE: "更新站点",
    CMS_SITE_FIELD_CODE: "编号",
    CMS_SITE_FIELD_TITLE: "名称",
    CMS_SITE_FIELD_URL: "URL",
    CMS_SITE_FIELD_REMARKS: "备注",
    CMS_SITE_MSG_CODE_REQUIRED: "站点编号不能为空",
    CMS_SITE_MSG_TITLE_REQUIRED: "站点标题不能为空",
    CMS_SITE_MSG_URL_REQUIRED: "站点地址不能为空",

    // 内容管理-栏目
    CMS_CHANNEL_ROUTE_CHANNEL_LIST: "栏目管理",
    CMS_CHANNEL_TITLE_CREATE: "创建新栏目",
    CMS_CHANNEL_TITLE_UPDATE: "更新栏目",
    CMS_CHANNEL_FIELD_PARENT: "父栏目",
    CMS_CHANNEL_FIELD_CODE: "编号",
    CMS_CHANNEL_FIELD_TITLE: "标题",
    CMS_CHANNEL_FIELD_TITLE_DESC: "请输入栏目标题关键字",
    CMS_CHANNEL_FIELD_TITLE_IMAGE: "标题图片",
    CMS_CHANNEL_FIELD_TITLE_IMAGE_PREVIEW: "标题图片预览",
    CMS_CHANNEL_FIELD_SEQUENCE: "序号",
    CMS_CHANNEL_FIELD_REMARKS: "备注",
    CMS_CHANNEL_MSG_CODE_REQUIRED: "栏目编号不能为空",
    CMS_CHANNEL_MSG_CODE_UNIQUE: "栏目编号重复",
    CMS_CHANNEL_MSG_TITLE_REQUIRED: "栏目标题不能为空",

    // 内容管理-内容
    CMS_CONTENT_ROUTE_CONTENT_MANAGEMENT: "内容管理",
    CMS_CONTENT_ROUTE_CONTENT_LIST: "内容列表",
    CMS_CONTENT_ROUTE_CONTENT_DRAFTS: "内容草稿箱",
    CMS_CONTENT_ROUTE_CONTENT_CREATE: "创建新内容",
    CMS_CONTENT_ROUTE_CONTENT_UPDATE: "更新内容",
    CMS_CONTENT_TITLE_CREATE: "创建内容",
    CMS_CONTENT_TITLE_UPDATE: "更新内容",
    CMS_CONTENT_TITLE_BASIC_INFO: "基本信息",
    CMS_CONTENT_TITLE_IMAGE_INFO: "标题图片",
    CMS_CONTENT_TITLE_SUMMARY_EDITOR: "摘要编辑",
    CMS_CONTENT_TITLE_BODY_EDITOR: "内容编辑",
    CMS_CONTENT_TITLE_EDITOR_MARKDOWN: "Markdown",
    CMS_CONTENT_TITLE_EDITOR_RICH: "富文本框",
    CMS_CONTENT_TITLE_FILE_LIST: "附件列表",
    CMS_CONTENT_DELETE_MODAL_TITLE: "删除内容",
    CMS_CONTENT_ATTACHMENT_MODAL_TITLE: "附件一览",
    CMS_CONTENT_FIELD_CHANNEL: "所属栏目",
    CMS_CONTENT_FIELD_CODE: "编号",
    CMS_CONTENT_FIELD_CLASSIFY: "分类",
    CMS_CONTENT_FIELD_TITLE: "标题",
    CMS_CONTENT_FIELD_TITLE_DESC: "请输入内容标题",
    CMS_CONTENT_FIELD_TITLE_IMAGE: "标题图片",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD: "标题图片上传",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_TEXT: "点击或拖拽到该区域上传",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_HINT: "支持单个或批量上传",
    CMS_CONTENT_FIELD_TITLE_LAYOUT: "布局分类",
    CMS_CONTENT_FIELD_SUB_TITLE: "副标题",
    CMS_CONTENT_FIELD_TYPE: "类型",
    CMS_CONTENT_FIELD_SOURCE: "来源",
    CMS_CONTENT_FIELD_PUBLISH_TIME: "发布时间",
    CMS_CONTENT_FIELD_SUMMARY: "摘要",
    CMS_CONTENT_FIELD_BODY: "内容",
    CMS_CONTENT_FIELD_FILES: "附件",
    CMS_CONTENT_MSG_CHANNEL_REQUIRED: "栏目必须选择",
    CMS_CONTENT_MSG_CODE_REQUIRED: "内容编号不能为空",
    CMS_CONTENT_MSG_CLASSIFY_REQUIRED: "内容分类不能为空",
    CMS_CONTENT_MSG_TITLE_REQUIRED: "内容标题不能为空",
    CMS_CONTENT_MSG_TYPE_REQUIRED: "内容类型不能为空",
    CMS_CONTENT_TYPE_ARTICLE: "文章",
    CMS_CONTENT_TITLE_LAYOUT_NO_PICTURE: "纯文字",
    CMS_CONTENT_TITLE_LAYOUT_RIGHT_PICTURE: "左字右图",
    CMS_CONTENT_TITLE_LAYOUT_LEFT_PICTURE: "左图右字",
    CMS_CONTENT_TITLE_LAYOUT_THREE_PICTURE: "上字下三图",
    CMS_CONTENT_TITLE_LAYOUT_BOTTOM_PICTURE: "上字下大图",
    CMS_CONTENT_TITLE_LAYOUT_SLIDE_SHOW: "轮播图",

    // 内容附件
    CMS_CONTENT_FILE_TITLE: "内容附件",
    CMS_CONTENT_FILE_TITLE_CREATE_MODAL: "创建内容附件",
    CMS_CONTENT_FILE_TITLE_DELETE_MODAL: "删除内容附件",
    CMS_CONTENT_FILE_FIELD_PREVIEW: "预览",
    CMS_CONTENT_FILE_FIELD_NAME: "名称",
    CMS_CONTENT_FILE_FIELD_PATH: "路径",
    CMS_CONTENT_FILE_FIELD_SEQUENCE: "序号",
    CMS_CONTENT_FILE_FIELD_TYPE: "类型",
    CMS_CONTENT_FILE_FIELD_SIZE: "大小",
    CMS_CONTENT_FILE_FIELD_REMARKS: "备注",
    CMS_CONTENT_FILE_FIELD_SUMMARY: "摘要",
    CMS_CONTENT_FILE_FIELD_FILE_VERSION: "版本号",
    CMS_CONTENT_FILE_MSG_NAME_REQUIRED: "附件名称不能为空",
    CMS_CONTENT_FILE_MSG_PATH_REQUIRED: "附件路径不能为空",

    // 测评系统
    EXAM_ROUTE_MANAGEMENT: "测评管理",
    // 题库
    EXAM_BANK_ROUTE_LIST: "题库一览",
    EXAM_BANK_TITLE_CREATE: "创建题库",
    EXAM_BANK_TITLE_UPDATE: "更新题库",
    EXAM_BANK_TITLE_DELETE: "删除题库",
    EXAM_BANK_FIELD_TITLE: "题库标题",
    EXAM_BANK_MSG_TITLE_REQUIRED: "题库标题是必填项",
    EXAM_BANK_MSG_DELETE_CONFIRM: "确认要删除题库吗？",
    // 题库题目
    EXAM_SUBJECT_ROUTE_LIST: "题库题目",
    EXAM_SUBJECT_TITLE_CREATE: "创建新考题",
    EXAM_SUBJECT_TITLE_UPDATE: "更新考题",
    EXAM_SUBJECT_TITLE_BASIC: "基本信息",
    EXAM_SUBJECT_TITLE_CONTENT: "考题内容",
    EXAM_SUBJECT_TITLE_FILE: "考题附件",
    EXAM_SUBJECT_FIELD_BANK: "所属题库",
    EXAM_SUBJECT_FIELD_TYPE: "类型",
    EXAM_SUBJECT_FIELD_TYPE_SINGLE_CHOICE: "单选题",
    EXAM_SUBJECT_FIELD_TYPE_MULTIPLE_CHOICE: "多选题",
    EXAM_SUBJECT_FIELD_TYPE_JUDGMENT: "判断题",
    EXAM_SUBJECT_FIELD_TYPE_QA: "问答",
    EXAM_SUBJECT_FIELD_TYPE_VIDEO: "视频",
    EXAM_SUBJECT_FIELD_TITLE: "标题",
    EXAM_SUBJECT_FIELD_CONTENT: "内容",
    EXAM_SUBJECT_FIELD_SEQUENCE: "序号",
    EXAM_SUBJECT_FIELD_SCORE: "分数",
    EXAM_SUBJECT_FIELD_OPTION: "选项",
    EXAM_SUBJECT_BUTTON_OPTIONS: "选项",
    EXAM_SUBJECT_MSG_NOT_FOUND: "该题目对象不存在",
    EXAM_SUBJECT_MSG_BANK_ID_REQUIRED: "所属题库是必填项",
    EXAM_SUBJECT_MSG_TITLE_REQUIRED: "题目标题是必填项",
    EXAM_SUBJECT_MSG_TYPE_REQUIRED: "题目类型是必填项",
    // 考题选项
    EXAM_SUBJECT_OPTION_FIELD_TITLE: "标题",
    EXAM_SUBJECT_OPTION_FIELD_SEQUENCE: "序号",
    EXAM_SUBJECT_OPTION_FIELD_RIGHT_FLAG: "正确答案",

    // 测评活动
    EXAM_EXAMINATION_ROUTE_LIST: "测评一览",
    EXAM_EXAMINATION_TITLE_CREATE: "创建测评",
    EXAM_EXAMINATION_TITLE_UPDATE: "测评更新",
    EXAM_EXAMINATION_TITLE_BASIC_INFO: "测评基本信息",
    EXAM_EXAMINATION_TITLE_BANK_INFO: "测评题库信息",
    EXAM_EXAMINATION_TITLE_INFORMATION: "测评信息",
    EXAM_EXAMINATION_TITLE_SUBJECTS: "测评题目",
    EXAM_EXAMINATION_TITLE_PUBLISH: "发布测评",
    EXAM_EXAMINATION_FIELD_CODE: "测评编号",
    EXAM_EXAMINATION_FIELD_TITLE: "测评标题",
    EXAM_EXAMINATION_FIELD_START_TIME: "开始时间",
    EXAM_EXAMINATION_FIELD_END_TIME: "结束时间",
    EXAM_EXAMINATION_FIELD_LIMIT_TIME: "限定时间",
    EXAM_EXAMINATION_FIELD_PASS_SCORE: "及格分数",
    EXAM_EXAMINATION_FIELD_STATUS: "状态",
    EXAM_EXAMINATION_FIELD_SUMMARY: "测评描述信息",
    EXAM_EXAMINATION_FIELD_SUBJECTS: "题目",
    EXAM_EXAMINATION_BUTTON_VERIFY: "验证身份",
    EXAM_EXAMINATION_BUTTON_IMPORT_FROM_BANK: "题库导入",
    EXAM_EXAMINATION_BUTTON_PUBLISH: "发布",
    EXAM_EXAMINATION_STATUS_NEW: "新创建",
    EXAM_EXAMINATION_STATUS_IN_PROGRESS: "进行中",
    EXAM_EXAMINATION_STATUS_COMPLETED: "完成",
    EXAM_EXAMINATION_STATUS_CANCELED: "取消结束",
    EXAM_EXAMINATION_MSG_CODE_REQUIRED: "测评编号不能为空",
    EXAM_EXAMINATION_MSG_TITLE_REQUIRED: "测评标题不能为空",
    EXAM_EXAMINATION_MSG_STATUS_REQUIRED: "状态不能为空",
    EXAM_EXAMINATION_MSG_CODE_DUPLICATE: "测评编号重复",
    EXAM_EXAMINATION_MSG_PUBLISH_DESCRIPTION: "复制二维码或者URL，分享给学员，在移动端扫码或者打开URL的网页，即可参加测评。",
    EXAM_EXAMINATION_MSG_ADD_SUBJECT: "请添加题目",

    // 测评考题
    EXAM_EXAMINATION_SUBJECT_TITLE_IMPORT: "测评题目导入",
    EXAM_EXAMINATION_SUBJECT_FIELD_BANK: "题库",
    EXAM_EXAMINATION_SUBJECT_MSG_BANK_ID_REQUIRED: "题库是必选项",

    // 学员
    EXAM_EXAMINEE_ROUTE_LIST: "学员一览",
    EXAM_EXAMINEE_TITLE_CREATE: "创建学员",
    EXAM_EXAMINEE_TITLE_UPDATE: "更新学员",
    EXAM_EXAMINEE_TITLE_DELETE: "删除学员",
    EXAM_EXAMINEE_MSG_DELETE_CONFIRM: "确认要删除学员吗？",
    EXAM_EXAMINEE_TITLE_VERIFY: "学员身份验证",
    EXAM_EXAMINEE_TITLE_VERIFY_SUCCESS: "学员身份验证通过",
    EXAM_EXAMINEE_TITLE_VERIFY_FAILED: "学员身份验证失败",
    EXAM_EXAMINEE_FIELD_ID_NUMBER_PLACEHOLDER: "请输入身份证号码",
    EXAM_EXAMINEE_FIELD_NAME: "学员姓名",
    EXAM_EXAMINEE_FIELD_LAST_NAME: "姓",
    EXAM_EXAMINEE_FIELD_FIRST_NAME: "名",
    EXAM_EXAMINEE_FIELD_ID_NUMBER: "身份证",
    EXAM_EXAMINEE_FIELD_MOBILE: "手机号",
    EXAM_EXAMINEE_FIELD_EMAIL: "邮箱",
    EXAM_EXAMINEE_FIELD_BIRTHDAY: "出生年月",
    EXAM_EXAMINEE_MSG_LAST_NAME_REQUIRED: "姓是必选项",
    EXAM_EXAMINEE_MSG_FIRST_NAME_REQUIRED: "名是必选项",
    EXAM_EXAMINEE_MSG_ID_NUMBER_REQUIRED: "身份证是必选项",
    EXAM_EXAMINEE_MSG_ID_NUMBER_DUPLICATE: "身份证重复",
    EXAM_EXAMINEE_MSG_MOBILE_REQUIRED: "手机号是必填项",
    EXAM_EXAMINEE_MSG_BIRTHDAY_REQUIRED: "出生年月是必填项",
    EXAM_EXAMINEE_MSG_VERIFY_SUCCESS: "验证通过",
    EXAM_EXAMINEE_MSG_VERIFY_SUCCESS_DESC: "您的身份验证通过，请确认以下信息是您的本人信息。",
    EXAM_EXAMINEE_MSG_VERIFY_FAILED: "验证失败",
    EXAM_EXAMINEE_MSG_VERIFY_FAILED_DESC: "您的身份验证未通过，请联系相关人员，完成后台登记。",

    // 测评题目
    EXAM_PAPER_SUBJECT_FIELD_TYPE: "类型",
    EXAM_PAPER_SUBJECT_FIELD_TITLE: "标题",
    EXAM_PAPER_SUBJECT_FIELD_SEQUENCE: "序号",
    EXAM_PAPER_SUBJECT_FIELD_SCORE: "分数",
    EXAM_PAPER_SUBJECT_FIELD_OPTION: "选项",
    EXAM_PAPER_SUBJECT_TYPE_SINGLE_CHOICE: "单选题",
    EXAM_PAPER_SUBJECT_TYPE_MULTIPLE_CHOICE: "多选题",
    EXAM_PAPER_SUBJECT_TYPE_JUDGMENT: "判断题",
    EXAM_PAPER_SUBJECT_TYPE_QA: "问答题",
    EXAM_PAPER_SUBJECT_TYPE_VIDEO: "视频题",
    EXAM_PAPER_SUBJECT_TITLE_CREATE: "创建新考题",
    EXAM_PAPER_SUBJECT_TITLE_UPDATE: "更新考题",
    EXAM_PAPER_SUBJECT_TITLE_DELETE: "删除考题",
    EXAM_PAPER_SUBJECT_BUTTON_OPTIONS: "选项",
    EXAM_PAPER_SUBJECT_MSG_NOT_FOUND: "该题目对象不存在",
    EXAM_PAPER_SUBJECT_MSG_TITLE_REQUIRED: "题目标题不能为空",
    EXAM_PAPER_SUBJECT_MSG_TYPE_REQUIRED: "题目类型不能为空",
    EXAM_PAPER_SUBJECT_MSG_CREATE_SUCCESS: "题目创建成功",
    EXAM_PAPER_SUBJECT_MSG_UPDATE_SUCCESS: "题目更新成功",
    EXAM_PAPER_SUBJECT_MSG_DELETE_SUCCESS: "题目删除成功",
    EXAM_PAPER_SUBJECT_MSG_DELETE_CONFIRM: "确认要删除考题吗？",
    // 考题选项
    EXAM_PAPER_SUBJECT_OPTION_FIELD_TITLE: "标题",
    EXAM_PAPER_SUBJECT_OPTION_FIELD_SEQUENCE: "序号",
    EXAM_PAPER_SUBJECT_OPTION_FIELD_RIGHT_FLAG: "正确答案",

    // 试卷
    EXAM_PAPER_TITLE_DETAIL: "试卷详细",
    EXAM_PAPER_TITLE_DELETE: "删除试卷",
    EXAM_PAPER_MSG_DELETE_CONFIRM: "确认要删除试卷吗？",
    EXAM_PAPER_MSG_SUBMIT_SUCCESS: "提交成功",
};
export default en_US;