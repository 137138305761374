import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Col, Button, Row, Modal} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";
import {ExaminationStatus} from "../../../Enums";
import {deletePaper, getPaperDetail, searchPapers, toggleDetailModal} from "../../../actions/PaperAction";
import moment from "moment";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import {RoutePath} from "../../../../../constants/RoutePath";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_NUMBER'}),
                    dataIndex: 'number',
                    key: 'number',
                    render: (text, record, index) => record.number
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_NAME'}),
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => record.examinee.lastName + record.examinee.firstName
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_TITLE'}),
                    dataIndex: 'examination.title',
                    key: 'examination.title'
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_STATUS'}),
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, record, index) => formatMessage({id: ExaminationStatus.Map[record.status]})
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_CREATE_TIME'}),
                    dataIndex: 'createTime',
                    key: 'createTime',
                    render: (text, record, index) => record.createTime? moment(record.createTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN) : null
                },

                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_START_TIME'}),
                //     key: 'examination.startTime',
                //     dataIndex: 'examination.startTime',
                //    ≈ },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_END_TIME'}),
                //     key: 'examination.endTime',
                //     dataIndex: 'examination.endTime',
                //     render: (text, record, index) => record.examination.endTime ? moment(record.examination.endTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN) : null
                // },
                // {
                //     title: formatMessage({id: 'EXAM_PAPER_SUBJECT_FIELD_SCORE'}),
                //     key: 'score',
                //     dataIndex: 'score'
                // },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DETAIL"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </a>
            </div>
        )
    }

    _handleDelete(record) {
        const self = this;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "EXAM_PAPER_TITLE_DELETE"}),
            content: formatMessage({id: "EXAM_PAPER_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                self.props.dispatch(deletePaper(record.id, self.props.searchCondition, formatMessage));
            }
        })
    }

    _handleModify(record) {
        this.props.router.push({
            pathname: RoutePath.EXAM_PAPER_RESULT_DETAIL.path,
            query: {paperId: record.id}
        })

    }


    render() {
        return (
            <div className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.papers} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchPapers(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        papers: store.ExamPaperReducer.papers,
        searchCondition: store.ExamPaperReducer.searchCondition,
        pagination: store.ExamPaperReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));