import React, {Component} from 'react';
import {connect} from 'react-redux';

import {changeLanguage} from './actions/AppAction';

import './App.css';

//import MessageBox from './components/MessageBox'

import {Modal} from 'antd';
// 从'react-intl'中引入addLocaleData,IntlProvider两个格式化组件；
import {addLocaleData, IntlProvider} from 'react-intl';

/*
 *引入与navigator.languages[0]所对应的语言；
 *如果没有引入对应的语言，会使用默认的“en”；
 *导致FormattedMessage的映射不会成功；
 */
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
import ja from 'react-intl/locale-data/ja';
/*
 *引入自定义的映射表；
 *通过与FormattedMessage的id值来当索引映射返回值；
 */
import zh_CN from './locale/zh_CN';
import en_US from './locale/en_US';
import ja_JP from './locale/ja_JP';
import HttpUtils from "./utils/HttpUtils";
import {CACHE_DATA_GROUP_ID, CACHE_LANGUAGE} from "./constants/Profile";
import {Language} from "./constants/Enums";
/*
 *messages是render()时IntlProvider组件所传的props，属性名固定：messages；
 *messages返回值为映射表，比如：'react-intl/locale-data/zh'&&'./locale/zh_CN'；
 */
let messages = {};
messages["en-US"] = en_US;
messages["zh-CN"] = zh_CN;
messages["ja-JP"] = ja_JP;
/*
 *获取浏览器设置的语言；
 *按我demo中的设置，返回["zh-CN", "zh", "en-US", "en"]，排序与语言设置顺序一直；
 */
const languages = navigator.languages;
const currentLang = "zh-CN";
//console.log("languages: ", languages);
//console.log("language: ", currentLang);
// 载入语言数据；
//载入一个addLocaleData(zh);
//载入多个；
addLocaleData([...zh, ...en, ...ja]);


class App extends Component {

    componentWillMount() {
        // const language = localStorage.getItem(CACHE_LANGUAGE);
        // if (language) {
        //     this.props.dispatch(changeLanguage(language));
        // } else {
            this.props.dispatch(changeLanguage(Language.Enum.ZH));
        // }
    }

    render() {
        const language = this.props.currentLanguage ? this.props.currentLanguage : "zh-CN";
        return (
            <IntlProvider key={language} locale={language} messages={messages[language]}>
                <div>
                    {this.props.children}
                </div>
            </IntlProvider>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage
    }
}

export default connect(mapStateToProps)(App);