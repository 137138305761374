
import {CMS_SERVER_HOST} from '../../constants/Profile';

// 内容管理系统
// 站点
export const getSites = CMS_SERVER_HOST + "/cms/sites";
export const getSite = CMS_SERVER_HOST + "/cms/sites/{id}";
export const createSite = CMS_SERVER_HOST + "/cms/sites";
export const copySite = CMS_SERVER_HOST + "/cms/sites/{id}/copy";
export const updateSite = CMS_SERVER_HOST + "/cms/sites/{id}";
export const deleteSite = CMS_SERVER_HOST + "/cms/sites/{id}";
// 栏目
export const searchChannels = CMS_SERVER_HOST + "/cms/sites/{siteId}/channels/queries";
export const getChannelTree = CMS_SERVER_HOST + "/cms/sites/{siteId}/channels/tree";
export const getChannel = CMS_SERVER_HOST + "/cms/channels/{id}";
export const createChannel = CMS_SERVER_HOST + "/cms/sites/{siteId}/channels";
export const updateChannel = CMS_SERVER_HOST + "/cms/channels/{id}";
export const deleteChannel = CMS_SERVER_HOST + "/cms/channels/{id}";
// 内容
export const searchContents = CMS_SERVER_HOST + "/cms/content/query";
export const getContent = CMS_SERVER_HOST + "/cms/content/detail";
export const getContentAnonymous = CMS_SERVER_HOST + "/openAPI/cms/content/detail";
export const createContent = CMS_SERVER_HOST + '/cms/content/create';
export const updateContent = CMS_SERVER_HOST + '/cms/content/update';
export const deleteContent = CMS_SERVER_HOST + '/cms/content/delete';
// 内容文件
export const getContentFiles = CMS_SERVER_HOST + "/cms/content/file/list";
export const getContentFileById = CMS_SERVER_HOST + "/cms/content/file/detail";
export const createContentFile = CMS_SERVER_HOST + "/cms/content/file/create";
export const updateContentFile = CMS_SERVER_HOST + "/cms/content/file/update";
export const deleteContentFile = CMS_SERVER_HOST + "/cms/content/file/delete";