/**
 * Created by Ethan on 17/1/13.
 */

import {ACCOUNT_SERVER_HOST} from '../../constants/Profile';

// 用户登录获取token
export const createToken = ACCOUNT_SERVER_HOST + "/openAPI/account/auth/token";
export const searchUsers = ACCOUNT_SERVER_HOST + "/account/users/queries";
export const getUser = ACCOUNT_SERVER_HOST + "/account/users/{id}";
export const createUser = ACCOUNT_SERVER_HOST + "/account/users";
export const registerUser = ACCOUNT_SERVER_HOST + "/openAPI/account/users";
export const updateUser = ACCOUNT_SERVER_HOST + "/account/users/{id}";
export const deleteUser = ACCOUNT_SERVER_HOST + "/account/user/delete";
export const getSessionUser = ACCOUNT_SERVER_HOST + "/account/auth/session";
export const updateSessionUser = ACCOUNT_SERVER_HOST + "/account/user/profile";
export const updateSessionUserPassword = ACCOUNT_SERVER_HOST + "/account/user/password";
export const resetPassword = ACCOUNT_SERVER_HOST + "/openAPI/account/user/password/reset";
export const resetDefaultPassword = ACCOUNT_SERVER_HOST + "/account/users/{id}/password";

// 用户角色
export const searchRoles = ACCOUNT_SERVER_HOST + "/account/role/query";
export const getRoleById = ACCOUNT_SERVER_HOST + "/account/role/detail";
export const createRole = ACCOUNT_SERVER_HOST + "/account/role/create";
export const updateRole = ACCOUNT_SERVER_HOST + "/account/role/update";
export const deleteRole = ACCOUNT_SERVER_HOST + "/account/role/delete";

// 系统资源
export const searchResources = ACCOUNT_SERVER_HOST + "/account/resource/query";
export const getResourceById = ACCOUNT_SERVER_HOST + "/account/resource/detail";
export const getResourceTree = ACCOUNT_SERVER_HOST + "/account/resource/tree";
export const getMyResourceTree = ACCOUNT_SERVER_HOST + "/account/resource/my/tree";
export const createResource = ACCOUNT_SERVER_HOST + "/account/resource/create";
export const updateResource = ACCOUNT_SERVER_HOST + "/account/resource/update";
export const deleteResource = ACCOUNT_SERVER_HOST + "/account/resource/delete";

// 数据组
export const getDataGroupById = ACCOUNT_SERVER_HOST + "/account/dataGroup/detail";
export const getDataGroupTree= ACCOUNT_SERVER_HOST + "/account/dataGroup/tree";
export const searchDataGroups = ACCOUNT_SERVER_HOST + "/account/dataGroup/query";
export const createDataGroup = ACCOUNT_SERVER_HOST + "/account/dataGroup/create";
export const updateDataGroup = ACCOUNT_SERVER_HOST + "/account/dataGroup/update";
export const deleteDataGroup = ACCOUNT_SERVER_HOST + "/account/dataGroup/delete";

