import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

import {Card, Form, Icon, Input, Button, Divider} from 'antd';
import {FormattedMessage, injectIntl} from 'react-intl';
import {RoutePath} from '../../../../../constants/RoutePath'
import {CACHE_LANGUAGE} from "../../../../../constants/Profile";
import {refreshUser, login} from '../../../actions/UserAction';
import {changeLanguage} from "../../../../../actions/AppAction";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {Language} from "../../../../../constants/Enums";
import {loginByLDAP} from "../../../../ORG/actions/EmployeeAction";
import {AuthType} from "../../../Enums";
import EnumItemRadio from "../../../../../components/EnumItemRadio";
import {getDictItemsByClassCode} from "../../../../Dict/actions/DictItemAction";
import {DICT_CLASS_ACCOUNT_REGISTER_PROPERTIES} from "../../../AccountConstants";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
        }
    }

    componentWillMount() {
        // const language = localStorage.getItem(CACHE_LANGUAGE);
        // if (language) {
        //     this.props.dispatch(changeLanguage(language));
        // }
        // this.props.dispatch(refreshUser({authType:AuthType.Enum.LDAP}));
        // this.props.dispatch(getDictItemsByClassCode(DICT_CLASS_ACCOUNT_REGISTER_PROPERTIES, this.props.dictItemMap, this.state.formatMessage));
    }

    _validate() {
        let messageMap = {};
        if (this.props.user.uid === undefined || this.props.user.uid === "") {
            messageMap["uid"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_UID_REQUIRED'});
        }
        if (this.props.user.password === undefined || this.props.user.password === "") {
            messageMap["password"] = this.state.formatMessage({id: 'ACCOUNT_USER_MSG_PASSWORD_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleLogin() {
        if (this._validate()) {
            // if (this.props.user.authType === AuthType.Enum.LDAP) {
            //     this.props.dispatch(loginByLDAP(this.props.user, this.props.router, this.state.formatMessage))
            // } else {
                this.props.dispatch(login(this.props.user, this.props.router, this.state.formatMessage));
            // }
        }
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        if (name === "uid") {
            // 后台支持邮箱和手机号登录，在uid无法登录的时候，进行后续判断
            user["email"] = value.target ? value.target.value : value;
            user["mobile"] = value.target ? value.target.value : value;
        }
        user[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshUser(user));
    }

    render() {
        const {formatMessage, messageMap} = this.state;
        return (
            <div style={{width: 550, margin: "auto auto", paddingTop: '100px'}}>
                <Card size='small'
                      headStyle={{
                          textAlign: 'center',
                          background: 'rgb(228, 0, 43)',
                          color: '#FFFFFF',
                          fontSize: '18px'
                      }}
                      title={formatMessage({id: "APP_BRAND"})}>
                    <Form style={{margin: '50px 80px 30px 80px'}}>
                        {/*<Form.Item>*/}
                            {/*<EnumItemRadio value={this.props.user.authType} data={AuthType.List}*/}
                                            {/*onChange={(value) => {*/}
                                                {/*this._handleChange("authType", value)*/}
                                            {/*}}/>*/}
                        {/*</Form.Item>*/}
                        <Form.Item required
                                   validateStatus={messageMap["uid"] ? "error" : ""}
                                   help={messageMap["uid"]}>
                            <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                   placeholder={formatMessage({id: "ACCOUNT_USER_FIELD_UID_PLACEHOLDER"})}
                                   onChange={(value) => this._handleChange("uid", value)}/>
                        </Form.Item>
                        <Form.Item required
                                   validateStatus={messageMap["password"] ? "error" : ""}
                                   help={messageMap["password"]}>
                            <Input type="password"
                                   prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                   placeholder={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD"})}
                                   onChange={(value) => this._handleChange("password", value)}/>
                        </Form.Item>
                        {/*<Form.Item>*/}
                            {/*<EnumItemSelect onChange={(value) => this.props.dispatch(changeLanguage(value))}*/}
                                            {/*value={this.props.currentLanguage} data={Language.List}*/}
                                            {/*placeholder={this.state.formatMessage({id: "APP_MULTI_LANGUAGE"})}/>*/}
                        {/*</Form.Item>*/}
                        {/*<Form.Item className="text-right">*/}
                            {/*<Link to={RoutePath.ORG_EMPLOYEE_REGISTER.path} target="_blank"><FormattedMessage*/}
                                {/*id="ACCOUNT_USER_ROUTE_REGISTER"/></Link>*/}
                            {/*<Divider type="vertical"/>*/}
                            {/*<Link to={RoutePath.ACCOUNT_USER_PASSWORD_FORGOT.path} target="_blank"><FormattedMessage*/}
                                {/*id="ACCOUNT_USER_ROUTE_PASSWORD_FORGOT"/></Link>*/}
                        {/*</Form.Item>*/}
                        <Form.Item className="text-center">
                            <Button type="primary" style={{width: '150px'}}
                                    onClick={() => this._handleLogin()}>
                                <FormattedMessage id="ACCOUNT_USER_BUTTON_SUBMIT"/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage,
        user: store.AccountUserReducer.user,
        dictItemMap: store.DictItemReducer.dictItemMap
    }
}

export default connect(mapStateToProps)(injectIntl(Index));