import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';
import {ExamPaperType} from "../Enums";

// import {refreshExaminationBanks} from "./ExaminationBankAction";

export function refreshExamination(examination) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_REFRESH_EXAMINATION,
        examination: examination,
    }
}


export function initExamination() {
    return {
        type: ActionTypes.EXAM_EXAMINATION_INIT_EXAMINATION
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function toggleSubjectsModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_TOGGLE_SUBJECTS_MODAL,
        showSubjectsModal: isShow
    }
}

export function togglePublishModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_TOGGLE_PUBLISH_MODAL,
        showPublishModal: isShow
    }
}


export function searchExaminations(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: true});
        condition.classify = ExamPaperType.Enum.Evaluation;
        HttpUtils.post(WebService.searchExaminations, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_EXAMINATION_SEARCH_EXAMINATION,
                examinations: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function getExamination(examinationId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getExamination, "id", examinationId);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshExamination(response.data));
            // dispatch(refreshExaminationBanks(response.data.banks));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createExamination(examination, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: true});

        HttpUtils.post(WebService.createExamination, examination, (response) => {
            dispatch(searchExaminations(condition, formatMessage));
            // dispatch(refreshExaminationBanks([]));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateExamination(examination, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateExamination, examination, (response) => {
            dispatch(searchExaminations(condition, formatMessage));
            // dispatch(refreshExaminationBanks([]));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteExamination(id, condition, formatMessage) {
    return dispatch => {
        let requestURL = HttpUtils.addQueryString(WebService.deleteExamination, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchExaminations(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, formatMessage);
    }
}

//用于课时的管理

export function refreshPeriodSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_REFRESH_PERIOD_SEARCH_CONDITION,
        periodSearchCondition: condition,
    }
}
export function searchExaminationPeriodList(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: true});
        condition.classify = ExamPaperType.Enum.Training;
        HttpUtils.post(WebService.searchExaminations, condition, (response) => {

            dispatch({
                type: ActionTypes.EXAM_EXAMINATION_SEARCH_EXAMINATION_PERIOD_LIST,
                examinationPeriodList:  response.data,
                periodPagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function initExaminationPeriod() {
    return {
        type: ActionTypes.EXAM_EXAMINATION_INIT_EXAMINATION_PERIOD
    }
}