/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Upload, Icon, Modal, message} from "antd";

import * as WebService from "../WebService";
import {CACHE_ACCESS_TOKEN} from "../../../constants/Profile";
import moment from "moment";
import {FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../../../constants/DateTimeFormats";

class FtpPortraitUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            loading: false
        }
    }

    componentDidMount() {
    }

    render() {

        const folder = this.props.folder ? this.props.folder : "upload%2F" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE);

        const self = this;
        const uploadProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            action: WebService.uploadFile + "?folder=" + folder,
            headers: {
                'Authorization': 'authorization-text',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN)
            },
            beforeUpload(file) {
                const isJPG = (file.type === 'image/jpeg') || (file.type === 'image/png') || (file.type === 'image/gif');
                if (!isJPG) {
                    message.warn(self.state.formatMessage({id: 'FTP_MSG_UPLOAD_FILE_TYPE_LIMIT'}, {type: "JPG, PNG, GIF"}));
                    return false;
                }
                // const isLt2M = info.file.size / 1024 / 1024 <= 2;
                const isLt500KB = file.size / 1024 < 500;
                if (!isLt500KB) {
                    message.warn(self.state.formatMessage({id: "FTP_MSG_UPLOAD_FILE_SIZE_LIMIT"}, {size: '500KB'}));
                    return false;
                }
            },
            onChange(info) {
                const status = info.file.status;

                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (status === 'done') {
                    self.props.onUpload && self.props.onUpload.call(this, info);
                } else if (status === 'error') {
                    Modal.error({
                        title: "ERROR",
                        content: self.state.formatMessage({id: "COMMON_MSG_UPLOAD_FILE_FAILED"}, {name: info.file.name}),
                        okText: "OK",
                        zIndex: 9999,
                        mask: false
                    });
                }
            },
        };

        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'}/>
                <div className="ant-upload-text"><FormattedMessage id="COMMON_BUTTON_UPLOAD"/></div>
            </div>
        );

        return (
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                {...uploadProps}>
                {this.props.value ?
                    <img src={"data:image/jpeg;base64," + this.props.value}
                         alt="avatar"
                         style={{width: '100%'}}/> : uploadButton}
            </Upload>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(FtpPortraitUpload));