import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col} from "antd";
import {
    refreshSearchCondition,
    searchBanks,
    refreshBank, toggleDetailModal
} from '../../../actions/BankAction';

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        const {searchCondition} = this.props;
        this.props.dispatch(searchBanks(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshBank({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_BANK_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.title}
                                       onChange={(value) => this._handleChange("bankIds", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus"
                                        onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.ExamBankReducer.searchCondition,
        pagination: store.ExamBankReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));