import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, defineMessages} from 'react-intl';
import {TreeSelect, Button, Icon} from 'antd';

import {getChannelTree, selectListItem} from '../actions/ChannelAction';

class ChannelSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
    }

    // onSelect = (value) => {
    //     console.log(value);
    //     this.props.dispatch(selectListItem(value));
    //     this.props.onSelect && this.props.onSelect.call(this, value);
    // };

    onChange = (value) => {
        // console.log(value);
        // this.setState({ value });
        // this.props.dispatch(selectListItem(value));
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <div>
                <TreeSelect
                    allowClear
                    multiple={this.props.multiple}
                    value={this.props.value}
                    treeDefaultExpandAll
                    // onSelect={this.onSelect}
                    onChange={this.onChange}>
                    {
                        this.props.channelTree.map((item, i) => {
                            return (
                                <TreeSelect.TreeNode value={item.id} title={item.title} key={item.id}>
                                    {this._renderChannelTreeNode(item)}
                                </TreeSelect.TreeNode>
                            )
                        })
                    }
                </TreeSelect>
            </div>
        );
    }

    _renderChannelTreeNode(channel) {
        if (channel.children && channel.children.length > 0) {
            return (
                channel.children.map((item, i) => {
                    return (
                        <TreeSelect.TreeNode value={item.id} title={item.title} key={item.id}>
                            {this._renderChannelTreeNode(item)}
                        </TreeSelect.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        // channelTree: store.CmsChannelReducer.channelTree,
        // selectItemKey: store.CmsChannelReducer.selectItemKey
    }
}

export default connect(mapStateToProps)(injectIntl(ChannelSelect));