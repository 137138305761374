import * as ActionTypes from '../ActionTypes';

const initialState = {
    subjects: [],
    currentIndex: 0,
    subject: {},
    showModal: false,
    isLoading: false
};

const PaperSubjectReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_PAPER_SUBJECT_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_PAPER_SUBJECT_TOGGLE_MODAL:
            return Object.assign({}, state, {
                showModal: action.showModal
            });
        case ActionTypes.EXAM_PAPER_SUBJECT_REFRESH_SUBJECT:
            return Object.assign({}, state, {
                subject: action.subject
            });
        case ActionTypes.EXAM_PAPER_SUBJECT_REFRESH_SUBJECTS:
            return Object.assign({}, state, {
                subjects: action.subjects
            });
        case ActionTypes.EXAM_PAPER_SUBJECT_REFRESH_CURRENT_INDEX:
            return Object.assign({}, state, {
                currentIndex: action.currentIndex
            });
        default:
            return state
    }
};

export default PaperSubjectReducer;