/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, InputNumber, Checkbox} from "antd";

import {togglePermissionModal, updateRole} from "../../../actions/RoleAction";

import TreeControl from "../../../../../components/TreeControl";
import {refreshSelectedResourceIds, updateRoleResources} from "../../../actions/ResourceAction";

class PermissionModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleOk() {
        const {dispatch, role, selectedResourceIds, searchCondition} = this.props;
        const {formatMessage} = this.state;
        role.resources = [];
        for (let i = 0; i < selectedResourceIds.checked.length; i++) {
            role.resources.push({id: selectedResourceIds.checked[i]})
        }
        dispatch(updateRole(role, searchCondition, formatMessage));
    }

    _handleCancel() {
        this.props.dispatch(togglePermissionModal(false));
    }

    render() {
        const {selectedResourceIds} = this.props;
        const {formatMessage} = this.state;

        return (
            <Modal
                maskClosable={false}
                title={formatMessage({id: "ACCOUNT_ROLE_TITLE_PERMISSION"})}
                visible={this.props.showPermissionModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_SUBMIT"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <TreeControl checkable={true}
                                 checkStrictly={true}/*checkable 状态下节点选择完全受控（父子节点选中状态不再关联）*/
                                 checkedKeys={selectedResourceIds}
                                 onCheck={(checkedKeys) => this._handleTreeNodeChecked(checkedKeys)}
                                 treeData={this.props.resourceTree}/>
                </Form>
            </Modal>
        );
    }

    _handleTreeNodeChecked(checkedKeys) {
        const {dispatch} = this.props;
        dispatch(refreshSelectedResourceIds(checkedKeys));
    }
}

function mapStateToProps(store) {
    return {
        role: store.AccountRoleReducer.role,
        searchCondition: store.AccountRoleReducer.searchCondition,
        showPermissionModal: store.AccountRoleReducer.showPermissionModal,
        resourceTree: store.AccountResourceReducer.resourceTree,
        selectedResourceIds: store.AccountResourceReducer.selectedResourceIds,
    }
}

export default connect(mapStateToProps)(injectIntl(PermissionModal));