import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Col, Button, Row} from 'antd';

import {Modal} from "antd/lib/index";

import PaginationBar from "../../../../../components/PaginationBar";
import {
    deleteExaminee,
    getExamineeDetail,
    searchExaminees,
    toggleDetailModal
} from "../../../actions/ExamineeAction";
import {FORMAT_DATE_HYPHEN, FORMAT_DATE_SIMPLE} from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import {ExamDataModelGrade, ExaminationStatus, ExamineeGender, SubjectType} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_NAME'}),
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => record.lastName
                },

                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_MOBILE'}),
                    dataIndex: 'mobile',
                    key: 'mobile'
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_BIRTHDAY'}),
                    dataIndex: 'birthday',
                    key: 'birthday',
                    render: (text, record, index) => text ? moment(text, FORMAT_DATE_SIMPLE).format(FORMAT_DATE_HYPHEN) : ""
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_GENDER'}),
                    dataIndex: 'gender',
                    key: 'gender',
                    render: (text, record, index) => <EnumItemLabel code={record.gender} map={ExamineeGender.Map}/>
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_GRADE'}),
                    dataIndex: 'grade',
                    key: 'grade',
                    render: (text, record, index) => ExamDataModelGrade.Map[record.grade]?formatMessage({id: ExamDataModelGrade.Map[record.grade]}):""


                },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_ID_NUMBER'}),
                //     dataIndex: 'idNumber',
                //     key: 'idNumber'
                // },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_EMAIL'}),
                //     dataIndex: 'email',
                //     key: 'email'
                // },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </a>
            </div>
        )
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "EXAM_EXAMINEE_TITLE_DELETE"}),
            content: formatMessage({id: "EXAM_EXAMINEE_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteExaminee(record.id, searchCondition, formatMessage));
            }
        })
    }

    _handleModify(record) {
        this.props.dispatch(getExamineeDetail(record.id, record.idNumber, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }


    render() {
        return (
            <div className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.examinees} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchExaminees(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        examinees: store.ExamExamineeReducer.examinees,
        searchCondition: store.ExamExamineeReducer.searchCondition,
        pagination: store.ExamExamineeReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));