import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Layout, Menu, Breadcrumb, Icon} from 'antd';

// import ChannelTree from '../../../components/ChannelTree';
import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';

// import {getChannelTree, selectTreeNode} from "../../../actions/ChannelAction";
// import {refreshSearchCondition, searchContents} from '../../../actions/ContentAction';
// import {CACHE_SITE_CODE, CACHE_SITE_ID} from "../../../../../constants/Profile";
import DetailModal from "./DetailModal";
import FileListModal from "./FileListModal";

// const {Content, Sider} = Layout;

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        // const siteId = localStorage.getItem(CACHE_SITE_ID);
        // this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
    }

    // _handleTreeNodeSelect(selectedKey) {
    //     const searchCondition = Object.assign({}, this.props.searchCondition);
    //     searchCondition["channelIds"] = [selectedKey];
    //
    //     this.props.dispatch(refreshSearchCondition(searchCondition));
    //     this.props.dispatch(selectTreeNode(selectedKey));
    //     const siteCode = localStorage.getItem(CACHE_SITE_CODE);
    //     this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));
    // }

    render() {
        return (
            <div>
                <DetailModal/>
                <FileListModal/>
                <SearchCondition {...this.props}/>
                <SearchResult {...this.props}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        // channelTree: store.CmsChannelReducer.channelTree,
        searchCondition: store.CmsContentReducer.searchCondition,
    }
}

export default connect(mapStateToProps)(injectIntl(Index));