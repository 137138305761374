import React, {Component} from 'react';
import {Tree} from 'antd';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Language} from "../constants/Enums";

class TreeControl extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // selectedKeys: [],
            // expandedKeys: []
        }
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.data) {
        //     // 计算所有的展开节点
        //     let expandedKeys = [];
        //     for (let i = 0; i < nextProps.data.length; i++) {
        //         const item = nextProps.data[i];
        //         expandedKeys.push(item.id);
        //         this._pushChildrenExpandedKeys(expandedKeys, item.children);
        //     }
        //     this.setState({expandedKeys:expandedKeys});
        // }
    }

    // _pushChildrenExpandedKeys(expandedKeys, children) {
    //     if (children !== null && children.length > 0) {
    //         for (let i = 0; i < children.length; i++) {
    //             const item = children[i];
    //             expandedKeys.push(item.id);
    //             this._pushChildrenExpandedKeys(expandedKeys, item.children);
    //         }
    //     }
    // }

    componentWillMount() {

    }

    _handleSelect(selectedKeys) {
        // this.setState({selectedKeys: selectedKeys});
        // const selectedKey = selectedKeys[0];
        this.props.onSelect && this.props.onSelect.call(this, selectedKeys);
    }

    _handleCheck(checkedKeys) {
        this.props.onCheck && this.props.onCheck.call(this, checkedKeys);
    }

    render() {
        console.log(this.props.checkedKeys);
        return (
            <div>
                <Tree
                    showLine
                    checkStrictly={this.props.checkStrictly}
                    checkable={this.props.checkable}
                    // expandedKeys={this.state.expandedKeys}
                    // selectedKeys={this.state.selectedKeys}
                    checkedKeys={this.props.checkedKeys}
                    onCheck={(checkedKeys) => this._handleCheck(checkedKeys)}
                    onSelect={(selectedKeys) => this._handleSelect(selectedKeys)}>
                    {
                        this.props.treeData.map((item, i) => {
                            const title = item.title || item.name || (this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn);
                            return (
                                <Tree.TreeNode title={title} key={item.id}>
                                    {this._renderChannelTreeNode(item)}
                                </Tree.TreeNode>
                            )
                        })
                    }
                </Tree>
            </div>
        );
    }

    _renderChannelTreeNode(item) {
        if (item.children && item.children.length > 0) {
            return (
                item.children.map((item, i) => {
                    const title = item.title || item.name || (this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn);
                    return (
                        <Tree.TreeNode title={title} key={item.id}>
                            {this._renderChannelTreeNode(item)}
                        </Tree.TreeNode>
                    )
                })
            );
        }
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage
    }
}

export default connect(mapStateToProps)(injectIntl(TreeControl));