import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Select} from 'antd';

import {searchAllExaminees} from "../../../actions/ExamineeAction";
import PinyinMatch from 'pinyin-match'

class ExamineeSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectItemKey: "",
        }
    }

    componentWillMount() {
this.props.dispatch(searchAllExaminees(this.state.formatMessage))
    }

    componentWillReceiveProps(nextProps) {
        this.setState({selectItemKey: nextProps.value});

    }

    componentWillUnmount() {
        // this.setState({selectItemKey: ""});
    }

    onChange = (value, label) => {
        this.setState({selectItemKey: value});
        this.props.onChange && this.props.onChange.call(this, value, label);
    };

    render() {
        let allExaminees = this.props.allExaminees ? this.props.allExaminees : [];
        // let filterCompanyList = [];
        // if(this.props.classify){
        //     allCompanyList = allCompanyList.filter(company => company.classify === this.props.classify);
        // }
        //
        //
        // if (this.props.brandId) {
        //     filterCompanyList = allCompanyList.filter((item) => {
        //         return item.brandId === this.props.brandId
        //     })
        // }
        // if (this.props.brandIdList&&this.props.brandIdList.length>0) {
        //     filterCompanyList = allCompanyList.filter((item) => {
        //         return this.props.brandIdList.includes(item.brandId)
        //     })
        // }
        // if (filterCompanyList.length === 0) {
        //     filterCompanyList = allCompanyList;
        // }
        return (<Select
            allowClear={true}
            disabled={this.props.disabled}
            style={this.props.style}
            className={this.props.className}
            value={this.props.value}
            showSearch
            filterOption={(input, option) =>PinyinMatch.match(option.props.children, input)}
            onChange={this.onChange}>
            {
                allExaminees.map((item, i) => {
                    return (
                        <Select.Option key={item.id} value={item.id}>
                            {item.lastName + item.firstName+ " - "+ item.mobile}
                        </Select.Option>
                    )
                })
            }
        </Select>)
    }


}

function mapStateToProps(store) {
    return {
        allExaminees: store.ExamExamineeReducer.allExaminees,
    }
}

export default connect(mapStateToProps)(injectIntl(ExamineeSelect));