export const ROLE_TYPES = {
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_SYS_MANAGER: "ROLE_SYS_MANAGER",
    ROLE_PROPERTY: "ROLE_PROPERTY",
    ROLE_OPERATOR: "ROLE_OPERATOR",
    ROLE_MANAGER: "ROLE_MANAGER",
    ROLE_FINANCE: "ROLE_FINANCE",
    ROLE_OWNER: "ROLE_OWNER",
    ROLE_MAINTENANCE: "ROLE_MAINTENANCE",
    ROLE_FINANCE_MANAGER: "ROLE_FINANCE_MANAGER",
    ROLE_CALL_CENTER: "ROLE_CALL_CENTER",
    ROLE_REPAIR_MAN: "ROLE_REPAIR_MAN",
    ROLE_ORG_OPERATOR: "ROLE_ORG_OPERATOR",
    ROLE_ORG_MANAGER: "ROLE_ORG_MANAGER",
    ROLE_MASTER_SETTING: "ROLE_MASTER_SETTING"
};

export const RoleCode = {
    List: [
        {value: "ROLE_ADMIN", label: "ACCOUNT_ROLE_FIELD_CODE_ADMIN"},
        {value: "ROLE_NORMAL", label: "ACCOUNT_ROLE_FIELD_CODE_NORMAL"}
    ],
    Map: {
        "ROLE_ADMIN": "ACCOUNT_ROLE_FIELD_CODE_ADMIN",
        "ROLE_NORMAL": "ACCOUNT_ROLE_FIELD_CODE_NORMAL"
    },
    Enum: {
        Admin: "ROLE_ADMIN",
        Normal: "ROLE_NORMAL",
    }
};

export const ResourceTypes = {
    Enum: {
        APP: "01",
        MENU: "02",
        ACTION: "03"
    },
    List: [
        {value: "01", label: "ACCOUNT_RESOURCE_FIELD_TYPE_APP"},
        {value: "02", label: "ACCOUNT_RESOURCE_FIELD_TYPE_MENU"},
        {value: "03", label: "ACCOUNT_RESOURCE_FIELD_TYPE_ACTION"},
    ],
    Map: {
        "01": "ACCOUNT_RESOURCE_FIELD_TYPE_APP",
        "02": "ACCOUNT_RESOURCE_FIELD_TYPE_MENU",
        "03": "ACCOUNT_RESOURCE_FIELD_TYPE_ACTION",
    }
};

export const HttpMethods = {
    Enum: {
        GET: "GET",
        PUT: "PUT",
        POST: "POST",
        DELETE: "DELETE"
    },
    List: [
        {value: "GET", label: "GET"},
        {value: "PUT", label: "PUT"},
        {value: "POST", label: "POST"},
        {value: "DELETE", label: "DELETE"},
    ],
    Map: {
        "GET": "GET",
        "PUT": "PUT",
        "POST": "POST",
        "DELETE": "DELETE",
    }
};

export const AuthType = {
    Enum: {
        Password: "01",
        LDAP: "02"
    },
    List: [
        {value: "01", label: "ACCOUNT_USER_FIELD_AUTH_TYPE_PASSWORD"},
        {value: "02", label: "ACCOUNT_USER_FIELD_AUTH_TYPE_LDAP"}
    ],
    Map: {
        "01": "ACCOUNT_USER_FIELD_AUTH_TYPE_PASSWORD",
        "02": "ACCOUNT_USER_FIELD_AUTH_TYPE_LDAP"
    }
};