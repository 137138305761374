import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';

import SubjectsModal from "../Subjects/Index";
import DetailModal from "../List/DetailModal";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    render() {
        return (
            <div>
                <DetailModal/>
                <SubjectsModal/>
                <SearchCondition/>
                <SearchResult {...this.props}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));