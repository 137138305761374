import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {connect} from "react-redux";
import {Language} from "../../../constants/Enums";

class DictItemLabel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    render() {
        const dictItems = this.props.dictItemMap[this.props.classCode] ? this.props.dictItemMap[this.props.classCode] : [];
        const dictItemMap = {};
        for (let dictItem of dictItems) {
            dictItemMap[dictItem.value] = dictItem;
        }

        const dictItem = dictItemMap[this.props.value] ? dictItemMap[this.props.value] : {};
        return (
            <span>
                {
                    this.props.currentLanguage === Language.Enum.ZH ? dictItem.nameZh : dictItem.nameEn
                }
            </span>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage,
        dictItemMap: store.DictItemReducer.dictItemMap
    }
}

export default connect(mapStateToProps)(injectIntl(DictItemLabel));