import React, {Component} from 'react';

import {Select} from "antd";

export default class ListSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <Select
                mode={this.props.mode}
                allowClear={this.props.allowClear}
                value={this.props.value}
                onChange={this.onChange}
                style={this.props.style}>
                {
                    this.props.data && this.props.data.map((item, i) => {
                        return (
                            <Select.Option key={i} value={item.id}>
                                {
                                    (item.title || item.name)
                                }
                            </Select.Option>
                        )
                    })
                }
            </Select>
        );
    }
}