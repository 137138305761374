import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Form, Modal, Table} from "antd";

import {
    refreshExamExamineeLesson,
    refreshExaminationSelectedIdList,
    toggleSelectExaminationModal
} from "../../../actions/ExamineeLessonAction";

class ExaminationSelectModal extends Component {

    constructor(props) {
        super(props);
        const formatMessage = this.props.intl['formatMessage'];

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            columns: [
                // {
                //     title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_ID'}),
                //     dataIndex: 'id',
                //     key: 'id'
                // },
                {
                    title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_TITLE'}),
                    dataIndex: 'title',
                    key: 'title'
                },



            ]
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }


    _handleCancel() {
        this.props.dispatch(toggleSelectExaminationModal(false));
    }

    _handleOk() {
        const examineeLesson = Object.assign({}, this.props.examineeLesson);
        let selectedIdList = this.props.examinationSelectedIdList ? this.props.examinationSelectedIdList : [];
        examineeLesson.examinationIds = selectedIdList.join(",");
        this.props.dispatch(refreshExamExamineeLesson(examineeLesson));
        this._handleCancel();
    }

    render() {
        const formatMessage = this.state.formatMessage;

console.log(this.props.allPeriodExaminationList)
        return (
            <Modal
                maskClosable={false}
                title={"选择课时"}
                visible={this.props.showSelectExaminationModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Table rowKey={(record) => record.id} size="middle"
                           rowSelection={{
                               selectedRowKeys: this.props.examinationSelectedIdList,
                               onChange: (selectedRowKeys) => this.props.dispatch(refreshExaminationSelectedIdList(selectedRowKeys))
                           }}
                           className="spacing-v" columns={this.state.columns}
                           dataSource={this.props.allPeriodExaminationList}
                           pagination={false}/>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        allPeriodExaminationList: store.ExamineeLessonReducer.allPeriodExaminationList,
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        showSelectExaminationModal: store.ExamineeLessonReducer.showSelectExaminationModal,
        examinationSelectedIdList: store.ExamineeLessonReducer.examinationSelectedIdList
    }
}

export default connect(mapStateToProps)(injectIntl(ExaminationSelectModal));