import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Tabs, Button, Card} from 'antd';

import PageTitle from "../../../../../components/PageTitle";
import ContentBasicForm from "../../../components/ContentBasicForm";
import ContentFileList from "../../../components/ContentFileList";
import ContentSummaryEditor from "../../../components/ContentSummaryEditor";
import ContentBodyEditor from "../../../components/ContentBodyEditor";
import {createContent, initContent} from "../../../actions/ContentAction";
import {refreshFiles} from "../../../actions/ContentFileAction";

import "./Index.css";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillUnmount() {
        this.props.dispatch(initContent());
        this.props.dispatch(refreshFiles([]));
    }

    _validate(content) {
        let messageMap = {};
        if (!content.channelId || content.channelId === "") {
            messageMap["channelId"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_CHANNEL_REQUIRED'});
        }
        if (!content.title || content.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_TITLE_REQUIRED'});
        }
        if (!content.code || content.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_CODE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleSubmit(isDraft) {
        const content = Object.assign({}, this.props.content);
        content["files"] = this.props.files;
        content["draftFlag"] = isDraft;
        if (this._validate(content)) {
            this.props.dispatch(createContent(content, {}, this.state.formatMessage));
        }
    }

    render() {
        const formatMessage = this.state.formatMessage;
        return (
            <div >
                <PageTitle title="CMS_CONTENT_ROUTE_CONTENT_CREATE"/>
                <Card>
                    <Tabs defaultActiveKey="1" tabBarExtraContent={
                        <div>
                            <Button type="primary" icon={"save"} className="spacing-h"
                                    onClick={() => this._handleSubmit(0)}>
                                <FormattedMessage id='COMMON_BUTTON_SAVE'/>
                            </Button>
                            <Button icon={"save"}
                                    onClick={() => this._handleSubmit(1)}>
                                <FormattedMessage id='COMMON_BUTTON_SAVE_DRAFT'/>
                            </Button>
                        </div>
                    }>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_BASIC_INFO"})} key="1">
                            <ContentBasicForm messageMap={this.state.messageMap} {...this.props}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_FILE_LIST"})} key="2">
                            <ContentFileList/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_SUMMARY_EDITOR"})} key="3">
                            <ContentSummaryEditor/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={formatMessage({id: "CMS_CONTENT_TITLE_BODY_EDITOR"})} key="4">
                            <ContentBodyEditor/>
                        </Tabs.TabPane>

                    </Tabs>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        content: store.CmsContentReducer.content,
        files: store.CmsContentFileReducer.files
    }
}

export default connect(mapStateToProps)(injectIntl(Index));