import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from "antd/lib/index";

export function toggleDetailModal(showModal) {
    return {
        type: ActionTypes.DICT_TYPE_TOGGLE_DETAIL_MODAL,
        showDetailModal: showModal
    }
}

export function refreshDictType(dictType) {
    return {
        type: ActionTypes.DICT_TYPE_REFRESH_TYPE,
        dictType: dictType
    }
}

export function getAllDictType(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: true});

        HttpUtils.post(WebService.getDictTypes, {paginationFlag: false}, (response) => {
            dispatch({
                type: ActionTypes.DICT_TYPE_SEARCH,
                dictTypes: response.data
            });
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getDictType(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getDictType, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshDictType(response.data));
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createDictType(dictType, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createDictType, dictType, (response) => {
            dispatch(getAllDictType(formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateDictType(dictType, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateDictType.replace("{id}", dictType.id), dictType, (response) => {
            dispatch(getAllDictType(formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteDictType(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: true});

        HttpUtils.delete(WebService.deleteDictType.replace("{id}", id), {}, (response) => {
            dispatch(getAllDictType(formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_TYPE_LOADING, isLoading: false});
        }, formatMessage)
    }
}