import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message, Modal} from 'antd';
import {searchSubjects} from "../WebService";
import {batchUpdateExaminationSubject} from "./ExaminationSubjectAction";
import {searchPapers} from "./PaperAction";

export function refreshDataModel(dataModel) {
    return {
        type: ActionTypes.EXAM_DATA_MODEL_REFRESH_DATA_MODEL,
        dataModel: dataModel,
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_DATA_MODEL_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_DATA_MODEL_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}



export function searchDataModels(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchDataModels, condition, (response) => {
            let data = response.data;
            data= data.sort((x,y)=>x.score-y.score);
            dispatch({
                type: ActionTypes.EXAM_DATA_MODEL_SEARCH_LIST,
                dataModelList: data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getDataModel(dataModelId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: true});

        let requestURL = WebService.getDataModel.replace("{id}", dataModelId);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshDataModel(response.data));
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createDataModel(dataModel, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: true});

        HttpUtils.post(WebService.createDataModel, dataModel, (response) => {
            dispatch(searchDataModels(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateDataModel(dataModel, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: true});

        let requestURL = WebService.updateDataModel.replace("{id}", dataModel.id);

        HttpUtils.put(requestURL, dataModel, (response) => {
            dispatch(searchDataModels(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteDataModel(id, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: true});
        let requestURL = WebService.deleteDataModel.replace("{id}", id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(searchDataModels(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, formatMessage);
    }
}

export function searchPapersWithDataModel(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: true});
        HttpUtils.post(WebService.searchPapersWithDataModel, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_DATA_MODEL_SEARCH_PAPER_LIST,
                papers: response.data,
                paperPagination: response.pagination
            });
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_DATA_MODEL_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function refreshPaperSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_DATA_MODEL_SEARCH_PAPER_SEARCH_CONDITION,
        paperSearchCondition: condition,
    }
}

export function refreshDataModelPaper(paper) {
    return {
        type: ActionTypes.EXAM_DATA_MODEL_REFRESH_PAPER,
        paper: paper,
    }
}

export function togglePaperDataModelModal(isShow) {
    return {
        type: ActionTypes.EXAM_DATA_MODEL_TOGGLE_PAPER_DATA_MODEL_MODAL,
        showPaperDataModelModal: isShow
    }
}

export function updateByPaper(paperContainer, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        HttpUtils.put(WebService.updateByPaper, paperContainer, (response) => {
            dispatch(searchPapersWithDataModel(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}