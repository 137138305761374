/**
 * Created by Ethan on 17/3/11.
 */
import monent from 'moment';
import * as DateTimeFormats from "../constants/DateTimeFormats";
// 日期格式化
Date.prototype.format = function (format) {
    var o = {
        "M+": this.getMonth() + 1, //month
        "d+": this.getDate(),   //day
        "h+": this.getHours(),  //hour
        "m+": this.getMinutes(), //minute
        "s+": this.getSeconds(), //second
        "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
        "S": this.getMilliseconds() //millisecond
    }
    if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
        (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)if (new RegExp("(" + k + ")").test(format))
        format = format.replace(RegExp.$1,
            RegExp.$1.length == 1 ? o[k] :
                ("00" + o[k]).substr(("" + o[k]).length));
    return format;
}


// 日期格式化
export const DateTimeFormat = {


    dateToHyphenString: (date) => {
        if (date) {
            return monent(date, DateTimeFormats.FORMAT_DATE_SIMPLE).format(DateTimeFormats.FORMAT_DATE_HYPHEN);
        }
        return ''
    },
    datetimeToHyphenString: (datetime) => {
        if (datetime) {
            return monent(datetime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN);
        }
        return ''
    },
    datetimeFullToHyphenString: (datetime) => {
        if (datetime) {
            return monent(datetime, DateTimeFormats.FORMAT_DATE_TIME_FULL_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_FULL_HYPHEN);
        }
        return ''
    },
}