import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Form, InputNumber, Modal} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

import {PaperSubjectStatus} from "../../../Enums";
import {togglePaperDataModelModal} from "../../../actions/ExamDataModelAction";
import {MoneyFormat} from "../../../../../utils/NumberUtil";
import {getPaperDetail, refreshPaperSubject, togglePaperSubjectModifyModal} from "../../../actions/PaperAction";
import {updatePaperSubject} from "../../../actions/PaperSubjectAction";

class PaperScoreModifyModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},


        }
    }

    componentWillMount() {

    }

    componentDidMount() {
    }


    _handleCancel() {
        this.props.dispatch(togglePaperSubjectModifyModal(false));
    }

    _handleOk() {
        const {paperSubject} = this.props;
        const {formatMessage} = this.state;
        paperSubject.status = JSON.stringify(paperSubject.statusObj);

        this.props.dispatch(updatePaperSubject(paperSubject, formatMessage, () => {
            this.props.dispatch(getPaperDetail(paperSubject.paperId, this.state.formatMessage));
            this._handleCancel();
        }));
    }

    _handleChange(name, value) {

        const paperSubject = Object.assign({}, this.props.paperSubject);
        let statusObj = Object.assign({}, paperSubject.statusObj);
        statusObj[name] = value.target ? value.target.value : value;
        paperSubject.statusObj = statusObj;
        this.props.dispatch(refreshPaperSubject(paperSubject));
    }


    render() {

        const formatMessage = this.state.formatMessage;
        const paper = this.props.paper ? this.props.paper : {};
        const paperSubject = this.props.paperSubject ? this.props.paperSubject : {};


        let statusObj = paperSubject.statusObj?paperSubject.statusObj:{};

        return (
            <Modal
                maskClosable={false}
                title={"修正找一找数据"}
                visible={this.props.showPaperSubjectModal}
                onOk={() => this._handleOk()}
                okButtonProps={{loading: this.props.isLoading}}

                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
            >
                <Form>
                    <Form.Item label="正确数" {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <InputNumber value={statusObj[PaperSubjectStatus.Enum.Right]}
                               onChange={(value) => {
                                   this._handleChange(PaperSubjectStatus.Enum.Right, value)
                               }}/>
                    </Form.Item>
                    <Form.Item label="错误数" {...Label_6_Wrapper_18}
                               required={true}>
                        <InputNumber value={statusObj[PaperSubjectStatus.Enum.Wrong]}
                               onChange={(value) => {
                                   this._handleChange(PaperSubjectStatus.Enum.Wrong, value)
                               }}/>
                    </Form.Item>
                </Form>


            </Modal>
        );
    }


}

function mapStateToProps(store) {
    return {
        isLoading: store.ExamPaperReducer.isLoading,
        paper: store.ExamPaperReducer.paper,
        showPaperSubjectModal: store.ExamPaperReducer.showPaperSubjectModal,
        paperSubject: store.ExamPaperReducer.paperSubject
    }
}

export default connect(mapStateToProps)(injectIntl(PaperScoreModifyModal));