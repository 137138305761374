import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col} from "antd";
import {
    refreshContent,
    refreshSearchCondition,
    toggleDetailModal,
    searchContents
} from '../../../actions/ContentAction';

import ChannelSelect from "../../../components/ChannelSelect";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {CACHE_SITE_CODE, CACHE_SITE_ID} from "../../../../../constants/Profile";
import {RoutePath} from "../../../../../constants/RoutePath";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const {searchCondition, pagination, channelId} = this.props;
        searchCondition.draftFlags = [0];
        this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {

        this.props.dispatch(refreshContent({}));
        this.props.dispatch(toggleDetailModal(true));
    }
    //
    // _linkToCreateView() {
    //     this.props.dispatch(refreshContent({}));
    //     const siteId = this.props.params.siteId;
    //     const {channelId} = this.props;
    //     console.log(this.props);
    //     let path = RoutePath.CMS_CONTENT_CREATE.path.replace(":siteId", siteId);
    //     this.props.router.push(path);
    // }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "CMS_CONTENT_FIELD_CHANNEL"})} {...Label_6_Wrapper_18}>
                                <ChannelSelect multiple={true}
                                               channelTree={this.props.channelTree}
                                               value={searchCondition.channelIds}
                                               onChange={(value) => this._handleChange("channelIds", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "CMS_CONTENT_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.title}
                                       onChange={(value) => this._handleChange("title", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="primary" icon="search" className="spacing-h"
                                    onClick={() => this._handleSearch()}>
                                <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                            </Button>
                            <Button icon="plus" className="spacing-h"
                                    onClick={() => this._handleShowCreateModal()}>
                                <FormattedMessage id='COMMON_BUTTON_ADD'/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        channelTree: store.CmsChannelReducer.channelTree,
        searchCondition: store.CmsContentReducer.searchCondition,
        pagination: store.CmsContentReducer.pagination,
        channelId: store.CmsChannelReducer.selectTreeKey
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));