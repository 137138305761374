import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, InputNumber, Checkbox} from "antd";

import {
    toggleDetailModal,
    refreshResource,
    createResource,
    updateResource
} from '../../../actions/ResourceAction';
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import TreeSelector from "../../../../../components/TreeSelector";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {HttpMethods, ResourceTypes} from "../../../Enums";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(resource) {
        let messageMap = {};
        if (resource.code === undefined || resource.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'ACCOUNT_RESOURCE_MSG_CODE_REQUIRED'});
        }
        if (resource.path === undefined || resource.path === "") {
            messageMap["path"] = this.state.formatMessage({id: 'ACCOUNT_RESOURCE_MSG_PATH_REQUIRED'});
        }
        if (resource.type === undefined || resource.type === "") {
            messageMap["type"] = this.state.formatMessage({id: 'ACCOUNT_RESOURCE_MSG_TYPE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
        this.setState({messageMap: {}})
    }

    _handleOk() {
        const {resource, searchCondition} = this.props;
        if (this._validate(resource)) {
            if (resource.id === undefined || resource.id === "") {
                this.props.dispatch(createResource(resource, searchCondition, this.state.formatMessage));
            }
            else {
                this.props.dispatch(updateResource(resource, searchCondition, this.state.formatMessage));
            }

        }
    }

    _handleChange(name, value) {

        const resource = Object.assign({}, this.props.resource);
        resource[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshResource(resource));
    }

    render() {
        const {showDetailModal, resource} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = "";
        if (resource.id === undefined || resource.id === "") {
            title = formatMessage({id: "ACCOUNT_RESOURCE_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "ACCOUNT_RESOURCE_TITLE_UPDATE"});
        }

        return (
            <Modal
                title={title}
                visible={showDetailModal}
                maskClosable={false}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_SUBMIT"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_PARENT'})} {...Label_6_Wrapper_18}>
                        <TreeSelector treeData={this.props.resourceTree} value={resource.parentId}
                                      onChange={(value) => {
                                          this._handleChange("parentId", value)
                                      }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_CODE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={resource.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_NAME_ZH'})} {...Label_6_Wrapper_18}>
                        <Input value={resource.nameZh}
                               onChange={(value) => {
                                   this._handleChange("nameZh", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_NAME_EN'})} {...Label_6_Wrapper_18}>
                        <Input value={resource.nameEn}
                               onChange={(value) => {
                                   this._handleChange("nameEn", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_PATH'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["path"] ? "error" : ""}
                               help={this.state.messageMap["path"]}>
                        <Input value={resource.path}
                               onChange={(value) => {
                                   this._handleChange("path", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_TYPE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["type"] ? "error" : ""}
                               help={this.state.messageMap["type"]}>
                        <EnumItemSelect value={resource.type}
                                        data={ResourceTypes.List}
                                        onChange={(value) => {
                                            this._handleChange("type", value)
                                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_METHOD'})} {...Label_6_Wrapper_18}>
                        <EnumItemSelect
                            value={resource.method}
                            data={HttpMethods.List}
                            localFlag={false}
                            allowClear={true}
                            onChange={(value) => {
                                this._handleChange("method", value)
                            }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_SEQUENCE'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={resource.sequence}
                                     onChange={(value) => {
                                         this._handleChange("sequence", value)
                                     }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_HIDDEN_FLAG'})} {...Label_6_Wrapper_18}>
                        <Checkbox checked={resource.hiddenFlag}
                                  onChange={(value) => this._handleChange("hiddenFlag", value.target.checked ? 1 : 0)}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_ICON'})} {...Label_6_Wrapper_18}>
                        <Input value={resource.icon}
                               onChange={(value) => {
                                   this._handleChange("icon", value)
                               }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        resource: store.AccountResourceReducer.resource,
        resourceTree: store.AccountResourceReducer.resourceTree,
        showDetailModal: store.AccountResourceReducer.showDetailModal,
        searchCondition: store.AccountResourceReducer.searchCondition,
        isLoading: store.FtpFileTransferReducer.isLoading
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));