import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl, FormattedMessage} from 'react-intl';

import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';
import DetailModal from "./DetailModal";
import {getResourceTree, refreshSearchCondition, searchResources} from "../../../actions/ResourceAction";
import {Layout} from "antd";
import TreeControl from "../../../../../components/TreeControl";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(getResourceTree(this.state.formatMessage));
    }

    _handleTreeNodeSelect(selectedKeys) {
        if (selectedKeys && selectedKeys.length > 0) {
            const searchCondition = Object.assign({}, this.props.searchCondition);
            searchCondition.parentIds = [];
            searchCondition.parentIds.push(selectedKeys[0]);
            this.props.dispatch(refreshSearchCondition(searchCondition));
            this.props.dispatch(searchResources(searchCondition, this.state.formatMessage));
        }
    }

    render() {
        return (
            <div>
                <DetailModal/>
                <Layout>
                    <Layout.Sider width={200}
                                  style={{
                                      background: '#fff',
                                      borderRight: '1px solid #e8e8e8',
                                      marginBottom: 'auto',
                                      maxHeight: 750
                                  }}>
                        <TreeControl onSelect={(selectedKeys) => this._handleTreeNodeSelect(selectedKeys)}
                                     treeData={this.props.resourceTree}/>
                    </Layout.Sider>
                    <Layout.Content style={{paddingLeft: '10px', minHeight: 200}}>
                        <SearchCondition/>
                        <SearchResult/>
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        resourceTree: store.AccountResourceReducer.resourceTree,
        searchCondition: store.AccountResourceReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(Index));