const zh_CN = {

    APP_BRAND: "注意力训练管理系统",
    APP_BRAND_COLLAPSED: "EXAM",
    APP_COPYRIGHT: "EXAM Professional",
    APP_MULTI_LANGUAGE: "请选择语言",
    APP_MULTI_LANGUAGE_ZH: "中文",
    APP_MULTI_LANGUAGE_EN: "English",

    COMMON_BUTTON_OK: "确定",
    COMMON_BUTTON_ADD: "添加",
    COMMON_BUTTON_CREATE: "创建",
    COMMON_BUTTON_UPDATE: "更新",
    COMMON_BUTTON_SAVE: "保存",
    COMMON_BUTTON_SAVE_DRAFT: "保存草稿",
    COMMON_BUTTON_MODIFY: "修改",
    COMMON_BUTTON_EDIT: "编辑",
    COMMON_BUTTON_REMOVE: "移除",
    COMMON_BUTTON_DELETE: "删除",
    COMMON_BUTTON_RESTART: "重启",
    COMMON_BUTTON_SUBMIT: "提交",
    COMMON_BUTTON_CANCEL: "取消",
    COMMON_BUTTON_SEARCH: "查询",
    COMMON_BUTTON_SAVEANDCLOSE: "保存并关闭",
    COMMON_BUTTON_SEARCH_CLEAR: "清空查询",
    COMMON_BUTTON_SEARCH_ADVANCE: "高级查询",
    COMMON_BUTTON_EXPORT: "导出",
    COMMON_BUTTON_SELECT: "选择",
    COMMON_BUTTON_SELECTED: "已选择",
    COMMON_BUTTON_BACK: "返回",
    COMMON_BUTTON_UPLOAD: "上传",
    COMMON_BUTTON_UPGRADE: "升级",
    COMMON_BUTTON_IMPORT: "导入",
    COMMON_BUTTON_SCAN: "扫描",
    COMMON_BUTTON_STOP: "停止",
    COMMON_BUTTON_REFRESH: "刷新",
    COMMON_BUTTON_DETAIL: "详细",
    COMMON_BUTTON_CLOSE: "关闭",
    COMMON_BUTTON_COPY: "复制",
    COMMON_BUTTON_NEW: "新建",
    COMMON_BUTTON_DOWNLOAD: "下载",
    COMMON_BUTTON_DOWNLOAD_TEMPLATE: "下载模版",
    COMMON_BUTTON_ENABLE: "启用",
    COMMON_BUTTON_EMPTY: "清空",
    COMMON_BUTTON_PARAMS: "参数",
    COMMON_BUTTON_NEXT: "下一页",
    COMMON_BUTTON_NEXT_STEP: "下一步",
    COMMON_BUTTON_PREVIOUS: "上一页",
    COMMON_BUTTON_PRINT: "打印",
    COMMON_BUTTON_CLEAR: "清空",
    COMMON_BUTTON_SORT: "排序",
    COMMON_BUTTON_SAVE_ORDER: "保存顺序",
    COMMON_BUTTON_HOME: "首页",

    COMMON_STATUS_WAITING: "等待",
    COMMON_STATUS_APPROVING: "待审批",
    COMMON_STATUS_APPROVED: "通过",
    COMMON_STATUS_APPROVED_PART: "部分通过",
    COMMON_STATUS_REJECT: "拒绝",
    COMMON_STATUS_DONE: "完成",

    COMMON_FIELD_UPDATE_TIME: "更新时间",
    COMMON_FIELD_REMARKS: "备注",
    COMMON_FIELD_REMARKS_PLACEHOLDER: "请输入备注",
    COMMON_FIELD_ENABLED: "有效",
    COMMON_FIELD_DISABLED: "无效",
    COMMON_FIELD_SUCCESS: "成功",
    COMMON_FIELD_FAILED: "失败",
    COMMON_FIELD_ACTION: "操作",
    COMMON_FIELD_HAVE: "有",
    COMMON_FIELD_NOTHING: "无",
    COMMON_FIELD_YES: "是",
    COMMON_FIELD_NO: "否",
    COMMON_FIELD_PAGINATION_TOTAL: "共 {sum} 条",
    COMMON_FIELD_HEIGHT: "高",
    COMMON_FIELD_WIDTH: "宽",
    COMMON_FIELD_DEEP: "深",
    COMMON_FIELD_FLOOR: "层",
    COMMON_FIELD_NUMBER: "序号",
    COMMON_FIELD_UNIT_M: "米",
    COMMON_FIELD_UNIT_M2: "平方米",
    COMMON_FIELD_UNIT_T_M2: "吨/平方米",
    COMMON_FIELD_STATUS: "状态",
    COMMON_TITLE_TEXT_EDITOR_MARKDOWN: "Markdown",
    COMMON_FIELD_EMAIL_VERIFY_CODE: "邮箱验证码",
    COMMON_TITLE_TEXT_EDITOR_RICH: "富文本框",
    COMMON_PLACEHOLDER_FUZZY_QUERY: "模糊查询",
    COMMON_PLACEHOLDER_SELECT_DATE: "选择日期",
    COMMON_PLACEHOLDER_SELECT_TIME: "选择时间",

    COMMON_MSG_UPLOAD_CONTINUE: "上传中，请稍后。。。",
    COMMON_MSG_RESTART_SUCCESS: "重启设备成功",
    COMMON_MSG_RESTART_ERROR: "重启设备失败",
    COMMON_MSG_RESTART_TITLE: "重启确认",
    COMMON_MSG_RESTART_CONFIRM: "是否重启该设备？",
    COMMON_MSG_IMPORT_SUCCESS: "导入成功",
    COMMON_MSG_CREATE_SUCCESS: "创建已完成",
    COMMON_MSG_UPDATE_SUCCESS: "更新已完成",
    COMMON_MSG_DELETE_SUCCESS: "删除已完成",
    COMMON_MSG_DELETE_TITLE: "删除确认",
    COMMON_MSG_UPDATE_TITLE: "修改确认",
    COMMON_MSG_DELETE_CONFIRM: "删除后无法恢复，请确认是否删除？",
    COMMON_MSG_UPDATE_CONFIRM: "是否修改主联系人，请确认？",
    COMMON_MSG_REQUIRED: "不能为空",
    COMMON_MSG_EXCEPTION: "未处理异常，请联系管理员",
    COMMON_MSG_EXCEPTION_403: "抱歉，您没有权限访问该页面。",
    COMMON_MSG_EXCEPTION_404: "抱歉，您访问的页面不存在。",
    COMMON_MSG_EXCEPTION_500: "抱歉，服务器发生了错误",
    COMMON_MSG_CREATE_FAVORITES: "收藏成功",
    COMMON_FIELD_VALUE_UNKNOWN: "不详",
    COMMON_FIELD_VALUE_NO_LIMIT: "不限",

    // 字典类型
    DICT_TYPE_TITLE_CREATE: "创建字典类型",
    DICT_TYPE_TITLE_UPDATE: "更新字典类型",
    DICT_TYPE_FIELD_CODE: "编号",
    DICT_TYPE_FIELD_NAME: "名称",
    DICT_TYPE_FIELD_SEQUENCE: "序号",
    DICT_TYPE_MSG_CODE_REQUIRED: "编号是必填项",
    DICT_TYPE_MSG_NAME_REQUIRED: "名称是必填项",
    DICT_TYPE_MSG_DELETE_FAILED_WHIT_CLASSES: "含字典类，删除失败",

    // 字典类
    DICT_CLASS_TITLE_CREATE: "创建字典类",
    DICT_CLASS_TITLE_UPDATE: "更新字典类",
    DICT_CLASS_FIELD_TYPE: "类型",
    DICT_CLASS_FIELD_CODE: "编号",
    DICT_CLASS_FIELD_NAME: "名称",
    DICT_CLASS_FIELD_SEQUENCE: "序号",
    DICT_CLASS_MSG_TYPE_REQUIRED: "类型是必填项",
    DICT_CLASS_MSG_CODE_REQUIRED: "编号是必填项",
    DICT_CLASS_MSG_NAME_REQUIRED: "名称是必填项",
    DICT_CLASS_MSG_DELETE_FAILED_WHIT_ITEMS: "含字典项，删除失败",

    // 字典项
    DICT_ITEM_ROUTE_LIST: "字典项",
    DICT_ITEM_TITLE_CREATE: "创建字典项",
    DICT_ITEM_TITLE_UPDATE: "更新字典项",
    DICT_ITEM_FIELD_CLASS: "分类",
    DICT_ITEM_FIELD_CODE: "编号",
    DICT_ITEM_FIELD_NAME_ZH: "中文名",
    DICT_ITEM_FIELD_NAME_EN: "英文名",
    DICT_ITEM_FIELD_VALUE: "值",
    DICT_ITEM_FIELD_SEQUENCE: "序号",
    DICT_ITEM_FIELD_REMARKS: "备注",
    DICT_ITEM_MSG_CLASS_REQUIRED: "分类是必填项",
    DICT_ITEM_MSG_CODE_REQUIRED: "编号是必填项",
    DICT_ITEM_MSG_TITLE_REQUIRED: "标题是必填项",

    // 账户管理
    ACCOUNT_ROUTER_MANAGEMENT: "账号管理",
    // 账户权限管理-资源
    ACCOUNT_RESOURCE_ROUTE_LIST: "资源一览",
    ACCOUNT_RESOURCE_TITLE_CREATE: "创建资源",
    ACCOUNT_RESOURCE_TITLE_UPDATE: "更新资源",
    ACCOUNT_RESOURCE_FIELD_PARENT: "父资源",
    ACCOUNT_RESOURCE_FIELD_CODE: "编号",
    ACCOUNT_RESOURCE_FIELD_NAME: "名称",
    ACCOUNT_RESOURCE_FIELD_NAME_ZH: "中文名称",
    ACCOUNT_RESOURCE_FIELD_NAME_EN: "英文名称",
    ACCOUNT_RESOURCE_FIELD_PATH: "路径",
    ACCOUNT_RESOURCE_FIELD_SEQUENCE: "序号",
    ACCOUNT_RESOURCE_FIELD_TYPE: "类型",
    ACCOUNT_RESOURCE_FIELD_TYPE_APP: "应用",
    ACCOUNT_RESOURCE_FIELD_TYPE_MENU: "菜单",
    ACCOUNT_RESOURCE_FIELD_TYPE_ACTION: "操作",
    ACCOUNT_RESOURCE_FIELD_METHOD: "方法",
    ACCOUNT_RESOURCE_FIELD_HIDDEN_FLAG: "隐藏",
    ACCOUNT_RESOURCE_FIELD_ICON: "图片",
    ACCOUNT_RESOURCE_MSG_CODE_REQUIRED: "编号不能为空",
    ACCOUNT_RESOURCE_MSG_NAME_REQUIRED: "名称不能为空",
    ACCOUNT_RESOURCE_MSG_PATH_REQUIRED: "路径不能为空",
    ACCOUNT_RESOURCE_MSG_TYPE_REQUIRED: "类型不能为空",
    // 账户管理-角色
    ACCOUNT_ROLE_ROUTE_LIST: "角色一览",
    ACCOUNT_ROLE_TITLE_CREATE: "创建角色",
    ACCOUNT_ROLE_TITLE_UPDATE: "更新角色",
    ACCOUNT_ROLE_TITLE_PERMISSION: "角色授权",
    ACCOUNT_ROLE_FIELD_CODE: "编号",
    ACCOUNT_ROLE_FIELD_NAME: "名称",
    ACCOUNT_ROLE_FIELD_SEQUENCE: "序号",
    ACCOUNT_ROLE_BUTTON_PERMISSION: "授权",
    ACCOUNT_ROLE_MSG_CODE_REQUIRED: "编号不能为空",
    ACCOUNT_ROLE_MSG_NAME_REQUIRED: "名称不能为空",
    // 账户权限管理-角色
    ACCOUNT_USER_ROUTE_LOGIN_PASSWORD: "密码登录",
    ACCOUNT_USER_ROUTE_LOGOUT: "退出登录",
    ACCOUNT_USER_ROUTE_REGISTER: "用户注册",
    ACCOUNT_USER_ROUTE_LIST: "账号一览",
    ACCOUNT_USER_ROUTE_CENTER: "个人中心",
    ACCOUNT_USER_ROUTE_PROFILE: "基本信息",
    ACCOUNT_USER_ROUTE_PASSWORD: "用户密码",
    ACCOUNT_USER_ROUTE_PASSWORD_MODIFY: "修改密码",
    ACCOUNT_USER_ROUTE_PASSWORD_FORGOT: "忘记密码",

    ACCOUNT_USER_TITLE_CREATE: "创建新用户",
    ACCOUNT_USER_TITLE_UPDATE: "更新用户",
    ACCOUNT_USER_TITLE_DELETE: "删除用户",
    ACCOUNT_USER_TITLE_PASSWORD_RESET: "密码重制",
    ACCOUNT_USER_TITLE_USER_SEARCHER_MODAL: "查找用户",
    ACCOUNT_USER_FIELD_UID: "用户ID",
    ACCOUNT_USER_FIELD_UID_PLACEHOLDER: "用户ID",
    ACCOUNT_USER_FIELD_PASSWORD_OLD: "旧密码",
    ACCOUNT_USER_FIELD_PASSWORD: "密码",
    ACCOUNT_USER_FIELD_PASSWORD_CONFIRM: "确认密码",
    ACCOUNT_USER_FIELD_EMAIL: "邮箱",
    ACCOUNT_USER_FIELD_EMAIL_VERIFY_CODE: "邮箱验证码",
    ORG_EMPLOYEE_FIELD_EMAIL_CONFIRM: "邮箱确认",
    ACCOUNT_USER_FIELD_MOBILE: "手机号",
    ACCOUNT_USER_FIELD_FIRST_NAME: "名",
    ACCOUNT_USER_FIELD_LAST_NAME: "姓",
    ACCOUNT_USER_FIELD_FULL_NAME: "姓名",
    ACCOUNT_USER_FIELD_ROLE: "角色",
    ACCOUNT_USER_FIELD_DISABLED: "禁用",
    ACCOUNT_USER_FIELD_PORTRAIT: "头像",
    ACCOUNT_USER_FIELD_DATA_GROUP: "数据组",
    ACCOUNT_USER_FIELD_AUTH_TYPE_PASSWORD: "用户密码",
    ACCOUNT_USER_FIELD_AUTH_TYPE_LDAP: "LDAP",
    ACCOUNT_USER_BUTTON_SUBMIT: "登录",
    ACCOUNT_USER_BUTTON_GO_LOGIN: "去登录",
    ACCOUNT_USER_BUTTON_REGISTER: "注册新用户",
    ACCOUNT_USER_BUTTON_EMAIL_VERIFY_CODE: "发送验证码",
    ACCOUNT_USER_BUTTON_PASSWORD_RESET: "重置密码",
    ACCOUNT_USER_BUTTON_SELECT_ME: "选择我",
    ACCOUNT_USER_MSG_UID_REQUIRED: "用户ID是不能为空",
    ACCOUNT_USER_MSG_PASSWORD_REQUIRED: "密码不能为空",
    ACCOUNT_USER_MSG_PASSWORD_WRONG: "密码错误",
    ACCOUNT_USER_MSG_OLD_PASSWORD_REQUIRED: "旧密码不能为空",
    ACCOUNT_USER_MSG_OLD_PASSWORD_WRONG: "旧密码错误",
    ACCOUNT_USER_MSG_PASSWORD_LENGTH_REQUIRED: "密码长度至少{value}个字符",
    ACCOUNT_USER_MSG_PASSWORD_SPECIAL_CHARACTER_REQUIRED: "必须包含特殊字符",
    ACCOUNT_USER_MSG_PASSWORD_NUMBER_REQUIRED: "必须包含数字",
    ACCOUNT_USER_MSG_PASSWORD_STRENGTH_REQUIRED: "密码必须包含字符，数字，特殊符号的组合",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_REQUIRED: "密码确认不能为空",
    ACCOUNT_USER_MSG_PASSWORD_CONFIRM_EQUAL_REQUIRED: "两次密码不一致",
    ACCOUNT_USER_MSG_MOBILE_REQUIRED: "手机号不能为空",
    ACCOUNT_USER_MSG_MOBILE_FORMAT_WRONG: "手机号格式不正确",
    ACCOUNT_USER_MSG_EMAIL_REQUIRED: "邮箱不能为空",
    ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG: "邮箱格式不正确",
    ACCOUNT_USER_MSG_EMAIL_CODE_REQUIRED: "邮箱验证码不能为空",
    ACCOUNT_USER_MSG_EMAIL_CODE_SEND_SUCCESS: "邮箱验证码发送成功",
    ACCOUNT_USER_MSG_EMAIL_CODE_NOT_FOUND: "邮箱验证码不正确",
    ACCOUNT_USER_MSG_EMAIL_CONFIRM_REQUIRED: "邮件确认不能为空",
    ACCOUNT_USER_MSG_EMAIL_CONFIRM_EQUAL_REQUIRED: "两次邮箱不一致",
    ACCOUNT_USER_MSG_LAST_NAME_REQUIRED: "姓不能为空",
    ACCOUNT_USER_MSG_FIRST_NAME_REQUIRED: "名不能为空",
    ACCOUNT_USER_MSG_ROLE_REQUIRED: "角色不能为空",
    ACCOUNT_USER_MSG_AUTH_TOKEN_EXPIRED: "登录令牌过期",
    ACCOUNT_USER_MSG_AUTH_TOKEN_NON_PRINCIPAL: "登录令牌不存在",
    ACCOUNT_USER_MSG_PASSWORD_RESET_SUCCESS: "密码重制成功",
    ACCOUNT_USER_MSG_PASSWORD_RESET_CONFIRM: "确定要重制该用户的密码吗？",
    ACCOUNT_USER_MSG_RESULT_LOGIN: "返回首页",
    ACCOUNT_USER_MSG_CREATE_SUCCESS: "用户创建成功",
    ACCOUNT_USER_MSG_UPDATE_SUCCESS: "用户更新成功",
    ACCOUNT_USER_MSG_DELETE_SUCCESS: "用户删除成功",
    ACCOUNT_USER_MSG_DELETE_CONFIRM: "用户删除确认吗？",
    ACCOUNT_USER_MSG_NOT_FOUND: "该用户不存在",
    ACCOUNT_USER_MSG_DISABLED: "该用户未激活",

    // 用户管理-数据组
    ACCOUNT_DATA_GROUP_ROUTE_LIST: "数据组",
    ACCOUNT_DATA_GROUP_TITLE_CREATE: "创建数据组",
    ACCOUNT_DATA_GROUP_TITLE_UPDATE: "更新数据组",
    ACCOUNT_DATA_GROUP_FIELD_CODE: "编号",
    ACCOUNT_DATA_GROUP_FIELD_NAME: "名称",
    ACCOUNT_DATA_GROUP_FIELD_SEQUENCE: "序号",
    ACCOUNT_DATA_GROUP_FIELD_PARENT: "所属",
    ACCOUNT_DATA_GROUP_MSG_CODE_REQUIRED: "数据组编号不能为空",
    ACCOUNT_DATA_GROUP_MSG_NAME_REQUIRED: "数据组名称不能为空",

    // 组织管理-组织
    ORG_ROUTER_MANAGEMENT: "人员管理",
    ORG_ORGANIZATION_ROUTE_LIST: "组织一览",
    ORG_ORGANIZATION_TITLE_CREATE: "创建新组织",
    ORG_ORGANIZATION_TITLE_UPDATE: "更新组织",
    ORG_ORGANIZATION_TITLE_DELETE: "删除组织",
    ORG_ORGANIZATION_FIELD_PARENT: "上级组织",
    ORG_ORGANIZATION_FIELD_CODE: "编号",
    ORG_ORGANIZATION_FIELD_NAME: "名称",
    ORG_ORGANIZATION_FIELD_TYPE: "类型",
    ORG_ORGANIZATION_FIELD_TYPE_COMPANY: "公司",
    ORG_ORGANIZATION_FIELD_TYPE_DEPARTMENT: "部门",
    ORG_ORGANIZATION_FIELD_CONTACTS: "联系人",
    ORG_ORGANIZATION_FIELD_TELEPHONE: "联系电话",
    ORG_ORGANIZATION_FIELD_ADDRESS: "联系地址",
    ORG_ORGANIZATION_FIELD_DISABLED: "禁用",
    ORG_ORGANIZATION_FIELD_TEMPORARY: "临时",
    ORG_ORGANIZATION_FIELD_DATA_GROUP: "数据组",
    ORG_ORGANIZATION_MSG_CODE_REQUIRED: "组织编号不能为空",
    ORG_ORGANIZATION_MSG_NAME_REQUIRED: "组织名不能为空",
    ORG_ORGANIZATION_MSG_TYPE_REQUIRED: "组织类型不能为空",
    ORG_ORGANIZATION_MSG_CREATE_SUCCESS: "组织创建成功",
    ORG_ORGANIZATION_MSG_UPDATE_SUCCESS: "组织更新成功",
    ORG_ORGANIZATION_MSG_DELETE_SUCCESS: "组织删除成功",
    ORG_ORGANIZATION_MSG_DELETE_CONFIRM: "组织删除确认吗？",
    ORG_ORGANIZATION_MSG_PARENT_DEAD_CYCLE: "不可设置父组织为自己",
    ORG_ORGANIZATION_MSG_HAVE_CHILDREN: "该组织下有子组织",
    ORG_ORGANIZATION_MSG_HAVE_EMPLOYEE: "该组织下有员工",
    ORG_ORGANIZATION_MSG_NOT_FOUND: "没有找到组织",

    // 组织管理-人员
    ORG_EMPLOYEE_ROUTE_LIST: "人员一览",
    ORG_EMPLOYEE_ROUTE_PROFILE: "基本信息",
    ORG_EMPLOYEE_TITLE_CREATE: "人员创建",
    ORG_EMPLOYEE_TITLE_UPDATE: "人员更新",
    ORG_EMPLOYEE_TITLE_DELETE: "人员删除",
    ORG_EMPLOYEE_TITLE_BASIC_INFO: "基本信息",
    ORG_EMPLOYEE_TITLE_EXTEND_INFO: "扩展信息",
    ORG_EMPLOYEE_TITLE_IMPORT: "员工批量导入",
    ORG_EMPLOYEE_TITLE_SELECTOR_MODAL: "员工选择",
    ORG_EMPLOYEE_FIELD_CODE: "工号",
    ORG_EMPLOYEE_FIELD_TYPE: "类型",
    ORG_EMPLOYEE_FIELD_TYPE_INNER: "内部",
    ORG_EMPLOYEE_FIELD_TYPE_EXTERNAL: "外部",
    ORG_EMPLOYEE_FIELD_FULL_NAME: "姓名",
    ORG_EMPLOYEE_FIELD_MOBILE: "手机号",
    ORG_EMPLOYEE_FIELD_ORGANIZATION: "部门",
    ORG_EMPLOYEE_FIELD_LAST_NAME: "姓",
    ORG_EMPLOYEE_FIELD_FIRST_NAME: "名",
    ORG_EMPLOYEE_FIELD_EMAIL: "邮箱",
    ORG_EMPLOYEE_FIELD_EMAIL_VERIFY_CODE: "Verify Code",
    ORG_EMPLOYEE_FIELD_SEX: "性别",
    ORG_EMPLOYEE_FIELD_SEX_MALE: "男",
    ORG_EMPLOYEE_FIELD_SEX_FEMALE: "女",
    ORG_EMPLOYEE_FIELD_STATUS: "状态",
    ORG_EMPLOYEE_FIELD_STATUS_AT_POST: "在职",
    ORG_EMPLOYEE_FIELD_STATUS_DIMISSION: "离职",
    ORG_EMPLOYEE_FIELD_ENTRY_DATE: "入职日期",
    ORG_EMPLOYEE_FIELD_DIMISSION_DATE: "离职日期",
    ORG_EMPLOYEE_FIELD_PHOTO: "照片",
    ORG_EMPLOYEE_FIELD_POSITION: "所属组",
    ORG_EMPLOYEE_FIELD_ID_NUMBER: "身份证",
    ORG_EMPLOYEE_FIELD_DATA_GROUP: "数据组",
    ORG_EMPLOYEE_FIELD_SUPERVISOR: "上级",
    ORG_EMPLOYEE_BUTTON_SEND_VERIFY_EMAIL_CODE: "Send verify code",
    ORG_EMPLOYEE_BUTTON_REGISTER: "Register",
    ORG_EMPLOYEE_MSG_ORGANIZATION_ID_REQUIRED: "人员所属组织是必填项。",
    ORG_EMPLOYEE_MSG_LAST_NAME_REQUIRED: "人员姓氏是必填项",
    ORG_EMPLOYEE_MSG_FIRST_NAME_REQUIRED: "人员名字是必填项",
    ORG_EMPLOYEE_MSG_CODE_REQUIRED: "人员工号是必填项。",
    ORG_EMPLOYEE_MSG_CODE_UNIQUE: "员工工号重复",
    ORG_EMPLOYEE_MSG_PHOTO_FILE_REQUIRED: "人员照片是必填项",
    ORG_EMPLOYEE_MSG_TYPE_REQUIRED: "人员类型是必填项",
    ORG_EMPLOYEE_MSG_EMAIL_REQUIRED: "人员邮箱是必填项。",
    ORG_EMPLOYEE_MSG_EMAIL_UNIQUE: "该邮箱已被他人登记，请使用其他邮箱。",
    ORG_EMPLOYEE_MSG_EMAIL_FORMAT_WRONG: "人员邮箱格式错误。",
    ORG_EMPLOYEE_MSG_MOBILE_REQUIRED: "人员手机号是必填项。",
    ORG_EMPLOYEE_MSG_MOBILE_FORMAT_WRONG: "人员手机号格式错误",
    ORG_EMPLOYEE_MSG_MOBILE_UNIQUE: "该手机号已被他人登记，请使用其他手机号。",
    ORG_EMPLOYEE_MSG_REGISTER_SUCCESS: "注册成功",
    ORG_EMPLOYEE_MSG_DELETE_CONFIRM: "人员删除确认吗？",
    ORG_EMPLOYEE_MSG_NOT_FOUND: "该员工不存在",
    ORG_EMPLOYEE_MSG_EMAIL_VERIFY_CODE_NOT_FOUND: "邮箱验证码找不到",

    // 岗位/用户组
    ORG_POSITION_ROUTE_LIST: "人员组",
    ORG_POSITION_TITLE_CREATE: "创建人员组",
    ORG_POSITION_TITLE_UPDATE: "更新人员组",
    ORG_POSITION_FIELD_CODE: "编号",
    ORG_POSITION_FIELD_NAME: "名称",
    ORG_POSITION_MSG_CODE_REQUIRED: "编号是必填项。",
    ORG_POSITION_MSG_NAME_REQUIRED: "名称是必填项。",

    // 文件管理
    FTP_FILE_ROUTE_LIST: "文件一览",
    FTP_FILE_FIELD_NAME: "文件名",
    FTP_FILE_FIELD_PATH: "文件路径",
    FTP_FILE_FIELD_LIST: "文件列表",
    FTP_FILE_FIELD_SEARCH: "点击查看",
    FTP_FILE_FIELD_UPLOAD_FILE: "上传文件",
    FTP_FILE_FIELD_FILE_UPLOAD: "文件上传",
    FTP_FILE_FIELD_LAST_MODIFIED: "最后修改日期",
    FTP_MSG_UPLOAD_SUCCEED: "上传成功",
    FTP_MSG_UPLOAD_FAILED: "上传失败",
    FTP_MSG_DOWNLOAD_FAILED: "下载失败",
    FTP_MSG_DELETE_SUCCEED: "删除成功",
    FTP_MSG_DELETE_FAILED: "删除失败",
    FTP_MSG_UPLOAD_FILE_TYPE_LIMIT: "请上传{type}类型的文件！",
    FTP_MSG_UPLOAD_FILE_SIZE_LIMIT: "请上传{size}以内的文件！",

    // 邮件管理
    // 邮件配置
    MAIL_PROPERTIES_ROUTE_LIST: "邮件配置",
    MAIL_PROPERTIES_TITLE_CREATE: "新建邮件配置",
    MAIL_PROPERTIES_TITLE_UPDATE: "更新邮件配置",
    MAIL_PROPERTIES_FIELD_USERNAME: "账号",
    MAIL_PROPERTIES_FIELD_PASSWORD: "密码",
    MAIL_PROPERTIES_FIELD_MAIL_HOST: "主机名",
    MAIL_PROPERTIES_FIELD_MAIL_FROM: "发件人",
    MAIL_PROPERTIES_FIELD_MAIL_PORT: "端口",
    MAIL_PROPERTIES_FIELD_SMTP_AUTH: "认证",
    MAIL_PROPERTIES_FIELD_SMTP_SSL: "SSL",
    MAIL_PROPERTIES_MSG_USERNAME_REQUIRED: "邮件配置账号不能为空",
    MAIL_PROPERTIES_MSG_MAIL_HOST_REQUIRED: "邮件配置主机名不能为空",
    MAIL_PROPERTIES_MSG_MAIL_FROM_REQUIRED: "邮件配置发件人不能为空",
    MAIL_PROPERTIES_MSG_MAIL_PORT_REQUIRED: "邮件配置端口不能为空",
    // 邮件模版
    MAIL_TEMPLATE_ROUTE_LIST: "邮件模版",
    MAIL_TEMPLATE_TITLE_CREATE: "新建邮件模版",
    MAIL_TEMPLATE_TITLE_UPDATE: "更新邮件模版",
    MAIL_TEMPLATE_FIELD_CODE: "编号",
    MAIL_TEMPLATE_FIELD_NAME: "名称",
    MAIL_TEMPLATE_FIELD_MAIL_FROM: "发件人",
    MAIL_TEMPLATE_FIELD_MAIL_TO: "收件人",
    MAIL_TEMPLATE_FIELD_MAIL_CC: "抄送",
    MAIL_TEMPLATE_FIELD_MAIL_BCC: "密送",
    MAIL_TEMPLATE_FIELD_MAIL_TITLE: "标题",
    MAIL_TEMPLATE_FIELD_MAIL_CONTENT: "内容",
    MAIL_TEMPLATE_MSG_CODE_REQUIRED: "邮件模版编号不能为空",
    MAIL_TEMPLATE_MSG_NAME_REQUIRED: "邮件模版名称不能为空",
    MAIL_TEMPLATE_MSG_MAIL_FROM_REQUIRED: "邮件模版发送者不能为空",
    MAIL_TEMPLATE_MSG_MAIL_TO_REQUIRED: "邮件模版收件人不能为空",
    MAIL_TEMPLATE_MSG_VERIFY_CODE_SEND_SUCCESS: "邮件验证码发送成功",

    // 审批流
    WORKFLOW_PROCESS_BUTTON_PASS: '同意',
    WORKFLOW_PROCESS_BUTTON_REJECT: '拒绝',
    WORKFLOW_PROCESS_MSG_APPROVE_PASS_SUCCESS: "审批通过提交成功",
    WORKFLOW_PROCESS_MSG_APPROVE_PASS_CONFIRM: "确认要审批通过这条请求吗？",
    WORKFLOW_PROCESS_MSG_APPROVE_REJECT_SUCCESS: "审批拒绝提交成功",
    WORKFLOW_PROCESS_MSG_APPROVE_REJECT_CONFIRM: "确认要审批拒绝这条请求吗？",
    // 审批代理人
    WORKFLOW_DEPUTY_ROUTE_LIST: "审批代理",
    WORKFLOW_DEPUTY_TITLE_DEPUTY: "代理人",
    WORKFLOW_DEPUTY_TITLE_DEPUTY_SETTING_MODAL: "代理人设定",
    WORKFLOW_DEPUTY_TITLE_DELETE_MODAL: "删除代理人",
    WORKFLOW_DEPUTY_FIELD_AGENT_NAME: "代理人姓名",
    WORKFLOW_DEPUTY_FIELD_AGENT_EMAIL: "代理人邮箱",
    WORKFLOW_DEPUTY_FIELD_AGENT_MOBILE: "代理人手机号",
    WORKFLOW_DEPUTY_FIELD_START_TIME: "开始时间",
    WORKFLOW_DEPUTY_FIELD_END_TIME: "结束时间",
    WORKFLOW_DEPUTY_MSG_AGENT_NAME_REQUIRED: "代理人姓名是必填项",
    WORKFLOW_DEPUTY_MSG_AGENT_EMAIL_REQUIRED: "代理人邮箱是必填项",
    WORKFLOW_DEPUTY_MSG_AGENT_MOBILE_REQUIRED: "代理人手机号是必填项",
    WORKFLOW_DEPUTY_MSG_START_TIME_REQUIRED: "开始时间是必填项",
    WORKFLOW_DEPUTY_MSG_END_TIME_REQUIRED: "结束时间是必填项",

    // 站点
    CMS_SITE_ROUTE_SITE_LIST: "站点管理",
    CMS_SITE_CREATE_MODAL_TITLE: "创建新站点",
    CMS_SITE_UPDATE_MODAL_TITLE: "更新站点",
    CMS_SITE_FIELD_CODE: "编号",
    CMS_SITE_FIELD_TITLE: "名称",
    CMS_SITE_FIELD_URL: "URL",
    CMS_SITE_FIELD_REMARKS: "备注",
    CMS_SITE_MSG_CODE_REQUIRED: "站点编号不能为空",
    CMS_SITE_MSG_TITLE_REQUIRED: "站点标题不能为空",
    CMS_SITE_MSG_URL_REQUIRED: "站点地址不能为空",

    // 内容管理-栏目
    CMS_CHANNEL_ROUTE_CHANNEL_LIST: "栏目管理",
    CMS_CHANNEL_TITLE_CREATE: "创建新栏目",
    CMS_CHANNEL_TITLE_UPDATE: "更新栏目",
    CMS_CHANNEL_FIELD_PARENT: "父栏目",
    CMS_CHANNEL_FIELD_CODE: "编号",
    CMS_CHANNEL_FIELD_TITLE: "标题",
    CMS_CHANNEL_FIELD_TITLE_DESC: "请输入栏目标题关键字",
    CMS_CHANNEL_FIELD_TITLE_IMAGE: "标题图片",
    CMS_CHANNEL_FIELD_TITLE_IMAGE_PREVIEW: "标题图片预览",
    CMS_CHANNEL_FIELD_SEQUENCE: "序号",
    CMS_CHANNEL_FIELD_REMARKS: "备注",
    CMS_CHANNEL_MSG_CODE_REQUIRED: "栏目编号不能为空",
    CMS_CHANNEL_MSG_CODE_DUPLICATE: "栏目编号重复",
    CMS_CHANNEL_MSG_TITLE_REQUIRED: "栏目标题不能为空",

    // 内容管理-内容
    CMS_CONTENT_ROUTE_CONTENT_MANAGEMENT: "内容管理",
    CMS_CONTENT_ROUTE_CONTENT_LIST: "内容列表",
    CMS_CONTENT_ROUTE_CONTENT_DRAFTS: "内容草稿箱",
    CMS_CONTENT_ROUTE_CONTENT_CREATE: "创建新内容",
    CMS_CONTENT_ROUTE_CONTENT_UPDATE: "更新内容",
    CMS_CONTENT_TITLE_CREATE: "创建内容",
    CMS_CONTENT_TITLE_UPDATE: "更新内容",
    CMS_CONTENT_TITLE_BASIC_INFO: "基本信息",
    CMS_CONTENT_TITLE_IMAGE_INFO: "标题图片",
    CMS_CONTENT_TITLE_SUMMARY_EDITOR: "摘要编辑",
    CMS_CONTENT_TITLE_BODY_EDITOR: "内容编辑",
    CMS_CONTENT_TITLE_EDITOR_MARKDOWN: "Markdown",
    CMS_CONTENT_TITLE_EDITOR_RICH: "富文本框",
    CMS_CONTENT_TITLE_FILE_LIST: "附件列表",
    CMS_CONTENT_DELETE_MODAL_TITLE: "删除内容",
    CMS_CONTENT_ATTACHMENT_MODAL_TITLE: "附件一览",
    CMS_CONTENT_FIELD_CHANNEL: "所属栏目",
    CMS_CONTENT_FIELD_CODE: "编号",
    CMS_CONTENT_FIELD_CLASSIFY: "分类",
    CMS_CONTENT_FIELD_TITLE: "标题",
    CMS_CONTENT_FIELD_TITLE_DESC: "请输入内容标题",
    CMS_CONTENT_FIELD_TITLE_IMAGE: "标题图片",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD: "标题图片上传",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_TEXT: "点击或拖拽到该区域上传",
    CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_HINT: "支持单个或批量上传",
    CMS_CONTENT_FIELD_TITLE_LAYOUT: "布局分类",
    CMS_CONTENT_FIELD_SUB_TITLE: "副标题",
    CMS_CONTENT_FIELD_TYPE: "类型",
    CMS_CONTENT_FIELD_SOURCE: "来源",
    CMS_CONTENT_FIELD_PUBLISH_TIME: "发布时间",
    CMS_CONTENT_FIELD_SUMMARY: "摘要",
    CMS_CONTENT_FIELD_BODY: "内容",
    CMS_CONTENT_FIELD_FILES: "附件",
    CMS_CONTENT_MSG_CHANNEL_REQUIRED: "栏目必须选择",
    CMS_CONTENT_MSG_CODE_REQUIRED: "内容编号不能为空",
    CMS_CONTENT_MSG_CLASSIFY_REQUIRED: "内容分类不能为空",
    CMS_CONTENT_MSG_TITLE_REQUIRED: "内容标题不能为空",
    CMS_CONTENT_MSG_TYPE_REQUIRED: "内容类型不能为空",
    CMS_CONTENT_TYPE_ARTICLE: "文章",
    CMS_CONTENT_TITLE_LAYOUT_NO_PICTURE: "纯文字",
    CMS_CONTENT_TITLE_LAYOUT_RIGHT_PICTURE: "左字右图",
    CMS_CONTENT_TITLE_LAYOUT_LEFT_PICTURE: "左图右字",
    CMS_CONTENT_TITLE_LAYOUT_THREE_PICTURE: "上字下三图",
    CMS_CONTENT_TITLE_LAYOUT_BOTTOM_PICTURE: "上字下大图",
    CMS_CONTENT_TITLE_LAYOUT_SLIDE_SHOW: "轮播图",

    // 内容附件
    CMS_CONTENT_FILE_TITLE: "内容附件",
    CMS_CONTENT_FILE_TITLE_CREATE_MODAL: "创建内容附件",
    CMS_CONTENT_FILE_TITLE_DELETE_MODAL: "删除内容附件",
    CMS_CONTENT_FILE_FIELD_PREVIEW: "预览",
    CMS_CONTENT_FILE_FIELD_NAME: "名称",
    CMS_CONTENT_FILE_FIELD_PATH: "路径",
    CMS_CONTENT_FILE_FIELD_SEQUENCE: "序号",
    CMS_CONTENT_FILE_FIELD_TYPE: "类型",
    CMS_CONTENT_FILE_FIELD_SIZE: "大小",
    CMS_CONTENT_FILE_FIELD_REMARKS: "备注",
    CMS_CONTENT_FILE_FIELD_SUMMARY: "摘要",
    CMS_CONTENT_FILE_FIELD_FILE_VERSION: "版本号",
    CMS_CONTENT_FILE_MSG_NAME_REQUIRED: "附件名称不能为空",
    CMS_CONTENT_FILE_MSG_PATH_REQUIRED: "附件路径不能为空",

    // 测评系统
    EXAM_ROUTE_MANAGEMENT: "测评管理",
    //课程管理
    EXAM_LESSON_ROUTE_MANAGEMENT:"课程管理",
    EXAM_EXAMINATION_PERIOD_ROUTE_LIST:"课时一览",

    EXAM_LESSON_TEMPLATE_ROUTE_LIST:"课程模板一览",

    // 题库
    EXAM_BANK_ROUTE_LIST: "题库一览",
    EXAM_BANK_TITLE_CREATE: "创建题库",
    EXAM_BANK_TITLE_UPDATE: "更新题库",
    EXAM_BANK_TITLE_DELETE: "删除题库",
    EXAM_BANK_FIELD_TITLE: "题库标题",
    EXAM_BANK_MSG_TITLE_REQUIRED: "题库标题是必填项",
    EXAM_BANK_MSG_DELETE_CONFIRM: "确认要删除题库吗？",
    // 题库题目
    EXAM_SUBJECT_ROUTE_LIST: "题库题目",
    EXAM_SUBJECT_TITLE_CREATE: "创建新考题",
    EXAM_SUBJECT_TITLE_UPDATE: "更新考题",
    EXAM_SUBJECT_TITLE_BASIC: "基本信息",
    EXAM_SUBJECT_TITLE_CONTENT: "考题内容",
    EXAM_SUBJECT_TITLE_SUMMARY: "题型说明",
    EXAM_SUBJECT_TITLE_FILE: "考题附件",
    EXAM_SUBJECT_TITLE_IMAGE: "图形考题",

    EXAM_SUBJECT_FIELD_BANK: "所属题库",
    EXAM_SUBJECT_FIELD_TYPE: "类型",
    EXAM_SUBJECT_FIELD_SUBJECT_SELECT: "题目选择",

    EXAM_SUBJECT_FIELD_TYPE_SINGLE_CHOICE: "单选题",
    EXAM_SUBJECT_FIELD_TYPE_MULTIPLE_CHOICE: "多选题",
    EXAM_SUBJECT_FIELD_TYPE_JUDGMENT: "判断题",
    EXAM_SUBJECT_FIELD_TYPE_QA: "问答",
    EXAM_SUBJECT_FIELD_TYPE_VIDEO: "视频",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_GO_NO: "Go-no go 任务",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_PAY_ATTENTION: "注意网络任务",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF: "数字划消",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT: "找一找",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL:"数字划销-横版",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL:"找一找-横版",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION:"视觉n-back",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION_1:"视觉1-back",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION_2:"视觉2-back",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_VISION_3:"视觉3-back",

    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING:"听觉n-back",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING_1:"听觉1-back",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING_2:"听觉2-back",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_HEARING_3:"听觉3-back",

    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SHIP_FLANKER:"舰艇flanker",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_ARROW_FLANKER:"箭头flanker",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT:"小松鼠CPT",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT_1:"小松鼠CPT Level1",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT_2:"小松鼠CPT Level2",
    EXAM_SUBJECT_FIELD_TYPE_ATTENTION_TRAINING_SQUIRREL_CPT_3:"小松鼠CPT Level3",


    EXAM_SUBJECT_FIELD_TITLE: "标题",
    EXAM_SUBJECT_FIELD_CONTENT: "内容",
    EXAM_SUBJECT_FIELD_DIFFICULTY_LEVEL: "难度系数",

    EXAM_SUBJECT_FIELD_SEQUENCE: "序号",
    EXAM_SUBJECT_FIELD_SCORE: "分数",
    EXAM_SUBJECT_FIELD_OPTION: "选项",
    EXAM_SUBJECT_FIELD_LIMIT_TIME: "限时（秒）",
    EXAM_SUBJECT_BUTTON_OPTIONS: "选项",
    EXAM_SUBJECT_MSG_NOT_FOUND: "该题目对象不存在",
    EXAM_SUBJECT_MSG_BANK_ID_REQUIRED: "所属题库是必填项",
    EXAM_SUBJECT_MSG_TITLE_REQUIRED: "题目标题是必填项",
    EXAM_SUBJECT_MSG_TYPE_REQUIRED: "题目类型是必填项",
    EXAM_SUBJECT_MSG_LIMIT_TIME_REQUIRED: "限时是必填项",
    // EXAM_SUBJECT_MSG_TYPE_REQUIRED: "题目类型是必填项",

    // 考题选项
    EXAM_SUBJECT_OPTION_FIELD_TITLE: "标题",
    EXAM_SUBJECT_OPTION_FIELD_SEQUENCE: "序号",
    EXAM_SUBJECT_OPTION_FIELD_RIGHT_FLAG: "正确答案",

    // 测评活动
    EXAM_EXAMINATION_ROUTE_LIST: "测评一览",
    EXAM_EXAMINATION_TITLE_CREATE: "创建测评",
    EXAM_EXAMINATION_TITLE_UPDATE: "测评更新",
    EXAM_EXAMINATION_TITLE_BASIC_INFO: "测评基本信息",
    EXAM_EXAMINATION_TITLE_BANK_INFO: "测评题库信息",
    EXAM_EXAMINATION_TITLE_INFORMATION: "测评信息",
    EXAM_EXAMINATION_TITLE_SUBJECTS: "测评题目",
    EXAM_EXAMINATION_TITLE_PUBLISH: "发布测评",
    EXAM_EXAMINATION_FIELD_CODE: "测评编号",
    EXAM_EXAMINATION_FIELD_TITLE: "测评标题",
    EXAM_EXAMINATION_FIELD_TYPE: "测评分类",
    EXAM_EXAMINATION_FIELD_START_TIME: "开始时间",
    EXAM_EXAMINATION_FIELD_END_TIME: "结束时间",
    EXAM_EXAMINATION_FIELD_LIMIT_TIME: "限定时间",
    EXAM_EXAMINATION_FIELD_PASS_SCORE: "及格分数",
    EXAM_EXAMINATION_FIELD_STATUS: "状态",
    EXAM_EXAMINATION_FIELD_CREATE_TIME:"创建时间",
    EXAM_EXAMINATION_FIELD_SUMMARY: "测评描述信息",
    EXAM_EXAMINATION_FIELD_SUBJECTS: "题目",
    EXAM_EXAMINATION_BUTTON_VERIFY: "验证身份",
    EXAM_EXAMINATION_BUTTON_IMPORT_FROM_BANK: "题库导入",
    EXAM_EXAMINATION_BUTTON_PUBLISH: "发布",
    EXAM_EXAMINATION_TYPE_EVALUATION:"注意力测评",
    EXAM_EXAMINATION_TYPE_TRAINING:"注意力训练",
    EXAM_EXAMINATION_STATUS_NEW: "新创建",
    EXAM_EXAMINATION_STATUS_IN_PROGRESS: "进行中",
    EXAM_EXAMINATION_STATUS_COMPLETED: "完成",
    EXAM_EXAMINATION_STATUS_CANCELED: "取消结束",
    EXAM_EXAMINATION_MSG_CODE_REQUIRED: "测评编号不能为空",
    EXAM_EXAMINATION_MSG_TITLE_REQUIRED: "测评标题不能为空",
    EXAM_EXAMINATION_MSG_STATUS_REQUIRED: "状态不能为空",
    EXAM_EXAMINATION_MSG_CODE_DUPLICATE: "测评编号重复",
    EXAM_EXAMINATION_MSG_PUBLISH_DESCRIPTION: "复制二维码或者URL，分享给学员，在移动端扫码或者打开URL的网页，即可参加测评。",
    EXAM_EXAMINATION_MSG_ADD_SUBJECT: "请添加题目",
    EXAM_EXAMINATION_CLASSIFY_EVALUATION:"注意力测评",
    EXAM_EXAMINATION_CLASSIFY_TRAINING:"注意力训练",
    //课程
    EXAM_LESSON_TEMPLATE_TITLE_CREATE: "创建课程",
    EXAM_LESSON_TEMPLATE_TITLE_UPDATE: "课程更新",

    EXAM_LESSON_TEMPLATE_FIELD_EXAMINATION:"关联的课时",
    EXAM_LESSON_TEMPLATE_FIELD_TITLE:"课程模版标题",
    EXAM_LESSON_TEMPLATE_FIELD_REMARKS:"课程备注",
    EXAM_LESSON_TEMPLATE_MSG_EXAMINATIONID_REQUIRED:"关联的课程ID不能为空",
    EXAM_LESSON_TEMPLATE_FIELD_TITLE_REQUIRED: "课程模板标题不能为空",

    //训练
    EXAM_EXAMINATION_PERIOD_FIELD_CODE: "课时编号",
    EXAM_EXAMINATION_PERIOD_FIELD_TITLE: "课时标题",
    EXAM_EXAMINATION_PERIOD_FIELD_SUMMARY: "课时描述信息",
    EXAM_EXAMINATION_PERIOD_MSG_TITLE_REQUIRED: "课时标题不能为空",
    EXAM_EXAMINATION_PERIOD_FIELD_CLASSIFY: "类型",

    //学员课程
    EXAM_EXAMINEE_LESSON_TITLE_CREATE: "创建学员课程",
    EXAM_EXAMINEE_LESSON_TITLE_UPDATE: "学员课程更新",

    EXAM_EXAMINEE_LESSON_FIELD_NUM: "学员课程编号",
    EXAM_EXAMINEE_LESSON_FIELD_REMARKS:"学员课程备注",
    EXAM_EXAMINEE_FIELD_CREATE:"新增学员" ,
    EXAM_EXAMINEE_LESSON_FIELD_TEMPLATE:"课程模板",
    EXAM_EXAMINEE_FIELD_SELECT:"选择课时",
    EXAM_EXAMINEE_LESSON_FIELD_DETAILS:"课程详情",
    EXAM_EXAMINEE_LESSON_FIELD_INFORMATION:"课程情况",

    //课时
    EXAM_EXAMINATION_PERIOD_TITLE_CREATE: "创建课时",
    EXAM_EXAMINATION_PERIOD_TITLE_UPDATE: "课时更新",

    EXAM_LESSON_FIELD_NUM: "课时编号",
    EXAM_LESSON_FIELD_START_TIME: "开始日期",
    EXAM_LESSON_FIELD_END_TIME: "结束日期",
    EXAM_LESSON_FIELD_REMARKS:"课程备注",
    EXAM_LESSON_FIELD_PERIOD_COUNT: "总课时数",
    EXAM_LESSON_FIELD_PAPER_COUNT: "已上课数",


    // 测评考题
    EXAM_EXAMINATION_SUBJECT_TITLE_IMPORT: "测评题目导入",
    EXAM_EXAMINATION_SUBJECT_FIELD_BANK: "题库",
    EXAM_EXAMINATION_SUBJECT_FIELD_LIMIT_TIME: "限时（秒）",
    EXAM_EXAMINATION_SUBJECT_MSG_BANK_ID_REQUIRED: "题库是必选项",

    // 学员
    EXAM_EXAMINEE_ROUTE_LIST: "学员一览",
    EXAM_EXAMINEE_TITLE_CREATE: "创建学员",
    EXAM_EXAMINEE_TITLE_UPDATE: "更新学员",
    EXAM_EXAMINEE_TITLE_DELETE: "删除学员",
    EXAM_EXAMINEE_MSG_DELETE_CONFIRM: "确认要删除学员吗？",
    EXAM_EXAMINEE_TITLE_VERIFY: "学员身份验证",
    EXAM_EXAMINEE_TITLE_VERIFY_SUCCESS: "学员身份验证通过",
    EXAM_EXAMINEE_TITLE_VERIFY_FAILED: "学员身份验证失败",
    EXAM_EXAMINEE_FIELD_ID_NUMBER_PLACEHOLDER: "请输入身份证号码",
    EXAM_EXAMINEE_FIELD_NAME: "学员姓名",
    EXAM_EXAMINEE_FIELD_NUMBER: "试卷编号",
    EXAM_EXAMINEE_FIELD_GENDER: "学员性别",

    EXAM_EXAMINEE_FIELD_LAST_NAME: "姓",
    EXAM_EXAMINEE_FIELD_FIRST_NAME: "名",
    EXAM_EXAMINEE_FIELD_ID_NUMBER: "身份证",
    EXAM_EXAMINEE_FIELD_MOBILE: "手机号",
    EXAM_EXAMINEE_FIELD_EMAIL: "邮箱",
    EXAM_EXAMINEE_FIELD_RESULT: "结果",
    EXAM_EXAMINEE_FIELD_BIRTHDAY: "出生年月",
    EXAM_EXAMINEE_FIELD_GRADE:"年级",
    EXAM_EXAMINEE_MSG_LAST_NAME_REQUIRED: "姓是必选项",
    EXAM_EXAMINEE_MSG_FIRST_NAME_REQUIRED: "名是必选项",
    EXAM_EXAMINEE_MSG_ID_NUMBER_REQUIRED: "身份证是必选项",
    EXAM_EXAMINEE_MSG_ID_NUMBER_DUPLICATE: "身份证重复",
    EXAM_EXAMINEE_MSG_MOBILE_REQUIRED: "手机号是必填项",
    EXAM_EXAMINEE_MSG_BIRTHDAY_REQUIRED: "出生年月是必填项",
    EXAM_EXAMINEE_MSG_VERIFY_SUCCESS: "验证通过",
    EXAM_EXAMINEE_MSG_VERIFY_SUCCESS_DESC: "您的身份验证通过，请确认以下信息是您的本人信息。",
    EXAM_EXAMINEE_MSG_VERIFY_FAILED: "验证失败",
    EXAM_EXAMINEE_MSG_VERIFY_FAILED_DESC: "您的身份验证未通过，请联系相关人员，完成后台登记。",
    EXAM_EXAMINEE_MSG_UPLOAD_TEMPLATE_FILE: "拖拽上传人员数据文件",

    // 测评题目
    EXAM_PAPER_SUBJECT_FIELD_TYPE: "类型",
    EXAM_PAPER_SUBJECT_FIELD_TITLE: "标题",
    EXAM_PAPER_SUBJECT_FIELD_SEQUENCE: "序号",
    EXAM_PAPER_SUBJECT_FIELD_SCORE: "分数",
    EXAM_PAPER_SUBJECT_FIELD_OPTION: "选项",
    EXAM_PAPER_SUBJECT_TYPE_SINGLE_CHOICE: "单选题",
    EXAM_PAPER_SUBJECT_TYPE_MULTIPLE_CHOICE: "多选题",
    EXAM_PAPER_SUBJECT_TYPE_JUDGMENT: "判断题",
    EXAM_PAPER_SUBJECT_TYPE_QA: "问答题",
    EXAM_PAPER_SUBJECT_TYPE_VIDEO: "视频题",
    EXAM_PAPER_SUBJECT_TITLE_CREATE: "创建新考题",
    EXAM_PAPER_SUBJECT_TITLE_UPDATE: "更新考题",
    EXAM_PAPER_SUBJECT_TITLE_DELETE: "删除考题",
    EXAM_PAPER_SUBJECT_BUTTON_OPTIONS: "选项",
    EXAM_PAPER_SUBJECT_MSG_NOT_FOUND: "该题目对象不存在",
    EXAM_PAPER_SUBJECT_MSG_TITLE_REQUIRED: "题目标题不能为空",
    EXAM_PAPER_SUBJECT_MSG_TYPE_REQUIRED: "题目类型不能为空",
    EXAM_PAPER_SUBJECT_MSG_CREATE_SUCCESS: "题目创建成功",
    EXAM_PAPER_SUBJECT_MSG_UPDATE_SUCCESS: "题目更新成功",
    EXAM_PAPER_SUBJECT_MSG_DELETE_SUCCESS: "题目删除成功",
    EXAM_PAPER_SUBJECT_MSG_DELETE_CONFIRM: "确认要删除考题吗？",
    // 考题选项
    EXAM_PAPER_SUBJECT_OPTION_FIELD_TITLE: "标题",
    EXAM_PAPER_SUBJECT_OPTION_FIELD_SEQUENCE: "序号",
    EXAM_PAPER_SUBJECT_OPTION_FIELD_RIGHT_FLAG: "正确答案",

    // 试卷
    EXAM_PAPER_TITLE_DETAIL: "试卷详细",
    EXAM_PAPER_TITLE_DELETE: "删除试卷",
    EXAM_PAPER_MSG_DELETE_CONFIRM: "确认要删除试卷吗？",
    EXAM_PAPER_MSG_SUBMIT_SUCCESS: "提交成功",


    EXAM_DATA_MODEL_FILED_TYPE: "常模类型",

    EXAM_DATA_MODEL_TYPE_ANT_1: "ANT警觉",
    EXAM_DATA_MODEL_TYPE_ANT_2: "ANT定向",
    EXAM_DATA_MODEL_TYPE_ANT_3: "ANT执行控制",
    EXAM_DATA_MODEL_TYPE_GO_NO_GO: "GO-NO-GO",
    EXAM_DATA_MODEL_TYPE_NUMBER: "数字划销",
    EXAM_DATA_MODEL_TYPE_FIND: "找一找",

    EXAM_DATA_MODEL_FILED_GRADE: "学龄",

    EXAM_DATA_MODEL_GRADE_1: "一年级",
    EXAM_DATA_MODEL_GRADE_2: "二年级",
    EXAM_DATA_MODEL_GRADE_3: "三年级",
    EXAM_DATA_MODEL_GRADE_4: "四年级",
    EXAM_DATA_MODEL_GRADE_5: "五年级",

    EXAM_DATA_MODEL_FILED_SCORE: "样本值",
    EXAM_DATA_MODEL_FILED_FREQUENCY: "频率",
    EXAM_DATA_MODEL_FILED_FREQUENCY_PERCENTAGE: "百分比",
    EXAM_DATA_MODEL_FILED_EFFECTIVE_PERCENTAGE: "有效百分比",
    EXAM_DATA_MODEL_FILED_CUMULATIVE_PERCENTAGE: "累积百分比",
    EXAM_DATA_MODEL_ROUTE_LIST:"常模数据管理",

    EXAM_ANT_CLUE_TYPE_NO: "无线索",
    EXAM_ANT_CLUE_TYPE_SPATIAL: "空间线索",
    EXAM_ANT_CLUE_TYPE_MIDDLE: "中央线索",
    EXAM_ANT_MIDDLE_SIDE_CONSISTENT_NO: "不一致",
    EXAM_ANT_MIDDLE_SIDE_CONSISTENT_YES: "一致",
    EXAM_MIDDLE_DIRECTION_LEFT:"左",
    EXAM_MIDDLE_DIRECTION_RIGHT:"右",
    EXAM_APPEAR_POSITION_TOP:"上",
    EXAM_APPEAR_POSITION_CENTER:"中",
    EXAM_APPEAR_POSITION_BOTTOM:"下",

    EXAM_PAPER_SUBJECT_STATUS_NEW: "未回答",
    EXAM_PAPER_SUBJECT_STATUS_RIGHT: "回答正确",
    EXAM_PAPER_SUBJECT_STATUS_WRONG: "回答错误",
    EXAM_PAPER_SUBJECT_STATUS_MISS: "错过答题",

    EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_1: "练习",
    EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_2: "第一页",
    EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_3: "第二页",
    EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_4: "第三页",
    EXAM_SUBJECT_IMAGE_OWN_PAGE_INDEX_5: "第四页",


};
export default zh_CN;