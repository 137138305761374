import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';

export default class EnumItemLabel extends Component {

    render() {
        return (
            <span>
                {
                    (this.props.code && this.props.map[this.props.code]) ?
                        <FormattedMessage id={this.props.map[this.props.code]}/> : ""
                }
            </span>
        );
    }
}