import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Button, Form, Input, Modal, Tag} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

import {
    createLessonTemplate, refreshExaminationSelectedIdList,
    refreshLessonTemplate,
    toggleDetailModal, toggleSelectExaminationModal,
    updateLessonTemplate
} from "../../../actions/LessonTemplateAction";
import ExaminationSelectModal from "./ExaminationSelectModal";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(lesson) {
        let messageMap = {};

        if (!lesson.title || lesson.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_TITLE_REQUIRED'});
        }


        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {lessonTemplate, searchCondition} = this.props;
        const {formatMessage} = this.state;

        if (this._validate(lessonTemplate)) {
            if (lessonTemplate.id && lessonTemplate.id !== "") {
                this.props.dispatch(updateLessonTemplate(lessonTemplate, searchCondition, formatMessage));
            } else {
                this.props.dispatch(createLessonTemplate(lessonTemplate, searchCondition, formatMessage));
            }
            this.props.dispatch(toggleDetailModal(false));
        }
    }

    _handleChange(name, value) {
        const lessonTemplate = Object.assign({}, this.props.lessonTemplate);
        lessonTemplate[name] = (value && value.target) ? value.target.value : value;
        this.props.dispatch(refreshLessonTemplate(lessonTemplate));
    }

    _handleSelectedExaminationModal() {
        const {lessonTemplate, searchCondition} = this.props;
        const {formatMessage} = this.state;
        let examinationIds = lessonTemplate.examinationIds;

        let selectedIdList = [];
        if (examinationIds) {
            selectedIdList = examinationIds.split(",");
        }
        this.props.dispatch(toggleSelectExaminationModal(true));
        this.props.dispatch(refreshExaminationSelectedIdList(selectedIdList));

    }

    render() {
        const {lessonTemplate} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = formatMessage({id: 'EXAM_LESSON_TEMPLATE_TITLE_CREATE'});
        if (lessonTemplate.id && lessonTemplate.id !== "") {
            title = formatMessage({id: 'EXAM_LESSON_TEMPLATE_TITLE_UPDATE'});
        }
        let examinationIds = lessonTemplate.examinationIds;
        let examinationIdList = examinationIds && examinationIds !== "" ? examinationIds.split(',') : [];

        let examinationList = this.props.allPeriodExaminationList ? this.props.allPeriodExaminationList : [];
        examinationList = examinationList.filter(examination => examinationIdList.includes(examination.id));
        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <ExaminationSelectModal/>
                <Form>
                    <Form.Item label={formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_TITLE'})}
                               {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["title"] ? "error" : ""}
                               help={this.state.messageMap["title"]}>
                        <Input value={lessonTemplate.title} onChange={(data) => {
                            this._handleChange("title", data)
                        }}/>
                    </Form.Item>

                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_REMARKS'})}>
                        <Input.TextArea value={lessonTemplate.remarks} onChange={(data) => {
                            this._handleChange("remarks", data)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_EXAMINATION'})}
                               {...Label_6_Wrapper_18}
                               validateStatus={this.state.messageMap["examinationIds"] ? "error" : ""}
                               help={this.state.messageMap["examinationIds"]}>
                       <div>
                           {examinationList.map(examination=>{
                            return  <Tag>{examination.title}</Tag>
                       })}
                       </div>
                        <Button type="primary " size={"small"} onClick={() => this._handleSelectedExaminationModal()}>选择课时</Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.LessonTemplateReducer.searchCondition,
        showDetailModal: store.LessonTemplateReducer.showDetailModal,
        lessonTemplate: store.LessonTemplateReducer.lessonTemplate,
        allPeriodExaminationList: store.LessonTemplateReducer.allPeriodExaminationList,

    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));