import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {HttpStatus} from '../../../constants/Enums';
import HttpUtils from '../../../utils/HttpUtils';
import {message, Modal} from 'antd';

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function toggleFilesModal(isShow) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_TOGGLE_FILES_MODAL,
        showFilesModal: isShow
    }
}

export function refreshFile(file) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_REFRESH_FILE,
        file: file
    }
}

export function refreshFiles(files) {
    return {
        type: ActionTypes.CMS_CONTENT_FILE_REFRESH_FILES,
        files: files
    }
}

export function getContentFileById(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getContentFileById, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshFile(response.data));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function getContentFiles(contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getContentFiles, "contentId", contentId);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshFiles(response.data));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function createContentFile(contentFile, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createContentFile, contentFile, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getContentFiles(contentFile.contentId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function updateContentFile(contentFile, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateContentFile, contentFile, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getContentFiles(contentFile.contentId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
        }, formatMessage)
    }
}

export function deleteContentFile(id, contentId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.CMS_CONTENT_FILE_LOADING, isLoading: true});
        let requestURL = HttpUtils.addQueryString(WebService.deleteContentFile, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(getContentFiles(contentId, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (error) => {
            message.error(error);
        }, formatMessage);
    }
}