import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col} from "antd";
import {
    refreshSearchCondition,
    toggleDetailModal,
    searchLessonTemplates, initLessonTemplate, searchAllExaminationPeriod, refreshLessonTemplate
} from '../../../actions/LessonTemplateAction';

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        const {searchCondition} = this.props;

        this.props.dispatch(searchLessonTemplates(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshLessonTemplate({}));
        this.props.dispatch(toggleDetailModal(true));
        this.props.dispatch(searchAllExaminationPeriod(this.state.formatMessage));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        {/*<Col span={8}>*/}
                        {/*    <Form.Item*/}
                        {/*        label={formatMessage({id: "EXAM_LESSON_TEMPLATE_FIELD_ID"})} {...Label_6_Wrapper_18}>*/}
                        {/*        <Input value={searchCondition.id}*/}
                        {/*               onChange={(value) => this._handleChange("id", value)}/>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_LESSON_TEMPLATE_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.title}
                                       onChange={(value) => this._handleChange("title", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus"
                                        onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.LessonTemplateReducer.searchCondition,
        pagination: store.LessonTemplateReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));