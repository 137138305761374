import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Button, Col, Divider, Popconfirm, Row, Table} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";
import {
    batchUpdateExaminationSubject,
    deleteExaminationSubject,
    getExaminationSubject,
    refreshExaminationSubject,
    searchExaminationSubjects,
    searchExaminationSubjectSortList,
    toggleDetailModal,
    toggleSubjectSortModal
} from "../../../actions/ExaminationSubjectAction";
import {AttentionAssessmentSubjectType, SubjectType} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";
import DragSortModal from "../../../components/DragSortModal";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'EXAM_SUBJECT_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'EXAM_SUBJECT_FIELD_TYPE'}), dataIndex: 'type', key: 'type',
                    width: 150,
                    render: (text, record, index) => <EnumItemLabel code={text} map={SubjectType.Map}/>
                },
                // {
                //     title: formatMessage({id: 'EXAM_SUBJECT_FIELD_SCORE'}), dataIndex: 'score', key: 'score',
                //     width: 50
                // },
                // {
                //     title: formatMessage({id: 'EXAM_SUBJECT_FIELD_LIMIT_TIME'}), dataIndex: 'limitTime', key: 'limitTime',
                //     width: 100
                // },
                {
                    title: formatMessage({id: 'EXAM_SUBJECT_FIELD_DIFFICULTY_LEVEL'}),
                    dataIndex: 'difficultyLevel',
                    key: 'difficultyLevel',
                    width: 250,
                    render: (text, record, index) => record.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO ?
                        ("NoGo "+record.difficultyLevel + " 题，Go " +(50-record.difficultyLevel)+ " 题，系数"+ Math.round(record.difficultyLevel * 100 / 50) + "%") : '-'
                },
                {
                    title: formatMessage({id: 'EXAM_SUBJECT_FIELD_SEQUENCE'}), dataIndex: 'sequence', key: 'sequence',
                    width: 50
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    width: 150,
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handleCopy.bind(this, record)}>*/}
                    {/*<FormattedMessage id="COMMON_BUTTON_COPY"/>*/}
                {/*</a>*/}
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleCopy(record) {
        this.props.dispatch(toggleDetailModal(true));
        record.id = "";
        this.props.dispatch(refreshExaminationSubject(record));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(deleteExaminationSubject(record.id, searchCondition, formatMessage));
    }

    _handleModify(record) {
        this.props.dispatch(getExaminationSubject(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleToggleSubjectSortModal() {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        let condition = {examinationId: searchCondition.examinationId, paginationFlag: false};
        this.props.dispatch(searchExaminationSubjectSortList(condition, this.state.formatMessage));
        this.props.dispatch(toggleSubjectSortModal(true));
    }

    render() {
        return (
            <div className="spacing-v">
                <DragSortModal sortList={this.props.subjectSortList}
                               showSortModal={this.props.showSubjectSortModal}
                               onOk={(sortList) => {
                                   console.log(sortList);
                                   this.props.dispatch(batchUpdateExaminationSubject(sortList, this.props.searchCondition, this.state.formatMessage));
                               }}
                               onCancel={() => {
                                   this.props.dispatch(toggleSubjectSortModal(false));
                               }}/>
                <Row gutter={16}>
                    <Col span={8}>
                        <Button type="primary"
                                onClick={() => this._handleToggleSubjectSortModal()}>
                            <FormattedMessage id='COMMON_BUTTON_SORT'/>
                        </Button>
                    </Col>
                    <Col span={16}>
                        <PaginationBar pagination={this.props.pagination}
                                       onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                    </Col>
                </Row>
                <Table rowKey={(record => record.id)} size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.subjects} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchExaminationSubjects(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        showSubjectSortModal: store.ExamExaminationSubjectReducer.showSubjectSortModal,
        subjectSortList: store.ExamExaminationSubjectReducer.subjectSortList,
        subjects: store.ExamExaminationSubjectReducer.subjects,
        searchCondition: store.ExamExaminationSubjectReducer.searchCondition,
        pagination: store.ExamExaminationSubjectReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));