import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Form, Input, Modal, Switch, InputNumber} from 'antd';

import {
    createProperties,
    refreshProperties,
    toggleDetailModal,
    updateProperties
} from "../../actions/PropertiesAction";
import {Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";

class DetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _handleCancel() {
        this.setState({messageMap: {}});
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleSave() {
        const {dispatch, searchCondition, properties} = this.props;
        if (this._validate(properties)) {
            if (properties.id === undefined || properties.id === "") {
                dispatch(createProperties(properties, searchCondition, this.state.formatMessage));
            } else {
                dispatch(updateProperties(properties, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(properties) {
        let messageMap = {};
        if (properties.username === undefined || properties.username === "") {
            messageMap["username"] = this.state.formatMessage({id: 'MAIL_PROPERTIES_MSG_USERNAME_REQUIRED'});
        }
        if (properties.mailHost === undefined || properties.mailHost === "") {
            messageMap["mailHost"] = this.state.formatMessage({id: 'MAIL_PROPERTIES_MSG_MAIL_HOST_REQUIRED'});
        }
        if (properties.mailFrom === undefined || properties.mailFrom === "") {
            messageMap["mailFrom"] = this.state.formatMessage({id: 'MAIL_PROPERTIES_MSG_MAIL_FROM_REQUIRED'});
        }
        if (properties.mailPort === undefined || properties.mailPort === "") {
            messageMap["mailPort"] = this.state.formatMessage({id: 'MAIL_PROPERTIES_MSG_MAIL_PORT_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleChange(name, value) {
        let properties = Object.assign({}, this.props.properties);
        properties[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshProperties(properties));
    }

    render() {
        const {properties} = this.props;
        const formatMessage = this.state.formatMessage;
        let title = "";
        if (properties.id === undefined || properties.id === "") {
            title = formatMessage({id: "MAIL_PROPERTIES_TITLE_CREATE"});
        } else {
            title = formatMessage({id: "MAIL_PROPERTIES_TITLE_UPDATE"});
        }
        return (
            <Modal
                title={title}
                visible={this.props.showDetailModal} maskClosable={false}
                onOk={() => this._handleSave()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_USERNAME'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["username"] ? "error" : ""}
                               help={this.state.messageMap["username"]}>
                        <Input value={properties.username}
                               onChange={(value) => {
                                   this._handleChange("username", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_PASSWORD'})} {...Label_6_Wrapper_18}>
                        <Input value={properties.password}
                               onChange={(value) => {
                                   this._handleChange("password", value)
                               }}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_MAIL_HOST'})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={this.state.messageMap["mailHost"] ? "error" : ""}
                        help={this.state.messageMap["mailHost"]}>
                        <Input value={properties.mailHost}
                               onChange={(value) => {
                                   this._handleChange("mailHost", value)
                               }}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_MAIL_FROM'})} {...Label_6_Wrapper_18}
                        required={true}
                        validateStatus={this.state.messageMap["mailFrom"] ? "error" : ""}
                        help={this.state.messageMap["mailFrom"]}>
                        <Input value={properties.mailFrom}
                               onChange={(value) => {
                                   this._handleChange("mailFrom", value)
                               }}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_MAIL_PORT'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={properties.mailPort}
                               onChange={(value) => {
                                   this._handleChange("mailPort", value)
                               }}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_SMTP_AUTH'})} {...Label_6_Wrapper_18}>
                        <Switch checked={properties.smtpAuth === 1}
                                onChange={(value) => {
                                    this._handleChange("smtpAuth", value ? 1 : 0)
                                }}/>
                    </Form.Item>
                    <Form.Item
                        label={formatMessage({id: 'MAIL_PROPERTIES_FIELD_SMTP_SSL'})} {...Label_6_Wrapper_18}>
                        <Switch checked={properties.smtpSsl === 1}
                                onChange={(value) => {
                                    this._handleChange("smtpSsl", value ? 1 : 0)
                                }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.MailPropertiesReducer.isLoading,
        properties: store.MailPropertiesReducer.properties,
        showDetailModal: store.MailPropertiesReducer.showDetailModal,
        searchCondition: store.MailPropertiesReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));