import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, Select, InputNumber} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {createDictClass, refreshDictClass, toggleDetailModal, updateDictClass} from "../../../actions/DictClassAction";

class DictClassModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    _validate(dictClass) {
        let messageMap = {};
        if (dictClass.typeId === undefined || dictClass.typeId === "") {
            messageMap["typeId"] = this.state.formatMessage({id: 'DICT_CLASS_MSG_TYPE_REQUIRED'});
        }
        if (dictClass.code === undefined || dictClass.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'DICT_CLASS_MSG_CODE_REQUIRED'});
        }
        if (dictClass.name === undefined || dictClass.name === "") {
            messageMap["name"] = this.state.formatMessage({id: 'DICT_CLASS_MSG_NAME_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.setState({messageMap: {}});
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const dictClass = this.props.dictClass;
        if (this._validate(this.props.dictClass)) {
            if (dictClass.id !== undefined && dictClass.id !== "") {
                this.props.dispatch(updateDictClass(this.props.dictClass, this.state.formatMessage));
            } else {
                this.props.dispatch(createDictClass(this.props.dictClass, this.state.formatMessage));
            }
        }
    }

    _handleChange(name, value) {

        const dictClass = Object.assign({}, this.props.dictClass);
        dictClass[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshDictClass(dictClass));
    }

    render() {
        const {dictClass} = this.props;
        const {formatMessage} = this.state;

        let title = formatMessage({id: 'DICT_CLASS_TITLE_CREATE'});
        if (dictClass.id !== undefined && dictClass.id !== "") {
            title = formatMessage({id: "DICT_CLASS_TITLE_UPDATE"});
        }

        return (
            <Modal
                title={title}
                maskClosable={false}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={this.state.formatMessage({id: 'COMMON_BUTTON_OK'})}
                cancelText={this.state.formatMessage({id: 'COMMON_BUTTON_CANCEL'})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'DICT_CLASS_FIELD_TYPE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["typeId"] ? "error" : ""}
                               help={this.state.messageMap["typeId"]}>
                        <Select value={dictClass.typeId}
                                onChange={(value) => this._handleChange("typeId", value)}>
                            {
                                this.props.dictTypes.map((item, index) => {
                                    return <Select.Option key={index} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_CLASS_FIELD_CODE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={dictClass.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_CLASS_FIELD_NAME'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={dictClass.name}
                               onChange={(value) => {
                                   this._handleChange("name", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_CLASS_FIELD_SEQUENCE'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={dictClass.sequence} onChange={(value) => {
                            this._handleChange("sequence", value)
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        dictTypes: store.DictTypeReducer.dictTypes,
        dictClass: store.DictClassReducer.dictClass,
        showDetailModal: store.DictClassReducer.showDetailModal
    }
}

export default connect(mapStateToProps)(injectIntl(DictClassModal));