// 邮件配置
export const MAIL_PROPERTIES_LOADING = "MAIL_PROPERTIES_LOADING";
export const MAIL_PROPERTIES_TOGGLE_DETAIL_MODAL = "MAIL_PROPERTIES_TOGGLE_DETAIL_MODAL";
export const MAIL_PROPERTIES_REFRESH_PROPERTIES = "MAIL_PROPERTIES_REFRESH_PROPERTIES";
export const MAIL_PROPERTIES_SEARCH = "MAIL_PROPERTIES_SEARCH";
export const MAIL_PROPERTIES_REFRESH_SEARCH_CONDITION = "MAIL_PROPERTIES_REFRESH_SEARCH_CONDITION";

// 邮件模版
export const MAIL_TEMPLATE_LOADING = "MAIL_TEMPLATE_LOADING";
export const MAIL_TEMPLATE_TOGGLE_DETAIL_MODAL = "MAIL_TEMPLATE_TOGGLE_DETAIL_MODAL";
export const MAIL_TEMPLATE_REFRESH_TEMPLATE = "MAIL_TEMPLATE_REFRESH_TEMPLATE";
export const MAIL_TEMPLATE_SEARCH = "MAIL_TEMPLATE_SEARCH";
export const MAIL_TEMPLATE_REFRESH_TEMPLATE_EDITOR = "MAIL_TEMPLATE_REFRESH_TEMPLATE_EDITOR";
export const MAIL_TEMPLATE_REFRESH_SEARCH_CONDITION = "MAIL_TEMPLATE_REFRESH_SEARCH_CONDITION";

export const MAIL_SEND_TOGGLE_SEND_INFO_MODAL = "MAIL_SEND_TOGGLE_SEND_INFO_MODAL";
export const MAIL_SEND_REFRESH_MAIL_TASK = "MAIL_SEND_REFRESH_MAIL_TASK";
export const MAIL_SEND_REFRESH_MAIL_ASSIST = "MAIL_SEND_REFRESH_MAIL_ASSIST";
export const MAIL_SEND_REFRESH_MAIL_EDITOR = "MAIL_SEND_REFRESH_MAIL_EDITOR";

export const MAIL_SEND_REFRESH_SEND_AFTER_FUNCTION = "MAIL_SEND_REFRESH_SEND_AFTER_FUNCTION";
export const MAIL_SEND_SEARCH_MAIL_LIST = "MAIL_SEND_SEARCH_MAIL_LIST";
export const MAIL_TOGGLE_SEND_MAIL_HISTORY_MODAL = "MAIL_TOGGLE_SEND_MAIL_HISTORY_MODAL";

