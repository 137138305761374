import * as ActionTypes from '../ActionTypes';

const initialState = {
    template: {},
    templates: [],
    editor: {},
    searchCondition: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
};

const TemplateReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.MAIL_TEMPLATE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.MAIL_TEMPLATE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.MAIL_TEMPLATE_REFRESH_TEMPLATE:
            return Object.assign({}, state, {
                template: action.template
            });
        case ActionTypes.MAIL_TEMPLATE_SEARCH:
            return Object.assign({}, state, {
                templates: action.templates,
                pagination: action.pagination
            });
        case ActionTypes.MAIL_TEMPLATE_REFRESH_TEMPLATE_EDITOR:
            return Object.assign({}, state, {
                editor: action.editor
            });
        case ActionTypes.MAIL_TEMPLATE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default TemplateReducer;