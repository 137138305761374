import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Space, Button} from "antd-mobile";

import {PaperSubjectStatus, SubjectType} from "../../../../Enums";
import PaperOptions from "./PaperSubjectOptions";

import {refreshCurrentIndex} from "../../../../actions/PaperSubjectAction";
import {handInPaper} from "../../../../actions/PaperAction";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {Toast} from "antd-mobile/es/index";
import PaperSubjectVideo from "./PaperSubjectVideo";
import ReactMarkdown from "react-markdown";
import {Input} from "antd";

class PaperSubject extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            content: ""
        }
    }

    componentWillMount() {
    }

    render() {
        const {formatMessage} = this.state;
        const {paperSubject, index, sum} = this.props;
        if (!paperSubject || !paperSubject.subject) {
            return null;
        }

        let titleComponent = null;
        let contentComponent = null;
        let subjectComponent = null;

        if (paperSubject.subject.type === SubjectType.Enum.VIDEO) {
            contentComponent = <ReactMarkdown source={paperSubject.subject && paperSubject.subject.content}
                                              escapeHtml={false}/>;
            subjectComponent = <PaperSubjectVideo subjectIndex={index}/>
        } else {
            titleComponent = <div>{paperSubject.subject.title}</div>;
            subjectComponent = <PaperOptions subjectIndex={index}/>;
        }

        return (
            <div className="p-5">
                <Card
                    title={<span style={{
                        borderLeftColor: "#BD0000",
                        borderLeftStyle: "solid",
                        borderLeftWidth: 3,
                        paddingLeft: "3px"
                    }}>{formatMessage({id: SubjectType.Map[paperSubject.subject.type]})}</span>}
                    extra={<div>
                        <span style={{color: "#000", fontSize: "24px", fontWeight: 800}}>{index + 1}</span>
                        <span className="text-muted">/</span>
                        <span className="text-muted">{sum}</span></div>}
                >
                    {titleComponent}
                    {contentComponent}
                    <Space size="lg"/>
                    {subjectComponent}
                    <Space size="lg"/>
                    <div className="text-right">
                        {this._renderPreButton(index)}
                        {this._renderNextButton(index, sum, paperSubject)}
                        {this._renderSubmitButton(index, sum, paperSubject)}
                    </div>
                </Card>
            </div>
        );
    }

    _renderPreButton(index) {
        if (index <= 0) {
            return null;
        }
        return (
            <Button type="ghost" size="small" inline className="spacing-h"
                    onClick={() => this.props.dispatch(refreshCurrentIndex(index - 1))}>上一题</Button>
        )
    }

    _renderNextButton(index, sum, paperSubject) {
        if ((index + 1) >= sum) {
            return null;
        }

        // let canNext = false;
        // for (let i = 0; i < paperSubject.options.length; i++) {
        //     if (paperSubject.options[i].selectFlag === 1) {
        //         canNext = true;
        //         break;
        //     }
        // }

        if (paperSubject.status === PaperSubjectStatus.Enum.New) {
            return null;
        }

        return (
            <Button type="ghost" size="small" inline
                    onClick={() => this.props.dispatch(refreshCurrentIndex(index + 1))}>下一题</Button>
        )
    }

    _renderSubmitButton(index, sum, paperSubject) {
        if (index + 1 !== sum) {
            return null;
        }

        // let canNext = false;
        // for (let i = 0; i < paperSubject.options.length; i++) {
        //     if (paperSubject.options[i].selectFlag === 1) {
        //         canNext = true;
        //         break;
        //     }
        // }

        if (paperSubject.status === PaperSubjectStatus.Enum.New) {
            return null;
        }

        return (
            <Button type="ghost" size="small" inline onClick={() => this._handleSubmit()}>提交</Button>
        )
    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.paper);
        const subjects = Object.assign([], this.props.subjects);
        paper.subjects = subjects;
        this.props.dispatch(handInPaper(paper, this.state.formatMessage, () => {

            Toast.show({
                icon: 'success',
                content: this.state.formatMessage({id: "EXAM_PAPER_MSG_SUBMIT_SUCCESS"}),
            });
            const examinationId = this.props.location.query.examinationId;
            const examineeId = this.props.location.query.examineeId;
            this.props.router.push({
                pathname: RoutePath.EXAM_PAPER_RESULT.path,
                query: {examinationId: examinationId, examineeId: examineeId}
            });
        }));
    }
}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper,
        subjects: store.ExamPaperSubjectReducer.subjects,
    }
}

export default connect(mapStateToProps)(injectIntl(PaperSubject));