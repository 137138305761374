import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Popconfirm} from 'antd';

import {deleteDictItem, getDictItemDetail, refreshDictItem, toggleDetailModal} from "../../../actions/DictItemAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'DICT_ITEM_FIELD_CODE'}), dataIndex: 'code', key: 'code'},
                {title: formatMessage({id: 'DICT_ITEM_FIELD_NAME_ZH'}), dataIndex: 'nameZh', key: 'nameZh'},
                {title: formatMessage({id: 'DICT_ITEM_FIELD_NAME_EN'}), dataIndex: 'nameEn', key: 'nameEn'},
                {title: formatMessage({id: 'DICT_ITEM_FIELD_VALUE'}), dataIndex: 'value', key: 'value'},
                {title: formatMessage({id: 'DICT_ITEM_FIELD_SEQUENCE'}), dataIndex: 'sequence', key: 'sequence'},
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleCopy.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_COPY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleCopy(record) {
        record.id = '';
        this.props.dispatch(refreshDictItem(record));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        this.props.dispatch(deleteDictItem(record, this.state.formatMessage));
    }

    _handleModify(record) {
        this.props.dispatch(getDictItemDetail(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        return (
            <div className="spacing-v">
                <Table rowKey="id" size="middle" columns={this.state.columns} dataSource={this.props.dictItems}/>
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        dictItems: store.DictItemReducer.dictItems,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));