import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col, Table, Collapse} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {searchPapers, refreshSearchCondition, exportPapers, handInPaper} from "../../../actions/PaperAction";
import {FORMAT_DATE_TIME_FULL_SIMPLE, FORMAT_DATE_TIME_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {DateTimeFormat} from "../../../../../utils/DateUtil";
import {
    ANTClueType,
    ANTMiddleSideConsistent, AttentionAssessmentSubjectType, ExamDataModelType,
    ExaminationStatus,
    getEnumsLabel,
    PaperSubjectStatus
} from "../../../Enums";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import moment from 'moment';
import {MoneyFormat} from "../../../../../utils/NumberUtil";

class PaperScoreShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],

            columns: [
                {
                    title: "维度",
                    dataIndex: 'name',
                    key: 'name',

                },
                {
                    title: "记分结果",
                    dataIndex: 'score',
                    key: 'score'
                },
                {
                    title: "得分(40～80)",
                    dataIndex: 'scoreInModel',
                    key: 'scoreInModel',

                },

            ]
        }

    }

    componentWillMount() {

    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.paper);
        const subjects = Object.assign([], this.props.subjects);

        this.props.dispatch(handInPaper(paper, this.state.formatMessage));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const relatedExaminationSubjectList = this.props.relatedExaminationSubjectList ? this.props.relatedExaminationSubjectList : [];

        const paper = this.props.paper ? this.props.paper : {};


        const scoreResult = paper.scoreResult && paper.scoreResult !== "" ? JSON.parse(paper.scoreResult) : {};
        let dataList = [];

        // dataList.push({
        //     name: "注意警觉",
        //     score: MoneyFormat.formatThree(scoreResult.antScore1),
        //     scoreInModel: MoneyFormat.formatThree(scoreResult.antScore1InModel)
        // });
        // dataList.push({
        //     name: "注意定向",
        //     score: MoneyFormat.formatThree(scoreResult.antScore2),
        //     scoreInModel: MoneyFormat.formatThree(scoreResult.antScore2InModel)
        // });
        // dataList.push({
        //     name: "执行控制",
        //     score: MoneyFormat.formatThree(scoreResult.antScore3),
        //     scoreInModel: MoneyFormat.formatThree(scoreResult.antScore3InModel)
        // });
        // dataList.push({
        //     name: "抗干扰",
        //     score: scoreResult.goNoGoScore,
        //     scoreInModel: MoneyFormat.formatThree(scoreResult.goNoGoScoreInModel)
        // });
        // dataList.push({
        //     name: "注意稳定性",
        //     score: MoneyFormat.formatThree(scoreResult.digitalScore),
        //     scoreInModel: MoneyFormat.formatThree(scoreResult.digitalScoreInModel)
        // });
        // dataList.push({
        //     name: "注意选择性",
        //     score: MoneyFormat.formatThree(scoreResult.findItScore),
        //     scoreInModel: MoneyFormat.formatThree(scoreResult.findItScoreInModel)
        // });

        ExamDataModelType.List.forEach(item => {
            let code = item.value;
            let showMapElement = ExamDataModelType.ShowMap[code];
            let data = {};
            data.code = code;
            data.name = showMapElement.scoreLabel;


            data.score = MoneyFormat.formatThree(scoreResult[showMapElement.score]);
            data.isFinish = scoreResult[showMapElement.isFinish];

            if (data.isFinish) {
                let scoreResultElement = scoreResult[showMapElement.scoreInModel];
                if (scoreResultElement > 95) {
                    data.scoreInModel = "＞95分（非常优秀）"
                } else if (scoreResultElement < 10) {
                    data.scoreInModel = "＜10分（较弱，有待提高）";
                } else {
                    data.scoreInModel = MoneyFormat.formatThree(scoreResultElement);
                }
            } else {
                data.scoreInModel = "-";
                data.score = "-";
            }
            dataList.push(data);
        });
        console.log(scoreResult);
        return (

            <Card>
                <h5>测评结果</h5>
                <div style={{border: "#c8dbfd 1px solid", marginBottom: '10px'}}>

                    <Table rowKey={(record, index) => index}
                           size="middle" className="spacing-v" columns={this.state.columns}
                           dataSource={dataList} pagination={false}/>
                    {/*<div*/}
                    {/*style={{backgroundColor: "#c8dbfd", lineHeight: "26px", padding: '3px 5px'}}>*/}
                    {/*注意网络任务*/}
                    {/*</div>*/}
                    {/*<div style={{padding: '3px 5px'}}>*/}
                    {/*<p> 注意警觉的记分：{MoneyFormat.formatThree(scoreResult.antScore1 * 1000)}</p>*/}
                    {/*<p> 注意定向的记分：{MoneyFormat.formatThree(scoreResult.antScore2 * 1000)}</p>*/}
                    {/*<p> 执行控制的记分：{MoneyFormat.formatThree(scoreResult.antScore3 * 1000)}</p>*/}
                    {/*<br/>*/}
                    {/*<div style={{color: "grey"}}>*/}
                    {/*计算参考值*/}
                    {/*<div> 无线索的反应时：{MoneyFormat.formatThree(scoreResult.antClueTypeNoTime * 1000)}</div>*/}
                    {/*<div> 中央线索的反应时：{MoneyFormat.formatThree(scoreResult.antClueTypeMiddleTime * 1000)}</div>*/}
                    {/*<div> 空间线索的反应时：{MoneyFormat.formatThree(scoreResult.antClueTypeSpatialTime * 1000)}</div>*/}
                    {/*<div> 不一致条件下的反应时：{MoneyFormat.formatThree(scoreResult.antMiddleSideConsistentNoTime * 1000)}</div>*/}
                    {/*<div> 一致条件下的反应时：{MoneyFormat.formatThree(scoreResult.antMiddleSideConsistentYesTime * 1000)}</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    <br/>
                    {scoreResult.antIsFinish ?
                        <div>
                            注意网络的完成情况：
                            <div><strong>「正确率」</strong>
                                {scoreResult.antAllCount > 0 ? MoneyFormat.formatOne(scoreResult.antRightCount * 100 / scoreResult.antAllCount) + "%" : "0%"}
                            </div>
                            <div><strong>「平均反应时(毫秒)」</strong>
                                {MoneyFormat.formatThree(scoreResult.antAllTimeAvg)}</div>
                            <div><strong>「正确平均反应时(毫秒)」</strong>
                                {MoneyFormat.formatThree(scoreResult.antRightTimeAvg)}</div>
                        </div> : null}
                </div>

            </Card>
        );
    }

}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper,
        relatedExaminationSubjectList: store.ExamPaperReducer.relatedExaminationSubjectList,
    }
}

export default connect(mapStateToProps)(injectIntl(PaperScoreShowParts));