import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from "antd";

export function refreshExaminee(examinee) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_REFRESH_EXAMINEE,
        examinee: examinee,
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function refreshImportResult(importResult) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_REFRESH_IMPORT_RESULT,
        importResult: importResult
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_TOGGLE_MODAL,
        showDetailModal: isShow
    }
}

export function toggleImportModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_TOGGLE_IMPORT_MODAL,
        showImportModal: isShow
    }
}

export function searchExaminees(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminees, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_EXAMINEE_SEARCH_EXAMINEE,
                examinees: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getExamineeDetail(id, idNumber, successCallback, failCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});
        let requestURL = "";
        if (id && idNumber) {
            requestURL = HttpUtils.addQueryString(WebService.getExamineeDetail, "id", id);
            requestURL = HttpUtils.addQueryString(requestURL, "idNumber", idNumber);
        } else if (id) {
            requestURL = HttpUtils.addQueryString(WebService.getExamineeDetail, "id", id);
        } else if (idNumber) {
            requestURL = HttpUtils.addQueryString(WebService.getExamineeDetail, "idNumber", idNumber);
        }

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshExaminee(response.data));
            successCallback && successCallback.call(this, response.data);
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            failCallback && failCallback.call(this, response.data);
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        })
    }
}

export function createExaminee(examinee, formatMessage, refreshFunction) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});
        HttpUtils.post(WebService.createExaminee, examinee, (response) => {
            if (refreshFunction) {
                refreshFunction.call();
            }

            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateExaminee(examinee, formatMessage, refreshFunction) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});
        HttpUtils.post(WebService.updateExaminee, examinee, (response) => {
            if (refreshFunction) {
                refreshFunction.call();
            }
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteExaminee(id, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});
        let requestURL = HttpUtils.addQueryString(WebService.deleteExaminee, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchExaminees(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage);
    }
}


export function importExaminee(examinees, condition, formatMessage, intl) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});
        HttpUtils.post(WebService.importExaminee, examinees, (response) => {
            let data = response.data;
            let examineeList = [];
            for (let i = 0; i < examinees.length; i++) {
                let examinee = examinees[i];
                if (data[examinee.idNumber]) {
                    if (intl.messages[data[examinee.idNumber]]) {
                        examinee.result = formatMessage({id: data[examinee.idNumber]});
                    } else {
                        examinee.result = data[examinee.idNumber];
                    }
                } else {
                    examinee.result = intl.formatMessage({id: "COMMON_MSG_IMPORT_SUCCESS"});
                }
                examineeList.push(examinee);
            }
            dispatch(searchExaminees(condition, formatMessage));
            dispatch(refreshImportResult(examineeList));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function searchAllExaminees(formatMessage) {
    let condition = {paginationFlag: false};

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminees, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_EXAMINEE_SEARCH_EXAMINEE_ALL_LIST,
                allExaminees: response.data,
            });

            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}