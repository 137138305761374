import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';


import {AttentionAssessmentSubjectType, ExamDataModelGrade, ExamDataModelType, getEnumsLabel} from "../../../Enums";

import {Button, Card, Table} from "antd";
import {doExportPaperReport, handInPaper} from "../../../actions/PaperAction";


import LessonReportChartShowParts from "./LessonReportChartShowParts";
import moment from "moment";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import {doExportLessonPaperReport} from "../../../actions/ExamineeLessonAction";
import {exportLessonReport} from "../../../WebService";
import LessonReportChartTimeShowParts from "./LessonReportChartTimeShowParts";

class LessonSubjectReport extends Component {


    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],

            columns: [
                {
                    title: "维度",
                    dataIndex: 'name',
                    key: 'name',

                    width: 80,
                },
                {
                    title: "含义",
                    dataIndex: 'info',
                    key: 'info',
                    render: (text, record, index) => <div>{text}</div>,


                    width: 200,
                },
                {
                    title: "行为表现",
                    dataIndex: 'info2',
                    key: 'info2',

                },
            ],
            dataColumns: [
                {
                    title: "指标",
                    dataIndex: 'name',
                    key: 'name',

                    width: 80,
                },
                {
                    title: "含义",
                    dataIndex: 'desc',
                    key: 'desc',

                    width: 350,
                },
                {
                    title: "计算方式",
                    dataIndex: 'calculateDesc',
                    key: 'calculateDesc',

                },
            ]
        }

    }

    componentWillMount() {

    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.paper);
        const subjects = Object.assign([], this.props.subjects);
        this.props.dispatch(handInPaper(paper, this.state.formatMessage));
    }

    _handleExport() {
        const examineeLesson = this.props.examineeLesson ? this.props.examineeLesson : {};
        const summaryInfo = this.props.summaryInfo ? this.props.summaryInfo : {};
        // console.log(this.LessonReportChartShowParts);
        let subjectLabel = getEnumsLabel(summaryInfo.subjectType, AttentionAssessmentSubjectType.Map, this.state.formatMessage);
        const exportLessonPaper = {
            id: examineeLesson.id,
            paperCount: summaryInfo.paperCount,
            subjectType: summaryInfo.subjectType,
            firstScore: summaryInfo.firstScore,
            lastScore: summaryInfo.lastScore,
            firstTimeAvg: summaryInfo.firstTimeAvg,
            lastTimeAvg: summaryInfo.lastTimeAvg,
            subjectLabel: subjectLabel,
        };
        if (this.LessonReportChartShowParts) {
            exportLessonPaper.imageData = this.LessonReportChartShowParts.chartToDataURL();
        }
        if (this.LessonReportChartTimeShowParts) {
            exportLessonPaper.imageData2 = this.LessonReportChartTimeShowParts.chartToDataURL();
        }
        this.props.dispatch(doExportLessonPaperReport(exportLessonPaper, this.state.formatMessage))

    }

    render() {
        const formatMessage = this.state.formatMessage;
        const examineeLesson = this.props.examineeLesson ? this.props.examineeLesson : {};

        let examinee = examineeLesson.examinee ? examineeLesson.examinee : {};

        let testerParts = null;
        let grand = null;
        let date = null;
        if (examinee) {
            testerParts = <span className="examinee-name">{examinee.lastName + examinee.firstName}</span>;
            grand = <span
                className="examinee-name">{ExamDataModelGrade.Map[examinee.grade] = this.state.formatMessage({id: ExamDataModelGrade.Map[examinee.grade]})}</span>
            date = <span className="examinee-name">{moment().format(DateTimeFormats.FORMAT_DATE_SIMPLE)}</span>
        }
        let summaryInfo = this.props.summaryInfo;
        // let length = summaryInfo.examinationList.length - 1;
        // let firstScore;
        // let lastScore;
        // if (summaryInfo.examinationList[0].scoreInfo !== undefined) {
        //     firstScore = summaryInfo.examinationList[0].scoreInfo.totalCount ?
        //         Math.round(summaryInfo.examinationList[0].scoreInfo.rightCount * 10000 / summaryInfo.examinationList[0].scoreInfo.totalCount) / 100 : 0;
        //     console.log(firstScore);
        //
        // } else {
        //     firstScore = 0;
        // }
        // // for (let i = 0; i < dataList.length; i++) {
        // //     let data = dataList[i];
        // //     if (data.lastName === undefined || data.lastName === "") {
        // //         data.result = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_LAST_NAME_REQUIRED'});
        // //         resultMap[data.idNumber] = data;
        // //         resultList.push(data);
        // //         continue;
        // //     }
        // for (let i = length; i>=0; i--) {
        //     if (summaryInfo.examinationList[i].scoreInfo !== undefined){
        //        lastScore = summaryInfo.examinationList[i].scoreInfo.totalCount ?
        //             Math.round(summaryInfo.examinationList[i].scoreInfo.rightCount * 10000 / summaryInfo.examinationList[i].scoreInfo.totalCount) / 100 : 0;
        //         console.log(lastScore);
        //         break;
        //     }else {
        //         lastScore = 0;
        //     }
        // }

        let subjectType = summaryInfo.subjectType;
        let subjectLabel = getEnumsLabel(subjectType, AttentionAssessmentSubjectType.Map, formatMessage);
        let dataModelCodeList = AttentionAssessmentSubjectType.TrainingShowInfoMap[subjectType];
        let dataModelCodeString = dataModelCodeList.map(enumKey => {
            return ExamDataModelType.ShowMap[enumKey].scoreLabel;
        }).join("、");
        let dataList = [];

        dataModelCodeList.forEach(enumKey => {
            let data = {};
            data.code = enumKey;
            let showMapElement = ExamDataModelType.ShowMap[enumKey];
            summaryInfo.paperCount = summaryInfo.paperCount ? summaryInfo.paperCount : 0;

            data.name = showMapElement.scoreLabel;
            data.info = showMapElement.scoreInfo;
            data.info2 = showMapElement.scoreInfo2;
            dataList.push(data);
        })
        const infoDataList = [
            {
                name: "正确率",
                desc: <span>是指儿童对信号刺激、项目问题进行<strong>正确应答</strong>的能力</span>,
                calculateDesc: "正确作答的题目数占总题目的比例"
            },
            {
                name: "反应时间",
                desc: <span>即反应速度，是指儿童对信号刺激、项目问题进行<strong>快速应答</strong>的能力</span>,
                calculateDesc: "正确按键作答题目的平均反应时长"
            }
        ];
        return (

            <Card>
                <div className="paper-report-panel ">
                    <Button onClick={() => this._handleExport()}>导出报表</Button>
                    {/*<Button onClick={() => this._handleExport()}>导出报表(开发中)</Button>*/}
                    <h4 className="text-center">儿童注意力训练阶段性反馈报告</h4>
                    <br/>
                    <h5 className="text-center">姓名：{testerParts}年级：{grand} 日期：{date}</h5>
                    <h5>训练任务：{subjectLabel}</h5>
                    <h5>训练维度：{dataModelCodeString}</h5>

                    <Table rowKey={(record, index) => index} bordered={true}
                           size="middle" className="spacing-v" columns={this.state.columns}
                           dataSource={dataList} pagination={false}/>
                    <br/>
                    <h5> 成绩指标：正确率、反应时间</h5>
                    <Table rowKey={(record, index) => index} bordered={true}
                           size="middle" className="spacing-v" columns={this.state.dataColumns}
                           dataSource={infoDataList} pagination={false}/>
                    <div className="report-paragraph">
                        训练结果：如图所示， {testerParts}在{summaryInfo.paperCount}次 {subjectLabel}训练中，答题正确率从{summaryInfo.firstScore}%逐步提升，反应时间从{summaryInfo.firstTimeAvg}ms
                        逐步减少，
                        表明其{dataModelCodeString}能力经过训练得到明显提高,反应速度明显加快，最后一次训练中的正确率达到{summaryInfo.lastScore}%，反应时间达到{summaryInfo.lastTimeAvg}ms
                        ,可以进入下一训练阶段。
                        {/*最后一次训练中的正确率达到**%，可以进入下一训练阶段。*/}
                    </div>
                    <br/>
                    <LessonReportChartShowParts summaryInfo={summaryInfo}
                                                onRef={(ref) => this.LessonReportChartShowParts = ref}/>
                    <br/>
                    <LessonReportChartTimeShowParts summaryInfo={summaryInfo}
                                                    onRef={(ref) => this.LessonReportChartTimeShowParts = ref}/>

                </div>
            </Card>
        )
    }

}

function mapStateToProps(store) {
    return {
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,


    }
}

export default connect(mapStateToProps)(injectIntl(LessonSubjectReport));