import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Table} from "antd";

import {ExamDataModelType} from "../../../Enums";
import {togglePaperDataModelModal, updateByPaper} from "../../../actions/ExamDataModelAction";
import {MoneyFormat} from "../../../../../utils/NumberUtil";

class PaperDataModelModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            selectedModelTypeList: [],
            columns: [
                {
                    title: "维度",
                    dataIndex: 'name',
                    key: 'name',

                },
                {
                    title: "记分结果",
                    dataIndex: 'score',
                    key: 'score'
                },
                {
                    title: "得分",
                    dataIndex: 'scoreInModel',
                    key: 'scoreInModel',

                },

            ]
        }
    }

    componentWillMount() {

    }

    componentDidMount() {
    }



    _handleCancel() {
        this.setState({selectedModelTypeList: []});
        this.props.dispatch(togglePaperDataModelModal(false));
    }

    _handleOk() {
        const {paper, searchCondition} = this.props;
        const {formatMessage} = this.state;

        if (this.state.selectedModelTypeList && this.state.selectedModelTypeList.length > 0) {
            this.props.dispatch(updateByPaper({paperId: paper.id,updateFieldList:this.state.selectedModelTypeList}, searchCondition, formatMessage));

        }
       this._handleCancel();

    }


    render() {

        const formatMessage = this.state.formatMessage;
        const paper = this.props.paper ? this.props.paper : {};
        let dataModalTypeList = paper.dataModalTypeList ? paper.dataModalTypeList : [];
        const scoreResult = paper.scoreResult && paper.scoreResult !== "" ? JSON.parse(paper.scoreResult) : {};
        let dataList = [];
        ExamDataModelType.List.forEach(item => {
            let code = item.value;
            let showMapElement = ExamDataModelType.ShowMap[code];
            let data = {};
            data.code = code;
            data.name = formatMessage({id: item.label});
            data.selected = dataModalTypeList.includes(code);
            data.score = MoneyFormat.formatThree(scoreResult[showMapElement.score]);
            data.scoreInModel = MoneyFormat.formatThree(scoreResult[showMapElement.scoreInModel]);
            dataList.push(data);
        });

        return (
            <Modal
                maskClosable={false}
                title={"加入常模数据"}
                visible={this.props.showPaperDataModelModal}
                onOk={() => this._handleOk()}
                okButtonProps={{loading: this.props.isLoading}}

                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
            >
                <div>教师备注：{paper.remarks&&paper.remarks!==""?paper.remarks:"暂无"}</div>
                <Table rowKey={"code"}
                       rowSelection={{
                           selectedRowKeys: this.state.selectedModelTypeList,
                           onChange: (selectedRowKeys) => this.setState({selectedModelTypeList: selectedRowKeys}),
                           getCheckboxProps: (record) => ({
                               disabled: record.selected, // Column configuration not to be checked
                           }),
                       }}

                       size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={dataList} pagination={false}/>

            </Modal>
        );
    }

}

function mapStateToProps(store) {
    return {
        isLoading : store.ExamDataModelReducer.isLoading,
        searchCondition: store.ExamDataModelReducer.paperSearchCondition,
        showPaperDataModelModal: store.ExamDataModelReducer.showPaperDataModelModal,
        paper: store.ExamDataModelReducer.paper
    }
}

export default connect(mapStateToProps)(injectIntl(PaperDataModelModal));