/**
 * Created by Ethan on 2017/9/8.
 */

// 存储访问令牌的键
export const CACHE_ACCESS_TOKEN = 'CACHE_ACCESS_TOKEN';
export const CACHE_SESSION_USER = "CACHE_SESSION_USER";
export const CACHE_SESSION_EMPLOYEE = "CACHE_SESSION_EMPLOYEE";
export const CACHE_LANGUAGE = "CACHE_LANGUAGE";
// 缓存数据组，以此判断自助终端要显示的数据组场所
export const CACHE_DATA_GROUP_ID = "CACHE_DATA_GROUP_ID";
export const CACHE_SITE_ID = "CACHE_SITE_ID";
export const CACHE_SITE_CODE = "CACHE_SITE_CODE";

// 字典项服务的访问地址
// export const DICT_SERVER_HOST = "http://localhost:8080"; // Dev
// export const DICT_SERVER_HOST = "http://47.100.238.45:8003"; // Test
export const DICT_SERVER_HOST = "https://yulai-server.iidooo.com";

// 上传文件服务的访问地址
// export const FTP_SERVER_HOST = "http://localhost:8080"; // Dev
// export const FTP_SERVER_HOST = "http://47.100.238.45:8003"; // Test
export const FTP_SERVER_HOST = "https://yulai-server.iidooo.com";

// 账户管理微服务
// export const ACCOUNT_SERVER_HOST = "http://localhost:8080"; // Dev
// export const ACCOUNT_SERVER_HOST = "http://47.100.238.45:8003"; // Test
export const ACCOUNT_SERVER_HOST = "https://yulai-server.iidooo.com";

// 组织管理微服务
// export const ORG_SERVER_HOST = "http://localhost:8080"; // Dev
// export const ORG_SERVER_HOST = "http://47.100.238.45:8003"; // Test
export const ORG_SERVER_HOST = "https://yulai-server.iidooo.com";

// 邮件发送服务
// export const MAIL_SERVER_HOST = "http://localhost:8080"; // Dev
// export const MAIL_SERVER_HOST = "http://47.100.238.45:8003"; // Test
export const MAIL_SERVER_HOST = "https://yulai-server.iidooo.com";// Prod

// 测评系统微服务
// export const EXAM_SERVER_HOST = "http://localhost:8080"; //Dev
// export const EXAM_SERVER_HOST = "http://47.100.238.45:8003"; //Test
export const EXAM_SERVER_HOST = "https://yulai-server.iidooo.com"; //Prod
//
// 内容管理微服务
// export const CMS_SERVER_HOST = "http://localhost:8110"; //Dev
// export const CMS_SERVER_HOST = "http://47.100.238.45:8003"; //Test
export const CMS_SERVER_HOST = "https://yulai-server.iidooo.com"; //Test

// 密码强度分三档，1：无限制，2：长度超过8位，3：长度+字母数字特殊符号组合
export const PASSWORD_STRENGTH_LEVEL = 3;

export const APP_VERSION = "棉花糖注意力训练后台系统 v1.2.20.202301219";
export const APP_COPYRIGHT = "© 上海育来文化传播有限公司";