import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Col, Form, Input, Modal, Row} from 'antd';

import MailTextArea from "../../../../components/TextEditorRichBox";
import {
    createTemplate,
    refreshTemplate,
    refreshTemplateEditor,
    toggleDetailModal,
    updateTemplate
} from "../../actions/TemplateAction";
import {Label_3_Wrapper_21, Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";

class DetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _handleCancel() {
        this.setState({messageMap: {}});
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleSave() {
        const {dispatch, searchCondition, template, editor} = this.props;
        let value = editor.getValue();
        template.mailContent = value;
        if (this._validate(template)) {
            if (template.id === undefined || template.id === "") {
                dispatch(createTemplate(template, searchCondition, this.state.formatMessage));
            } else {
                dispatch(updateTemplate(template, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(template) {
        let messageMap = {};
        if (template.code === undefined || template.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'MAIL_TEMPLATE_MSG_CODE_REQUIRED'});
        }
        if (template.name === undefined || template.name === "") {
            messageMap["name"] = this.state.formatMessage({id: 'MAIL_TEMPLATE_MSG_NAME_REQUIRED'});
        }
        if (template.mailFrom === undefined || template.mailFrom === "") {
            messageMap["mailFrom"] = this.state.formatMessage({id: 'MAIL_TEMPLATE_MSG_MAIL_FROM_REQUIRED'});
        }
        if (template.mailTo === undefined || template.mailTo === "") {
            messageMap["mailTo"] = this.state.formatMessage({id: 'MAIL_TEMPLATE_MSG_MAIL_TO_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleChange(name, value) {
        let template = Object.assign({}, this.props.template);
        template[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshTemplate(template));
    }

    render() {
        const {template} = this.props;
        const formatMessage = this.state.formatMessage;
        let title = "";
        if (template.id === undefined || template.id === "") {
            title = formatMessage({id: "MAIL_TEMPLATE_TITLE_CREATE"});
        } else {
            title = formatMessage({id: "MAIL_TEMPLATE_TITLE_UPDATE"});
        }
        return (
            <Modal
                title={title}
                width={1000}
                style={{top: '20px'}}
                visible={this.props.showDetailModal} maskClosable={false}
                onOk={() => this._handleSave()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_CODE'})} {...Label_6_Wrapper_18}
                                       required={true}
                                       validateStatus={this.state.messageMap["code"] ? "error" : ""}
                                       help={this.state.messageMap["code"]}>
                                <Input value={template.code}
                                       onChange={(value) => {
                                           this._handleChange("code", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_NAME'})} {...Label_6_Wrapper_18}
                                       required={true}
                                       validateStatus={this.state.messageMap["name"] ? "error" : ""}
                                       help={this.state.messageMap["name"]}>
                                <Input value={template.name}
                                       onChange={(value) => {
                                           this._handleChange("name", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_MAIL_FROM'})} {...Label_6_Wrapper_18}
                                required={true}
                                validateStatus={this.state.messageMap["mailFrom"] ? "error" : ""}
                                help={this.state.messageMap["mailFrom"]}>
                                <Input value={template.mailFrom}
                                       onChange={(value) => {
                                           this._handleChange("mailFrom", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_MAIL_TO'})} {...Label_6_Wrapper_18}
                                required={true}
                                validateStatus={this.state.messageMap["mailTo"] ? "error" : ""}
                                help={this.state.messageMap["mailTo"]}>
                                <Input value={template.mailTo}
                                       onChange={(value) => {
                                           this._handleChange("mailTo", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_MAIL_CC'})} {...Label_6_Wrapper_18}>
                                <Input value={template.mailCc}
                                       onChange={(value) => {
                                           this._handleChange("mailCc", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_MAIL_BCC'})} {...Label_6_Wrapper_18}>
                                <Input value={template.mailBcc}
                                       onChange={(value) => {
                                           this._handleChange("mailBcc", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_MAIL_TITLE'})} {...Label_3_Wrapper_21}>
                                <Input value={template.mailTitle}
                                       onChange={(value) => {
                                           this._handleChange("mailTitle", value)
                                       }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={formatMessage({id: 'MAIL_TEMPLATE_FIELD_MAIL_CONTENT'})} {...Label_3_Wrapper_21}>
                                <MailTextArea value={template.mailContent}
                                              onLoadEditor={(editor) => this.props.dispatch(refreshTemplateEditor(editor))}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.MailTemplateReducer.isLoading,
        template: store.MailTemplateReducer.template,
        showDetailModal: store.MailTemplateReducer.showDetailModal,
        editor: store.MailTemplateReducer.editor,
        searchCondition: store.MailTemplateReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));