import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Card} from "antd";
import {Column} from '@ant-design/plots';
import {AttentionAssessmentSubjectType, getEnumsLabel} from "../../../Enums";

let ref;

class LessonReportChartShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],


        }

    }

    componentWillMount() {

    }

    componentDidMount() {
        //导出功能用
        this.props.onRef(this);
    }


    //导出报表用
    chartToDataURL() {

        let base64Data = this.chart ? this.chart.toDataURL() : null;
        console.log(base64Data);

        return base64Data;
    };


    render() {
        const formatMessage = this.state.formatMessage;
        const summaryInfo = this.props.summaryInfo ? this.props.summaryInfo : {};

        let subjectType = summaryInfo.subjectType;
        let subjectLabel = getEnumsLabel(subjectType, AttentionAssessmentSubjectType.Map, formatMessage);
        let dataList = [];
        summaryInfo.examinationList.forEach(thisExam => {
            let scoreInfo = thisExam.scoreInfo;
            let data = {};

            data.name = thisExam.name;
            data.value = 0;
            if (scoreInfo) {
                data.value = scoreInfo.scoreValue
            }


            dataList.push(data);

        });

        const config = {
            data: dataList,
            xField: 'name',
            yField: 'value',
            label: {
                // 可手动配置 label 数据标签位置
                position: 'middle',
                // 'top', 'bottom', 'middle',
                // 配置样式
                style: {
                    fill: '#FFFFFF',
                    opacity: 0.6,
                },
                formatter: (text1, text2) => {
                    return text1.value + "%";

                }
            },
            // seriesField: 'value',
            legend:true,
            meta: {
                name: {
                    alias: '课时',
                },
                value: {
                    alias: '正确率',
                    // formatter: (text1, text2) => {
                    //     return text1.value + "%";
                    //
                    // }
                },
            },
        };
        let title = subjectLabel + "任务" + summaryInfo.paperCount + "次训练结果-答题正确率";
        return (

            <Card>

                <h4 className="text-center">{title}</h4>


                <Column {...config} onReady={(chart) => this.chart = chart}/>
            </Card>
        );
    }

}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(LessonReportChartShowParts));