import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Col, Button, Row, Modal, Tag} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";
import {ExaminationStatus} from "../../../Enums";
import {deletePaper, getPaperDetail, searchPapers, toggleDetailModal} from "../../../actions/PaperAction";
import moment from "moment";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import {RoutePath} from "../../../../../constants/RoutePath";
import {
    refreshDataModelPaper,
    searchPapersWithDataModel,
    togglePaperDataModelModal,
    updateByPaper
} from "../../../actions/ExamDataModelAction";
import PaperDataModelModal from "./PaperDataModelModal";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_NAME'}),
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => record.examinee.lastName + record.examinee.firstName
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_CODE'}),
                    dataIndex: 'examination.code',
                    key: 'examination.code'
                },

                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_START_TIME'}),
                    key: 'examination.startTime',
                    dataIndex: 'examination.startTime',
                    render: (text, record, index) => record.examination.startTime ? moment(record.examination.startTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN) : null
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_END_TIME'}),
                    key: 'examination.endTime',
                    dataIndex: 'examination.endTime',
                    render: (text, record, index) => record.examination.endTime ? moment(record.examination.endTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN) : null
                },
                {
                    title: "是否已加入常模",
                    key: 'inDataModal',
                    dataIndex: 'inDataModal',
                    render: (text, record, index) => {
                        let showLabel = null;
                        if (record.inDataModal === 1) {
                            showLabel = <Tag color={"blue"}>已加入</Tag>
                        } else if (record.inDataModal === 2) {
                            showLabel = <Tag color={"cyan"}>部分加入</Tag>
                        }
                        return showLabel;
                    }


                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => record.inDataModal === 1?null:this._renderButtonGroup(text, record, index)
                }
            ],
            selectedDataModelType: []
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleAddDataModel.bind(this, record)}>
                    加入常模
                </a>
            </div>
        )
    }

    _handleAddDataModel(record) {
        this.props.dispatch(refreshDataModelPaper(record));
        this.props.dispatch(togglePaperDataModelModal(true));

        // const self = this;
        // const formatMessage = this.state.formatMessage;
        // Modal.confirm({
        //     title: "请确认需要加入常模",
        //     content: "请确认是否需要加入常模",
        //     okText: 'Yes',
        //     okType: 'danger',
        //     cancelText: 'No',
        //     onOk() {
        //         self.props.dispatch(updateByPaper({paperId:record.id, }, self.props.searchCondition, formatMessage));
        //     }
        // })
    }

    _handleModify(record) {
        this.props.router.push({
            pathname: RoutePath.EXAM_PAPER_RESULT_DETAIL.path,
            query: {paperId: record.id}
        })

    }


    render() {
        return (
            <div className="spacing-v">
                <PaperDataModelModal/>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.papers} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchPapersWithDataModel(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        papers: store.ExamDataModelReducer.papers,
        searchCondition: store.ExamDataModelReducer.paperSearchCondition,
        pagination: store.ExamDataModelReducer.paperPagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));