import * as ActionTypes from "../ActionTypes";
import HttpUtils from "../../../utils/HttpUtils";
import * as WebService from "../WebService";
import {HttpStatus} from "../../../constants/Enums";
import moment from "moment";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../constants/DateTimeFormats";
import {message} from 'antd';

export function aliOSSPolicyUpload(file, targetFolder, formatMessage, callback) {

    return dispatch => {
        dispatch({type: ActionTypes.FTP_LOADING, isLoading: true});

        HttpUtils.get(WebService.getOSSPolicy + "?folder=" + targetFolder, (response) => {
                let formData = new FormData();
                let filename = file.name.substring(0, file.name.lastIndexOf('.'));
                const suffix = file.name.slice(file.name.lastIndexOf('.'));
                filename = filename + "_" + moment().format(FORMAT_DATE_TIME_FULL_SIMPLE) + suffix;
                const key = response.data.dir + "/" + filename;

                formData.append("key", key);
                formData.append("file", file);
                formData.append("policy", response.data.policy);
                formData.append("OSSAccessKeyId", response.data.accessid);
                formData.append("Signature", response.data.signature);
                formData.append("success_action_status", 200);

                fetch(response.data.host, {
                    method: "POST",
                    body: formData
                }).then((response) => {
                        console.log(response);
                        if (response.status === HttpStatus.SUCCESS) {
                            callback.call(this, response.url + key);
                            message.success(formatMessage({id: "FTP_MSG_UPLOAD_SUCCEED"}));
                        } else {
                            message.error(formatMessage({id: "FTP_MSG_UPLOAD_FAILED"}));
                        }
                    }
                ).catch((error) => {
                    console.log(error);
                    message.error(formatMessage({id: "FTP_MSG_UPLOAD_FAILED"}));
                });

                dispatch({type: ActionTypes.FTP_LOADING, isLoading: false});
            }, (response) => {
                dispatch({type: ActionTypes.FTP_LOADING, isLoading: false});
            }, formatMessage
        )
    }
}

export function downloadFile(id, name, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.FTP_LOADING, isLoading: true});
        let requestUrl = "";
        if (id && id.length === 32) {
            requestUrl = WebService.downloadFileById.replace("{id}", id);
        } else {
            requestUrl = WebService.downloadFile + "?path=" + encodeURI(name);
        }
        HttpUtils.getFile(requestUrl, name, (response) => {
            console.log(response);
            if (response.status !== HttpStatus.SUCCESS) {
                message.error(formatMessage({id: "FTP_MSG_DOWNLOAD_FAILED"}));
            }
            dispatch({type: ActionTypes.FTP_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
            dispatch({type: ActionTypes.FTP_LOADING, isLoading: false});
        });
    }
}

export function deleteFile(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.FTP_LOADING, isLoading: true});

        let requestUrl = WebService.deleteFile.replace("{id}", id);
        HttpUtils.delete(requestUrl, id, (response) => {
            console.log(response);
            if (response.status === HttpStatus.SUCCESS) {
                message.success(formatMessage({id: "FTP_MSG_DELETE_SUCCEED"}));
            }else {
                message.error(formatMessage({id: "FTP_MSG_DELETE_FAILED"}));
            }
            dispatch({type: ActionTypes.FTP_LOADING, isLoading: false});
        }, (error) => {
            message.error(error);
            dispatch({type: ActionTypes.FTP_LOADING, isLoading: false});
        });
    }
}