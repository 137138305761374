/**
 * Created by Ethan on 17/1/13.
 */

import {EXAM_SERVER_HOST} from '../../constants/Profile';

// 题库
export const getBank = EXAM_SERVER_HOST + "/exam/banks/{id}";
export const searchBanks = EXAM_SERVER_HOST + "/exam/banks/query";
export const createBank = EXAM_SERVER_HOST + "/exam/banks";
export const updateBank = EXAM_SERVER_HOST + "/exam/banks/{id}";
export const deleteBank = EXAM_SERVER_HOST + "/exam/banks/{id}";

// 题目
export const getSubjects = EXAM_SERVER_HOST + "/exam/examinations/{examinationId}/subjects";
export const searchSubjects = EXAM_SERVER_HOST + "/exam/subject/query";
export const getSubject = EXAM_SERVER_HOST + "/exam/subject/detail";
export const createSubject = EXAM_SERVER_HOST + "/exam/subject/create";
export const updateSubject = EXAM_SERVER_HOST + "/exam/subject/update";
export const deleteSubject = EXAM_SERVER_HOST + "/exam/subject/delete";
export const sortSubject = EXAM_SERVER_HOST + "/exam/subject/sort";
export const synchronizationSubject = EXAM_SERVER_HOST + "/exam/subject/synchronization";

// 测评
export const searchExaminations = EXAM_SERVER_HOST + "/exam/examination/query";
export const getExamination = EXAM_SERVER_HOST + "/exam/examination/detail";
export const createExamination = EXAM_SERVER_HOST + "/exam/examination/create";
export const updateExamination = EXAM_SERVER_HOST + "/exam/examination/update";
export const deleteExamination = EXAM_SERVER_HOST + "/exam/examination/delete";
//课程
export const searchLessonTemplates = EXAM_SERVER_HOST + "/exam/lessonTemplate/query";
export const getLessonTemplate = EXAM_SERVER_HOST + "/exam/lessonTemplate/detail";
export const createLessonTemplate = EXAM_SERVER_HOST + "/exam/lessonTemplate/create";
export const updateLessonTemplate = EXAM_SERVER_HOST + "/exam/lessonTemplate/update";
export const deleteLessonTemplate = EXAM_SERVER_HOST + "/exam/lessonTemplate/delete";

//课时
export const searchExamExamineeLesson = EXAM_SERVER_HOST + "/exam/lesson/query";
export const getExamExamineeLesson = EXAM_SERVER_HOST + "/exam/lesson/detail";
export const createExamExamineeLesson = EXAM_SERVER_HOST + "/exam/lesson/create";
export const updateExamExamineeLesson = EXAM_SERVER_HOST + "/exam/lesson/update";
export const deleteExamExamineeLesson = EXAM_SERVER_HOST + "/exam/lesson/delete";
export const exportLessonReport = EXAM_SERVER_HOST + "/exam/lesson/exportLessonReport";
export const changeExamination = EXAM_SERVER_HOST + "/exam/lesson/changeExamination";


// 测评题目
export const searchExaminationSubjects = EXAM_SERVER_HOST + "/exam/examination/subject/query";
export const getExaminationSubject = EXAM_SERVER_HOST + "/exam/examination/subject/detail";
export const importExaminationSubject = EXAM_SERVER_HOST + "/exam/examination/subject/import";
export const importExaminationSubjectFromBank = EXAM_SERVER_HOST + "/exam/examination/subject/import_from_bank";
export const createExaminationSubject = EXAM_SERVER_HOST + "/exam/examination/subject/create";
export const updateExaminationSubject = EXAM_SERVER_HOST + "/exam/examination/subject/update";
export const deleteExaminationSubject = EXAM_SERVER_HOST + "/exam/examination/subject/delete";
export const batchExaminationSubject = EXAM_SERVER_HOST + "/exam/examination/subject/sort";


// 考卷
export const getMyExaminationPaper = EXAM_SERVER_HOST + "/exam/paper/getMyExaminationPaper";
export const handInPaper = EXAM_SERVER_HOST + "/exam/paper/hand_in_paper";
export const handInPaperForLesson = EXAM_SERVER_HOST + "/exam/paper/handInPaperForLesson";

export const searchPapers = EXAM_SERVER_HOST + "/exam/paper/query";
export const searchPapersForLesson = EXAM_SERVER_HOST + "/exam/paper/queryForLesson";


export const searchPapersWithDataModel = EXAM_SERVER_HOST + "/exam/paper/queryWithDataModel";
export const getPaper = EXAM_SERVER_HOST + "/exam/paper/detail";
export const deletePaper = EXAM_SERVER_HOST + "/exam/paper/delete";
export const exportPaper = EXAM_SERVER_HOST + "/exam/paper/export";
export const updatePaper = EXAM_SERVER_HOST + "/exam/paper/update";
export const exportPaperReport = EXAM_SERVER_HOST + "/exam/paper/exportReport";
// 学员
export const getExamineeDetail = EXAM_SERVER_HOST + "/exam/examinee/detail";
export const getExaminee = EXAM_SERVER_HOST + "/exam/examinee/{id}";
export const searchExaminees = EXAM_SERVER_HOST + "/exam/examinee/query";
export const createExaminee = EXAM_SERVER_HOST + "/exam/examinee/create";
export const updateExaminee = EXAM_SERVER_HOST + "/exam/examinee/update";
export const deleteExaminee = EXAM_SERVER_HOST + "/exam/examinee/delete";
export const importExaminee = EXAM_SERVER_HOST + "/exam/examinee/import";


// 题库
export const getDataModel = EXAM_SERVER_HOST + "/exam/dataModel/{id}";
export const searchDataModels = EXAM_SERVER_HOST + "/exam/dataModel/query";
export const createDataModel = EXAM_SERVER_HOST + "/exam/dataModel";
export const updateDataModel = EXAM_SERVER_HOST + "/exam/dataModel/{id}";
export const deleteDataModel= EXAM_SERVER_HOST + "/exam/dataModel/{id}";
export const updateByPaper = EXAM_SERVER_HOST + "/exam/dataModel/updateByPaperResult";
export const updatePaperSubject = EXAM_SERVER_HOST + "/exam/paper/subject/update";
