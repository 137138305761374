import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, InputNumber, DatePicker, Button, Table} from "antd";

import {Label_6_Wrapper_18, Label_8_Wrapper_16} from "../../../../../constants/FormItemLayout";

import {
    createLessonTemplate, initLessonTemplate, refreshExaminationSelectedIdList,
    refreshLessonTemplate,
    toggleDetailModal, toggleSelectExaminationModal,
    updateLessonTemplate
} from "../../../actions/LessonTemplateAction";

import {ExaminationStatus, ExamPaperType} from "../../../Enums";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import EnumItemSelect from "../../../../../components/EnumItemSelect";

class ExaminationSelectModal extends Component {

    constructor(props) {
        super(props);
        const formatMessage = this.props.intl['formatMessage'];

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            columns: [
                // {
                //     title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_ID'}),
                //     dataIndex: 'id',
                //     key: 'id'
                // },
                {
                    title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_TITLE'}),
                    dataIndex: 'title',
                    key: 'title'
                },



            ]
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(lesson) {
        let messageMap = {};
        // if (!lesson.id || lesson.id === "") {
        //     messageMap["title"] = this.state.formatMessage({id: 'EXAM_LESSON_TEMPLATE_MSG_ID_REQUIRED'});
        // }
        if (!lesson.title || lesson.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_TITLE_REQUIRED'});
        }
        if (!lesson.examinationIds || lesson.examinationIds === "") {
            messageMap["examinationIds"] = this.state.formatMessage({id: 'EXAM_LESSON_TEMPLATE_MSG_EXAMINATIONID_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleSelectExaminationModal(false));
    }

    _handleOk() {
        const lessonTemplate = Object.assign({}, this.props.lessonTemplate);
        let selectedIdList = this.props.examinationSelectedIdList ? this.props.examinationSelectedIdList : [];
        lessonTemplate.examinationIds = selectedIdList.join(",");
        this.props.dispatch(refreshLessonTemplate(lessonTemplate));
        this._handleCancel();
    }

    render() {
        const formatMessage = this.state.formatMessage;

console.log(this.props.allPeriodExaminationList)
        return (
            <Modal
                maskClosable={false}
                title={"选择课时"}
                visible={this.props.showSelectExaminationModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Table rowKey={(record) => record.id} size="middle"
                           rowSelection={{
                               selectedRowKeys: this.props.examinationSelectedIdList,
                               onChange: (selectedRowKeys) => this.props.dispatch(refreshExaminationSelectedIdList(selectedRowKeys))
                           }}
                           className="spacing-v" columns={this.state.columns}
                           dataSource={this.props.allPeriodExaminationList}
                           pagination={false}/>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        allPeriodExaminationList: store.LessonTemplateReducer.allPeriodExaminationList,
        lessonTemplate: store.LessonTemplateReducer.lessonTemplate,
        showSelectExaminationModal: store.LessonTemplateReducer.showSelectExaminationModal,
        examinationSelectedIdList: store.LessonTemplateReducer.examinationSelectedIdList
    }
}

export default connect(mapStateToProps)(injectIntl(ExaminationSelectModal));