import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Button, DatePicker, Form, Input, Modal, Tag} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {toggleDetailModal as toggleExamineeDetailModal} from "../../../actions/ExamineeAction";
import {
    createExamExamineeLesson,
    refreshExamExamineeLesson,
    toggleDetailModal,
    updateExamExamineeLesson, refreshExaminationSelectedIdList, toggleSelectExaminationModal
} from "../../../actions/ExamineeLessonAction";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import ExamineeSelect from "../../Examinee/components/ExamineeSelect";
import LessonTemplateSelect from "../../LessonTemplate/components/LessonTemplateSelect";
import ExaminationSelectModal from "./ExaminationSelectModal";
import {refreshExaminee, searchAllExaminees} from "../../../actions/ExamineeAction";
import ExamineesDetailModal from "../../Examinee/List/DetailModal";


class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(examineeLesson) {
        let messageMap = {};

        if (!examineeLesson.examineeId || examineeLesson.examineeId === "") {
            messageMap["examineeId"] = this.state.formatMessage({id: 'COMMON_MSG_REQUIRED'});
        }
        // if (!examination.status || examination.status === "") {
        //     messageMap["status"] = this.state.formatMessage({id: 'EXAM_EXAMINATION_MSG_STATUS_REQUIRED'});
        // }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {examineeLesson, searchCondition} = this.props;
        const {formatMessage} = this.state;

        if (this._validate(examineeLesson)) {
            if (examineeLesson.id && examineeLesson.id !== "") {
                this.props.dispatch(updateExamExamineeLesson(examineeLesson, searchCondition, formatMessage));
            } else {
                this.props.dispatch(createExamExamineeLesson(examineeLesson, searchCondition, formatMessage));
            }
            this.props.dispatch(toggleDetailModal(false));
        }
    }

    _handleChange(name, value) {
        const examineeLesson = Object.assign({}, this.props.examineeLesson);
        examineeLesson[name] = (value && value.target) ? value.target.value : value;
        if (name === 'templateId') {
            const allLessonTemplateList = this.props.allLessonTemplateList;

            let filter = allLessonTemplateList.filter(item => item.id === examineeLesson.templateId);
            if (filter.length > 0) {
                let template = filter[0];
                examineeLesson.examinationIds = template.examinationIds;
            }
        }
        this.props.dispatch(refreshExamExamineeLesson(examineeLesson));
    }


    _handleSelectedExaminationModal() {
        const {examineeLesson, searchCondition} = this.props;
        const {formatMessage} = this.state;
        let examinationIds = examineeLesson.examinationIds;

        let selectedIdList = [];
        if (examinationIds) {
            selectedIdList = examinationIds.split(",");
        }
        this.props.dispatch(toggleSelectExaminationModal(true));
        this.props.dispatch(refreshExaminationSelectedIdList(selectedIdList));

    }
    _handleShowCreateExamineeModal() {
        this.props.dispatch(refreshExaminee({}));
        this.props.dispatch(toggleExamineeDetailModal(true));
    }
    render() {
        const {examineeLesson} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = formatMessage({id: 'EXAM_EXAMINEE_LESSON_TITLE_CREATE'});
        if (examineeLesson.id && examineeLesson.id !== "") {
            title = formatMessage({id: 'EXAM_EXAMINEE_LESSON_TITLE_UPDATE'});
        }
        let examinationIds = examineeLesson.examinationIds;
        let examinationIdList = examinationIds && examinationIds !== "" ? examinationIds.split(',') : [];

        let examinationList = this.props.allPeriodExaminationList ? this.props.allPeriodExaminationList : [];
        examinationList = examinationList.filter(examination => examinationIdList.includes(examination.id));

        return (
            <Modal
                maskClosable={false}
                title={title}
                width={800}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <ExaminationSelectModal/>
                <ExamineesDetailModal refreshFunction={()=>{
                    this.props.dispatch(searchAllExaminees(this.state.formatMessage))
                }}/>
                <Form>
                    <Form.Item label={formatMessage({id:'EXAM_EXAMINEE_FIELD_NAME'})}
                               {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["examineeId"] ? "error" : ""}
                               help={this.state.messageMap["examineeId"]}>
                        <ExamineeSelect onChange={(data) => {
                            this._handleChange("examineeId", data)
                        }} value={examineeLesson.examineeId}
                        />
                        <Button size={"small"} type={"primary"} onClick={()=>this._handleShowCreateExamineeModal()}>{formatMessage({id:'EXAM_EXAMINEE_FIELD_CREATE'})}</Button>
                    </Form.Item>


                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_LESSON_FIELD_START_TIME'})}>
                        <DatePicker
                            format={DateTimeFormats.FORMAT_DATE_HYPHEN}
                            value={(examineeLesson.startTime && examineeLesson.startTime !== "") ?
                                moment(examineeLesson.startTime, DateTimeFormats.FORMAT_DATE_SIMPLE) : null}
                            onChange={(value, dateString) => this._handleChange("startTime", value && value.format(DateTimeFormats.FORMAT_DATE_SIMPLE))}
                        />
                    </Form.Item>
                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_LESSON_FIELD_END_TIME'})}>
                        <DatePicker

                            format={DateTimeFormats.FORMAT_DATE_HYPHEN}
                            value={(examineeLesson.endTime && examineeLesson.endTime !== "") ?
                                moment(examineeLesson.endTime, DateTimeFormats.FORMAT_DATE_SIMPLE) : null}
                            onChange={(value, dateString) => this._handleChange("endTime", value && value.format(DateTimeFormats.FORMAT_DATE_SIMPLE))}
                        />
                    </Form.Item>
                    <Form.Item label={formatMessage({id:'EXAM_EXAMINEE_LESSON_FIELD_TEMPLATE'})}
                               {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["templateId"] ? "error" : ""}
                               help={this.state.messageMap["templateId"]}>
                        <LessonTemplateSelect onChange={(data) => {
                            this._handleChange("templateId", data)
                        }} value={examineeLesson.templateId}
                        />
                    </Form.Item>


                    <Form.Item label={formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_EXAMINATION'})}
                               {...Label_6_Wrapper_18}
                               validateStatus={this.state.messageMap["examinationIds"] ? "error" : ""}
                               help={this.state.messageMap["examinationIds"]}>
                        <div>
                            {examinationList.map(examination => {
                                return <Tag>{examination.title}</Tag>
                            })}
                        </div>
                        <Button type="primary " size={"small"}
                                onClick={() => this._handleSelectedExaminationModal()}>{formatMessage({id:'EXAM_EXAMINEE_FIELD_SELECT'})}</Button>
                    </Form.Item>

                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_EXAMINEE_LESSON_FIELD_REMARKS'})}>
                        <Input.TextArea value={examineeLesson.remarks} onChange={(data) => {
                            this._handleChange("remarks", data)
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.ExamineeLessonReducer.searchCondition,
        showDetailModal: store.ExamineeLessonReducer.showDetailModal,
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        allLessonTemplateList: store.LessonTemplateReducer.allLessonTemplateList,
        allPeriodExaminationList: store.ExamineeLessonReducer.allPeriodExaminationList,

    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));