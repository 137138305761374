import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Radio} from "antd";

class FieldRadioGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <Radio.Group value={this.props.value} onChange={this.onChange}>
                {
                    this.props.data.map((item, index) => {
                        return <Radio.Button key={index} value={item.value}>
                            <FormattedMessage id={item.label}/>
                        </Radio.Button>
                    })
                }
            </Radio.Group>
        );
    }
}

export default (injectIntl(FieldRadioGroup));