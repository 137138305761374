/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, Switch, Checkbox} from "antd";

import {toggleDetailModal, createUser, updateUser, refreshUser} from "../../../actions/UserAction";

import Validator from "../../../../../utils/Validator";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import RoleSelector from "../../../components/RoleSelector";
import DictItemSelect from "../../../../../components/EnumItemSelect";
import TreeSelector from "../../../../../components/TreeSelector";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleChange(name, value) {
        const user = Object.assign({}, this.props.user);
        if (name === "roles") {
            const roles = [];
            value.map((item, i) => {
                return (
                    roles.push({id: item})
                )
            });
            user[name] = roles;
        } else {
            user[name] = value.target ? value.target.value : value;
        }
        this.props.dispatch(refreshUser(user));
    }

    _handleOk() {
        const {dispatch, searchCondition, user} = this.props;
        if (this._validate(user)) {
            if (user.id === undefined || user.id === "") {
                dispatch(createUser(user, searchCondition, this.state.formatMessage));
            } else {
                dispatch(updateUser(user, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(user) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};
        if (user.uid === undefined || user.uid === "") {
            messageMap["uid"] = formatMessage({id: "ACCOUNT_USER_MSG_UID_REQUIRED"});
        }
        // 创建用户的时候需要验证密码必填项
        if ((user.id === undefined || user.id === "") && user.password === undefined || user.password === "") {
            messageMap["password"] = formatMessage({id: "ACCOUNT_USER_MSG_PASSWORD_REQUIRED"});
        }
        if (user.mobile === undefined || user.mobile === "") {
            messageMap["mobile"] = formatMessage({id: "ACCOUNT_USER_MSG_MOBILE_REQUIRED"});
        }
        // else if (!Validator.isMobileNumber(user.mobile)) {
        //     messageMap["mobile"] = formatMessage({id: "ACCOUNT_USER_MSG_MOBILE_FORMAT_WRONG"});
        // }
        if (user.email === undefined || user.email === "") {
            messageMap["email"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_REQUIRED"});
        }
        // else if (!Validator.isEmail(user.email)) {
        //     messageMap["email"] = formatMessage({id: "ACCOUNT_USER_MSG_EMAIL_FORMAT_WRONG"});
        // }
        if (user.lastName === undefined || user.lastName === "") {
            messageMap["lastName"] = formatMessage({id: "ACCOUNT_USER_MSG_LAST_NAME_REQUIRED"});
        }
        if (user.firstName === undefined || user.firstName === "") {
            messageMap["firstName"] = formatMessage({id: "ACCOUNT_USER_MSG_FIRST_NAME_REQUIRED"});
        }
        if (user.roles === undefined || user.roles.length <= 0) {
            messageMap["roles"] = formatMessage({id: "ACCOUNT_USER_MSG_ROLE_REQUIRED"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
        this.props.dispatch(refreshUser({}));
    }

    render() {
        const {user} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = "";
        if (user.id === undefined || user.id === "") {
            title = formatMessage({id: "ACCOUNT_USER_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "ACCOUNT_USER_TITLE_UPDATE"});
        }

        return (
            <Modal
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={this.state.formatMessage({id: 'COMMON_BUTTON_OK'})}
                cancelText={this.state.formatMessage({id: 'COMMON_BUTTON_CANCEL'})}>
                <Form>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_UID"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["uid"] ? "error" : ""}
                               help={this.state.messageMap["uid"]}>
                        <Input value={user.uid} disabled={user.id !== undefined && user.id !== ""}
                               onChange={(value) => {
                                   this._handleChange("uid", value)
                               }}/>
                    </Form.Item>
                    {this._renderPassword(user, formatMessage, Label_6_Wrapper_18)}
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_MOBILE"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["mobile"] ? "error" : ""}
                               help={this.state.messageMap["mobile"]}>
                        <Input value={user.mobile}
                               onChange={(value) => {
                                   this._handleChange("mobile", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_EMAIL"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["email"] ? "error" : ""}
                               help={this.state.messageMap["email"]}>
                        <Input value={user.email}
                               onChange={(value) => {
                                   this._handleChange("email", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_LAST_NAME"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["lastName"] ? "error" : ""}
                               help={this.state.messageMap["lastName"]}>
                        <Input value={user.lastName}
                               onChange={(value) => {
                                   this._handleChange("lastName", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_FIRST_NAME"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["firstName"] ? "error" : ""}
                               help={this.state.messageMap["firstName"]}>
                        <Input value={user.firstName}
                               onChange={(value) => {
                                   this._handleChange("firstName", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_DISABLED"})} {...Label_6_Wrapper_18}
                               required={true}>
                        <Switch checked={user.disabled === 1} onChange={(value) => {
                            this._handleChange("disabled", value ? 1 : 0)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_ROLE"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["roles"] ? "error" : ""}
                               help={this.state.messageMap["roles"]}>
                        <DictItemSelect
                            mode="multiple" localFlag={false}
                            value={
                                user.roles && user.roles.map((item, index) => {
                                    return item.id
                                })
                            }
                            data={
                                this.props.roles.map((item, index) => {
                                    return {value: item.id, label: item.name}
                                })}
                            onChange={(value) => {
                                this._handleChange("roles", value)
                            }}/>
                    </Form.Item>
                    {/*<Form.Item label={formatMessage({id: 'ACCOUNT_USER_FIELD_DATA_GROUP'})} {...Label_6_Wrapper_18}>*/}
                        {/*<TreeSelector treeData={this.props.groupTree}*/}
                                      {/*value={user.groupId}*/}
                                      {/*onChange={(value) => this._handleChange("groupId", value)}/>*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        );
    }

    _renderPassword(user, formatMessage, Label_6_Wrapper_18) {

        if (user.id !== undefined && user.id !== "") {
            return null;
        }

        return (
            <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_PASSWORD"})} {...Label_6_Wrapper_18}
                       required={true}
                       validateStatus={this.state.messageMap["password"] ? "error" : ""}
                       help={this.state.messageMap["password"]}>
                <Input type="password" value={user.password}
                       onChange={(value) => {
                           this._handleChange("password", value)
                       }}/>
            </Form.Item>
        );
    }

    // _renderRoles() {
    //     const options = [];
    //
    //     this.props.roles.map((item, i) => {
    //         return (
    //             options.push({label: item.name, value: item.id})
    //         )
    //     });
    //
    //     const checkedValues = [];
    //     if (this.props.user.roles !== undefined && this.props.user.roles.length > 0) {
    //         this.props.user.roles.map((item, i) => {
    //             return (
    //                 checkedValues.push(item.id)
    //             )
    //         });
    //     }
    //
    //     return (
    //         <Checkbox.Group options={options} value={checkedValues} onChange={(value) => {
    //             this._handleChange("roles", value)
    //         }}/>
    //     );
    // }
}

function mapStateToProps(store) {
    return {
        user: store.AccountUserReducer.user,
        searchCondition: store.AccountUserReducer.searchCondition,
        showDetailModal: store.AccountUserReducer.showDetailModal,
        roles: store.AccountRoleReducer.roles,
        groupTree: store.AccountGroupReducer.groupTree
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));