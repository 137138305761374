import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Button, Col, Modal, Row} from 'antd';
import {ReactSortable} from "react-sortablejs";
import "./DragSortModal.css";
import {SubjectType} from "../Enums";
import EnumItemLabel from "../../../components/EnumItemLabel";

class DragSortModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            sortList: [],
        }
    }

    componentWillMount() {
    }

    _handleCancel() {
        this.props.onCancel && this.props.onCancel.call(this);
    }

    componentWillReceiveProps(nextProps) {
        let newList = nextProps.sortList;

        this.setState({sortList: Object.assign([], newList)});
    }

    _handleSort(newSortList) {
        this.setState({sortList: Object.assign([], newSortList)})
    }


    _handleSubmitSort() {
        let sortList = this.state.sortList;
        let savedSortList = sortList.map((item, index) => {
                item.sequence = index + 1;
                return item;
            }
        );

        this.props.onOk && this.props.onOk.call(this, savedSortList);
    }


    compareTwoList(oriList, newList) {
        let toString = oriList.map(progress => progress.id).toString();
        let newString = newList.map(progress => progress.id).toString();
        return toString === newString;
    }

    render() {
        const {formatMessage} = this.state;
        let sortList = this.state.sortList ? this.state.sortList : [];
        const iconUrl = require("../../../images/move.svg");

        return (
            <Modal
                title={formatMessage({id: "COMMON_BUTTON_SORT"})}
                placement="right"
                width={800}
                maskClosable={false}
                visible={this.props.showSortModal}
                footer={null}
                onCancel={() => this._handleCancel()}
                cancelText={formatMessage({id: 'COMMON_BUTTON_CLOSE'})}>

                <p style={{color: "#03acff"}}> ※ 拖拽移动可修改题目顺序</p>
                <section className="main-menu-inner">
                    <Row className="sort-item-title">
                        <Col span={14}>{formatMessage({id: "EXAM_SUBJECT_FIELD_TITLE"})}</Col>
                        <Col span={3}>{formatMessage({id: "EXAM_SUBJECT_FIELD_TYPE"})}</Col>
                        <Col span={3}>{formatMessage({id: "EXAM_SUBJECT_FIELD_SEQUENCE"})}</Col>
                        <Col span={3}>{formatMessage({id: "EXAM_SUBJECT_FIELD_SCORE"})}</Col>
                        <Col span={1} className="text-center">
                        </Col>
                    </Row>
                    <ReactSortable Direction
                                   ghostClass='background-sort-item'
                                   dragClass='drag-sort-item'
                                   list={sortList}
                                   setList={(newSortList) => {
                                       if (!this.compareTwoList(sortList, newSortList)) {
                                           this._handleSort(newSortList)
                                       }
                                   }}>
                        {
                            sortList.map((record, index) => {
                                return (
                                    <Row className="sort-item" key={index}>
                                        <Col span={14}>{record.title}</Col>
                                        <Col span={3}><EnumItemLabel code={record.type} map={SubjectType.Map}/></Col>
                                        <Col span={3}>{record.sequence}</Col>
                                        <Col span={3}>{record.score}</Col>
                                        <Col span={1} className="text-center">
                                            <img src={iconUrl} style={{width: "18px"}}/>
                                        </Col>
                                    </Row>
                                )
                            })}
                    </ReactSortable>
                </section>
                <br/>
                <div className="text-right">
                    <Button type="primary"
                            onClick={() => this._handleSubmitSort()}>{formatMessage({id: "COMMON_BUTTON_SAVE_ORDER"})}</Button>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(DragSortModal));