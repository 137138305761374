// 站点
export const CMS_SITE_LOADING = 'CMS_SITE_LOADING';
export const CMS_SITE_LIST = 'CMS_SITE_LIST';
export const CMS_SITE_REFRESH_SITE = 'CMS_SITE_REFRESH_SITE';
export const CMS_SITE_TOGGLE_CREATE_MODAL = "CMS_SITE_TOGGLE_CREATE_MODAL";
export const CMS_SITE_TOGGLE_UPDATE_MODAL = "CMS_SITE_TOGGLE_UPDATE_MODAL";

// 栏目
export const CMS_CHANNEL_LOADING = 'CMS_CHANNEL_LOADING';
export const CMS_CHANNEL_TREE = 'CMS_CHANNEL_TREE';
export const CMS_CHANNEL_TREE_SELECT = 'CMS_CHANNEL_TREE_SELECT';
export const CMS_CHANNEL_LIST_SELECT = 'CMS_CHANNEL_LIST_SELECT';
export const CMS_CHANNEL_SEARCH = "CMS_CHANNEL_SEARCH";
export const CMS_CHANNEL_TOGGLE_DETAIL_MODAL = "CMS_CHANNEL_TOGGLE_DETAIL_MODAL";
export const CMS_CHANNEL_REFRESH_CHANNEL = "CMS_CHANNEL_REFRESH_CHANNEL";
export const CMS_CHANNEL_REFRESH_SEARCH_CONDITION = "CMS_CHANNEL_REFRESH_SEARCH_CONDITION";

// 内容
export const CMS_CONTENT_LOADING = 'CMS_CONTENT_LOADING';
export const CMS_CONTENT_SEARCH = 'CMS_CONTENT_SEARCH';
export const CMS_CONTENT_REFRESH_CONTENT = "CMS_CONTENT_REFRESH_CONTENT";
export const CMS_CONTENT_INITIALIZE_CONTENT = "CMS_CONTENT_INITIALIZE_CONTENT";
export const CMS_CONTENT_REFRESH_SEARCH_CONDITION = "CMS_CONTENT_REFRESH_SEARCH_CONDITION";
export const CMS_CONTENT_TOGGLE_DETAIL_MODAL = "CMS_CONTENT_TOGGLE_DETAIL_MODAL";

// 内容附件
export const CMS_CONTENT_FILE_LOADING = 'CMS_CONTENT_FILE_LOADING';
export const CMS_CONTENT_FILE_REFRESH_FILE = 'CMS_CONTENT_FILE_REFRESH_FILE';
export const CMS_CONTENT_FILE_REFRESH_FILES = 'CMS_CONTENT_FILE_REFRESH_FILES';
export const CMS_CONTENT_FILE_TOGGLE_DETAIL_MODAL = "CMS_CONTENT_FILE_TOGGLE_DETAIL_MODAL";
export const CMS_CONTENT_FILE_TOGGLE_FILES_MODAL = "CMS_CONTENT_FILE_TOGGLE_FILES_MODAL";

// Attachment Actions
export const ATTACHMENT_LOADING = 'ATTACHMENT_LOADING';
export const ATTACHMENT_SEARCH = 'ATTACHMENT_SEARCH';

export const DEVICE_ECG_DATA_SEARCH_DONE = 'DEVICE_ECG_DATA_SEARCH_DONE';

export const USER_LIST_SEARCH_DOING = 'USER_LIST_SEARCH_DOING';
export const USER_LIST_SEARCH_DONE = 'USER_LIST_SEARCH_DONE';

export const USER_DETAIL_SEARCH_DOING = 'USER_DETAIL_SEARCH_DOING';
export const USER_DETAIL_SEARCH_DONE = 'USER_DETAIL_SEARCH_DONE';
