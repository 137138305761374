import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from "antd/lib/index";

export function toggleDetailModal(showModal) {
    return {
        type: ActionTypes.DICT_CLASS_TOGGLE_DETAIL_MODAL,
        showDetailModal: showModal
    }
}

export function refreshDictClass(dictClass) {
    return {
        type: ActionTypes.DICT_CLASS_REFRESH_CLASS,
        dictClass: dictClass
    }
}

export function getAllDictClass(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchDictClasses, {paginationFlag: false}, (response) => {
            dispatch({
                type: ActionTypes.DICT_CLASS_SEARCH,
                dictClasses: response.data
            });
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getDictClass(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: true});

        HttpUtils.get(WebService.getDictClass.replace("{id}", id), (response) => {
            dispatch(refreshDictClass(response.data));
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createDictClass(dictClass, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: true});

        HttpUtils.post(WebService.createDictClass, dictClass, (response) => {
            dispatch(getAllDictClass(formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateDictClass(dictClass, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: true});

        HttpUtils.put(WebService.updateDictClass.replace("{id}", dictClass.id), dictClass, (response) => {
            dispatch(getAllDictClass(formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteDictClass(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: true});

        HttpUtils.delete(WebService.deleteDictClass.replace("{id}", id), {}, (response) => {
            dispatch(getAllDictClass(formatMessage));
            dispatch(toggleDetailModal(false));
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
        }, (response) => {
            dispatch({type: ActionTypes.DICT_CLASS_LOADING, isLoading: false});
        }, formatMessage)
    }
}