import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input} from "antd";

import {refreshContent, updateContent, toggleDetailModal} from "../../../../CMS/actions/ContentAction";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {getChannelTree} from "../../../actions/ChannelAction";
import {CACHE_SITE_ID} from "../../../../../constants/Profile";
import ChannelSelect from "../../../components/ChannelSelect";
import {createContent} from "../../../actions/ContentAction";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
        const siteId = localStorage.getItem(CACHE_SITE_ID);
        this.props.dispatch(getChannelTree(siteId, this.state.formatMessage));
    }

    componentDidMount() {
    }

    _validate(content) {
        let messageMap = {};
        if (content.channelId === undefined || content.channelId === "") {
            messageMap["channelId"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_CHANNEL_REQUIRED'});
        }
        if (content.code === undefined || content.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_CODE_REQUIRED'});
        }
        if (content.title === undefined || content.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'CMS_CONTENT_MSG_TITLE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {content, searchCondition} = this.props;
        if (this._validate(content)) {
            if (content.id === undefined || content.id === "") {
                this.props.dispatch(createContent(content, searchCondition, this.state.formatMessage));
            } else {
                this.props.dispatch(updateContent(content, searchCondition, this.state.formatMessage));
            }
        }
    }

    _handleChange(name, value) {
        const content = Object.assign({}, this.props.content);
        content[name] = (value && value.target) ? value.target.value : value;
        this.props.dispatch(refreshContent(content));
    }

    render() {
        const {formatMessage, messageMap} = this.state;
        const {content} = this.props;

        let title = "";
        if (content.id === undefined || content.id === "") {
            title = formatMessage({id: "CMS_CONTENT_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "CMS_CONTENT_TITLE_UPDATE"});
        }
        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form.Item  {...Label_6_Wrapper_18}
                            label={formatMessage({id: 'CMS_CONTENT_FIELD_CHANNEL'})}
                            required={true}
                            validateStatus={messageMap["channelId"] ? "error" : ""}
                            help={messageMap["channelId"]}>
                    <ChannelSelect channelTree={this.props.channelTree}
                                   value={content.channelId}
                                   onChange={(value) => this._handleChange("channelId", value)}/>
                </Form.Item>
                <Form.Item {...Label_6_Wrapper_18}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_CODE'})}
                           required={true}
                           validateStatus={messageMap["code"] ? "error" : ""}
                           help={messageMap["code"]}>
                    <Input
                        value={content.code}
                        onChange={(data) => {
                            this._handleChange("code", data)
                        }}/>
                </Form.Item>
                <Form.Item {...Label_6_Wrapper_18}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_TITLE'})}
                           required={true}
                           validateStatus={messageMap["title"] ? "error" : ""}
                           help={messageMap["title"]}>
                    <Input value={content.title}
                           onChange={(data) => {
                               this._handleChange("title", data)
                           }}/>
                </Form.Item>
                <Form.Item {...Label_6_Wrapper_18}
                           label={formatMessage({id: 'CMS_CONTENT_FIELD_SUMMARY'})}>
                    <Input.TextArea value={content.summary}
                           onChange={(data) => {
                               this._handleChange("summary", data)
                           }}/>
                </Form.Item>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        channelTree: store.CmsChannelReducer.channelTree,
        content: store.CmsContentReducer.content,
        showDetailModal: store.CmsContentReducer.showDetailModal,
        searchCondition: store.CmsContentReducer.searchCondition,
        editor: store.CmsContentReducer.editor
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));