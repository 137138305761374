import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Form, Row, Col, Button, Input} from 'antd';

import {refreshSearchCondition, refreshTemplate, searchTemplates, toggleDetailModal} from "../../actions/TemplateAction";
import {Label_6_Wrapper_18} from "../../../../constants/FormItemLayout";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    componentDidMount() {
    }

    _handleSearch() {
        this.props.dispatch(searchTemplates(this.props.searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshTemplate({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const {searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;

        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "MAIL_TEMPLATE_FIELD_CODE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.templateCode}
                                       onChange={(value) => this._handleChange("templateCode", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "MAIL_TEMPLATE_FIELD_NAME"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.templateName}
                                       onChange={(value) => this._handleChange("templateName", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus" onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.MailTemplateReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));