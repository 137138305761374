import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Col, Button, Row} from 'antd';

import {Modal} from "antd/lib/index";

import PaginationBar from "../../../../../components/PaginationBar";
import {
    searchBanks,
    deleteBank,
    refreshBank,
    toggleDetailModal,
    batchUpdateBankSubject, searchBankSubjectSortList, toggleSubjectSortModal
} from "../../../actions/BankAction";
import DragSortModal from "../../../components/DragSortModal";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'EXAM_BANK_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </a>
                <Divider type="vertical"/>
                <a onClick={this._handleToggleSubjectSortModal.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_SORT"/>
                </a>
            </div>
        )
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "EXAM_BANK_TITLE_DELETE"}),
            content: formatMessage({id: "EXAM_BANK_MSG_DELETE_CONFIRM"}),
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                dispatch(deleteBank(record.id, searchCondition, formatMessage));
            }
        })
    }

    _handleModify(record) {
        const {dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(refreshBank(record, formatMessage));
        dispatch(toggleDetailModal(true));
    }

    _handleToggleSubjectSortModal(record) {
        let searchCondition = {bankIds: [record.id], paginationFlag: false}
        this.props.dispatch(searchBankSubjectSortList(searchCondition, this.state.formatMessage));
        this.props.dispatch(toggleSubjectSortModal(true));
    }

    render() {
        return (
            <div className="spacing-v">
                <DragSortModal sortList={this.props.subjectSortList}
                               showSortModal={this.props.showSubjectSortModal}
                               onOk={(sortList) => {
                                   console.log(sortList);
                                   this.props.dispatch(batchUpdateBankSubject(sortList, this.props.searchCondition, this.state.formatMessage));
                               }}
                               onCancel={() => {
                                   this.props.dispatch(toggleSubjectSortModal(false));
                               }}/>
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.banks} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchBanks(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        banks: store.ExamBankReducer.banks,
        searchCondition: store.ExamBankReducer.searchCondition,
        pagination: store.ExamBankReducer.pagination,
        showSubjectSortModal: store.ExamBankReducer.showSubjectSortModal,
        subjectSortList: store.ExamBankReducer.subjectSortList,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));