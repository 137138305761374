import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_SUBJECT_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}



export function refreshSubject(subject) {
    return {
        type: ActionTypes.EXAM_SUBJECT_REFRESH_SUBJECT,
        subject: subject
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_SUBJECT_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function getSubject(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: true});

        const requestURL = HttpUtils.addQueryString(WebService.getSubject, "id", id);

        HttpUtils.get(requestURL, (response) => {
            let subject = response.data;
            subject.contentObject=  subject.content&&subject.content!==''?JSON.parse(subject.content):{};
            // if(subject.contentObject.items){
            //     subject.contentObject.items.forEach(item=>item.pageIndex = 2)
            // }

            dispatch(refreshSubject(subject));
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchSubjects(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: true});
        dispatch(refreshSearchCondition(condition))

        HttpUtils.post(WebService.searchSubjects, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_SUBJECT_SEARCH_SUBJECTS,
                subjects: response.data,
                pagination: response.pagination
            });
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createSubject(subject, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.createSubject, subject, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateSubject(subject, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateSubject, subject, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteSubject(id, condition, formatMessage) {
    return dispatch => {
        const requestURL = HttpUtils.addQueryString(WebService.deleteSubject, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage);
    }
}
export function synchronizationSubject(subject, condition, formatMessage) {
    return dispatch => {
        const requestURL = WebService.synchronizationSubject;
        HttpUtils.post(requestURL, subject, (response) => {
            dispatch(searchSubjects(condition, formatMessage));
            message.success("同步成功");
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage);
    }
}

export function toggleContentItemEditModal(isShow) {
    return {
        type: ActionTypes.EXAM_SUBJECT_TOGGLE_CONTENT_ITEM_EDIT_MODAL,
        showContentItemEditModal: isShow
    }
}
export function refreshSubjectContentItem(contentItem) {
    return {
        type: ActionTypes.EXAM_SUBJECT_REFRESH_CONTENT_ITEM,
        contentItem: contentItem
    }
}

export function searchAllSubjectList(formatMessage) {
    let condition = {paginationFlag: false};

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchSubjects, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_SUBJECT_SEARCH_SUBJECT_ALL_LIST,
                allSubjectList: response.data,
            });

            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}