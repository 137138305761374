import MenuItem from '../entity/MenuItem';

export const RoutePath = {

    COMMON_EXCEPTION_403: new MenuItem("COMMON_EXCEPTION_403", "/common/exception/403"),
    COMMON_EXCEPTION_404: new MenuItem("COMMON_EXCEPTION_404", "/common/exception/404"),
    COMMON_EXCEPTION_417: new MenuItem("COMMON_EXCEPTION_417", "/common/exception/417"),
    COMMON_EXCEPTION_500: new MenuItem("COMMON_EXCEPTION_500", "/common/exception/500"),

    // 字典项目树
    DICT_ITEM_LIST: new MenuItem("DICT_ITEM_ROUTE_LIST", "/dict/item/list", "database"),

    // 账号管理
    ACCOUNT_MANAGEMENT: new MenuItem("ACCOUNT_ROUTER_MANAGEMENT", "/org/organizations", "lock"),
    ACCOUNT_USER_LOGIN_PASSWORD: new MenuItem("ACCOUNT_USER_ROUTE_LOGIN_PASSWORD", "/account/login"),
    ACCOUNT_USER_LIST: new MenuItem("ACCOUNT_USER_ROUTE_LIST", "/account/users", "team"),

    ACCOUNT_USER_CENTER: new MenuItem("ACCOUNT_USER_ROUTE_CENTER", "/org/employee/profile", "user"),
    ACCOUNT_USER_PROFILE: new MenuItem("ACCOUNT_USER_ROUTE_PROFILE", "/account/user/profile", "user"),
    ACCOUNT_USER_REGISTER: new MenuItem("ACCOUNT_USER_ROUTE_REGISTER", "/account/user/register"),
    ACCOUNT_USER_PASSWORD: new MenuItem("ACCOUNT_USER_ROUTE_PASSWORD", "/account/user/password", "lock"),
    ACCOUNT_USER_PASSWORD_FORGOT: new MenuItem("ACCOUNT_USER_ROUTE_PASSWORD_FORGOT", "/account/user/password/forgot", "user"),

    ACCOUNT_DATA_GROUP_LIST: new MenuItem("ACCOUNT_DATA_GROUP_ROUTE_LIST", "/account/data/groups", "build"),
    ACCOUNT_ROLE_LIST: new MenuItem("ACCOUNT_ROLE_ROUTE_LIST", "/account/roles", "solution"),
    ACCOUNT_RESOURCE_LIST: new MenuItem("ACCOUNT_RESOURCE_ROUTE_LIST", "/account/resources", "appstore"),

    // 邮件管理
    MAIL_PROPERTIES_LIST: new MenuItem("MAIL_PROPERTIES_ROUTE_LIST", "/mail/properties/list", "profile"),
    MAIL_TEMPLATE_LIST: new MenuItem("MAIL_TEMPLATE_ROUTE_LIST", "/mail/template/list", "mail"),

    // 内容管理
    CMS_SITE_LIST: new MenuItem("CMS_SITE_ROUTE_SITE_LIST", "/cms/sites", "cluster"),
    CMS_CHANNEL_LIST: new MenuItem("CMS_CHANNEL_ROUTE_CHANNEL_LIST", "/cms/channels", "book"),
    CMS_CONTENT_MANAGEMENT: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_MANAGEMENT", "/cms/contents", "file"),
    CMS_CONTENT_LIST: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_LIST", "/cms/contents"),
    CMS_CONTENT_DRAFTS: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_DRAFTS", "/cms/contents/drafts"),
    CMS_CONTENT_CREATE: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_CREATE", "/cms/contents/new"),
    CMS_CONTENT_UPDATE: new MenuItem("CMS_CONTENT_ROUTE_CONTENT_UPDATE", "/cms/contents/:contentId"),

    // 用户组织管理
    ORG_MANAGEMENT: new MenuItem("ORG_ROUTER_MANAGEMENT", "/org/organizations", "team"),
    ORG_ORGANIZATION_LIST: new MenuItem("ORG_ORGANIZATION_ROUTE_LIST", "/org/organizations", "apartment"),
    ORG_EMPLOYEE_REGISTER: new MenuItem("ORG_EMPLOYEE_ROUTE_REGISTER", "/org/employee/register", "user"),
    ORG_EMPLOYEE_LIST: new MenuItem("ORG_EMPLOYEE_ROUTE_LIST", "/org/employees", "team"),
    ORG_EMPLOYEE_PROFILE: new MenuItem("ORG_EMPLOYEE_ROUTE_PROFILE", "/org/employee/profile", "user"),
    ORG_EMPLOYEE_POSITION: new MenuItem("ORG_EMPLOYEE_ROUTE_POSITION", "/org/employee/positions", "solution"),
    ORG_POSITION_LIST: new MenuItem("ORG_POSITION_ROUTE_LIST", "/org/positions", "folder"),
    ORG_EMPLOYEE_REGISTER_SUCCESS: new MenuItem("ORG_EMPLOYEE_ROUTE_REGISTER_SUCCESS", "/org/employee/register/success", ""),

    // 审批流
    WORKFLOW_DEPUTY_LIST: new MenuItem('WORKFLOW_DEPUTY_ROUTE_LIST', '/workflow/deputies', "branches"),

    // 访问注册
    VMS_VISIT_REQUEST: new MenuItem("VMS_VISIT_REQUEST_ROUTE_MANAGEMENT", "/vms/visit/request/create", "idcard"),
    VMS_VISIT_CREATE: new MenuItem("VMS_VISIT_REQUEST_ROUTE_CREATE", "/vms/visit/request/create", "solution"),
    VMS_VISIT_APPROVE_LIST: new MenuItem("VMS_VISIT_REQUEST_ROUTE_APPROVE_LIST", "/vms/visit/request/approve/list", "ordered-list"),
    VMS_VISIT_APPROVE_DETAIL: new MenuItem('VMS_VISIT_REQUEST_ROUTE_APPROVE_DETAIL', '/vms/request/approve/detail'),
    VMS_VISIT_HISTORY_LIST: new MenuItem("VMS_VISIT_REQUEST_ROUTE_HISTORY", "/vms/visit/request/history/list", "contacts"),
    VMS_VISIT_HISTORY_DETAIL: new MenuItem("VMS_VISIT_ROUTE_HISTORY_DETAIL", "/vms/visit/request/history/detail"),

    // 访客管理
    VMS_VISITOR_MANAGEMENT: new MenuItem("VMS_VISITOR_ROUTE_MANAGEMENT", "/vms/visitor/history/list", "contacts"),
    VMS_VISITOR_HISTORY_LIST: new MenuItem("VMS_VISITOR_ROUTE_HISTORY", "/vms/visitor/history/list", "contacts"),
    VMS_VISITOR_LIST: new MenuItem("VMS_VISITOR_ROUTE_LIST", "/vms/visitor/list", "solution"),
    VMS_VISITOR_DETAIL: new MenuItem("VMS_VISITOR_ROUTE_DETAIL", "/vms/visitor/detail"),
    VMS_VISITOR_VERIFY: new MenuItem("VMS_VISITOR_ROUTE_VERIFY", "/vms/visitor/verify", "audit"),
    VMS_VISITOR_IMPORT: new MenuItem("VMS_VISITOR_ROUTE_IMPORT", "/vms/visitor/import", "upload"),

    // 系统管理
    VMS_SYSTEM_MANAGEMENT: new MenuItem("VMS_SYSTEM_ROUTE_MANAGEMENT", "/vms/site/list", "setting"),
    VMS_SITE_LIST: new MenuItem("VMS_SYSTEM_ROUTE_SITE_LIST", "/vms/site/list", "global"),
    VMS_LOCATION_LIST: new MenuItem("VMS_SYSTEM_ROUTE_LOCATION_LIST", "/vms/location/list", "bank"),
    VMS_AREA_LIST: new MenuItem("VMS_SYSTEM_ROUTE_AREA_LIST", "/vms/area/list", "apartment"),
    VMS_TERMINAL_LIST: new MenuItem("VMS_TERMINAL_ROUTE_TERMINAL_LIST", "/vms/terminal/list", "appstore"),
    VMS_CARD_LIST: new MenuItem("VMS_CARD_ROUTE_CARD_LIST", "/vms/card/list", "credit-card"),

    // 自助
    VMS_TERMINAL_WELCOME: new MenuItem("VMS_TERMINAL_ROUTE_WELCOME", "/vms/terminal/welcome"),
    VMS_TERMINAL_REQUEST_CREATE: new MenuItem('VMS_REQUEST_ROUTE_CREATE', '/vms/terminal/request/create'),
    VMS_TERMINAL_REQUEST_VERIFY: new MenuItem('VMS_REQUEST_ROUTE_VERIFY', '/vms/terminal/request/verify'),
    VMS_TERMINAL_REQUEST_VISITOR_VERIFY: new MenuItem("VMS_TERMINAL_REQUEST_VISITOR_ROUTE_VERIFY", "/vms/terminal/request/visitor/verify"),
    VMS_TERMINAL_REQUEST_VISITOR_REISSUE: new MenuItem("VMS_TERMINAL_REQUEST_VISITOR_ROUTE_REISSUE", "/vms/terminal/request/visitor/reissue"),
    //自助竖屏
    VMS_TERMINAL_PORTRAIT_SETTING: new MenuItem("VMS_TERMINAL_PORTRAIT_ROUTE_SETTING", "/vms/terminal/portrait/setting"),
    VMS_TERMINAL_PORTRAIT_WELCOME: new MenuItem("VMS_TERMINAL_PORTRAIT_ROUTE_WELCOME", "/vms/terminal/portrait/welcome"),
    VMS_TERMINAL_PORTRAIT_REQUEST_VISITOR_VERIFY: new MenuItem("VMS_TERMINAL_PORTRAIT_REQUEST_VISITOR_ROUTE_VERIFY", "/vms/terminal/portrait/request/visitor/verify"),
    VMS_TERMINAL_PORTRAIT_REQUEST_VISITOR_REISSUE: new MenuItem('VMS_TERMINAL_PORTRAIT_REQUEST_ROUTE_VISITOR_REISSUE', '/vms/terminal/portrait/request/visitor/reissue'),
    VMS_TERMINAL_PORTRAIT_REQUEST_CREATE: new MenuItem('VMS_TERMINAL_PORTRAIT_REQUEST_ROUTE_CREATE', '/vms/terminal/portrait/request/create'),

    // 点名管理
    ROLL_CALL_MANAGEMENT: new MenuItem("ROLL_CALL_ROUTER_MANAGEMENT", "/rollcall/management", "safety-certificate"),
    // 点名主题
    ROLL_CALL_THEME_LIST: new MenuItem("ROLL_CALL_THEME_ROUTER_LIST", "/rollcall/themes"),
    ROLL_CALL_THEME_MONITOR: new MenuItem("ROLL_CALL_THEME_ROUTER_MONITOR", "/rollcall/themes/:id/monitor"),
    // Place管理
    ROLL_CALL_PLACE_LIST: new MenuItem("ROLL_CALL_PLACE_ROUTER_LIST", "/rollcall/places"),

    //考勤管理
    //考勤一览
    ATTENDANCE_RECORD_LIST: new MenuItem("ATTENDANCE_RECORD_LIST", "/attendance/records", "team"),

    // 测评系统
    //测评管理
    EXAM_MANAGEMENT: new MenuItem("EXAM_ROUTE_MANAGEMENT", "/exam/examinations", "file-done"),
    EXAM_EXAMINATION_LIST: new MenuItem("EXAM_EXAMINATION_ROUTE_LIST", "/exam/examinations"),
    EXAM_EXAMINEE_LIST: new MenuItem("EXAM_EXAMINEE_ROUTE_LIST", "/exam/examinees"),
    EXAM_PAPER_LIST: new MenuItem("EXAM_PAPER_ROUTE_LIST", "/exam/papers"),

    //训练
    // EXAM_CLASS_LIST: new MenuItem("EXAM_CLASS_ROUTE_LIST", "/exam/examinations/classlist"),

    EXAM_EXAMINATION_PERIOD_LIST: new MenuItem("EXAM_EXAMINATION_PERIOD_ROUTE_LIST", "/exam/examinationPeriodList"),

    //课程管理
    LESSON_MANAGEMENT: new MenuItem("EXAM_LESSON_ROUTE_MANAGEMENT", "/exam/lessons", "read"),

    EXAM_LESSON_TEMPLATE_LIST:new MenuItem("EXAM_LESSON_TEMPLATE_ROUTE_LIST", "/exam/lessonTemplates"),
    EXAM_EXAMINEE_LESSON_LIST:new MenuItem("EXAM_EXAMINEE_LESSON_ROUTE_LIST", "/exam/lessons"),


    //题库题目
    EXAM_BANK_LIST: new MenuItem("EXAM_BANK_ROUTE_LIST", "/exam/banks"),
    EXAM_SUBJECT_LIST: new MenuItem("EXAM_SUBJECT_ROUTE_LIST", "/exam/subjects"),

    // 测评系统移动端界面
    EXAM_MOBILE_EXAMINATION: new MenuItem("EXAM_EXAMINATION_ROUTE_DETAIL", "/exam/mobile/examination"),
    // 学员
    EXAM_MOBILE_EXAMINEE_VERIFY: new MenuItem("EXAM_EXAMINEE_ROUTE_VERIFY", "/exam/mobile/examinee/verify"),
    EXAM_MOBILE_EXAMINEE_VERIFY_SUCCESS: new MenuItem("EXAM_EXAMINEE_ROUTE_VERIFY_SUCCESS", "/exam/mobile/examinee/verify/success"),
    EXAM_MOBILE_EXAMINEE_VERIFY_FAILED: new MenuItem("EXAM_EXAMINEE_ROUTE_VERIFY_FAILED", "/exam/mobile/examinee/verify/failed"),
    // 测评试卷
    EXAM_PAPER_DETAIL: new MenuItem("EXAM_PAPER_ROUTE_DETAIL", "/exam/paper/detail"),
    EXAM_PAPER_RESULT: new MenuItem("EXAM_PAPER_ROUTE_RESULT", "/exam/paper/result"),
    EXAM_PAPER_RESULT_DETAIL: new MenuItem("EXAM_PAPER_ROUTE_RESULT_DETAIL", "/exam/paper/resultDetail"),
    ZHUOLI_VIDEO_LIST: new MenuItem("EXAM_EXAMINATION_ROUTE_DETAIL", "/zhuoli/video/list"),
    ZHUOLI_VIDEO_PLAY: new MenuItem("EXAM_EXAMINATION_ROUTE_DETAIL", "/zhuoli/video/play"),

    EXAM_DATA_MODEL_LIST: new MenuItem("EXAM_DATA_MODEL_ROUTE_LIST", "/exam/dataModel/list"),
    EXAM_DATA_MODEL_PAPER_UPDATE: new MenuItem("EXAM_DATA_MODEL_ROUTE_PAPER_UPDATE", "/exam/dataModel/paperUpdate"),
    EXAM_EXAMINEE_LESSON_RESULT_DETAIL: new MenuItem("EXAM_EXAMINEE_LESSONROUTE_RESULT_DETAIL", "/exam/lesson/resultDetail"),

};