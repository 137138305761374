import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Form, Input, List, NavBar, Space} from "antd-mobile";
import {getExamination} from "../../../../actions/ExaminationAction";

import moment from "moment";
import {FORMAT_DATE_TIME_SIMPLE, FORMAT_DATE_TIME_SLASH} from "../../../../../../constants/DateTimeFormats";
import {ExaminationStatus} from "../../../../Enums";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {getContentAnonymous} from "../../../../../CMS/actions/ContentAction";
import {getContentFileById, updateContentFile} from "../../../../../CMS/actions/ContentFileAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        const fileId = this.props.location.query.fileId;
        this.props.dispatch(getContentFileById(fileId, this.state.formatMessage));
    }

    componentDidMount() {
        const {file, dispatch} = this.props;
        const {formatMessage} = this.state;
        const video = document.getElementById("video");
        video.addEventListener("ended", function () {
            const file = Object.assign({}, file);
            file["remarks"] = "已播放";
            dispatch(updateContentFile(file, formatMessage));
        }, false)
    }

    _handleBack() {
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.router.push({
            pathname: RoutePath.ZHUOLI_VIDEO_LIST.path,
            query: {examinationId: examinationId, examineeId: examineeId}
        })
    }

    render() {
        const {file} = this.props;
        return (
            <div>
                <NavBar>{file.name}</NavBar>
                <Space block direction='vertical'>
                    <video id="video" src={file.path} autoPlay={true} width={"100%"}>
                        您的浏览器不支持 video 标签。
                    </video>
                    <div className="p-5">
                        <Button block color='primary' onClick={() => this._handleBack()}>
                            <FormattedMessage id="COMMON_BUTTON_BACK"/>
                        </Button>
                    </div>
                </Space>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        file: store.CmsContentFileReducer.file
    }
}

export default connect(mapStateToProps)(injectIntl(Index));