import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Button, Card, Table, Tag} from "antd";
import {handInPaper} from "../../../actions/PaperAction";
import {AttentionAssessmentSubjectType, getEnumsLabel} from "../../../Enums";
import LessonSubjectReport from "./LessonSubjectReport";
import {refreshChangeLessonSubjectInfo, toggleChangeLessonSubjectModal} from "../../../actions/ExamineeLessonAction";
import ChangeLessonSubjectModal from "./ChangeLessonSubjectModal";

class ChangeLessonSubjectShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }

    }



    componentWillMount() {

    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.paper);
        const subjects = Object.assign([], this.props.subjects);

        this.props.dispatch(handInPaper(paper, this.state.formatMessage));
    }
    _handleShowChangeSubjectModal(){
        let canChangeSubjectMap = this.props.canChangeSubjectMap;
        this.props.dispatch(toggleChangeLessonSubjectModal(true));
        this.props.dispatch(refreshChangeLessonSubjectInfo(canChangeSubjectMap));
    }

    render() {
        let checkLevelList = this.props.checkLevelList;
        let canChangeSubjectMap = this.props.canChangeSubjectMap;
        return (

            <div>
                <ChangeLessonSubjectModal checkLevelList={checkLevelList}/>
                <span style={{color: 'red'}}>
                {checkLevelList.map(item => {
                    if (item.isUpStandard) {
                        return <span>【{getEnumsLabel(item.subjectType, AttentionAssessmentSubjectType.Map, this.state.formatMessage)}】 已有分数超过记分标准，</span>
                    }
                    if (item.isDownStandard) {
                        return <span>{getEnumsLabel(item.subjectType, AttentionAssessmentSubjectType.Map, this.state.formatMessage)} 还未达到标准分，</span>
                    }
                })}
                    是否需要调整后续课程？
                    <Button size={"small"} type={"orange"} disabled={Object.keys(canChangeSubjectMap).length===0}
                    onClick={()=>this._handleShowChangeSubjectModal()}>去调整</Button>
                </span>
            </div>
        );
    }

}

function mapStateToProps(store) {
    return {
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        relatedExaminationList: store.ExamineeLessonReducer.relatedExaminationList,
        relatedPaperList: store.ExamineeLessonReducer.relatedPaperList,

    }
}

export default connect(mapStateToProps)(injectIntl(ChangeLessonSubjectShowParts));