import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import SearchCondition from './SearchCondition';
import SearchResult from './SearchResult';
import DetailModal from "./DetailModal";
import {Button, Modal} from "antd";
import {toggleSubjectsModal} from "../../../actions/ExaminationAction";
import ImportModal from "./ImportModal";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    _handleCancel() {
        this.props.dispatch(toggleSubjectsModal(false));
    }

    _handleOk() {

    }

    render() {
        const {formatMessage} = this.state;
        return (
            <Modal
                width={1000}
                style={{top:'20px'}}
                maskClosable={false}
                title={formatMessage({id: "EXAM_EXAMINATION_TITLE_SUBJECTS"})}
                visible={this.props.showSubjectsModal}
                onCancel={() => this._handleCancel()}
                footer={[
                    <Button type="primary" key="id" onClick={() => this._handleCancel()}>
                        <FormattedMessage id="COMMON_BUTTON_CLOSE"/>
                    </Button>
                ]}
            >
                <DetailModal/>
                <ImportModal/>
                <SearchCondition/>
                <SearchResult {...this.props}/>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showSubjectsModal: store.ExamExaminationReducer.showSubjectsModal
    }
}

export default connect(mapStateToProps)(injectIntl(Index));