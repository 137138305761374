import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Button, Table, Divider, Modal, Popconfirm, Upload, Icon, message} from "antd";
import moment from 'moment';

import {
    toggleDetailModal,
    refreshFile,
    refreshFiles,
    createContentFile,
    deleteContentFile
} from '../actions/ContentFileAction';
import * as FTPWebService from "../../FTP/WebService";
import {CACHE_ACCESS_TOKEN, CACHE_SITE_CODE} from "../../../constants/Profile";
import {HttpStatus} from "../../../constants/Enums";
import ContentFileModal from "./ContentFileModal";

class ContentFileList extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_PREVIEW'}),
                    key: "preview",
                    render: (text, record, index) => <img style={{width: '100px'}} src={record.path}/>
                },
                {title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_NAME'}), dataIndex: 'name', key: 'name'},
                {title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_PATH'}), dataIndex: 'path', key: 'path'},
                {title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_TYPE'}), dataIndex: 'type', key: 'type'},
                {title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SIZE'}), dataIndex: 'size', key: 'size'},
                {title: formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SEQUENCE'}), dataIndex: 'sequence', key: 'sequence'},
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record, index)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a> <FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleDelete(record, index) {
        const {content, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteContentFile(record.id, content.id, formatMessage));
    }

    _handleShowUpdateModal(record, index) {
        this.props.dispatch(refreshFile(record));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const {content} = this.props;
        const {formatMessage} = this.state;
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const yearMonth = moment().format("YYYYMM");
        const self = this;
        const uploadProps = {
            name: 'file',
            multiple: true,
            action: FTPWebService.uploadOSSFile + "?folder=" + siteCode + "%2Fcontents%2F" + yearMonth,
            headers: {
                'Authorization': 'authorization-text',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            onChange(info) {
                console.log(info);
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done' && info.file.response.status === HttpStatus.SUCCESS) {
                    const files = Object.assign([], self.props.files);
                    const file = {
                        contentId: content.id,
                        id: info.file.uid,
                        name: info.file.name,
                        path: info.file.response.data,
                        size: info.file.size,
                        type: info.file.type
                    };
                    files.push(file);
                    self.props.dispatch(createContentFile(file, formatMessage));
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <ContentFileModal/>
                <div>
                    <Table rowKey="id" columns={this.state.columns} dataSource={this.props.files}
                           pagination={false}/>
                </div>
                <div className={"spacing-v"}>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox"/>
                        </p>
                        <p className="ant-upload-text"><FormattedMessage
                            id={"CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_TEXT"}/></p>
                        <p className="ant-upload-hint"><FormattedMessage
                            id={"CMS_CONTENT_FIELD_TITLE_IMAGE_UPLOAD_HINT"}/></p>
                    </Upload.Dragger>
                </div>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        files: store.CmsContentFileReducer.files,
        content: store.CmsContentReducer.content,
    }
}

export default connect(mapStateToProps)(injectIntl(ContentFileList));