// 用户
export const USER_LOADING = 'USER_LOADING';
export const USER_REFRESH_SEARCH_CONDITION = "USER_REFRESH_SEARCH_CONDITION";
export const USER_REFRESH_USER = 'USER_REFRESH_USER';
export const USER_REFRESH_USERS = 'USER_REFRESH_USERS';
export const USER_REFRESH_SESSION_USER = 'USER_REFRESH_SESSION_USER';
export const USER_SEARCH = 'USER_SEARCH';
export const USER_TOGGLE_DETAIL_MODAL = "USER_TOGGLE_DETAIL_MODAL";
export const USER_TOGGLE_PASSWORD_CHANGE_MODAL = "USER_TOGGLE_PASSWORD_CHANGE_MODAL";
export const USER_TOGGLE_PASSWORD_RESET_MODAL = "USER_TOGGLE_PASSWORD_RESET_MODAL";

// 角色
export const ACCOUNT_ROLE_LOADING = 'ACCOUNT_ROLE_LOADING';
export const ACCOUNT_ROLE_REFRESH_ROLE = 'ACCOUNT_ROLE_REFRESH_ROLE';
export const ACCOUNT_ROLE_REFRESH_SEARCH_CONDITION = "ACCOUNT_ROLE_REFRESH_SEARCH_CONDITION";
export const ACCOUNT_ROLE_SEARCH = 'ACCOUNT_ROLE_SEARCH';
export const ACCOUNT_ROLE_TOGGLE_DETAIL_MODAL = "ACCOUNT_ROLE_TOGGLE_DETAIL_MODAL";
export const ACCOUNT_ROLE_TOGGLE_PERMISSION_MODAL = "ACCOUNT_ROLE_TOGGLE_PERMISSION_MODAL";
export const ACCOUNT_ROLE_REFRESH_SELECTED_ROLES = 'ACCOUNT_ROLE_REFRESH_SELECTED_ROLES';

// 资源
export const ACCOUNT_RESOURCE_LOADING = 'ACCOUNT_RESOURCE_LOADING';
export const ACCOUNT_RESOURCE_REFRESH_RESOURCE = 'ACCOUNT_RESOURCE_REFRESH_RESOURCE';
export const ACCOUNT_RESOURCE_REFRESH_SEARCH_CONDITION = "ACCOUNT_RESOURCE_REFRESH_SEARCH_CONDITION";
export const ACCOUNT_RESOURCE_SEARCH = 'ACCOUNT_RESOURCE_SEARCH';
export const ACCOUNT_RESOURCE_TREE = 'ACCOUNT_RESOURCE_TREE';
export const ACCOUNT_RESOURCE_MY_MENU_TREE = 'ACCOUNT_RESOURCE_MY_MENU_TREE';
export const ACCOUNT_RESOURCE_TOGGLE_DETAIL_MODAL = "ACCOUNT_RESOURCE_TOGGLE_DETAIL_MODAL";
export const ACCOUNT_RESOURCE_REFRESH_SELECTED_RESOURCE_IDS = 'ACCOUNT_RESOURCE_REFRESH_SELECTED_RESOURCE_IDS';

// 数据组
export const ACCOUNT_DATA_GROUP_LOADING = 'ACCOUNT_DATA_GROUP_LOADING';
export const ACCOUNT_DATA_GROUP_SEARCH = 'ACCOUNT_DATA_GROUP_SEARCH';
export const ACCOUNT_DATA_GROUP_TREE = 'ACCOUNT_DATA_GROUP_TREE';
export const ACCOUNT_DATA_GROUP_SEARCH_CONDITION = "ACCOUNT_DATA_GROUP_SEARCH_CONDITION";
export const ACCOUNT_DATA_GROUP_REFRESH_DATA_GROUP = 'ACCOUNT_DATA_GROUP_REFRESH_DATA_GROUP';
export const ACCOUNT_DATA_GROUP_TOGGLE_DETAIL_MODAL = "ACCOUNT_DATA_GROUP_TOGGLE_DETAIL_MODAL";