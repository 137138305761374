import * as ActionTypes from '../ActionTypes';

const initialState = {
    properties: {},
    propertiesList: [],
    searchCondition: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
};

const PropertiesReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.MAIL_PROPERTIES_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.MAIL_PROPERTIES_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.MAIL_PROPERTIES_REFRESH_PROPERTIES:
            return Object.assign({}, state, {
                properties: action.properties
            });
        case ActionTypes.MAIL_PROPERTIES_SEARCH:
            return Object.assign({}, state, {
                propertiesList: action.propertiesList,
                pagination: action.pagination
            });
        case ActionTypes.MAIL_PROPERTIES_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        default:
            return state
    }
};

export default PropertiesReducer;