/**
 * Created by Ethan on 2017/9/8.
 */

import * as ActionTypes from '../constants/ActionTypes'

import {RoutePath} from '../constants/RoutePath';
import {CACHE_LANGUAGE, CACHE_SESSION_USER} from "../constants/Profile";
import HttpUtils from "../utils/HttpUtils";
import * as WebService from "../modules/Account/WebService";
import {ResourceTypes} from "../modules/Account/Enums";

export function changeLanguage(language) {
    localStorage.setItem(CACHE_LANGUAGE, language);
    return dispatch => {
        dispatch({
            type: ActionTypes.APP_CHANGE_LANGUAGE,
            currentLanguage: language
        })
    }
}

export function getMainMenu(formatMessage) {

    // const user = JSON.parse(localStorage.getItem(CACHE_SESSION_USER));
    // let menuItems = [];
    // let menuItemMap = {};

    return dispatch => {
        const requestUrl = HttpUtils.addQueryString(WebService.getMyResourceTree, "types", ResourceTypes.Enum.MENU);
        HttpUtils.get(requestUrl, (response) => {
            console.log(response.data);
            const menuItems = [];
            const menuItemMap = {};
            for (let mainMenu of response.data) {
                menuItems.push(mainMenu);
                menuItemMap[mainMenu.path] = mainMenu;
                setChildrenMenuItemMap(menuItemMap, mainMenu);
            }
            // dispatch({
            //     type: ActionTypes.ACCOUNT_RESOURCE_MY_MENU_TREE,
            //     myResourceMenuTree: response.data
            // });
            dispatch({
                type: ActionTypes.APP_GET_MAIN_MENU,
                menuItems: menuItems,
                menuItemMap: menuItemMap
            })
        }, (response) => {
        }, formatMessage)
    };

    // // 访问注册
    // let menuVMSVisitRequest = RoutePath.VMS_VISIT_REQUEST;
    // menuVMSVisitRequest.children = [];
    // menuItems.push(menuVMSVisitRequest);
    // menuItemMap[menuVMSVisitRequest.path] = menuVMSVisitRequest;
    // // 访客邀请
    // let menuVMSVisitorRegistration = RoutePath.VMS_VISIT_CREATE;
    // menuVMSVisitorRegistration.setParent(menuVMSVisitRequest);
    // menuItemMap[menuVMSVisitorRegistration.path] = menuVMSVisitorRegistration;
    // // 访问审批列表
    // let menuVMSVisitApproveList = RoutePath.VMS_VISIT_APPROVE_LIST;
    // menuVMSVisitApproveList.setParent(menuVMSVisitRequest);
    // menuItemMap[menuVMSVisitApproveList.path] = menuVMSVisitApproveList;
    // // 访问审批详情
    // let menuVMSVisitApproveDetail = RoutePath.VMS_VISIT_APPROVE_DETAIL;
    // menuVMSVisitApproveDetail.hiddenFlag = true;
    // menuVMSVisitApproveDetail.setParent(menuVMSVisitRequest);
    // menuItemMap[menuVMSVisitApproveDetail.path] = menuVMSVisitApproveDetail;
    // // 访问审批代理
    // const menuVMSVisitApproveDeputy = RoutePath.WORKFLOW_DEPUTY_LIST;
    // menuVMSVisitApproveDeputy.setParent(menuVMSVisitRequest);
    // menuItemMap[menuVMSVisitApproveDeputy.path] = menuVMSVisitApproveDeputy;
    // // 访问历史
    // let menuVMSVisitHistory = RoutePath.VMS_VISIT_HISTORY_LIST;
    // menuVMSVisitHistory.setParent(menuVMSVisitRequest);
    // menuItemMap["/"] = menuVMSVisitorRegistration;
    // menuItemMap[menuVMSVisitHistory.path] = menuVMSVisitHistory;
    // // 访问历史详情
    // let menuVMSVisitHistoryDetail = RoutePath.VMS_VISIT_HISTORY_DETAIL;
    // menuVMSVisitHistoryDetail.hiddenFlag = true;
    // menuVMSVisitHistoryDetail.setParent(menuVMSVisitRequest);
    // menuItemMap[menuVMSVisitHistoryDetail.path] = menuVMSVisitHistoryDetail;
    // if (user && user.roles && (user.roles.some(role => role.code === 'ROLE_ADMIN') ||
    //     user.roles.some(role => role.code === 'ROLE_VMS_MANAGER'))) {
    //     // 访客管理
    //     const menuVMSVisitorManagement = RoutePath.VMS_VISITOR_MANAGEMENT;
    //     menuVMSVisitorManagement.children = [];
    //     menuItems.push(menuVMSVisitorManagement);
    //     menuItemMap[menuVMSVisitorManagement.path] = menuVMSVisitorManagement;
    //     // 访客历史列表
    //     const menuVMSVisitorHistory = RoutePath.VMS_VISITOR_HISTORY_LIST;
    //     menuVMSVisitorHistory.setParent(menuVMSVisitorManagement);
    //     menuItemMap[menuVMSVisitorHistory.path] = menuVMSVisitorHistory;
    //     // 访客历史详情
    //     const menuVMSVisitorDetail = RoutePath.VMS_VISITOR_DETAIL;
    //     menuVMSVisitorDetail.hiddenFlag = true;
    //     menuVMSVisitorDetail.setParent(menuVMSVisitorManagement);
    //     menuItemMap[menuVMSVisitorDetail.path] = menuVMSVisitorDetail;
    //     // 访客清单
    //     let menuVMSVisitorList = RoutePath.VMS_VISITOR_LIST;
    //     menuVMSVisitorList.setParent(menuVMSVisitorManagement);
    //     menuItemMap[menuVMSVisitorList.path] = menuVMSVisitorList;
    //     //访客认证
    //     const menuVMSVisitorVerify = RoutePath.VMS_VISITOR_VERIFY;
    //     menuVMSVisitorVerify.setParent(menuVMSVisitorManagement);
    //     menuItemMap[menuVMSVisitorVerify.path] = menuVMSVisitorVerify;
    //     // 访客导入
    //     const menuVMSVisitorImport = RoutePath.VMS_VISITOR_IMPORT;
    //     menuVMSVisitorImport.setParent(menuVMSVisitorManagement);
    //     menuItemMap[menuVMSVisitorImport.path] = menuVMSVisitorImport;
    // }
    // if (user && user.roles && (user.roles.some(role => role.code === 'ROLE_ADMIN') ||
    //     user.roles.some(role => role.code === 'ROLE_SYS_MANAGER'))) {
    //     // 系统管理
    //     const menuVMSSystemManagement = RoutePath.VMS_SYSTEM_MANAGEMENT;
    //     menuVMSSystemManagement.children = [];
    //     menuItems.push(menuVMSSystemManagement);
    //     menuItemMap[menuVMSSystemManagement.path] = menuVMSSystemManagement;
    //     // 通行站点列表
    //     const menuVMSSiteList = RoutePath.VMS_SITE_LIST;
    //     menuVMSSiteList.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuVMSSiteList.path] = menuVMSSiteList;
    //     // 访问位置列表
    //     const menuVMSLocationList = RoutePath.VMS_LOCATION_LIST;
    //     menuVMSLocationList.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuVMSLocationList.path] = menuVMSLocationList;
    //     // 访问场所列表
    //     const menuVMSArea = RoutePath.VMS_AREA_LIST;
    //     menuVMSArea.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuVMSArea.path] = menuVMSArea;
    //     // 访客卡
    //     const menuVMSCard = RoutePath.VMS_CARD_LIST;
    //     menuVMSCard.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuVMSCard.path] = menuVMSCard;
    //     // 字典项
    //     const menuDictItemList = RoutePath.DICT_ITEM_LIST;
    //     menuDictItemList.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuDictItemList.path] = menuDictItemList;
    //     // 内容管理
    //     const menuCMSContentList = RoutePath.CMS_CONTENT_LIST;
    //     menuCMSContentList.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuCMSContentList.path] = menuCMSContentList;
    //     // 邮件配置
    //     const menuMailPropertiesList = RoutePath.MAIL_PROPERTIES_LIST;
    //     menuMailPropertiesList.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuMailPropertiesList.path] = menuMailPropertiesList;
    //     // 邮件模版
    //     const menuMailTemplateList = RoutePath.MAIL_TEMPLATE_LIST;
    //     menuMailTemplateList.setParent(menuVMSSystemManagement);
    //     menuItemMap[menuMailTemplateList.path] = menuMailTemplateList;
    //
    //     // 账号管理
    //     const menuAccountManagement = RoutePath.ACCOUNT_MANAGEMENT;
    //     menuAccountManagement.children = [];
    //     menuItems.push(menuAccountManagement);
    //     menuItemMap[menuAccountManagement.path] = menuAccountManagement;
    //     // 组织一览
    //     const menuOrganizationList = RoutePath.ORG_ORGANIZATION_LIST;
    //     menuOrganizationList.setParent(menuAccountManagement);
    //     menuItemMap[menuOrganizationList.path] = menuOrganizationList;
    //     // 账号一览
    //     const menuAccountUserList = RoutePath.ORG_EMPLOYEE_LIST;
    //     menuAccountUserList.setParent(menuAccountManagement);
    //     menuItemMap[menuAccountUserList.path] = menuAccountUserList;
    //     // 角色一览
    //     const menuAccountRoleList = RoutePath.ACCOUNT_ROLE_LIST;
    //     menuAccountRoleList.setParent(menuAccountManagement);
    //     menuItemMap[menuAccountRoleList.path] = menuAccountRoleList;
    //     // 数据组一览
    //     const menuAccountDataGroupList = RoutePath.ACCOUNT_DATA_GROUP_LIST;
    //     menuAccountDataGroupList.setParent(menuAccountManagement);
    //     menuItemMap[menuAccountDataGroupList.path] = menuAccountDataGroupList;
    //     // 资源一览
    //     const menuAccountResourceList = RoutePath.ACCOUNT_RESOURCE_LIST;
    //     menuAccountResourceList.setParent(menuAccountManagement);
    //     menuItemMap[menuAccountResourceList.path] = menuAccountResourceList;
    // }
    //
    // // 个人中心
    // const menuAccountUser = RoutePath.ACCOUNT_USER_CENTER;
    // menuAccountUser.children = [];
    // menuItems.push(menuAccountUser);
    // menuItemMap[menuAccountUser.path] = menuAccountUser;
    // // 个人信息
    // const menuAccountProfile = RoutePath.ORG_EMPLOYEE_PROFILE;
    // menuAccountProfile.setParent(menuAccountUser);
    // menuItemMap[menuAccountProfile.path] = menuAccountProfile;
    // // 修改密码
    // const menuAccountPassword = RoutePath.ACCOUNT_USER_PASSWORD;
    // menuAccountPassword.setParent(menuAccountUser);
    // menuItemMap[menuAccountPassword.path] = menuAccountPassword;
    //
    // return {
    //     type: ActionTypes.APP_GET_MAIN_MENU,
    //     menuItems: menuItems,
    //     menuItemMap: menuItemMap
    // }
}

function setChildrenMenuItemMap(menuItemMap, menuItem) {
    if (menuItem.children != null && menuItem.children.length > 0) {
        for (let child of menuItem.children) {
            menuItemMap[child.path] = child;
            child.parent = menuItem;
            setChildrenMenuItemMap(menuItemMap, child);
        }
    }
}