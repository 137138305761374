import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import HttpUtils from "../../../utils/HttpUtils";
import {message} from "antd/lib/index";
import {togglePermissionModal} from "./RoleAction";
import {ResourceTypes} from "../Enums";

export function refreshResource(resource) {
    return {
        type: ActionTypes.ACCOUNT_RESOURCE_REFRESH_RESOURCE,
        resource: resource
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.ACCOUNT_RESOURCE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function toggleDetailModal(showDetailModal) {
    return {
        type: ActionTypes.ACCOUNT_RESOURCE_TOGGLE_DETAIL_MODAL,
        showDetailModal: showDetailModal
    }
}

export function refreshSelectedResourceIds(selectedResources) {
    return {
        type: ActionTypes.ACCOUNT_RESOURCE_REFRESH_SELECTED_RESOURCE_IDS,
        selectedResourceIds: selectedResources
    }
}

export function getResource(id, formatMessage, forCopy) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getResourceById, "id", id);
        HttpUtils.get(requestURL, (response) => {
            if (forCopy) {
                response.data.id = '';
            }
            dispatch(refreshResource(response.data));
            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getResourceTree(formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        HttpUtils.get(WebService.getResourceTree, (response) => {
            dispatch({
                type: ActionTypes.ACCOUNT_RESOURCE_TREE,
                resourceTree: response.data
            });

            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getMyResourceMenuTree(formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        const requestUrl = HttpUtils.addQueryString(WebService.getMyResourceTree, "types", ResourceTypes.Enum.MENU);
        HttpUtils.get(requestUrl, (response) => {
            dispatch({
                type: ActionTypes.ACCOUNT_RESOURCE_MY_MENU_TREE,
                myResourceMenuTree: response.data
            });

            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function searchResources(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchResources, condition, (response) => {
            dispatch({
                type: ActionTypes.ACCOUNT_RESOURCE_SEARCH,
                resources: response.data,
                pagination: response.pagination
            });
            dispatch(refreshSearchCondition(condition));
            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getResourcesByRoleId(roleId, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchResources, {paginationFlag: false, roleIds: [roleId]}, (response) => {
            const selectedResourceIds = [];
            for (let resource of response.data) {
                selectedResourceIds.push(resource.id);
            }
            dispatch(refreshSelectedResourceIds(selectedResourceIds));
            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function createResource(resource, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createResource, resource, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getResourceTree(formatMessage));
            dispatch(searchResources(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateResource(resource, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateResource, resource, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(getResourceTree(formatMessage));
            dispatch(searchResources(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteResource(resource, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: true});
        HttpUtils.post(WebService.deleteResource, resource, (response) => {
            dispatch(getResourceTree(formatMessage));
            dispatch(searchResources(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.ACCOUNT_RESOURCE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage);
    }
}