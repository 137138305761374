import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Row, Col, Input, Button, Select, Checkbox} from "antd";

import {
    refreshResource,
    refreshSearchCondition,
    searchResources,
    toggleDetailModal
} from '../../../actions/ResourceAction';
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import TreeSelector from "../../../../../components/TreeSelector";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import {ResourceTypes} from "../../../Enums";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch({});
        this.props.dispatch(refreshSearchCondition({}));
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchResources(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshResource({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const {resourceTree, searchCondition} = this.props;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "ACCOUNT_RESOURCE_FIELD_PARENT"})} {...Label_6_Wrapper_18}>
                                <TreeSelector multiple={true}
                                              treeData={resourceTree}
                                              value={searchCondition.parentIds}
                                              onChange={(value) => this._handleChange("parentIds", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "ACCOUNT_RESOURCE_FIELD_CODE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.code}
                                       onChange={(value) => this._handleChange("code", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "ACCOUNT_RESOURCE_FIELD_PATH"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.path}
                                       onChange={(value) => this._handleChange("path", value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "ACCOUNT_RESOURCE_FIELD_TYPE"})} {...Label_6_Wrapper_18}>
                                <EnumItemSelect mode="multiple"
                                                data={ResourceTypes.List}
                                                value={searchCondition.types}
                                                onChange={(value) => this._handleChange("types", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "ACCOUNT_RESOURCE_FIELD_NAME"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.name}
                                       onChange={(value) => this._handleChange("name", value)}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="text-center">
                    <Button type="primary" icon="search"
                            onClick={() => this._handleSearch(this.props.searchCondition)}
                            className="spacing-h">
                        <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                    </Button>
                    <Button icon="plus" onClick={() => this._handleShowCreateModal()}>
                        <FormattedMessage id='COMMON_BUTTON_CREATE'/>
                    </Button>
                </div>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        resourceTree: store.AccountResourceReducer.resourceTree,
        searchCondition: store.AccountResourceReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));