import React, {Component} from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Select} from "antd";

class FieldSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    onChange = (value) => {
        this.props.onChange && this.props.onChange.call(this, value);
    };

    render() {
        return (
            <Select
                style={this.props.style?this.props.style:{width: '100%'}}
                allowClear={true} value={this.props.value}
                onChange={this.onChange}>
                {
                    this.props.data.map((item, index) => {
                        return <Select.Option key={index} value={item.value}>
                            <FormattedMessage id={item.label}/>
                        </Select.Option>
                    })
                }
            </Select>
        );
    }
}

export default (injectIntl(FieldSelect));