import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {PaperSubjectStatus} from "../../../../Enums";
import {refreshSubjects} from "../../../../actions/PaperSubjectAction";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        const self = this;
        const video = document.getElementById("video");
        video.addEventListener("ended", function () {
            const subjects = Object.assign([], self.props.subjects);
            const subject = subjects[self.props.subjectIndex];
            subject.status = PaperSubjectStatus.Enum.Completed;
            self.props.dispatch(refreshSubjects(subjects));
        }, false)
    }

    render() {
        const {subjectIndex, subjects} = this.props;
        const paperSubject = subjects[subjectIndex];
        console.log(paperSubject);
        const filePaths = paperSubject && paperSubject.subject && paperSubject.subject.filePath ? JSON.parse(paperSubject.subject.filePath) : [];
        const filePath = filePaths.length > 0 ? filePaths[0] : "";
        return (
            <div>
                {/*指示当页面加载后仅加载音频/视频的元数据。*/}
                <video id="video" src={filePath} preload={"metadata"} width={"100%"} controls>
                    您的浏览器不支持 video 标签。
                </video>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        subjects: store.ExamPaperSubjectReducer.subjects
    }
}

export default connect(mapStateToProps)(injectIntl(Index));