import HttpUtils from "../../../utils/HttpUtils";
import {message} from 'antd';
import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import {searchEmployees} from "../../ORG/actions/EmployeeAction";

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.MAIL_PROPERTIES_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.MAIL_PROPERTIES_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshProperties(properties) {
    return {
        type: ActionTypes.MAIL_PROPERTIES_REFRESH_PROPERTIES,
        properties: properties
    }
}

export function searchPropertiesList(condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: true});
        HttpUtils.post(WebService.searchProperties, condition, (response) => {
            dispatch({
                type: ActionTypes.MAIL_PROPERTIES_SEARCH,
                propertiesList: response.data,
                pagination: response.pagination
            });
            dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getPropertiesDetail(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: true});
        let requestUrl = HttpUtils.addQueryString(WebService.getPropertiesDetail, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshProperties(response.data));
            dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)

    }
}

export function createProperties(properties, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: true});
        HttpUtils.post(WebService.createProperties, properties, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchPropertiesList(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateProperties(properties, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: true});
        HttpUtils.post(WebService.updateProperties, properties, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchPropertiesList(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteProperties(properties, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: true});
        HttpUtils.post(WebService.deleteProperties, properties, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchPropertiesList(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_PROPERTIES_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}