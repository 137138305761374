import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Select} from 'antd';
import {searchAllLessonTemplatesList} from "../../../actions/LessonTemplateAction";

class LessonTemplateSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectItemKey: "",
        }
    }

    componentWillMount() {
this.props.dispatch(searchAllLessonTemplatesList(this.state.formatMessage))
    }

    componentWillReceiveProps(nextProps) {
        this.setState({selectItemKey: nextProps.value});

    }

    componentWillUnmount() {
        // this.setState({selectItemKey: ""});
    }

    onChange = (value, label) => {
        this.setState({selectItemKey: value});
        this.props.onChange && this.props.onChange.call(this, value, label);
    };

    render() {
        let allLessonTemplateList = this.props.allLessonTemplateList ? this.props.allLessonTemplateList : [];

        return (<Select
            allowClear={true}
            disabled={this.props.disabled}
            style={this.props.style}
            className={this.props.className}
            value={this.props.value}
            showSearch
            onChange={this.onChange}>
            {
                allLessonTemplateList.map((item, i) => {
                    return (
                        <Select.Option key={item.id} value={item.id}>
                            {item.title}
                        </Select.Option>
                    )
                })
            }
        </Select>)
    }


}

function mapStateToProps(store) {
    return {
        allLessonTemplateList: store.LessonTemplateReducer.allLessonTemplateList,
    }
}

export default connect(mapStateToProps)(injectIntl(LessonTemplateSelect));