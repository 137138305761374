import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import {HttpStatus} from '../../../constants/Enums';
import HttpUtils from '../../../utils/HttpUtils';
import {message, Modal} from 'antd';
import {
    EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECT_SORT_LIST,
    EXAM_EXAMINATION_SUBJECT_TOGGLE_SUBJECT_SORT_MODAL
} from "../ActionTypes";

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_SUBJECT_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function toggleImportModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_SUBJECT_TOGGLE_IMPORT_MODAL,
        showImportModal: isShow
    }
}

export function toggleSubjectSortModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_SUBJECT_TOGGLE_SUBJECT_SORT_MODAL,
        showSubjectSortModal: isShow
    }
}

export function refreshExaminationSubject(subject) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_SUBJECT_REFRESH_SUBJECT,
        subject: subject
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_EXAMINATION_SUBJECT_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function getExaminationSubject(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: true});

        const requestURL = HttpUtils.addQueryString(WebService.getExaminationSubject, "id", id);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshExaminationSubject(response.data));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchExaminationSubjects(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminationSubjects, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECTS,
                subjects: response.data,
                pagination: response.pagination
            });
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchExaminationSubjectSortList(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminationSubjects, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECT_SORT_LIST,
                subjectSortList: response.data
            });
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function importExaminationSubjectFromBank(examinationId, bankId, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: true});

        let requestUrl = HttpUtils.addQueryString(WebService.importExaminationSubjectFromBank, "examinationId", examinationId);
        requestUrl = HttpUtils.addQueryString(requestUrl, "bankId", bankId);
        HttpUtils.post(requestUrl, {}, (response) => {
            dispatch(toggleImportModal(false));
            dispatch(searchExaminationSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_IMPORT_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createExaminationSubject(subject, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.createExaminationSubject, subject, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchExaminationSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateExaminationSubject(subject, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateExaminationSubject, subject, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchExaminationSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteExaminationSubject(id, condition, formatMessage) {
    return dispatch => {
        const requestURL = HttpUtils.addQueryString(WebService.deleteExaminationSubject, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchExaminationSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage);
    }
}

export function batchUpdateExaminationSubject(subjects, condition, formatMessage) {
    let subjectList = subjects.map((item, index) => {
        return {id: item.id, sequence: item.sequence};
    })

    return dispatch => {
        HttpUtils.post(WebService.batchExaminationSubject, subjectList, (response) => {
            dispatch(toggleSubjectSortModal(false));
            dispatch(searchExaminationSubjects(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINATION_SUBJECT_LOADING, isLoading: false});
        }, formatMessage);
    }
}