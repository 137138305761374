import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message, Modal} from 'antd';
import {searchSubjects} from "../WebService";
import {batchUpdateExaminationSubject} from "./ExaminationSubjectAction";

export function refreshBank(bank) {
    return {
        type: ActionTypes.EXAM_BANK_REFRESH_BANK,
        bank: bank,
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_BANK_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_BANK_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function toggleSubjectSortModal(isShow) {
    return {
        type: ActionTypes.EXAM_BANK_TOGGLE_SUBJECT_SORT_MODAL,
        showSubjectSortModal: isShow
    }
}

export function searchBanks(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchBanks, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_BANK_SEARCH_BANK,
                banks: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function searchBankSubjectSortList(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchSubjects, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_BANK_SUBJECT_SEARCH_SUBJECT_SORT_LIST,
                subjectSortList: response.data,
            });

            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getBank(bankId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: true});

        let requestURL = WebService.getBank.replace("{id}", bankId);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshBank(response.data));
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createBank(bank, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: true});

        HttpUtils.post(WebService.createBank, bank, (response) => {
            dispatch(searchBanks(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateBank(bank, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: true});

        let requestURL = WebService.updateBank.replace("{id}", bank.id);

        HttpUtils.put(requestURL, bank, (response) => {
            dispatch(searchBanks(condition, formatMessage));
            dispatch(toggleDetailModal(false));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteBank(id, condition, formatMessage) {
    return dispatch => {
        let requestURL = WebService.deleteBank.replace("{id}", id);
        HttpUtils.delete(requestURL, {}, (response) => {
            dispatch(searchBanks(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage);
    }
}

export function batchUpdateBankSubject(subjects, searchCondition, formatMessage) {
    let subjectList = subjects.map((item, index) => {
        return {id: item.id, sequence: item.sequence};
    })

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: true});
        HttpUtils.post(WebService.sortSubject, subjectList, (response) => {
            dispatch(searchBanks(searchCondition, formatMessage));
            dispatch(toggleSubjectSortModal(false));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_BANK_LOADING, isLoading: false});
        }, formatMessage);
    }
}


