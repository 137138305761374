import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Layout, Card, Table, Divider, Popconfirm} from 'antd';

import PageTitle from "../../../../../components/PageTitle";

import {deleteContent, searchContents} from '../../../actions/ContentAction';
import {CACHE_SITE_CODE} from "../../../../../constants/Profile";
import {RoutePath} from "../../../../../constants/RoutePath";
import {Modal} from "antd/lib/index";

class Index extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                // {title: formatMessage({id: 'CMS_CONTENT_FIELD_CODE'}), dataIndex: 'code', key: 'code'},
                {title: formatMessage({id: 'CMS_CONTENT_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'CMS_CONTENT_FIELD_CHANNEL'}),
                    key: 'channel',
                    dataIndex: 'channel.title'
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    componentWillMount() {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const searchCondition = {
            draftFlags: [1],
            paginationFlag: false
        };
        this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handleShowAttachmentModal.bind(this, record)}>附件</a>*/}
            </div>
        )
    }

    _handleDelete(record) {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const {searchCondition, dispatch} = this.props;
        const state = this.state;
        dispatch(deleteContent(siteCode, record, searchCondition, state.formatMessage));
    }

    _handleModify(record) {
        this.props.router.push(RoutePath.CMS_CONTENT_UPDATE.path.replace(":contentId", record.id));
    }

    render() {
        return (
            <div >
                <PageTitle title="CMS_CONTENT_ROUTE_CONTENT_DRAFTS"/>
                <Card>
                    <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                           dataSource={this.props.contents}
                           pagination={false}/>
                </Card>
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        contents: store.CmsContentReducer.contents,
        searchCondition: store.CmsContentReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(Index));