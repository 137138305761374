import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {WhiteSpace, NavBar, Icon, SearchBar, Button, Result, Form, Input} from 'antd-mobile';

import {getExamineeDetail} from "../../../../actions/ExamineeAction";
import {LeftOutline} from "antd-mobile-icons";
import {RoutePath} from "../../../../../../constants/RoutePath";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const {examinee} = this.props;
        const {formatMessage} = this.state;
        return (
            <div>
                <NavBar
                    icon={<LeftOutline type="left"/>}
                    onBack={() => this._handleBack()}><FormattedMessage
                    id="EXAM_EXAMINEE_TITLE_VERIFY_SUCCESS"/></NavBar>
                <Result
                    status='success'
                    title={formatMessage({id: "EXAM_EXAMINEE_MSG_VERIFY_SUCCESS"})}
                    description={formatMessage({id: "EXAM_EXAMINEE_MSG_VERIFY_SUCCESS_DESC"})}/>

                <Form
                    layout='horizontal'
                    footer={
                        <Button block color='primary' onClick={() => this._handleNext()}>
                            <FormattedMessage id="COMMON_BUTTON_NEXT_STEP"/>
                        </Button>
                    }
                >
                    <Form.Item label={formatMessage({id: "EXAM_EXAMINEE_FIELD_NAME"})}>
                        <Input value={examinee.lastName + examinee.firstName} readOnly={true}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "EXAM_EXAMINEE_FIELD_ID_NUMBER"})}>
                        <Input value={examinee.idNumber} readOnly={true}/>
                    </Form.Item>
                </Form>
            </div>
        );
    }

    _handleBack() {
        const examinationId = this.props.location.query.examinationId;
        this.props.router.push({
            pathname: RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY.path,
            query: {examinationId: examinationId}
        });
    }

    _handleNext() {
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.router.push({
            pathname: RoutePath.EXAM_PAPER_DETAIL.path,
            query: {examinationId: examinationId, examineeId: examineeId}
        })
    }
}

function mapStateToProps(store) {
    return {
        examinee: store.ExamExamineeReducer.examinee
    }
}

export default connect(mapStateToProps)(injectIntl(Index));