import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Button, Card, Table} from "antd";
import {MoneyFormat} from "../../../../../utils/NumberUtil";
import "./PaperReportShowParts.css"
import {AttentionAssessmentSubjectType, ExamDataModelType} from "../../../Enums";
import PaperReportChartShowParts from "./PaperReportChartShowParts";
import {doExportPaperReport} from "../../../actions/PaperAction";

class PaperReportSingleShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],

            columns: [
                {
                    title: "维度",
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => ({
                        children: text,
                        props: {rowSpan: index % 2 === 0 ? 2 : 0},
                    }),
                    width: 80,
                },
                {
                    title: "含义",
                    dataIndex: 'info',
                    key: 'info',
                    render: (text, record, index) => <div>{text}</div>,


                    // width: 200,
                },
                {
                    title: "得分",
                    dataIndex: 'scoreInModel',
                    key: 'scoreInModel',
                    render: (text, record, index) => ({
                        children: <div>{text}</div>,
                        props: {rowSpan: index % 2 === 0 ? 2 : 0},
                    }),
                    width: 80,
                },

                {
                    title: "能力描述",
                    dataIndex: 'desc',
                    key: 'desc',
                    render: (text, record, index) => ({
                        children: <div>{text}</div>,
                        // children: <div>{this._getDesc(record.scoreInModel)}</div>,
                        props: {rowSpan: index % 2 === 0 ? 2 : 0},
                    }),
                    width: 80,

                },
            ]
        }

    }

    componentWillMount() {

    }

    _getDesc(score) {
        if (score > 95) {
            return "非常优秀";
        } else if (score > 90) {
            return "优秀";
        } else if (score >= 70) {
            return "良好";
        } else if (score >= 40) {
            return "一般";
        } else if (score < 10) {
            return "较弱，有待提高";
        } else {
            return "较弱";
        }
    }

    _renderChatResultInfo(scoreResult, testerParts) {
        if(!scoreResult.antIsFinish){
            return null;
        }
        let resultMap = {};
        this._handleAddInLevel(scoreResult.antScore1InModel, "注意警觉", resultMap);
        this._handleAddInLevel(scoreResult.antScore2InModel, "注意定向", resultMap);
        this._handleAddInLevel(scoreResult.antScore3InModel, "注意控制", resultMap);
        // this._handleAddInLevel(scoreResult.goNoGoScoreInModel, "抗干扰", resultMap);
        // this._handleAddInLevel(scoreResult.digitalScoreInModel, "注意稳定性", resultMap);
        // this._handleAddInLevel(scoreResult.findItScoreInModel, "注意选择性", resultMap);
        return <div>
            {testerParts}的表现如图所示：
            {resultMap.level1 ?
                <div>表现<span style={{fontWeight: '600'}}>优秀</span>的注意力维度是:{resultMap.level1.join("、")}</div> : null}
            {resultMap.level2 ?
                <div>表现<span style={{ontWeight: '600'}}>良好</span>的注意力维度是:{resultMap.level2.join("、")}</div> : null}
            {resultMap.level3 ?
                <div>表现<span style={{color: 'red'}}>一般</span>的注意力维度是:{resultMap.level3.join("、")}</div> : null}
            {resultMap.level4 ?
                <div>表现<span style={{color: 'red'}}>较弱</span>的注意力维度是:{resultMap.level4.join("、")}</div> : null}
        </div>
    }

    _handleAddInLevel(score, label, resultMap) {
        let desc = this._getDesc(score);
        if (desc === '优秀' || desc === '非常优秀') {
            this._addInLevel(resultMap, "level1", label);
        } else if (desc === '良好') {
            this._addInLevel(resultMap, "level2", label);
        } else if (desc === '一般') {
            this._addInLevel(resultMap, "level3", label);
        } else if (desc.includes('较弱')) {
            this._addInLevel(resultMap, "level4", label);
        }
    }

    _addInLevel(resultMap, levelName, label) {
        if (resultMap[levelName]) {
            resultMap[levelName].push(label);
        }
        else {
            resultMap[levelName] = [label];
        }
    }

    _handleExport() {
        const paper = this.props.paper ? this.props.paper : {};

        const exportPaper = {id: paper.id, subjectType: this.props.subjectType};
        // if (this.paperReportChartShowParts) {
        //     exportPaper.imageData = this.paperReportChartShowParts.chartToDataURL();
        // }
        this.props.dispatch(doExportPaperReport(exportPaper, this.state.formatMessage))

    }

    render() {
        const formatMessage = this.state.formatMessage;
        const relatedExaminationSubjectList = this.props.relatedExaminationSubjectList ? this.props.relatedExaminationSubjectList : [];

        const paper = this.props.paper ? this.props.paper : {};


        const scoreResult = paper.scoreResult && paper.scoreResult !== "" ? JSON.parse(paper.scoreResult) : {};
        let dataList = [];


        let subjectType = this.props.subjectType;
        let dataTypeEnumInfo = {};
        if (AttentionAssessmentSubjectType.DateModelTypeMap[subjectType]) {
            dataTypeEnumInfo = AttentionAssessmentSubjectType.DateModelTypeMap[subjectType]
        }

        dataTypeEnumInfo.modelDataTypeList && dataTypeEnumInfo.modelDataTypeList.forEach(enumKey => {
            // let code = item.value;
            let showMapElement = ExamDataModelType.ShowMap[enumKey];
            let data = {};
            data.code = enumKey;
            data.name = showMapElement.scoreLabel;
            data.info = showMapElement.scoreInfo;

            data.score = MoneyFormat.formatThree(scoreResult[showMapElement.score]);
            data.isFinish = scoreResult[showMapElement.isFinish];

            if (data.isFinish) {
                let scoreResultElement = scoreResult[showMapElement.scoreInModel];
                if (scoreResultElement > 95) {
                    data.scoreInModel = "＞95分"
                } else if (scoreResultElement < 10) {
                    data.scoreInModel = "＜10分";
                } else {
                    data.scoreInModel = MoneyFormat.formatThree(scoreResultElement);
                }
                data.desc = this._getDesc(scoreResultElement);
            } else {
                data.scoreInModel = "-";
                data.desc = "-"
            }


            dataList.push(data);
            dataList.push({info: showMapElement.scoreInfo2});
        });
        let examinee = paper.examinee;
        let testerParts = null;
        if (examinee) {
            testerParts = <span className="examinee-name">{examinee.lastName + examinee.firstName}</span>;
        }


        return (

            <Card>

                <div className="paper-report-panel ">
                    <Button onClick={() => this._handleExport()}>导出报表</Button>
                    <h4 className="text-center">测评报告</h4>
                    <div className="report-paragraph">
                        {testerParts}家长，您好！本套测试将基于注意网络模型，评估儿童的注意力发展的{dataTypeEnumInfo.label1}维度。
                        报告中呈现的“得分”表示{testerParts}的注意力发展水平在同龄孩子中的所处的百分位置，
                        分数范围为0-100；“能力描述”是对其表现的评价，分为优秀(＞90分)、良好(70-90分)、一般(40-69分)、较弱(＜40分)四级。
                        例如，{testerParts}的{dataTypeEnumInfo.label2}得分是 80 分，则表明{testerParts}的{dataTypeEnumInfo.label2}能力比80%的同龄人都要好，表现良好。
                    </div>
                    <Table rowKey={(record, index) => index} bordered={true}
                           size="middle" className="spacing-v" columns={this.state.columns}
                           dataSource={dataList} pagination={false}/>
                    <br/>
                    {subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_PAY_ATTENTION ?
                        this._renderChatResultInfo(scoreResult, testerParts) : null}
                    {/*<PaperReportChartShowParts onRef={(ref) => this.paperReportChartShowParts = ref}/>*/}

                </div>

            </Card>
        );
    }

}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper,
        relatedExaminationSubjectList: store.ExamPaperReducer.relatedExaminationSubjectList,
    }
}

export default connect(mapStateToProps)(injectIntl(PaperReportSingleShowParts));