import * as ActionTypes from '../ActionTypes';

const initialState = {
    subjects: [],
    subject: {},
    searchCondition: {},
    pagination: {},
    showDetailModal: false,
    isLoading: false,
    allSubjectList: []
};

const SubjectReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_SUBJECT_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_SUBJECT_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.EXAM_SUBJECT_REFRESH_SUBJECT:
            return Object.assign({}, state, {
                subject: action.subject
            });
        case ActionTypes.EXAM_SUBJECT_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_SUBJECT_SEARCH_SUBJECTS:
            return Object.assign({}, state, {
                subjects: action.subjects,
                pagination: action.pagination
            });
        case ActionTypes.EXAM_SUBJECT_TOGGLE_CONTENT_ITEM_EDIT_MODAL:
            return Object.assign({}, state, {
                showContentItemEditModal: action.showContentItemEditModal
            });
        case ActionTypes.EXAM_SUBJECT_REFRESH_CONTENT_ITEM:
            return Object.assign({}, state, {
                contentItem: action.contentItem
            });
        case ActionTypes.EXAM_SUBJECT_SEARCH_SUBJECT_ALL_LIST:
            return Object.assign({}, state, {
                allSubjectList: action.allSubjectList
            });
        default:
            return state
    }
};

export default SubjectReducer;