import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import DetailModal from "./DetailModal";
import SearchCondition from "./SearchCondition";
import SearchResult from "./SearchResult";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        return (
            <div >
                <DetailModal/>
                <SearchCondition/>
                <SearchResult/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {

    }
}

export default connect(mapStateToProps)(injectIntl(Index));