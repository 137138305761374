import React, {Component} from 'react';
import {DeleteOutlined, FormOutlined} from '@ant-design/icons';

import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, Col, Row} from "antd";
import RectangleParts from "./RectangleParts";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {refreshSubject, refreshSubjectContentItem, toggleContentItemEditModal} from "../../../actions/SubjectAction";
import moment from "moment";
import SubjectContentEditModal from "./SubjectContentEditModal";
import {Rnd} from "react-rnd";
import './SubjectContentItemParts.css'
import {AttentionAssessmentSubjectType, SubjectImagePageIndex} from "../../../Enums";
import ParseUtils from "../../../../../utils/ParseUtils";

class SubjectContentShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    // _handleMoveItem(event, currentPageItem) {
    //     currentPageItem = Object.assign({}, currentPageItem);
    //     currentPageItem.positionX = event.x;
    //     currentPageItem.positionY = event.y;
    //
    //     let pptPage = Object.assign({}, this.props.pptPage);
    //     let itemList = Object.assign([], pptPage.itemList);
    //     if (currentPageItem.zOrder === undefined) {
    //         currentPageItem.zOrder = itemList.length;
    //         itemList.push(currentPageItem);
    //     } else {
    //         itemList[currentPageItem.zOrder] = currentPageItem;
    //     }
    //     pptPage.itemList = itemList;
    //
    //     this.props.dispatch(refreshCurrentPage(pptPage));
    // }
    //
    // _handleResizeItem(style, currentPageItem) {
    //     currentPageItem = Object.assign({}, currentPageItem);
    //     currentPageItem.width = parseFloat(style.width.replace("px", ""));
    //     currentPageItem.height = parseFloat(style.height.replace("px", ""));
    //
    //     let pptPage = Object.assign({}, this.props.pptPage);
    //     let itemList = Object.assign([], pptPage.itemList);
    //     if (currentPageItem.zOrder === undefined) {
    //         currentPageItem.zOrder = itemList.length;
    //         itemList.push(currentPageItem);
    //     } else {
    //         itemList[currentPageItem.zOrder] = currentPageItem;
    //     }
    //     pptPage.itemList = itemList;
    //
    //     this.props.dispatch(refreshCurrentPage(pptPage));
    // }
    //
    //
    //
    // _handleDeleteItem(currentPageItem) {
    //
    //     let pptPage = Object.assign({}, this.props.pptPage);
    //     let itemList = Object.assign([], pptPage.itemList);
    //     itemList.splice(currentPageItem.zOrder, 1)
    //     pptPage.itemList = itemList.map((item, index) => {
    //         let newItem = Object.assign({}, item);
    //         newItem.zOrder = index;
    //         return newItem;
    //     });
    //     this.props.dispatch(refreshCurrentPage(pptPage));
    // }
    _handleAddItem() {
        let currentContentItem = {key: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE),
            pageIndex:SubjectImagePageIndex.Enum.PAGE3};

        this.props.dispatch(refreshSubjectContentItem(currentContentItem));
        this.props.dispatch(toggleContentItemEditModal(true));

    }

    _handleModifyItem(item) {
        // let currentContentItem = {key: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE)};

        this.props.dispatch(refreshSubjectContentItem(item));
        this.props.dispatch(toggleContentItemEditModal(true));

    }

    _handleDeleteItem(index) {
        // let currentContentItem = {key: moment().format(FORMAT_DATE_TIME_FULL_SIMPLE)};


        let subject = Object.assign({}, this.props.subject);
        let contentObject = Object.assign({}, subject.contentObject);
        let itemList = Object.assign([], contentObject.items);


        itemList.splice(index, 1);

        contentObject.items = itemList;
        subject.contentObject = contentObject;
        this.props.dispatch(refreshSubject(subject));

    }

    render() {

        let formatMessage = this.state.formatMessage;
        let subject = this.props.subject;
        let contentObject = subject.contentObject ? subject.contentObject : {};
        let itemList = contentObject.items ? contentObject.items : [];
        let imageElements = document.getElementsByClassName("content-item-image");
        let height = "107px";
        let width = "671px";
        let pageIndexList =SubjectImagePageIndex.List;
        if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT||
            subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL) {
            height = "132px";
            width = "167px";
            pageIndexList = SubjectImagePageIndex.FindItList;
        }else if (subject.type === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL) {
            height = "89px";
            width = "1090px";

        }
        let pageMap = {};
        itemList.forEach(item => {
            if (pageMap[item.pageIndex]) {
                pageMap[item.pageIndex] = pageMap[item.pageIndex] + 1
            } else {
                pageMap[item.pageIndex] = 1;
            }
        });

        return <div >
            <SubjectContentEditModal/>
            {pageIndexList.map((pageItem) => {
                return <span>{formatMessage({id: pageItem.label})}({pageMap[pageItem.value] ? pageMap[pageItem.value] : "0"}) &nbsp;</span>
            })}
            <Button type='primary' onClick={() => this._handleAddItem()}>新增</Button>
            <br/>
            <div style={{height: "500px", overflow: 'auto'}}>
            {itemList.map((item, index) => {

                let currentPageStyle = {};
                if (item.imagePath && item.imagePath !== '') {
                    currentPageStyle.backgroundImage = `url('${item.imagePath }')`;
                }
                let rectangleList = item.answerKeys ? item.answerKeys : [];
                let mapElement = SubjectImagePageIndex.Map[item.pageIndex];
                return <Row className="content-item-line">
                    <Col span={22}>
                        <div className="content-item-show-penal" style={{height: height, width: width}}>
                            <img src={item.imagePath} className="content-item-image"
                                 style={{height: height, width: width}}>
                            </img>
                            <div className="answer-rectangle-content" style={{height: height, width: width}}>
                                {rectangleList.map((rectangle, index) => {
                                    return <Rnd
                                        size={{width: rectangle.width, height: rectangle.height}}
                                        position={{x: rectangle.x, y: rectangle.y}}
                                        enableResizing={false}
                                        disableDragging={false}>
                                        <RectangleParts rectangleItem={rectangle}/>
                                    </Rnd>
                                })}
                            </div>
                        </div>
                    </Col>

                    <Col span={2}>
                       {mapElement?formatMessage({id: mapElement}):" 第"+(item.pageIndex)+"页"}
                       <br/>
                        <Button type='primary' size="small" onClick={() => this._handleModifyItem(item)}>修改</Button>
                        <br/>
                        <Button size="small"
                                onClick={() => this._handleDeleteItem(index)}>删除</Button>
                    </Col>
                </Row>
            })}</div>
        </div>
    }
}

function mapStateToProps(store) {
    return {
        // currentPptPageItem: store.WMSResearchPptReducer.currentPptPageItem,
        subject: store.ExamSubjectReducer.subject


    }
}

export default connect(mapStateToProps)(injectIntl(SubjectContentShow));