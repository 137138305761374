import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Form, Input, NavBar} from "antd-mobile";
import {getExamination} from "../../../actions/ExaminationAction";

import moment from "moment";
import {FORMAT_DATE_TIME_SIMPLE, FORMAT_DATE_TIME_SLASH} from "../../../../../constants/DateTimeFormats";
import {ExaminationStatus} from "../../../Enums";
import {RoutePath} from "../../../../../constants/RoutePath";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        // console.log(this.props.location.query.id);
        this.props.dispatch(getExamination(this.props.location.query.id, this.state.formatMessage));
    }

    componentDidMount() {
    }

    _handleClickVerify() {
        this.props.router.push({
            pathname: RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY.path,
            query: {examinationId: this.props.location.query.id}
        });
    }

    render() {
        const {formatMessage} = this.state;
        const {examination} = this.props;
        return (
            <div>
                <NavBar><FormattedMessage id="EXAM_EXAMINATION_TITLE_INFORMATION"/></NavBar>
                <Form
                    layout='horizontal'
                    footer={
                        <Button block type='submit' color='primary'
                                onClick={() => this._handleClickVerify()}>
                            <FormattedMessage id="EXAM_EXAMINATION_BUTTON_VERIFY"/>
                        </Button>
                    }
                >
                    <Form.Item label={formatMessage({id: "EXAM_EXAMINATION_FIELD_TITLE"})}>
                        <Input value={examination.title} readOnly={true}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "EXAM_EXAMINATION_FIELD_START_TIME"})}>
                        <Input
                            value={moment(examination.startTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_SLASH)}
                            readOnly={true}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "EXAM_EXAMINATION_FIELD_END_TIME"})}>
                        <Input
                            value={moment(examination.endTime, FORMAT_DATE_TIME_SIMPLE).format(FORMAT_DATE_TIME_SLASH)}
                            readOnly={true}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "EXAM_EXAMINATION_FIELD_STATUS"})}>
                        <Input value={formatMessage({id: ExaminationStatus.Map[examination.status]})} readOnly={true}/>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        examination: store.ExamExaminationReducer.examination
    }
}

export default connect(mapStateToProps)(injectIntl(Index));