import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Divider, Popconfirm, Table} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";
import {
    deleteExamination,
    getExamination,
    refreshExamination,
    searchExaminationPeriodList,
    toggleDetailModal,
    togglePublishModal,
    toggleSubjectsModal
} from "../../../actions/ExaminationAction";
import {refreshSearchCondition, searchExaminationSubjects} from "../../../actions/ExaminationSubjectAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_PERIOD_FIELD_CODE'}),
                    dataIndex: 'code',
                    key: 'code'
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_PERIOD_FIELD_TITLE'}),
                    dataIndex: 'title',
                    key: 'title'
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_PERIOD_FIELD_SUMMARY'}),
                    dataIndex: 'summary',
                    key: 'summary'
                },
                // {
                //     title: formatMessage({id: 'EXAM_CLASS_FIELD_TYPE'}),
                //     dataIndex: 'classify',
                //     key: 'classify',
                //     render: (text, record, index) =>record.classify&& formatMessage({id: ExamPaperType.Map[record.classify]})
                // },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_STATUS'}),
                //     dataIndex: 'status',
                //     key: 'status',
                //     render: (text, record, index) => formatMessage({id: ExaminationStatus.Map[record.status]})
                // },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_START_TIME'}),
                //     key: 'startTime',
                //     dataIndex: 'startTime',
                //     render: (text, record, index) => record.startTime ? moment(record.startTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN) : null
                // },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_END_TIME'}),
                //     key: 'endTime',
                //     dataIndex: 'endTime',
                //     render: (text, record, index) => record.endTime ? moment(record.endTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE).format(DateTimeFormats.FORMAT_DATE_TIME_HYPHEN) : null
                // },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                <Divider type="vertical"/>
                <a onClick={this._handleShowSubjects.bind(this, record)}>
                    <FormattedMessage id="EXAM_EXAMINATION_FIELD_SUBJECTS"/>
                </a>
            </div>
        )
    }

    _handlePublish(record) {
        //处理题目为空的情况
        const searchCondition = {examinationId: record.id};
        this.props.dispatch(searchExaminationSubjects(searchCondition, this.state.formatMessage));

        this.props.dispatch(refreshExamination(record));
        this.props.dispatch(togglePublishModal(true));
    }

    _handleShowSubjects(record) {
        const searchCondition = Object.assign({}, this.props.subjectSearchCondition);
        searchCondition.examinationId = record.id;
        this.props.dispatch(refreshExamination(record));
        this.props.dispatch(refreshSearchCondition(searchCondition));
        this.props.dispatch(searchExaminationSubjects(searchCondition, this.state.formatMessage));
        this.props.dispatch(toggleSubjectsModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteExamination(record.id, searchCondition, formatMessage));
    }

    _handleModify(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(getExamination(record.id, formatMessage));
        dispatch(toggleDetailModal(true));
    }

    render() {
        return (
            <div className="spacing-v">

                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey={(record) => record.id} size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.examinationPeriodList} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchExaminationPeriodList(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        examinationPeriodList: store.ExamExaminationReducer.examinationPeriodList,
        searchCondition: store.ExamExaminationReducer.periodSearchCondition,
        pagination: store.ExamExaminationReducer.periodPagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));