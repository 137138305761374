import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Form, Input, List, NavBar, NoticeBar, Space} from "antd-mobile";
import {getExamination} from "../../../../actions/ExaminationAction";

import moment from "moment";
import {FORMAT_DATE_TIME_SIMPLE, FORMAT_DATE_TIME_SLASH} from "../../../../../../constants/DateTimeFormats";
import {ExaminationStatus} from "../../../../Enums";
import {RoutePath} from "../../../../../../constants/RoutePath";
import {getContentAnonymous} from "../../../../../CMS/actions/ContentAction";
import {PlayOutline} from "antd-mobile-icons";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        this.props.dispatch(getContentAnonymous("EXAM_VIDEO", this.state.formatMessage));
    }

    _handlePlay(item, index) {
        const examinationId = this.props.location.query.examinationId;
        const examineeId = this.props.location.query.examineeId;
        this.props.router.push({
            pathname: RoutePath.ZHUOLI_VIDEO_PLAY.path,
            query: {
                examinationId: examinationId,
                examineeId: examineeId,
                fileId: item.id
            }
        });
    }

    render() {
        const {formatMessage} = this.state;
        const {content} = this.props;
        return (
            <div>
                <NavBar>{content.title}</NavBar>
                <Space block direction='vertical'>
                    <NoticeBar content={content.summary} color='info'/>
                    <List>
                        {
                            content.files && content.files.map((item, index) => {
                                return <List.Item clickable extra='待播放'
                                                  onClick={() => this._handlePlay(item, index)}>{item.name}</List.Item>
                            })
                        }
                    </List>
                    <div className="p-5">
                        <Button block color='primary' onClick={() => this._handleNext()}>
                            <FormattedMessage id="COMMON_BUTTON_NEXT_STEP"/>
                        </Button>
                    </div>
                </Space>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        content: store.CmsContentReducer.content
    }
}

export default connect(mapStateToProps)(injectIntl(Index));