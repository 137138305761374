// 题库
export const EXAM_BANK_LOADING = "EXAM_BANK_LOADING";
export const EXAM_BANK_TOGGLE_DETAIL_MODAL = "EXAM_BANK_TOGGLE_DETAIL_MODAL";
export const EXAM_BANK_REFRESH_BANK = 'EXAM_BANK_REFRESH_BANK';
export const EXAM_BANK_REFRESH_SEARCH_CONDITION = 'EXAM_BANK_REFRESH_SEARCH_CONDITION';
export const EXAM_BANK_SEARCH_BANK = 'EXAM_BANK_SEARCH_BANK';
export const EXAM_BANK_TOGGLE_SUBJECT_SORT_MODAL = "EXAM_BANK_TOGGLE_SUBJECT_SORT_MODAL";
export const EXAM_BANK_SUBJECT_SEARCH_SUBJECT_SORT_LIST = "EXAM_BANK_SUBJECT_SEARCH_SUBJECT_SORT_LIST";

// 题目
export const EXAM_SUBJECT_LOADING = "EXAM_SUBJECT_LOADING";
export const EXAM_SUBJECT_SEARCH_SUBJECTS = "EXAM_SUBJECT_SEARCH_SUBJECTS";
export const EXAM_SUBJECT_REFRESH_SUBJECT = "EXAM_SUBJECT_REFRESH_SUBJECT";
export const EXAM_SUBJECT_REFRESH_SEARCH_CONDITION = "EXAM_SUBJECT_REFRESH_SEARCH_CONDITION";
export const EXAM_SUBJECT_TOGGLE_DETAIL_MODAL = "EXAM_SUBJECT_TOGGLE_DETAIL_MODAL";
export const EXAM_SUBJECT_TOGGLE_CONTENT_ITEM_EDIT_MODAL = "EXAM_SUBJECT_TOGGLE_CONTENT_EDIT_MODAL";
export const EXAM_SUBJECT_REFRESH_CONTENT_ITEM = "EXAM_SUBJECT_REFRESH_CONTENT_ITEM";
export const EXAM_SUBJECT_REFRESH_CURRENT_RECTANGLE_ITEM = "EXAM_SUBJECT_REFRESH_CURRENT_RECTANGLE_ITEM";
export const EXAM_SUBJECT_SEARCH_SUBJECT_ALL_LIST = "EXAM_SUBJECT_SEARCH_SUBJECT_ALL_LIST";


// 测评活动
export const EXAM_EXAMINATION_LOADING = "EXAM_EXAMINATION_LOADING";
export const EXAM_EXAMINATION_INIT_EXAMINATION = 'EXAM_EXAMINATION_INIT_EXAMINATION';
export const EXAM_EXAMINATION_REFRESH_EXAMINATION = 'EXAM_EXAMINATION_REFRESH_EXAMINATION';
export const EXAM_EXAMINATION_REFRESH_SEARCH_CONDITION = 'EXAM_EXAMINATION_REFRESH_SEARCH_CONDITION';
export const EXAM_EXAMINATION_SEARCH_EXAMINATION = 'EXAM_EXAMINATION_SEARCH_EXAMINATION';
export const EXAM_EXAMINATION_TOGGLE_DETAIL_MODAL = "EXAM_EXAMINATION_TOGGLE_DETAIL_MODAL";
export const EXAM_EXAMINATION_TOGGLE_SUBJECTS_MODAL = "EXAM_EXAMINATION_TOGGLE_SUBJECTS_MODAL";
export const EXAM_EXAMINATION_TOGGLE_PUBLISH_MODAL = "EXAM_EXAMINATION_TOGGLE_PUBLISH_MODAL";


//课时
export const EXAM_EXAMINATION_TOGGLE_PERIOD_MODAL = "EXAM_EXAMINATION_TOGGLE_PERIOD_MODAL";

export const EXAM_EXAMINATION_REFRESH_PERIOD_SEARCH_CONDITION = 'EXAM_EXAMINATION_REFRESH_PERIOD_SEARCH_CONDITION';
export const EXAM_EXAMINATION_REFRESH_EXAMINATION_PERIOD ='EXAM_EXAMINATION_REFRESH_EXAMINATION_PERIOD';
export const EXAM_EXAMINATION_SEARCH_EXAMINATION_PERIOD_LIST ='EXAM_EXAMINATION_SEARCH_EXAMINATION_PERIOD_LIST';
export const EXAM_EXAMINATION_INIT_EXAMINATION_PERIOD ='EXAM_EXAMINATION_INIT_EXAMINATION_PERIOD';




//课程模版管理管理
export const EXAM_LESSON_TEMPLATE_LOADING = "EXAM_LESSON_TEMPLATE_LOADING";
export const EXAM_LESSON_TEMPLATE_INIT_LESSON = 'EXAM_LESSON_TEMPLATE_INIT_LESSON';
export const EXAM_LESSON_TEMPLATE_REFRESH_LESSON_TEMPLATE = 'EXAM_LESSON_TEMPLATE_REFRESH_LESSON_TEMPLATE';
export const EXAM_LESSON_TEMPLATE_REFRESH_SEARCH_CONDITION = 'EXAM_LESSON_TEMPLATE_REFRESH_SEARCH_CONDITION';
export const EXAM_LESSON_TEMPLATE_SEARCH_LESSON_TEMPLATE_LIST = 'EXAM_LESSON_TEMPLATE_SEARCH_LESSON_TEMPLATE_LIST';
export const EXAM_LESSON_TEMPLATE_TOGGLE_DETAIL_MODAL = "EXAM_LESSON_TEMPLATE_TOGGLE_DETAIL_MODAL";
export const EXAM_LESSON_TEMPLATE_TOGGLE_PUBLISH_MODAL = "EXAM_LESSON_TEMPLATE_TOGGLE_PUBLISH_MODAL";
export const EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_LIST = "EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_LIST";
export const EXAM_LESSON_TEMPLATE_TOGGLE_EXAMINATION_SELECT_MODAL = "EXAM_LESSON_TEMPLATE_TOGGLE_EXAMINATION_SELECT_MODAL";
export const EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_ID_SELECTED_LIST = "EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_ID_SELECTED_LIST";
export const EXAM_LESSON_TEMPLATE_REFRESH_ALL_LIST = "EXAM_LESSON_TEMPLATE_REFRESH_ALL_LIST";

//课程
export const EXAM_EXAMINEE_LESSON_LOADING = "EXAM_EXAMINEE_LESSON_LOADING";
export const EXAM_EXAMINEE_LESSON_INIT_LESSON = 'EXAM_EXAMINEE_LESSON_INIT_LESSON';
export const EXAM_EXAMINEE_LESSON_REFRESH_LESSON = 'EXAM_EXAMINEE_LESSON_REFRESH_LESSON';
export const EXAM_EXAMINEE_LESSON_REFRESH_SEARCH_CONDITION = 'EXAM_EXAMINEE_LESSON_REFRESH_SEARCH_CONDITION';
export const EXAM_EXAMINEE_LESSON_SEARCH_LESSON_LIST = 'EXAM_EXAMINEE_LESSON_SEARCH_LESSON_LIST';
export const EXAM_EXAMINEE_LESSON_TOGGLE_DETAIL_MODAL = "EXAM_EXAMINEE_LESSON_TOGGLE_DETAIL_MODAL";
export const EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_LIST = "EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_LIST";
export const EXAM_EXAMINEE_LESSON_TOGGLE_CHANGE_LESSON_SUBJECT_MODAL = "EXAM_EXAMINEE_LESSON_TOGGLE_CHANGE_LESSON_SUBJECT_MODAL";
export const EXAM_EXAMINEE_LESSON_REFRESH_CHANGE_LESSON_SUBJECT_INFO = "EXAM_EXAMINEE_LESSON_REFRESH_CHANGE_LESSON_SUBJECT_INFO";

export const EXAM_EXAMINEE_LESSON_TOGGLE_EXAMINATION_SELECT_MODAL = "EXAM_EXAMINEE_LESSON_TOGGLE_EXAMINATION_SELECT_MODAL";
export const EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_ID_SELECTED_LIST = "EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_ID_SELECTED_LIST";
export const EXAM_EXAMINEE_LESSON_REFRESH_ALL_EXAMINEE_LIST = "EXAM_EXAMINEE_LESSON_REFRESH_ALL_EXAMINEE_LIST";

export const EXAM_EXAMINEE_LESSON_REFRESH_RELATED_PAPER_LIST = "EXAM_EXAMINEE_LESSON_REFRESH_RELATED_PAPER_LIST";
export const EXAM_EXAMINEE_LESSON_REFRESH_RELATED_EXAMINATION_LIST = "EXAM_EXAMINEE_LESSON_REFRESH_RELATED_EXAMINATION_LIST";


// 测评题目
export const EXAM_EXAMINATION_SUBJECT_LOADING = "EXAM_EXAMINATION_SUBJECT_LOADING";
export const EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECTS = "EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECTS";
export const EXAM_EXAMINATION_SUBJECT_REFRESH_SUBJECT = "EXAM_EXAMINATION_SUBJECT_REFRESH_SUBJECT";
export const EXAM_EXAMINATION_SUBJECT_REFRESH_SEARCH_CONDITION = "EXAM_EXAMINATION_SUBJECT_REFRESH_SEARCH_CONDITION";
export const EXAM_EXAMINATION_SUBJECT_TOGGLE_DETAIL_MODAL = "EXAM_EXAMINATION_SUBJECT_TOGGLE_DETAIL_MODAL";
export const EXAM_EXAMINATION_SUBJECT_TOGGLE_IMPORT_MODAL = "EXAM_EXAMINATION_SUBJECT_TOGGLE_IMPORT_MODAL";
export const EXAM_EXAMINATION_SUBJECT_TOGGLE_SUBJECT_SORT_MODAL = "EXAM_EXAMINATION_SUBJECT_TOGGLE_SUBJECT_SORT_MODAL";
export const EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECT_SORT_LIST = "EXAM_EXAMINATION_SUBJECT_SEARCH_SUBJECT_SORT_LIST";

// // 测评考题
// export const EXAM_EXAMINATION_BANK_LOADING = "EXAM_EXAMINATION_BANK_LOADING";
// export const EXAM_EXAMINATION_BANK_REFRESH_BANKS = "EXAM_EXAMINATION_BANK_REFRESH_BANKS";

// 考卷
export const EXAM_PAPER_LOADING = "EXAM_PAPER_LOADING";
export const EXAM_PAPER_REFRESH_PAPER = "EXAM_PAPER_REFRESH_PAPER";
export const EXAM_PAPER_SEARCH_PAPER = "EXAM_PAPER_SEARCH_PAPER";
export const EXAM_PAPER_REFRESH_SEARCH_CONDITION = 'EXAM_PAPER_REFRESH_SEARCH_CONDITION';
export const EXAM_PAPER_TOGGLE_MODAL = "EXAM_PAPER_TOGGLE_MODAL";
export const EXAM_PAPER_TOGGLE_PAPER_SUBJECT_MODIFY_MODAL = "EXAM_PAPER_TOGGLE_PAPER_SUBJECT_MODIFY_MODAL";
export const EXAM_PAPER_REFRESH_SUBJECT = 'EXAM_PAPER_REFRESH_SUBJECT';

export const EXAM_PAPER_REFRESH_RELATED_EXAM_SUBJECT_LIST = "EXAM_PAPER_REFRESH_RELATED_EXAM_SUBJECT_LIST";


// 测评题目
export const EXAM_PAPER_SUBJECT_LOADING = "EXAM_PAPER_SUBJECT_LOADING";
export const EXAM_PAPER_SUBJECT_REFRESH_SUBJECTS = "EXAM_PAPER_SUBJECT_REFRESH_SUBJECTS";
export const EXAM_PAPER_SUBJECT_REFRESH_SUBJECT = "EXAM_PAPER_SUBJECT_REFRESH_SUBJECT";
export const EXAM_PAPER_SUBJECT_REFRESH_CURRENT_INDEX = "EXAM_PAPER_SUBJECT_REFRESH_CURRENT_INDEX";
export const EXAM_PAPER_SUBJECT_TOGGLE_MODAL = "EXAM_PAPER_SUBJECT_TOGGLE_MODAL";

// 学员
export const EXAM_EXAMINEE_LOADING = "EXAM_EXAMINEE_LOADING";
export const EXAM_EXAMINEE_SEARCH_EXAMINEE = "EXAM_EXAMINEE_SEARCH_EXAMINEE";
export const EXAM_EXAMINEE_REFRESH_EXAMINEE = "EXAM_EXAMINEE_REFRESH_EXAMINEE";
export const EXAM_EXAMINEE_REFRESH_IMPORT_RESULT = "EXAM_EXAMINEE_REFRESH_IMPORT_RESULT";
export const EXAM_EXAMINEE_REFRESH_SEARCH_CONDITION = 'EXAM_EXAMINEE_REFRESH_SEARCH_CONDITION';
export const EXAM_EXAMINEE_TOGGLE_MODAL = "EXAM_EXAMINEE_TOGGLE_MODAL";
export const EXAM_EXAMINEE_TOGGLE_IMPORT_MODAL = "EXAM_EXAMINEE_TOGGLE_IMPORT_MODAL";
export const EXAM_EXAMINEE_SEARCH_EXAMINEE_ALL_LIST = "EXAM_EXAMINEE_SEARCH_EXAMINEE_ALL_LIST";

// 常模
export const EXAM_DATA_MODEL_LOADING = "EXAM_DATA_MODEL_LOADING";
export const EXAM_DATA_MODEL_TOGGLE_DETAIL_MODAL = "EXAM_DATA_MODEL_TOGGLE_DETAIL_MODAL";
export const EXAM_DATA_MODEL_REFRESH_DATA_MODEL = 'EXAM_DATA_MODEL_REFRESH_DATA_MODEL';
export const EXAM_DATA_MODEL_REFRESH_SEARCH_CONDITION = 'EXAM_DATA_MODEL_REFRESH_SEARCH_CONDITION';
export const EXAM_DATA_MODEL_SEARCH_LIST = 'EXAM_DATA_MODEL_SEARCH_LIST';
export const EXAM_DATA_MODEL_SEARCH_PAPER_LIST = "EXAM_DATA_MODEL_SEARCH_PAPER_LIST";
export const EXAM_DATA_MODEL_SEARCH_PAPER_SEARCH_CONDITION = "EXAM_DATA_MODEL_SEARCH_PAPER_SEARCH_CONDITION";

export const EXAM_DATA_MODEL_REFRESH_PAPER = "EXAM_DATA_MODEL_REFRESH_PAPER";

export const EXAM_DATA_MODEL_TOGGLE_PAPER_DATA_MODEL_MODAL = "EXAM_DATA_MODEL_TOGGLE_PAPER_DATA_MODEL_MODAL";
