import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Result} from "antd";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const {formatMessage} = this.state;
        return (
            <Result
                status="403"
                title="403"
                subTitle={formatMessage({id: "COMMON_MSG_EXCEPTION_403"})}
                extra={<Button type="primary"><FormattedMessage id="COMMON_BUTTON_HOME"/></Button>}
            />
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));