/**
 * Created by wangyixian on 2018/6/19.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Table, Divider, Popconfirm, Modal} from 'antd';
import PaginationBar from "../../../../../components/PaginationBar";
import {
    deleteRole,
    getRole,
    getRoleResources, refreshRole,
    searchRoles,
    toggleDetailModal,
    togglePermissionModal
} from "../../../actions/RoleAction";
import {getResourcesByRoleId, getResourceTree} from "../../../actions/ResourceAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'ACCOUNT_ROLE_FIELD_CODE'}),
                    dataIndex: 'code',
                    key: 'code'
                },
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_ROLE'}),
                    dataIndex: 'name',
                    key: 'name'
                },
                {
                    title: formatMessage({id: 'ACCOUNT_ROLE_FIELD_SEQUENCE'}),
                    dataIndex: 'sequence',
                    key: 'sequence'
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {

        const {formatMessage} = this.state;
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                            cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                <Divider type="vertical"/>
                <a onClick={this._handleShowPermissionModal.bind(this, record)}>
                    <FormattedMessage id="ACCOUNT_ROLE_BUTTON_PERMISSION"/>
                </a>
            </div>
        )
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getRole(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleShowPermissionModal(record) {
        const {formatMessage} = this.state;
        this.props.dispatch(refreshRole(record));
        this.props.dispatch(getResourcesByRoleId(record.id, formatMessage));
        this.props.dispatch(getResourceTree(formatMessage));
        this.props.dispatch(togglePermissionModal(true));
    }

    _handleDelete(record) {
        this.props.dispatch(deleteRole(record, this.props.searchCondition, this.state.formatMessage));
    }

    render() {
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.roles} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchRoles(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        roles: store.AccountRoleReducer.roles,
        searchCondition: store.AccountRoleReducer.searchCondition,
        pagination: store.AccountRoleReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));