import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Button, Card, Table, Tag} from "antd";
import {handInPaper} from "../../../actions/PaperAction";
import {AttentionAssessmentSubjectType, getEnumsLabel} from "../../../Enums";
import LessonSubjectReport from "./LessonSubjectReport";
import ChangeLessonSubjectShowParts from "./ChangeLessonSubjectShowParts";

class LessonSubjectShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedType: "",
            columns: [
                {
                    title: "上机任务",
                    dataIndex: 'subjectType',
                    key: 'subjectType',
                    render: (text, record) => {
                        return getEnumsLabel(text, AttentionAssessmentSubjectType.Map, formatMessage)
                    }
                },

                {
                    title: "完成情况",
                    dataIndex: 'status',
                    key: 'status',
                    render: (text, record) => {

                        return (record.paperCount ? record.paperCount : 0) + " / " + record.examinationList.length;
                    }
                },
                {
                    title: "完成情况",
                    dataIndex: 'status2',
                    key: 'status2',
                    render: (text, record) => {
                        let examinationList = record.examinationList;
                        return <div> {examinationList.map(thisExam => {
                            let subInfo = null;
                            let tagColor = "green";
                            let scoreInfo = thisExam.scoreInfo;
                            if (scoreInfo) {
                                tagColor = "#21d00a";
                                if (scoreInfo.isUpStandard) {
                                    tagColor = "#f48b0f";
                                    subInfo = "(已达标)";
                                }
                            }
                            return <Tag key={thisExam.name} color={tagColor}>{thisExam.name}{subInfo}</Tag>
                        })}</div>

                    }
                },

                {
                    title: "反馈报告",
                    dataIndex: 'scoreInModel',
                    key: 'scoreInModel',
                    render: (text, record) => {
                        if (this.state.selectedType === record.subjectType) {
                            return <Tag color="cyan">当前报告</Tag>
                        }
                        else {
                            return <Button size={"small"} type={'primary'}
                                           onClick={() => this.setState({selectedType: record.subjectType})}>选择</Button>
                        }
                    }
                },
            ]
        }

    }


    componentWillMount() {

    }


    render() {
        const formatMessage = this.state.formatMessage;
        const examineeLesson = this.props.examineeLesson ? this.props.examineeLesson : {};
        let examinationIds = examineeLesson.examinationIds;
        let examinee = examineeLesson.examinee ? examineeLesson.examinee : {};
        let examinationIdList = examinationIds ? examinationIds.split(",") : [];


        const relatedExaminationList = this.props.relatedExaminationList ? this.props.relatedExaminationList : [];
        let subjectTypeSummaryMap = {};
        relatedExaminationList.forEach(examination => {

            let subjectList = examination.subjects;
            subjectList.forEach(examSubject => {
                let thisExam = {id: examination.id, name: examination.title};
                let subjectType = examSubject.type;
                if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION ||
                    subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING ||
                    subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT) {
                    subjectType = subjectType + examSubject.difficultyLevel;
                }
                let subjectSummary = subjectTypeSummaryMap[subjectType];
                if (subjectSummary) {
                    subjectSummary.examinationList.push(thisExam);
                    subjectSummary.examinationMap[thisExam.id] = thisExam;
                } else {
                    subjectSummary = {};
                    subjectSummary.subjectType = subjectType;
                    subjectSummary.examinationList = [thisExam];
                    subjectSummary.examinationMap = {};
                    subjectSummary.examinationMap[thisExam.id] = thisExam;
                    subjectTypeSummaryMap[subjectType] = subjectSummary;
                }
            })
        });


        const relatedPaperList = this.props.relatedPaperList ? this.props.relatedPaperList : [];

        relatedPaperList.forEach(paper => {

            let scoreResultStr = paper.scoreResult;
            const scoreResult = scoreResultStr && scoreResultStr !== "" ? JSON.parse(scoreResultStr) : {};

            Object.keys(scoreResult).forEach(subjectType => {
                let scoreInfo = Object.assign({}, scoreResult[subjectType]);
                let subjectSummary = subjectTypeSummaryMap[subjectType];
                console.log(subjectType);
                if (subjectType.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION) ||
                    subjectType.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING) ||
                    subjectType.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT)) {
                    scoreInfo.scoreValue = scoreInfo.completeCount ? Math.round(scoreInfo.rightCount * 10000 / scoreInfo.completeCount) / 100 : 0;
                } else {
                    scoreInfo.scoreValue = scoreInfo.totalCount ? Math.round(scoreInfo.rightCount * 10000 / scoreInfo.totalCount) / 100 : 0;
                }

                scoreInfo.timeAvg = scoreInfo.rightAnswerCount ? Math.round(scoreInfo.rightTimeAmount / scoreInfo.rightAnswerCount) : 0;
                if (subjectSummary) {
                    let levelElement = AttentionAssessmentSubjectType.TrainingCheckScoreLevel[subjectType];
                    if (levelElement && levelElement.grade2 < scoreInfo.scoreValue) {
                        scoreInfo.isUpStandard = true;
                    }
                    let examinationMap = subjectSummary.examinationMap;
                    subjectSummary.paperCount = subjectSummary.paperCount ? subjectSummary.paperCount + 1 : 1;
                    let examSummary = examinationMap[paper.examinationId];
                    if (examSummary) {
                        examSummary.scoreInfo = scoreInfo;

                    }
                }

            })
        });
        let dataList = [];
        let checkLevelList = [];
        let needAdjustChangeFlag = false;
        let canChangeSubjectMap = {};
        AttentionAssessmentSubjectType.TrainingShowList.forEach(type => {
            let subjectSummary = subjectTypeSummaryMap[type];
            if (subjectSummary) {
                dataList.push(subjectSummary);

                let firstScore;
                let laseScore;
                let examinationList = subjectSummary.examinationList;

                examinationList.forEach(examSummary => {
                    if (examSummary.scoreInfo) {
                        if (firstScore === undefined) {
                            firstScore = examSummary.scoreInfo;
                        }
                        laseScore = examSummary.scoreInfo;
                    }
                });


                subjectSummary.firstScore = 0;
                subjectSummary.lastScore = 0;
                subjectSummary.firstTimeAvg = 0;
                subjectSummary.lastTimeAvg = 0;
                if (firstScore) {
                    subjectSummary.firstScore = firstScore.scoreValue;
                    subjectSummary.firstTimeAvg = firstScore.timeAvg;
                }
                if (laseScore) {
                    subjectSummary.lastScore = laseScore.scoreValue;
                    subjectSummary.lastTimeAvg = laseScore.timeAvg;

                }

                if (AttentionAssessmentSubjectType.TrainingChangeSubjectList[type]) {
                    let notPaperCount = examinationList.length - subjectSummary.paperCount;

                    if (notPaperCount > 0) {
                        subjectSummary.isUpStandard = examinationList.some(examSummary => examSummary.scoreInfo && examSummary.scoreInfo.isUpStandard)
                    } else if (notPaperCount === 0) {
                        subjectSummary.isDownStandard = examinationList.every(examSummary => examSummary.scoreInfo && !examSummary.scoreInfo.isUpStandard)
                        subjectSummary.isCompleteFlag = examinationList.some(examSummary => examSummary.scoreInfo && examSummary.scoreInfo.isUpStandard);
                    }

                    if (subjectSummary.isUpStandard || subjectSummary.isDownStandard) {
                        needAdjustChangeFlag = true;
                        checkLevelList.push(subjectSummary);

                        if (subjectSummary.isUpStandard) {
                            let subList = examinationList.filter(examSummary => examSummary.scoreInfo === undefined).map(examSummary => {
                                let assign = Object.assign({}, examSummary);
                                assign.subjectType = type;
                                return assign;
                            });
                            if (subList.length > 0) {
                                canChangeSubjectMap[type] = subList;
                            }
                        } else if (subjectSummary.isDownStandard) {
                            let changeSubjectList = AttentionAssessmentSubjectType.TrainingChangeSubjectList[type];
                            let subList = [];
                            changeSubjectList.forEach(changeType => {
                                let otherSummary = subjectTypeSummaryMap[changeType];
                                if (otherSummary) {
                                    let otherExaminationList = otherSummary.examinationList;
                                    otherExaminationList.forEach(otherExamItem => {
                                        if (otherExamItem.scoreInfo === undefined) {
                                            let assign = Object.assign({}, otherExamItem);
                                            assign.subjectType = otherSummary.subjectType;
                                            subList.push(assign);
                                        }
                                    })
                                }
                            });
                            if (subList.length > 0) {
                                canChangeSubjectMap[type] = subList;

                            }
                        }
                    }
                }
            }
        });

        let selectedType = this.state.selectedType;
        return (

            <div>
                <Card>
                    <h5>上机任务的课程情况</h5>
                    {checkLevelList.length > 0 ? <ChangeLessonSubjectShowParts checkLevelList={checkLevelList}
                                                                               canChangeSubjectMap={canChangeSubjectMap}/> : null}
                    <Table rowKey={(record, index) => index} bordered={true}
                           size="small" columns={this.state.columns}
                           dataSource={dataList} pagination={false}/>


                </Card>
                {selectedType !== "" ? <LessonSubjectReport summaryInfo={subjectTypeSummaryMap[selectedType]}/> : null}
            </div>
        );
    }

}

function mapStateToProps(store) {
    return {
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        relatedExaminationList: store.ExamineeLessonReducer.relatedExaminationList,
        relatedPaperList: store.ExamineeLessonReducer.relatedPaperList,

    }
}

export default connect(mapStateToProps)(injectIntl(LessonSubjectShowParts));