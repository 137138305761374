import React, {Component,useRef} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Card, Table} from "antd";
import {MoneyFormat} from "../../../../../utils/NumberUtil";
import "./PaperReportShowParts.css"
import {ExamDataModelType} from "../../../Enums";
import { Radar } from '@ant-design/plots';
let ref ;
class PaperReportChartShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],

            columns: [
                {
                    title: "维度",
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => ({
                        children: text,
                        props: {rowSpan: index % 2 === 0 ? 2 : 0},
                    }),
                    width: 80,
                },
                {
                    title: "含义",
                    dataIndex: 'info',
                    key: 'info',
                    render: (text, record, index) => <div>{text}</div>,


                    // width: 200,
                },
                {
                    title: "得分",
                    dataIndex: 'scoreInModel',
                    key: 'scoreInModel',
                    render: (text, record, index) => ({
                        children: <div>{text}</div>,
                        props: {rowSpan: index % 2 === 0 ? 2 : 0},
                    }),
                    width: 80,
                },

                {
                    title: "能力描述",
                    dataIndex: 'desc',
                    key: 'desc',
                    render: (text, record, index) => ({
                        children: <div>{text}</div>,
                        // children: <div>{this._getDesc(record.scoreInModel)}</div>,
                        props: {rowSpan: index % 2 === 0 ? 2 : 0},
                    }),
                    width: 80,

                },
            ]
        }

    }

    componentWillMount() {

    }
    componentDidMount() {
        // // Step 1: 创建 Chart 对象
        // chart = new Chart({
        //     container: this.props.chartKey ? this.props.chartKey : "pieId", // 指定图表容器 ID
        //     // autoFit: true,
        //     height: 375,
        //     width: 550
        // });
        // // this.forceUpdate();
        this.props.onRef(this);
    }

    _getDesc(score) {
        if (score > 95) {
            return "非常优秀";
        } else if (score > 90) {
            return "优秀";
        } else if (score >= 70) {
            return "良好";
        } else if (score >= 40) {
            return "一般";
        } else if (score < 10) {
            return "较弱，有待提高";
        } else {
            return "较弱";
        }
    }


    chartToDataURL() {

        let base64Data = this.chart?this.chart.toDataURL():null;
        console.log(base64Data);

        return base64Data;
    };
    _handleAddInLevel(score, label, resultMap) {
        let desc = this._getDesc(score);
        if (desc === '优秀' || desc === '非常优秀') {
            this._addInLevel(resultMap, "level1", label);
        } else if (desc === '良好') {
            this._addInLevel(resultMap, "level2", label);
        } else if (desc === '一般') {
            this._addInLevel(resultMap, "level3", label);
        } else if (desc.includes('较弱')) {
            this._addInLevel(resultMap, "level4", label);
        }
    }

    _addInLevel(resultMap, levelName, label) {
        if (resultMap[levelName]) {
            resultMap[levelName].push(label);
        }
        else {
            resultMap[levelName] = [label];
        }
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const relatedExaminationSubjectList = this.props.relatedExaminationSubjectList ? this.props.relatedExaminationSubjectList : [];

        const paper = this.props.paper ? this.props.paper : {};


        const scoreResult = paper.scoreResult && paper.scoreResult !== "" ? JSON.parse(paper.scoreResult) : {};
        let dataList = [];


        ExamDataModelType.List.forEach(item => {
            let code = item.value;
            let showMapElement = ExamDataModelType.ShowMap[code];
            let data = {};
            data.code = code;
            data.name = showMapElement.scoreLabel;


            data.scoreInModel =scoreResult[showMapElement.scoreInModel];


            dataList.push(data);

        });

        const config = {
            data: dataList,
            xField: 'name',
            yField: 'scoreInModel',
            appendPadding: [0, 10, 0, 10],
            meta: {
                star: {
                    alias: '分数',
                    min: 0,
                    nice: true,
                    formatter: (v) => Number(v).toFixed(2),
                },
            },
            xAxis: {
                tickLine: null,
            },
            yAxis: {
                label: false,
                grid: {
                    alternateColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
            // 开启辅助点
            point: {
                size: 2,
            },
            area: {},
        };

        return (

            <Card>

                <Radar {...config} onReady={(chart) => this.chart = chart}/>

            </Card>
        );
    }

}

function mapStateToProps(store) {
    return {
        paper: store.ExamPaperReducer.paper,
        relatedExaminationSubjectList: store.ExamPaperReducer.relatedExaminationSubjectList,
    }
}

export default connect(mapStateToProps)(injectIntl(PaperReportChartShowParts));