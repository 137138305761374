import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input} from "antd";

import {toggleUpdateModal, refreshSite, updateSite} from '../../actions/SiteAction';

class CreateModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(site) {
        let messageMap = {};
        if (site.code === undefined || site.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'CMS_SITE_MSG_CODE_REQUIRED'});
        }
        if (site.title === undefined || site.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'CMS_SITE_MSG_TITLE_REQUIRED'});
        }
        if (site.url === undefined || site.url === "") {
            messageMap["url"] = this.state.formatMessage({id: 'CMS_SITE_MSG_URL_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleUpdateModal(false));
    }

    _handleOk() {
        if (this._validate(this.props.site)) {
            this.props.dispatch(updateSite(this.props.site, this.state.formatMessage));
        }
    }

    _handleChange(name, value) {

        const site = Object.assign({}, this.props.site);
        site[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshSite(site));
    }

    render() {
        const {site} = this.props;
        const {formatMessage} = this.state;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 18},
            },
        };

        return (
            <Modal
                title={formatMessage({id: 'CMS_SITE_UPDATE_MODAL_TITLE'})}
                visible={this.props.showUpdateModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}>
                <Form>
                    <Form.Item label={formatMessage({id: 'CMS_SITE_FIELD_CODE'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={site.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_SITE_FIELD_TITLE'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["title"] ? "error" : ""}
                               help={this.state.messageMap["title"]}>
                        <Input value={site.title}
                               onChange={(value) => {
                                   this._handleChange("title", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_SITE_FIELD_URL'})} {...formItemLayout} required={true}
                               validateStatus={this.state.messageMap["url"] ? "error" : ""}
                               help={this.state.messageMap["url"]}>
                        <Input value={site.url}
                               onChange={(value) => {
                                   this._handleChange("url", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_SITE_FIELD_REMARKS'})} {...formItemLayout}>
                        <Input.TextArea value={site.remarks}
                                        onChange={(value) => this._handleChange("remarks", value)}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        site: store.CmsSiteReducer.site,
        showUpdateModal: store.CmsSiteReducer.showUpdateModal
    }
}

export default connect(mapStateToProps)(injectIntl(CreateModal));