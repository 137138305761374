import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';
import {ExaminationClassifyEnums} from "../Enums";
import {CACHE_ACCESS_TOKEN} from "../../../constants/Profile";

// import {refreshExaminationBanks} from "./ExaminationBankAction";

export function refreshExamExamineeLesson(examineeLesson) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_LESSON,
        examineeLesson: examineeLesson,
    }
}

export function initExamExamineeLesson() {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_INIT_LESSON
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}


export function searchExamineeLesson(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExamExamineeLesson, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_EXAMINEE_LESSON_SEARCH_LESSON_LIST,
                examineeLessonList: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getExamExamineeLesson(id, formatMessage,withRelatedInfo) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getExamExamineeLesson, "id", id);

        HttpUtils.get(requestURL, (response) => {
            let lesson = response.data;
            dispatch(refreshExamExamineeLesson(lesson));
            if(withRelatedInfo){
                dispatch(searchLessonRelatedExaminationList(lesson,formatMessage));
                dispatch(searchLessonRelatedPaperList(lesson,formatMessage));
            }
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createExamExamineeLesson(examineeLesson, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: true});

        HttpUtils.post(WebService.createExamExamineeLesson, examineeLesson, (response) => {
            dispatch(searchExamineeLesson(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateExamExamineeLesson(examineeLesson, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateExamExamineeLesson, examineeLesson, (response) => {
            dispatch(searchExamineeLesson(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteExamExamineeLesson(id, condition, formatMessage) {
    return dispatch => {
        let requestURL = HttpUtils.addQueryString(WebService.deleteExamExamineeLesson, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchExamineeLesson(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, formatMessage);
    }
}


//查找所有课时一览，用于课程中的数据绑定
export function searchAllExaminationPeriod(formatMessage) {

    let condition = {paginationFlag: false, classify: ExaminationClassifyEnums.Enum.Training};
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminations, condition, (response) => {
            dispatch(refreshAllPeriodExaminationList(response.data));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }

}

//查找所有课时一览，用于课程模版中的数据绑定
export function refreshAllPeriodExaminationList(list) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_LIST,
        allPeriodExaminationList: list
    }
}

export function toggleSelectExaminationModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_TOGGLE_EXAMINATION_SELECT_MODAL,
        showSelectExaminationModal: isShow
    }
}

export function refreshExaminationSelectedIdList(selectedIdList) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_ID_SELECTED_LIST,
        examinationSelectedIdList: selectedIdList
    }
}


//查找所有课时一览，用于课程中的数据绑定
export function searchAllExamineeList(formatMessage) {

    let condition = {paginationFlag: false};
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminees, condition, (response) => {
            dispatch(refreshAllExamineeList(response.data));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }

}

//查找所有课时一览，用于课程模版中的数据绑定
export function refreshAllExamineeList(list) {

    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_ALL_EXAMINEE_LIST,
        allExamineeList: list
    }
}

//查找所有课时一览，用于课程中的数据绑定
export function searchLessonRelatedPaperList(lesson, formatMessage) {

    let condition = {paginationFlag: false, lessonId: lesson.id};
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchPapersForLesson, condition, (response) => {
            let list = response.data;
            list.forEach(paper=>{
                let subjects = paper.subjects;
                if(subjects){
                    subjects.forEach(item=>{
                        item.contentJson = item.content && item.content !== "" ? JSON.parse(item.content) : {};
                    })
                }
            });
            dispatch(refreshRelatedPaperList(list));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }
}


//查找所有课时一览，用于课程模版中的数据绑定
export function refreshRelatedPaperList(list) {

    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_RELATED_PAPER_LIST,
        relatedPaperList: list
    }
}


//查找所有课时一览，用于课程中的数据绑定
export function searchLessonRelatedExaminationList(lesson, formatMessage) {

    return dispatch => {
        let examinationIds = lesson.examinationIds;
        let split = examinationIds ? examinationIds.split(",") : [];
        if (split.length > 0) {
            let condition = {paginationFlag: false, examinationIdList: split,withSubjectsFlag:true};
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});
            HttpUtils.post(WebService.searchExaminations, condition, (response) => {
                dispatch(refreshRelatedExaminationList(response.data));
                dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
            }, (response) => {
                dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
            }, formatMessage)
        } else {
            dispatch(refreshRelatedExaminationList([]));
        }
    }

}


//查找所有课时一览，用于课程模版中的数据绑定
export function refreshRelatedExaminationList(list) {

    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_RELATED_EXAMINATION_LIST,
        relatedExaminationList: list
    }
}

export function doExportLessonPaperReport(exportLessonReport, formatMessage) {


    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});
        let requestUrl = WebService.exportLessonReport;
        fetch(requestUrl, {
            method: "post",
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            body: JSON.stringify(exportLessonReport)
        })
            .then((response) => response.arrayBuffer())
            .then((response) => {
                //console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', '课程报告.docx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
            })
    }
}


export function changeLessonExamination(lessonId, changeInfo, formatMessage,doRefreshFunction) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: true});
        let requestURL = HttpUtils.addQueryString(WebService.changeExamination, "lessonId", lessonId);

        HttpUtils.put(requestURL, changeInfo, (response) => {
            if(doRefreshFunction){
                doRefreshFunction.call();
            }

            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LESSON_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function toggleChangeLessonSubjectModal(isShow) {
    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_TOGGLE_CHANGE_LESSON_SUBJECT_MODAL,
        showChangeLessonSubjectModal: isShow
    }
}


export function refreshChangeLessonSubjectInfo(changeInfo) {

    return {
        type: ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_CHANGE_LESSON_SUBJECT_INFO,
        changeLessonSubjectInfo: changeInfo
    }
}
