import moment from "moment";
import * as ActionTypes from '../ActionTypes';
import * as DateTimeFormats from "../../../constants/DateTimeFormats";
import {ExamPaperType} from "../Enums";

const initialState = {
    examineeLesson: {
        code: moment().format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE),
        type: ExamPaperType.Enum.Evaluation
    },
    examineeLessons: [],
    searchCondition: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
    showSubjectsModal: false,
    showPublishModal: false
};

const ExamineeLessonReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_EXAMINEE_LESSON_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_INIT_LESSON:
            return Object.assign({}, state, {
                examineeLesson: initialState.examineeLesson
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_LESSON:
            return Object.assign({}, state, {
                examineeLesson: action.examineeLesson
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });


        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_SEARCH_LESSON_LIST:
            return Object.assign({}, state, {
                examineeLessonList: action.examineeLessonList,
                pagination: action.pagination
            });


        case ActionTypes.EXAM_EXAMINEE_LESSON_TOGGLE_EXAMINATION_SELECT_MODAL:
            return Object.assign({}, state, {
                showSelectExaminationModal: action.showSelectExaminationModal
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_LIST:
            return Object.assign({}, state, {
                allPeriodExaminationList: action.allPeriodExaminationList
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_EXAMINATION_ID_SELECTED_LIST:
            return Object.assign({}, state, {
                examinationSelectedIdList: action.examinationSelectedIdList
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_ALL_EXAMINEE_LIST:
            return Object.assign({}, state, {
                allExamineeList: action.allExamineeList
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_RELATED_PAPER_LIST:
            return Object.assign({}, state, {
                relatedPaperList: action.relatedPaperList
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_RELATED_EXAMINATION_LIST:
            return Object.assign({}, state, {
                relatedExaminationList: action.relatedExaminationList
            });
        case ActionTypes.EXAM_EXAMINEE_LESSON_TOGGLE_CHANGE_LESSON_SUBJECT_MODAL:
            return Object.assign({}, state, {
                showChangeLessonSubjectModal: action.showChangeLessonSubjectModal
            });

        case ActionTypes.EXAM_EXAMINEE_LESSON_REFRESH_CHANGE_LESSON_SUBJECT_INFO:
            return Object.assign({}, state, {
                changeLessonSubjectInfo: action.changeLessonSubjectInfo
            });


        default:
            return state
    }
};

export default ExamineeLessonReducer;