import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Divider, Popconfirm, Table} from 'antd';


import PaginationBar from "../../../../../components/PaginationBar";
import {
    deleteLessonTemplate,
    refreshLessonTemplate,
    searchAllExaminationPeriod,
    searchLessonTemplates,
    toggleDetailModal
} from "../../../actions/LessonTemplateAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                // {
                //     title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_ID'}),
                //     dataIndex: 'id',
                //     key: 'id'
                // },
                {
                    title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_TITLE'}),
                    dataIndex: 'title',
                    key: 'title'
                },
                {
                    title: formatMessage({id: 'EXAM_LESSON_TEMPLATE_FIELD_REMARKS'}),
                    dataIndex: 'remarks',
                    key: 'remarks'
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }

            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                {/*<Divider type="vertical"/>*/}
                {/*<Popover content={<QRCode value={qrPath}/>}>*/}
                {/*<a onClick={() => this.setState({showQrCode: true, selectedRecord: record})}> <Icon type="qrcode"/></a>*/}
                {/*</Popover>*/}
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handlePublish.bind(this, record)}>*/}
                {/*<FormattedMessage id="EXAM_EXAMINATION_BUTTON_PUBLISH"/>*/}
                {/*</a>*/}
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handleShowSubjects.bind(this, record)}>*/}
                {/*    <FormattedMessage id="EXAM_EXAMINATION_FIELD_SUBJECTS"/>*/}
                {/*</a>*/}
            </div>
        )
    }



    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteLessonTemplate(record.id, searchCondition, formatMessage));
    }

    _handleModify(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;

        dispatch(refreshLessonTemplate(record));
        dispatch(toggleDetailModal(true));
        dispatch(searchAllExaminationPeriod(formatMessage));

    }

    render() {

        return (
            <div className="spacing-v">
                {/*<PublishModal/>*/}
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey={(record) => record.id} size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.lessonTemplateList} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchLessonTemplates(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        lessonTemplateList: store.LessonTemplateReducer.lessonTemplateList,
        searchCondition: store.LessonTemplateReducer.searchCondition,
        subjectSearchCondition: store.ExamExaminationSubjectReducer.searchCondition,
        pagination: store.LessonTemplateReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));