export const Label_6_Wrapper_18 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 6},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
    },
    style: {marginBottom: "3px"}
};
export const Label_4_Wrapper_10 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    style: {marginBottom: "3px"}
};
export const Label_4_Wrapper_20 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
    style: {marginBottom: "3px"}
};
export const Label_8_Wrapper_16 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 8},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
    },
    style: {marginBottom: "3px"}
};
export const Label_10_Wrapper_14 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
    },
    style: {marginBottom: "3px"}
};
export const Label_4_Wrapper_18 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 4},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 20},
    },
    style: {marginBottom: "3px"}
};
export const Label_14_Wrapper_10 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 14},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 10},
    },
    style: {marginBottom: "3px"}
};
export const Label_3_Wrapper_21 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 3},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 21},
    },
    style: {marginBottom: "3px"}
};
export const Label_2_Wrapper_22 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 2},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 22},
    },
    style: {marginBottom: "3px"}
};
export const Label_5_Wrapper_19 = {
    labelCol: {
        xs: {span: 24},
        sm: {span: 5},
    },
    wrapperCol: {
        xs: {span: 24},
        sm: {span: 19},
    },
    style: {marginBottom: "3px"}
};