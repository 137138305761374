import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Divider, Popconfirm, Table} from 'antd';
import {
    deleteExamination,
    getExamination,
    refreshExamination,
    searchExaminations,
    toggleDetailModal,
    togglePublishModal,
    toggleSubjectsModal
} from "../../../actions/ExaminationAction";
import {refreshSearchCondition, searchExaminationSubjects} from "../../../actions/ExaminationSubjectAction";
import {MoneyFormat} from "../../../../../utils/NumberUtil";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: "#",
                    dataIndex: 'index',
                    key: 'index',
                    render: (text, record, index) => index+1

                },
                {
                    title: formatMessage({id: 'EXAM_DATA_MODEL_FILED_SCORE'}),
                    dataIndex: 'score',
                    key: 'score'
                },
                {
                    title: formatMessage({id: 'EXAM_DATA_MODEL_FILED_FREQUENCY'}),
                    dataIndex: 'frequency',
                    key: 'frequency'
                },
                {
                    title: formatMessage({id: 'EXAM_DATA_MODEL_FILED_FREQUENCY_PERCENTAGE'}),
                    dataIndex: 'frequencyPercentage',
                    key: 'frequencyPercentage'
                },
                {
                    title: formatMessage({id: 'EXAM_DATA_MODEL_FILED_EFFECTIVE_PERCENTAGE'}),
                    dataIndex: 'effectivePercentage',
                    key: 'effectivePercentage'
                },
                {
                    title: formatMessage({id: 'EXAM_DATA_MODEL_FILED_CUMULATIVE_PERCENTAGE'}),
                    dataIndex: 'cumulativePercentage',
                    key: 'cumulativePercentage',
                    render: (text, record, index) => MoneyFormat.format(record.cumulativePercentage)
                },

            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                {/*<Divider type="vertical"/>*/}
                {/*<Popover content={<QRCode value={qrPath}/>}>*/}
                {/*<a onClick={() => this.setState({showQrCode: true, selectedRecord: record})}> <Icon type="qrcode"/></a>*/}
                {/*</Popover>*/}
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handlePublish.bind(this, record)}>*/}
                    {/*<FormattedMessage id="EXAM_EXAMINATION_BUTTON_PUBLISH"/>*/}
                {/*</a>*/}
                <Divider type="vertical"/>
                <a onClick={this._handleShowSubjects.bind(this, record)}>
                    <FormattedMessage id="EXAM_EXAMINATION_FIELD_SUBJECTS"/>
                </a>
            </div>
        )
    }

    _handlePublish(record) {
        //处理题目为空的情况
        const searchCondition = {examinationId: record.id};
        this.props.dispatch(searchExaminationSubjects(searchCondition, this.state.formatMessage));

        this.props.dispatch(refreshExamination(record));
        this.props.dispatch(togglePublishModal(true));
    }

    _handleShowSubjects(record) {
        const searchCondition = Object.assign({}, this.props.subjectSearchCondition);
        searchCondition.examinationId = record.id;
        this.props.dispatch(refreshExamination(record));
        this.props.dispatch(refreshSearchCondition(searchCondition));
        this.props.dispatch(searchExaminationSubjects(searchCondition, this.state.formatMessage));
        this.props.dispatch(toggleSubjectsModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteExamination(record.id, searchCondition, formatMessage));
    }

    _handleModify(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(getExamination(record.id, formatMessage));
        dispatch(toggleDetailModal(true));
    }

    render() {
        return (
            <div className="spacing-v">


                <Table rowKey={(record)=> record.id} size="middle" className="spacing-v" columns={this.state.columns} bordered={true}
                       dataSource={this.props.dataModelList} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchExaminations(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        dataModelList: store.ExamDataModelReducer.dataModelList,
        searchCondition: store.ExamDataModelReducer.searchCondition,
        pagination: store.ExamDataModelReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));