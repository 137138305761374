import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Button, Card, Collapse, Form, Input, Modal, Table} from "antd";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {
    ANTClueType,
    ANTMiddleSideConsistent,
    AttentionAssessmentSubjectType,
    getEnumsLabel,
    PaperSubjectStatus, SubjectType
} from "../../../Enums";
import moment from 'moment';
import {MoneyFormat} from "../../../../../utils/NumberUtil";
import {
    getPaper,
    handInPaper,
    refreshPaperSubject,
    togglePaperSubjectModifyModal,
    updatePaper
} from "../../../actions/PaperAction";
import PaperScoreModifyModal from "./PaperScoreModifyModal";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";


class PaperSubjectListShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            antColumns: [
                {
                    title: "#",
                    dataIndex: 'index',
                    key: 'index',
                    width: 40,
                    render: (text, record, index) => index + 1

                },

                {
                    title: "线索类型",
                    dataIndex: 'clueType',
                    key: 'clueType',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.clueType, ANTClueType.Map, formatMessage)
                },
                {
                    title: "是否一致",
                    key: 'middleSideConsistent',
                    dataIndex: 'middleSideConsistent',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.middleSideConsistent, ANTMiddleSideConsistent.Map, formatMessage)
                },
                {
                    title: "回答结果",
                    dataIndex: 'status',
                    key: 'status',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)

                },
                {
                    title: "反应时长（秒）",
                    key: 'startTime',
                    dataIndex: 'startTime',
                    render: (text, record, index) => {

                        return this._getTimeString(record) * 0.001;


                    }
                },
            ],
            goNoGoColumns: [
                {
                    title: "#",
                    dataIndex: 'index',
                    key: 'index',
                    width: 40,
                    render: (text, record, index) => index + 1

                },

                {
                    title: "类型",
                    dataIndex: 'nextWord',
                    key: 'nextWord',
                    width: 150,
                    render: (text, record, index) => record.contentJson.nextWord + " （" + (record.contentJson.nextWord === "G" ? "Go" : "NoGo") + "）"
                },

                {
                    title: "回答结果",
                    dataIndex: 'status',
                    key: 'status',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)

                },
                {
                    title: "反应时长（毫秒）",
                    key: 'startTime',
                    dataIndex: 'startTime',
                    render: (text, record, index) => {
                        if (record.status === PaperSubjectStatus.Enum.Right && record.contentJson.nextWord === "S") {
                            return "0";

                        } else {
                            return this._getTimeString(record);
                        }
                    }
                },
            ],
            showRemarksModal: false,
            remarks: "",
        }

    }

    componentWillMount() {

    }

    _handleCancel() {
        this.setState({showRemarksModal: false});
    }

    _handleOpenRemarksModel(paper) {
        this.setState({
            showRemarksModal: true,
            remarks: paper.remarks
        });
    }

    _handleOk() {
        const {paper} = this.props;
        const {formatMessage} = this.state;
        paper.remarks = this.state.remarks;

        this.props.dispatch(updatePaper(paper, formatMessage, () => {
            this.props.dispatch(getPaper(paper.id, this.state.formatMessage));
            this._handleCancel();
        }));


    }


    _getTimeString(record) {
        if (record.startTime !== "" && record.endTime !== "") {
            let startTime = moment(record.startTime, FORMAT_DATE_TIME_FULL_SIMPLE);
            let endTime = moment(record.endTime, FORMAT_DATE_TIME_FULL_SIMPLE);
            let diff = endTime.diff(startTime);
            return diff;
        } else {
            return 0;
        }
    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.paper);
        const subjects = Object.assign([], this.props.subjects);

        this.props.dispatch(handInPaper(paper, this.state.formatMessage));
    }

    _renderRemarksModal() {
        const formatMessage = this.state.formatMessage;

        return <Modal
            maskClosable={false}
            title={"教师备注"}
            visible={this.state.showRemarksModal}
            onOk={() => this._handleOk()}
            onCancel={() => this._handleCancel()}
            okText={formatMessage({id: "COMMON_BUTTON_OK"})}
            cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
            <Form>
                <Form.Item label="备注" {...Label_6_Wrapper_18}>
                    <Input.TextArea autosize={{minRows: 2, maxRows: 6}} value={this.state.remarks}
                                    onChange={(value) => {
                                        this.setState({remarks: value.target ? value.target.value : value})

                                    }}/>

                </Form.Item>

            </Form>


        </Modal>
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const relatedExaminationSubjectList = this.props.relatedExaminationSubjectList ? this.props.relatedExaminationSubjectList : [];

        const paper = this.props.paper ? this.props.paper : {};


        const subjectList = paper.subjects ? paper.subjects : [];
        let subjectInfoMap = {};
        subjectList.forEach(subject => {
            let subItemMap;
            if (subjectInfoMap[subject.subjectType]) {
                subItemMap = subjectInfoMap[subject.subjectType];
            }
            else {
                subItemMap = {blockList: []};
                subjectInfoMap[subject.subjectType] = subItemMap;

            }
            let subList;
            if (subItemMap.blockList.includes(subject.block)) {
                subList = subItemMap[subject.block];

            }
            else {
                subItemMap.blockList.push(subject.block);
                subList = [];
                subItemMap[subject.block] = subList;
            }
            subList.push(subject);

        });

        return (

            <Card>
                <PaperScoreModifyModal/>
                {this._renderRemarksModal()}
                <h5>答题详情  &nbsp;<Button type='primary' size="small" onClick={() => this._handleSubmit()}> 重新算分 </Button>
                    &nbsp;</h5>

                <div>教师备注：{paper.remarks&&paper.remarks!==""?paper.remarks:"暂无"} <Button size="small" onClick={() => this._handleOpenRemarksModel(paper)}> 教师备注 </Button></div>
                <br/>
                {AttentionAssessmentSubjectType.List.map(item => {
                    return this.renderExamSubjectShow(subjectInfoMap, item);
                })}
            </Card>
        );
    }

    _handleModifySubjectModel(subject) {
        this.props.dispatch(refreshPaperSubject(subject));
        this.props.dispatch(togglePaperSubjectModifyModal(true));

        // const self = this;
        // const formatMessage = this.state.formatMessage;
        // Modal.confirm({
        //     title: "请确认需要加入常模",
        //     content: "请确认是否需要加入常模",
        //     okText: 'Yes',
        //     okType: 'danger',
        //     cancelText: 'No',
        //     onOk() {
        //         self.props.dispatch(updateByPaper({paperId:record.id, }, self.props.searchCondition, formatMessage));
        //     }
        // })
    }

    renderBlockShow(blockKey, subjectInfoMapElement, enumItem, showBlockNumber) {
        let subList = Object.assign([], subjectInfoMapElement[blockKey]);
        subList.sort((a, b) => a.sequence - b.sequence);
        let blockMsg = "";
        if (subList.length > 0) {
            blockMsg = subList[0].practiceFlag ? "【练习】" : "【实测】"
        }
        // let formatMessage = this.state.formatMessage;
        let subjectType = enumItem.value;
        if (subjectType=== AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_PAY_ATTENTION) {
            return <Collapse.Panel header={"Block-" + blockKey + " " + blockMsg} key={blockKey}>
                {/*{showBlockNumber ? <p> Block-{blockKey}</p> : null}*/}
                <Table rowKey={(record) => record.id} size="middle" className="spacing-v"
                       columns={this.state.antColumns} bordered={true}
                       dataSource={subList} pagination={false}/>
            </Collapse.Panel>
        } else if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO) {
            return <Collapse.Panel header={"Block-" + blockKey + " " + blockMsg} key={blockKey}>
                {/*{showBlockNumber ? <p> Block-{blockKey}</p> : null}*/}
                <Table rowKey={(record) => record.id} size="middle" className="spacing-v"
                       columns={this.state.goNoGoColumns} bordered={true}
                       dataSource={subList} pagination={false}/>
            </Collapse.Panel>
        } else if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF||
            subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL) {
            return <Collapse.Panel header={"Block-" + blockKey + " " + blockMsg} key={blockKey}>
                {subList.map(subject => {
                    if (subject.status.startsWith("{")) {
                        let statusObj = subject.statusObj;
                        let rightCount = statusObj[PaperSubjectStatus.Enum.Right];
                        let wrongCount = statusObj[PaperSubjectStatus.Enum.Wrong];

                        return <div>
                            <span>正确划销 :<span style={{fontWeight: 600}}>{rightCount} </span> 个 </span> &nbsp;&nbsp;
                            <span>错误划销 :<span style={{fontWeight: 600}}>{wrongCount} </span> 个 </span>&nbsp;&nbsp;
                            <span>反应时 :<span
                                style={{fontWeight: 600}}>{statusObj.timeout ? "61" : this._getTimeString(subject) * 0.001} </span>秒 </span>&nbsp;&nbsp;
                            <span>正确划销率 :<span
                                style={{fontWeight: 600}}>{MoneyFormat.formatOne((rightCount * 100 / statusObj.answerKeyCount))} </span>%</span>&nbsp;&nbsp;
                        </div>

                    } else {
                        return "无结果"
                    }
                })}
            </Collapse.Panel>
        }
        else if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT||
            subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL) {
            return <Collapse.Panel header={"Block-" + blockKey + " " + blockMsg} key={blockKey}>
                {subList.map(subject => {
                    if (subject.status.startsWith("{")) {
                        let statusObj = subject.statusObj;
                        let rightCount = statusObj[PaperSubjectStatus.Enum.Right];
                        let wrongCount = statusObj[PaperSubjectStatus.Enum.Wrong];
                        return <div>
                            <span>正确数 :<span style={{fontWeight: 600}}>{rightCount} </span> 个 </span> &nbsp;&nbsp;
                            <span>错误数 :<span style={{fontWeight: 600}}>{wrongCount} </span> 个 </span>&nbsp;&nbsp;
                            <span>反应时 :<span
                                style={{fontWeight: 600}}>{statusObj.timeout ? "31" : this._getTimeString(subject) * 0.001} </span>秒 </span>&nbsp;&nbsp;
                            <span>正确划销率 :<span
                                style={{fontWeight: 600}}>{MoneyFormat.formatOne((rightCount * 100 / statusObj.answerKeyCount))} </span>% </span>&nbsp;&nbsp;
                            <Button onClick={() => this._handleModifySubjectModel(subject)}>修正结果</Button>
                        </div>
                    } else {
                        return "无结果"
                    }
                })}
            </Collapse.Panel>
        } else {
            return "暂无"
        }
    }

    renderExamSubjectShow(subjectInfoMap, enumItem) {
        let formatMessage = this.state.formatMessage;
        let subjectInfoMapElement = subjectInfoMap[enumItem.value];
        if (subjectInfoMapElement) {
            let blockList = subjectInfoMapElement.blockList;
            let showBlockNumber = blockList.length > 1;
            let subjectType = enumItem.value;
            if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_PAY_ATTENTION ||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO ||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF ||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF_HORIZONTAL ||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT_HORIZONTAL) {
                return <div key={subjectType} style={{border: "#c8dbfd 1px solid", marginBottom: '10px'}}>
                    <div
                        style={{
                            backgroundColor: "#c8dbfd",
                            lineHeight: "26px",
                            padding: '3px 5px'
                        }}> {getEnumsLabel(subjectType, AttentionAssessmentSubjectType.Map, formatMessage)}</div>

                    <div style={{padding: '3px 5px'}}><Collapse>
                        {blockList.map(blockKey => {
                            return this.renderBlockShow(blockKey, subjectInfoMapElement, enumItem, showBlockNumber)
                        })}
                    </Collapse>
                    </div>
                </div>
            } else {
                return <div key={subjectType} style={{border: "#c8dbfd 1px solid", marginBottom: '10px'}}>
                    <div
                        style={{
                            backgroundColor: "#c8dbfd",
                            lineHeight: "26px",
                            padding: '3px 5px'
                        }}> {getEnumsLabel(subjectType, AttentionAssessmentSubjectType.Map, formatMessage)}</div>
                    暂无
                </div>
            }
        } else {
            return null;
        }
    }
}

function mapStateToProps(store) {
    return {
        isLoading: store.ExamPaperReducer.isLoading,

        paper: store.ExamPaperReducer.paper,
        relatedExaminationSubjectList: store.ExamPaperReducer.relatedExaminationSubjectList,
    }
}

export default connect(mapStateToProps)(injectIntl(PaperSubjectListShowParts));