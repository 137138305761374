import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Upload, Input, Table, message, Icon} from "antd";
import * as XLSX from 'xlsx';
class ImportModal extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {},
            fileList: [],
            dataList: [],
            titleMap: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        let dataList = nextProps.dataList;


        this.setState({
            dataList: Object.assign([], dataList)
        });
    }

    _handleCancel() {
        this.props.onCancel && this.props.onCancel.call(this);
    }

    _handleOk() {
        this.props.onOk && this.props.onOk.call(this, this.state.dataList);
    }

    _onImportExcel(file) {
        // console.log(file);
        // 获取上传的文件对象
        // const {files} = file.target;
        // 通过FileReader对象读取文件
        const fileReader = new FileReader();
        const self = this;
        fileReader.onload = event => {
            try {
                const {result} = event.target;
                // 以二进制流方式读取得到整份excel表格对象
                const workbook = XLSX.read(result, {type: 'binary'});
                // 遍历每张工作表进行读取（这里默认只读取第一张表）
                let data = []; // 存储获取到的数据
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        // 利用 sheet_to_json 方法将 excel 转成 json 数据
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        // break; // 如果只取第一张表，就取消注释这行
                    }
                }

                console.log(data);
                const titleMap = {};
                const dataList = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i] && data[i].idNumber) {
                        const idNumber = data[i].idNumber.toString();
                        titleMap[idNumber.trim()] = i;
                        dataList.push(data[i]);
                    }
                }

                self.setState({dataList: dataList, titleMap: titleMap});
            } catch (e) {
                // 这里可以抛出文件类型错误不正确的相关提示
                console.log('文件类型不正确');
            }
        };
        // 以二进制方式打开文件
        fileReader.readAsBinaryString(file);
    }

    render() {
        const {formatMessage, fileList, dataList} = this.state;
        const self = this;
        const fileUploadProps = {
            multiple: false,
            showUploadList: false,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file],
                }));
                return false;
            },
            onChange(info) {
                const {status} = info.file;
                if (status !== 'uploading') {
                    self._onImportExcel(info.file);
                }
                if (status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
            fileList,
        };

        return (
            <Modal
                maskClosable={false}
                title={formatMessage({id: "COMMON_BUTTON_IMPORT"})}
                visible={this.props.showImportModal}
                width={800}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <div>
                    <Upload.Dragger {...fileUploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="file-excel"/>
                        </p>
                        <p className="ant-upload-text">
                            <FormattedMessage id="EXAM_EXAMINEE_MSG_UPLOAD_TEMPLATE_FILE"/>
                        </p>
                    </Upload.Dragger>
                    <div>
                        <a href="/ExamineeImportFile.xlsx">
                            <FormattedMessage id='COMMON_BUTTON_DOWNLOAD_TEMPLATE'/>
                        </a>
                    </div>
                </div>
                <div>
                    <Table rowKey="id" size="middle" className="spacing-v" columns={this.props.columns}
                           dataSource={dataList} pagination={true}/>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(ImportModal));