import moment from "moment";
import * as ActionTypes from '../ActionTypes';
import * as DateTimeFormats from "../../../constants/DateTimeFormats";

const initialState = {
    lessonTemplate: {
        code: moment().format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE),
    },
    lessonTemplateList: [],
    searchCondition: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
    allExaminees:[]
};

const LessonTemplateReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_LESSON_TEMPLATE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });

        case ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_LESSON_TEMPLATE:
            return Object.assign({}, state, {
                lessonTemplate: action.lessonTemplate
            });
        case ActionTypes.EXAM_LESSON_TEMPLATE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });

        case ActionTypes.EXAM_LESSON_TEMPLATE_TOGGLE_PUBLISH_MODAL:
            return Object.assign({}, state, {
                showPublishModal: action.showPublishModal
            });
        case ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_LESSON_TEMPLATE_SEARCH_LESSON_TEMPLATE_LIST:
            return Object.assign({}, state, {
                lessonTemplateList: action.lessonTemplateList,
                pagination: action.pagination
            });

        case ActionTypes.EXAM_LESSON_TEMPLATE_TOGGLE_EXAMINATION_SELECT_MODAL:
            return Object.assign({}, state, {
                showSelectExaminationModal: action.showSelectExaminationModal
            });
        case ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_LIST:
            return Object.assign({}, state, {
                allPeriodExaminationList: action.allPeriodExaminationList
            });
        case ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_ID_SELECTED_LIST:
        return Object.assign({}, state, {
            examinationSelectedIdList: action.examinationSelectedIdList
        });
        case ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_ALL_LIST:
            return Object.assign({}, state, {
                allLessonTemplateList: action.allLessonTemplateList
            });
        default:
            return state
    }
};

export default LessonTemplateReducer;