import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Popconfirm} from 'antd';

import {Modal} from "antd/lib/index";

import PaginationBar from "../../../../../components/PaginationBar";
import {
    searchSubjects,
    deleteSubject,
    getSubject,
    toggleDetailModal,
    synchronizationSubject
} from "../../../actions/SubjectAction";
import {SubjectType} from "../../../Enums";
import EnumItemLabel from "../../../../../components/EnumItemLabel";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'EXAM_SUBJECT_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'EXAM_SUBJECT_FIELD_TYPE'}), dataIndex: 'type', key: 'type',
                    width: 150,
                    render: (text, record, index) => <EnumItemLabel code={text} map={SubjectType.Map}/>
                },
                // {
                //     title: formatMessage({id: 'EXAM_SUBJECT_FIELD_SCORE'}), dataIndex: 'score', key: 'score',
                //     width: 50
                // },
                {
                    title: formatMessage({id: 'EXAM_SUBJECT_FIELD_LIMIT_TIME'}), dataIndex: 'limitTime', key: 'limitTime',
                    width: 100
                },
                {
                    title: formatMessage({id: 'EXAM_SUBJECT_FIELD_SEQUENCE'}), dataIndex: 'sequence', key: 'sequence',
                    width: 50
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    width: 180,
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={"是否同步测评？"} onConfirm={() => this._handleSynchronization(record)}>
                    <a>同步测评</a>
                </Popconfirm>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(deleteSubject(record.id, searchCondition, formatMessage));
    }
    _handleSynchronization(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(synchronizationSubject(record, searchCondition, formatMessage));
    }

    _handleModify(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(getSubject(record.id, formatMessage));
        dispatch(toggleDetailModal(true));
    }

    render() {
        return (
            <div className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey={(record) => record.id} size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.subjects} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchSubjects(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        subjects: store.ExamSubjectReducer.subjects,
        searchCondition: store.ExamSubjectReducer.searchCondition,
        pagination: store.ExamSubjectReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));