import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    roles: [],
    role: {},
    selectedRoles: [],
    searchCondition: {},
    showDetailModal: false,
    showPermissionModal: false,
    pagination: {},
};

const RoleReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_ROLE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ACCOUNT_ROLE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ACCOUNT_ROLE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ACCOUNT_ROLE_TOGGLE_PERMISSION_MODAL:
            return Object.assign({}, state, {
                showPermissionModal: action.showPermissionModal
            });
        case ActionTypes.ACCOUNT_ROLE_REFRESH_ROLE:
            return Object.assign({}, state, {
                role: action.role
            });
        case ActionTypes.ACCOUNT_ROLE_SEARCH:
            return Object.assign({}, state, {
                roles: action.roles,
                pagination: action.pagination
            });
        case ActionTypes.ACCOUNT_ROLE_REFRESH_SELECTED_ROLES:
            return Object.assign({}, state, {
                selectedRoles: action.selectedRoles
            });
        default:
            return state
    }
};

export default RoleReducer;