
import numeral from 'numeral';
// 金额
export const MoneyFormat ={

    format:(amount)=>{
        if(amount){
            return numeral(amount).format('0,0.00')

        }
        return '0.00'

    },

    formatOne:(amount)=>{
        if(amount){
            return numeral(amount).format('0,0.0')

        }
        return '0.0'

    },
    formatThree:(amount)=>{
        if(amount){
            return numeral(amount).format('0,0.000')

        }
        return '0.000'

    },
    formatFour:(amount)=>{
        if(amount){
            return numeral(amount).format('0,0.0000')

        }
        return '0.0000'

    },

}
//公里
export const KilometreFormat ={

    format:(kilometre)=>{
        if(kilometre){
            return numeral(kilometre).format('0.000')

        }
        return '0.000'

    },

}