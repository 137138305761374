import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Form, Row, Col, Input, Tabs} from "antd";
import ReactMarkdown from 'react-markdown';

import Simditor from 'simditor';
import {findDOMNode} from 'react-dom';
import 'simditor/styles/simditor.css';
import {CACHE_ACCESS_TOKEN, FTP_SERVER_HOST} from "../constants/Profile";
import moment from "moment";
import {FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../constants/DateTimeFormats";

class RichTextEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedTabKey: "rich"
        }
    }

    componentDidMount() {
        let textbox = findDOMNode(this.componentRef);
        if (this.componentRef) {
            this.editor = new Simditor({
                textarea: textbox,
                toolbar: [
                    'title',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'fontScale',
                    'color',
                    'ol',
                    'ul',
                    'blockquote',
                    'code',
                    'table',
                    'image',
                    'indent',
                    'outdent',
                    'alignment',
                    'hr',
                ],
                defaultImage: 'images/image.png',
                upload: {
                    url: "",//FTP_SERVER_HOST + "/ftp/oss/file?editorType=Simditor&X-Auth-Token=" + localStorage.getItem(CACHE_ACCESS_TOKEN),
                    // params: {
                    //     folder: "simditor/" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE)
                    // },
                    fileKey: 'file',
                    connectionCount: 1,
                    leaveConfirm: 'Uploading is in progress, are you sure to leave this page?'
                },
                pasteImage: true
            });
            this.editor.setValue(this.props.value);

            //监听改变
            this.editor.on("valuechanged", (e, src) => {
                if (this.state.selectedTabKey === "rich") {
                    const html = this.editor.getValue().trim();
                    this._handleChange(html);
                }
            });
        }
    }



    render() {
        const {value} = this.props;
        return (
            <Tabs type="card" onChange={(key) => this.setState({selectedTabKey: key})}>
                <Tabs.TabPane tab={this.state.formatMessage({id: "CMS_CONTENT_TITLE_EDITOR_RICH"})} key="rich">
                    {this._renderRich(value)}
                </Tabs.TabPane>
                <Tabs.TabPane tab={this.state.formatMessage({id: "CMS_CONTENT_TITLE_EDITOR_MARKDOWN"})} key="markdown">
                    {this._renderMarkdown(value)}
                </Tabs.TabPane>
            </Tabs>
        );
    }

    _handleChange(value) {
        this.props.onChange && this.props.onChange.call(this, value);
    }

    _renderMarkdown(value) {

        const rows = this.props.rows ? this.props.rows : 15;
        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Input.TextArea rows={rows} value={value} onChange={(data) => {
                        this._handleChange(data);
                        this.editor.setValue(this.props.value);
                    }}/>
                </Col>
                <Col span={12}>
                    <div style={{height: '100%', overflowY: 'scroll'}}>
                        <ReactMarkdown source={value} escapeHtml={false}/>
                    </div>
                </Col>
            </Row>
        )
    }

    _renderRich() {
        return (
            <div>
            <textarea id="editer" ref={el => {
                console.log("editer", el);
                this.componentRef = el;
            }} placeholder="" autoFocus></textarea>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(RichTextEditor));