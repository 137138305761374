import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Button, Icon, Tree, Popconfirm} from 'antd';
import {
    deleteDictType,
    getDictType,
    refreshDictType,
    toggleDetailModal as toggleTypeDetailModal
} from "../../../actions/DictTypeAction";
import {
    deleteDictClass,
    getDictClass, refreshDictClass,
    toggleDetailModal as toggleClassDetailModal
} from "../../../actions/DictClassAction";
import DictTypeModal from "./DictTypeModal";
import DictClassModal from "./DictClassModal";
import {getDictItemsByClassId} from "../../../actions/DictItemAction";

class DictTypeTree extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            selectedNode: {},
            selectedKeys: []
        }
    }

    componentWillMount() {
    }

    _handleSelect(selectedKeys, info) {
        console.log(selectedKeys);
        console.log(info);
        this.setState({selectedKeys: selectedKeys, selectedNode: info.node});
        if (selectedKeys.length > 0 && info.node.props.pos.split("-").length === 3) {
            this.props.dispatch(getDictItemsByClassId(selectedKeys[0], this.state.formatMessage));
        }
    }

    _handleCreate() {
        if (this.state.selectedKeys.length <= 0) {
            this.props.dispatch(refreshDictType({}));
            this.props.dispatch(toggleTypeDetailModal(true));
        } else {
            this.props.dispatch(refreshDictClass({}));
            this.props.dispatch(toggleClassDetailModal(true));
        }
    }

    _handleModify() {
        if (this.state.selectedKeys.length <= 0) {
            return;
        }

        if (this.state.selectedNode.props.pos.split("-").length === 2) {
            console.log("选中的是类型");
            this.props.dispatch(getDictType(this.state.selectedKeys[0], this.state.formatMessage));
            this.props.dispatch(toggleTypeDetailModal(true));
        } else {
            console.log("选中的是分类");
            this.props.dispatch(getDictClass(this.state.selectedKeys[0], this.state.formatMessage));
            this.props.dispatch(toggleClassDetailModal(true));
        }
    }

    _handleDelete() {
        if (this.state.selectedKeys.length <= 0) {
            return;
        }
        if (this.state.selectedNode.props.pos.split("-").length === 2) {
            this.props.dispatch(deleteDictType(this.state.selectedKeys[0], this.state.formatMessage));
        } else {
            this.props.dispatch(deleteDictClass(this.state.selectedKeys[0], this.state.formatMessage));
        }
    }

    render() {

        const dictClasses = this.props.dictClasses;
        const dictClassesMap = {};
        for (let dictClass of dictClasses) {
            if (!dictClassesMap[dictClass.typeId]) {
                dictClassesMap[dictClass.typeId] = [];
            }
            dictClassesMap[dictClass.typeId].push(dictClass);
        }

        const formatMessage = this.state.formatMessage;

        return (
            <div>
                <DictTypeModal/>
                <DictClassModal/>
                <div style={{padding:'5px'}}>
                    <Button type="primary" size="small" icon="plus" className="spacing-h"
                            onClick={() => this._handleCreate()}>
                    </Button>
                    <Button size="small" icon="edit" className="spacing-h"
                            onClick={() => this._handleModify()}>
                    </Button>
                    <Popconfirm title={formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                                onConfirm={() => this._handleDelete()}>
                        <Button type="danger" size="small" icon="delete">
                        </Button>
                    </Popconfirm>
                </div>
                <Tree
                    showIcon
                    defaultExpandAll
                    onSelect={(selectedKeys, info) => this._handleSelect(selectedKeys, info)}>
                    {
                        this.props.dictTypes.map((item, i) => {
                            return (
                                <Tree.TreeNode icon={<Icon type="cluster"/>} key={item.id} title={item.name}>
                                    {this._renderDictClasses(dictClassesMap[item.id])}
                                </Tree.TreeNode>
                            )
                        })
                    }
                </Tree>
            </div>
        );
    }

    _renderDictClasses(dictClasses) {
        return (
            dictClasses && dictClasses.map((item, i) => {
                return (
                    <Tree.TreeNode icon={<Icon type="tablet"/>} key={item.id} title={item.name}>
                    </Tree.TreeNode>
                )
            })
        );
    }
}

function mapStateToProps(store) {
    return {
        dictTypes: store.DictTypeReducer.dictTypes,
        dictClasses: store.DictClassReducer.dictClasses,
    }
}

export default connect(mapStateToProps)(injectIntl(DictTypeTree));