import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Card, Col, Form, Input, Row} from "antd";
import {
    refreshExaminationSubject,
    refreshSearchCondition,
    searchExaminationSubjects,
    toggleDetailModal,
    toggleImportModal
} from '../../../actions/ExaminationSubjectAction';

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        // this._handleSearch();
    }

    _handleSearch() {
        this.props.dispatch(searchExaminationSubjects(this.props.searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = (value && value.target) ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshExaminationSubject({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleImportModal() {
        this.props.dispatch(toggleImportModal(true));
    }

    render() {
        const {searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_SUBJECT_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.title}
                                       onChange={(value) => this._handleChange("title", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus" type={"green"} className="spacing-h"
                                        onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                                <Button icon="import"
                                        onClick={() => this._handleImportModal()}>
                                    <FormattedMessage id='EXAM_EXAMINATION_BUTTON_IMPORT_FROM_BANK'/>
                                </Button>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        examination: store.ExamExaminationReducer.examination,
        searchCondition: store.ExamExaminationSubjectReducer.searchCondition,
        pagination: store.ExamExaminationSubjectReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));