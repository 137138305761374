import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    positions: [],
    position: {},
    pagination: {},
    searchCondition: {},
    showDetailModal: false
};

const PositionReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ORG_POSITION_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ORG_POSITION_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ORG_POSITION_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ORG_POSITION_REFRESH_POSITION:
            return Object.assign({}, state, {
                position: action.position
            });
        case ActionTypes.ORG_POSITION_SEARCH:
            return Object.assign({}, state, {
                positions: action.positions,
                pagination: action.pagination
            });
        default:
            return state
    }
};

export default PositionReducer;