import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {WhiteSpace, NavBar, Icon, SearchBar, Button, Result, Form, Input} from 'antd-mobile';

import {getExamineeDetail} from "../../../../actions/ExamineeAction";
import {LeftOutline} from "antd-mobile-icons";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const {formatMessage} = this.state;
        return (
            <div>
                <NavBar
                    mode="light"
                    icon={<LeftOutline type="left"/>}
                    onBack={() => this.props.router.goBack()}><FormattedMessage
                    id="EXAM_EXAMINEE_TITLE_VERIFY_FAILED"/></NavBar>

                <Result
                    status='warning'
                    title={formatMessage({id: "EXAM_EXAMINEE_MSG_VERIFY_FAILED"})}
                    description={formatMessage({id: "EXAM_EXAMINEE_MSG_VERIFY_FAILED_DESC"})}/>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
    }
}

export default connect(mapStateToProps)(injectIntl(Index));