import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Button, Card, Collapse, Form, Input, Modal, Table, Tag} from "antd";
import {FORMAT_DATE_TIME_FULL_SIMPLE} from "../../../../../constants/DateTimeFormats";
import {
    ANTClueType,
    ANTMiddleSideConsistent, AppearPosition,
    AttentionAssessmentSubjectType,
    getEnumsLabel, KeyboardCode, MiddleDirection,
    PaperSubjectStatus, SubjectType
} from "../../../Enums";
import moment from 'moment';
import {MoneyFormat} from "../../../../../utils/NumberUtil";
import {
    getPaper,
    handInPaper, handInPaperForLesson,
    refreshPaperSubject,
    togglePaperSubjectModifyModal,
    updatePaper
} from "../../../actions/PaperAction";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {getExamExamineeLesson} from "../../../actions/ExamineeLessonAction";


class PaperSubjectListShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            shipFlankerColumns: [
                {
                    title: "#",
                    dataIndex: 'index',
                    key: 'index',
                    width: 40,
                    render: (text, record, index) => index + 1

                },
                {
                    title: "是否一致",
                    key: 'middleSideConsistent',
                    dataIndex: 'middleSideConsistent',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.middleSideConsistent, ANTMiddleSideConsistent.Map, formatMessage)
                },
                {
                    title: "方向",
                    key: 'middleSideConsistent',
                    dataIndex: 'middleSideConsistent',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.middleDirection, MiddleDirection.Map, formatMessage)
                },
                {
                    title: "屏幕位置",
                    key: 'appearPosition',
                    dataIndex: 'appearPosition',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.appearPosition, AppearPosition.Map, formatMessage)
                },
                {
                    title: "回答结果",
                    dataIndex: 'status',
                    key: 'status',
                    width: 150,
                    render: (text, record, index) => {
                        let statusTag = null;
                        if (record.status === PaperSubjectStatus.Enum.Right) {
                            statusTag = <Tag color={'blue'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        } else if (record.status === PaperSubjectStatus.Enum.Wrong) {
                            statusTag = <Tag color={'red'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        } else {
                            statusTag = <Tag color={'grey'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        }
                        return <span>{statusTag}&nbsp;{record.answer && record.answer !== "" ? KeyboardCode.Map[record.answer] : ""}
                            </span>
                    }

                },
                {
                    title: "反应时长（秒）",
                    key: 'startTime',
                    dataIndex: 'startTime',
                    render: (text, record, index) => {

                        return this._getTimeString(record) * 0.001;


                    }
                },
            ],
            arrowFlankerColumns: [
                {
                    title: "#",
                    dataIndex: 'index',
                    key: 'index',
                    width: 40,
                    render: (text, record, index) => index + 1

                },
                {
                    title: "是否一致",
                    key: 'middleSideConsistent',
                    dataIndex: 'middleSideConsistent',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.middleSideConsistent, ANTMiddleSideConsistent.Map, formatMessage)
                },
                {
                    title: "方向",
                    key: 'middleSideConsistent',
                    dataIndex: 'middleSideConsistent',
                    width: 150,
                    render: (text, record, index) => getEnumsLabel(record.contentJson.middleDirection, MiddleDirection.Map, formatMessage)
                },
                // {
                //     title: "屏幕位置",
                //     key: 'appearPosition',
                //     dataIndex: 'appearPosition',
                //     width: 150,
                //     render: (text, record, index) => getEnumsLabel(record.contentJson.appearPosition, AppearPosition.Map, formatMessage)
                // },
                {
                    title: "回答结果",
                    dataIndex: 'status',
                    key: 'status',
                    // width: 150,
                    render: (text, record, index) => {
                        let statusTag = null;
                        if (record.status === PaperSubjectStatus.Enum.Right) {
                            statusTag = <Tag color={'blue'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        } else if (record.status === PaperSubjectStatus.Enum.Wrong) {
                            statusTag = <Tag color={'red'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        } else {
                            statusTag = <Tag color={'grey'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        }
                        return <span>{statusTag}&nbsp;{record.answer && record.answer !== "" ? KeyboardCode.Map[record.answer] : ""}
                            </span>
                    }

                },
                {
                    title: "反应时长（秒）",
                    key: 'startTime',
                    dataIndex: 'startTime',
                    render: (text, record, index) => {
                        return this._getTimeString(record) * 0.001;
                    }
                },
            ],
            subjectColumns: [
                {
                    title: "#",
                    dataIndex: 'index',
                    key: 'index',
                    width: 40,
                    render: (text, record, index) => index + 1

                },

                {
                    title: "页面显示",
                    dataIndex: 'letter',
                    key: 'letter',
                    // width: 10,
                    render: (text, record, index) => {
                        return record.contentJson ? record.contentJson.letter : ""
                    }
                },

                {
                    title: "回答结果",
                    dataIndex: 'status',
                    key: 'status',
                    // width: 150,
                    render: (text, record, index) => {
                        let statusTag = null;
                        if (record.status === PaperSubjectStatus.Enum.Right) {
                            statusTag = <Tag color={'blue'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        } else if (record.status === PaperSubjectStatus.Enum.Wrong) {
                            statusTag = <Tag color={'red'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        } else {
                            statusTag = <Tag color={'grey'}>
                                {getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)}</Tag>
                        }
                        return <span>
                           {statusTag} &nbsp;{record.answer && record.answer !== "" ? KeyboardCode.Map[record.answer] : ""}</span>
                    }

                    // getEnumsLabel(record.status, PaperSubjectStatus.Map, formatMessage)

                },
                {
                    title: "反应时长（秒）",
                    key: 'startTime',
                    dataIndex: 'startTime',
                    render: (text, record, index) => {
                        if (record.status === PaperSubjectStatus.Enum.Right && record.contentJson.nextWord === "S") {
                            return "0";

                        } else {
                            return this._getTimeString(record) * 0.001;
                        }
                    }
                },
            ],
            reportColumns: [
                {
                    title: "成绩指标",
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => <div>{text}</div>,


                    // width: 200,
                }, {
                    title: "含义",
                    dataIndex: 'desc',
                    key: 'desc',
                    render: (text, record, index) => <div>{text}</div>,


                    // width: 200,
                },
                {
                    title: "得分",
                    dataIndex: 'value',
                    key: 'value',
                    render: (text, record, index) => <div>{text}</div>,
                },

                {
                    title: "能力评级",
                    dataIndex: 'scoreDesc',
                    key: 'scoreDesc',

                    width: 80,

                },
            ],
            showRemarksModal: false,
            remarks: "",
        }

    }

    componentWillMount() {

    }

    _handleCancel() {
        this.setState({showRemarksModal: false});
    }

    _handleOpenRemarksModel(paper) {
        this.setState({
            showRemarksModal: true,
            remarks: paper.remarks
        });
    }

    _handleOk() {
        const {paper} = this.props;
        const {formatMessage} = this.state;
        paper.remarks = this.state.remarks;

        this.props.dispatch(updatePaper(paper, formatMessage, () => {
            this.props.dispatch(getPaper(paper.id, this.state.formatMessage));
            this._handleCancel();
        }));


    }


    _getTimeString(record) {
        if (record.startTime !== "" && record.endTime !== "") {
            let startTime = moment(record.startTime, FORMAT_DATE_TIME_FULL_SIMPLE);
            let endTime = moment(record.endTime, FORMAT_DATE_TIME_FULL_SIMPLE);
            let diff = endTime.diff(startTime);
            return Math.round(diff);
        } else {
            return 0;
        }
    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.currentPaper);
        const subjects = Object.assign([], this.props.subjects);

        this.props.dispatch(handInPaperForLesson(paper, this.state.formatMessage, () => this._handleRefresh()));
    }

    _handleRefresh() {
        const examineeLesson = Object.assign({}, this.props.examineeLesson);


        this.props.dispatch(getExamExamineeLesson(examineeLesson.id, this.state.formatMessage, true));

    }

    _renderRemarksModal() {
        const formatMessage = this.state.formatMessage;

        return <Modal
            maskClosable={false}
            title={"教师备注"}
            visible={this.state.showRemarksModal}
            onOk={() => this._handleOk()}
            onCancel={() => this._handleCancel()}
            okText={formatMessage({id: "COMMON_BUTTON_OK"})}
            cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
            <Form>
                <Form.Item label="备注" {...Label_6_Wrapper_18}>
                    <Input.TextArea autosize={{minRows: 2, maxRows: 6}} value={this.state.remarks}
                                    onChange={(value) => {
                                        this.setState({remarks: value.target ? value.target.value : value})

                                    }}/>

                </Form.Item>

            </Form>


        </Modal>
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const currentExamination = this.props.currentExamination ? this.props.currentExamination : {};

        const paper = this.props.currentPaper ? this.props.currentPaper : {};
        console.log(paper);

        const subjectList = paper.subjects ? paper.subjects : [];
        let subjectInfoMap = {};
        subjectList.forEach(subject => {
            let subItemMap;
            let subjectType = subject.subjectType;
            if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION ||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING ||
                subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT) {
                subjectType = subjectType + subject.difficultyLevel;
            }
            if (subjectInfoMap[subjectType]) {
                subItemMap = subjectInfoMap[subjectType];
            }
            else {
                subItemMap = {blockList: []};
                subjectInfoMap[subjectType] = subItemMap;

            }
            let subList;
            if (subItemMap.blockList.includes(subject.block)) {
                subList = subItemMap[subject.block];

            }
            else {
                subItemMap.blockList.push(subject.block);
                subList = [];
                subItemMap[subject.block] = subList;
            }
            subList.push(subject);

        });
        let clientHeight = document.documentElement.clientHeight - 200;
        return (

            <Card style={{height: clientHeight, overflowY: 'auto'}}>
                {/*{this._renderRemarksModal()}*/}
                <h5>答题详情  &nbsp;
                    <Button type='primary' size="small" disabled={!currentExamination.subjects}
                            onClick={() => this._handleSubmit()}> 重新算分 </Button>
                    &nbsp;</h5>

                {/*<div>教师备注：{paper.remarks&&paper.remarks!==""?paper.remarks:"暂无"} <Button size="small" onClick={() => this._handleOpenRemarksModel(paper)}> 教师备注 </Button></div>*/}
                {/*<br/>*/}
                {currentExamination.subjects && currentExamination.subjects.map(examSubject => {
                    let subjectType = examSubject.type;
                    if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION ||
                        subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING ||
                        subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT) {
                        subjectType = subjectType + examSubject.difficultyLevel;
                    }
                    return this.renderExamSubjectShow(subjectInfoMap, subjectType, paper);
                })}
            </Card>
        );
    }

    _handleModifySubjectModel(subject) {
        this.props.dispatch(refreshPaperSubject(subject));
        this.props.dispatch(togglePaperSubjectModifyModal(true));

    }

    renderBlockShow(blockKey, subjectInfoMapElement, subjectType, showBlockNumber) {

        let subList = Object.assign([], subjectInfoMapElement[blockKey]);
        subList.sort((a, b) => a.sequence - b.sequence);
        let blockMsg = "";
        if (subList.length > 0) {
            blockMsg = subList[0].practiceFlag ? "【练习】" : "【实测】"
        }
        // let formatMessage = this.state.formatMessage;
        console.log(subjectType)
        let columns = this.state.subjectColumns;
        if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SHIP_FLANKER) {
            columns = this.state.shipFlankerColumns;
        } else if (subjectType === AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_ARROW_FLANKER) {
            columns = this.state.arrowFlankerColumns;
        }
        return <Collapse.Panel header={"Block-" + blockKey + " " + blockMsg} key={blockKey}>
            {/*{showBlockNumber ? <p> Block-{blockKey}</p> : null}*/}
            <Table rowKey={(record) => record.id} size="middle" className="spacing-v"
                   columns={columns} bordered={true}
                   dataSource={subList} pagination={false}/>
        </Collapse.Panel>

    }

    renderSingleReport(paper, subjectType) {
        let scoreResultStr = paper.scoreResult;


        if (scoreResultStr && scoreResultStr !== "") {
            const scoreResult = JSON.parse(scoreResultStr);
            let thisScoreResult = scoreResult[subjectType];
            console.log(thisScoreResult)
            let scoreValue=thisScoreResult.completeCount ? Math.round(thisScoreResult.rightCount * 10000 / thisScoreResult.completeCount) / 100 : 0;
            // if (subjectType.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION) ||
            //     subjectType.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING) ||
            //     subjectType.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT)) {
            //     scoreValue = thisScoreResult.completeCount ? Math.round(thisScoreResult.rightCount * 10000 / thisScoreResult.completeCount) / 100 : 0;
            // } else {
            //     scoreValue = thisScoreResult.totalCount ? Math.round(thisScoreResult.rightCount * 10000 / thisScoreResult.totalCount) / 100 : 0;
            // }
            let timeAvg = thisScoreResult.rightTimeAmount / thisScoreResult.rightAnswerCount;
            let dataList = [
                {name: "正确率", desc: "是指儿童对信号刺激、项目问题进行正确应答的能力", value: scoreValue, scoreDesc:this._getScoreDesc(scoreValue)},
                {
                    name: "反应时间",
                    desc: "即反应速度，是指儿童对信号刺激、项目问题进行快速应答的能力。",
                    value: Math.round(timeAvg) +"毫秒",
                    scoreDesc:this._getTimeDesc(timeAvg)
                }
            ];

            return <div><h5>单次反馈报告</h5>
                <Table rowKey={(record, index) => index} size="middle" className="spacing-v"
                       columns={this.state.reportColumns} bordered={true}
                       dataSource={dataList} pagination={false}/></div>
        } else {
            return null;
        }
    }
    _getScoreDesc(score) {
        if (score > 90) {
            return "优秀";
        }  else if (score >= 75) {
            return "良好";
        }
        else if (score >= 60) {
            return "一般";
        } else if (score >= 50) {
            return "较差";
        } else if (score < 50) {
            return "未认真作答，请重做";
        }
    }
    _getTimeDesc(score) {
        if (score > 800) {
            return "一般";
        }  else if (score >= 500) {
            return "良好";
        }
        else {
            return "优秀";
        }
    }
    renderExamSubjectShow(subjectInfoMap, subjectType, paper) {
        let formatMessage = this.state.formatMessage;
        let subjectInfoMapElement = subjectInfoMap[subjectType];
        if (subjectInfoMapElement) {
            let blockList = subjectInfoMapElement.blockList;
            let showBlockNumber = blockList.length > 1;

            return <div key={subjectType} style={{border: "#c8dbfd 1px solid", marginBottom: '10px'}}>
                <div
                    style={{
                        backgroundColor: "#c8dbfd",
                        lineHeight: "26px",
                        padding: '3px 5px'
                    }}> {getEnumsLabel(subjectType, AttentionAssessmentSubjectType.Map, formatMessage)}</div>

                {this.renderSingleReport(paper, subjectType)}
                <div style={{padding: '3px 5px'}}><Collapse>
                    {blockList.map(blockKey => {
                        return this.renderBlockShow(blockKey, subjectInfoMapElement, subjectType, showBlockNumber)
                    })}
                </Collapse>
                </div>
            </div>

        } else {
            return null;
        }
    }
}

function mapStateToProps(store) {
    return {
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        relatedExaminationList: store.ExamineeLessonReducer.relatedExaminationList,
    }
}

export default connect(mapStateToProps)(injectIntl(PaperSubjectListShowParts));