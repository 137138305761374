import "./SiderLayout.css"

import {getMainMenu} from "../actions/AppAction";

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {getSessionUser, logout, togglePasswordChangeModal} from "../modules/Account/actions/UserAction";
import {RoutePath} from '../constants/RoutePath';
import PasswordChange from "../modules/Account/components/PasswordChange";

import {Layout, Menu, Icon, Avatar, Dropdown, Badge} from 'antd';
import {Language} from "../constants/Enums";
import {APP_COPYRIGHT, APP_VERSION} from "../constants/Profile";

const {Header, Footer, Sider, Content} = Layout;

class SiderLayout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            collapsed: false,
        };
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    componentWillMount() {
        this.props.dispatch(getSessionUser(this.state.formatMessage));
        this.props.dispatch(getMainMenu(this.state.formatMessage));
        // this.props.dispatch(getMyResourceMenuTree(this.state.formatMessage));
    }

    render() {
        const {location: {pathname}} = this.props;
        const selectedMenu = this.props.menuItemMap[pathname];
        let parentPaths = [];
        let childrenMenu = [];
        if (selectedMenu) {
            console.log(selectedMenu);
            // parentPaths = selectedMenu.getParentPath();
            let tempParent = selectedMenu.parent;
            while (tempParent != null) {
                childrenMenu = tempParent.children;
                parentPaths.push(tempParent.path);
                tempParent = tempParent.parent;
            }

        }

        console.log(parentPaths);

        // console.log(this.props.menuItems);
        // console.log(this.props.menuItemMap);

        return (
            <Layout id="classic-layout">
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                    width={250}>
                    <div className="sider-header">
                        {/*<img src={this.state.collapsed ?require("../images/logo-min.png"):require("../images/logo.png")} alt="logo"/>*/}
                        <div className="logo">{this.state.collapsed ? <FormattedMessage id="APP_BRAND_COLLAPSED"/> :
                            <FormattedMessage id="APP_BRAND"/>}</div>
                        <div className="trigger">
                            <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                  onClick={this.toggle}/>
                        </div>
                    </div>
                    <Menu mode="inline"
                        //defaultSelectedKeys={['1']}
                          defaultOpenKeys={this.getCurrentOpenKeys()}
                        //onOpenChange={this.handleOpenChange}
                          selectedKeys={this.getCurrentMenuSelectedKeys()}
                          style={{margin: '16px 0', width: '100%'}}>
                        {this._renderMenuItems(childrenMenu)}
                    </Menu>
                </Sider>
                <Layout>
                    <Header style={{background: '#fff', padding: 0, height: '100px'}}>
                        <div className="pull-left">
                            <div className="main-menu">
                                {
                                    this.props.menuItems.map((item, index) => {
                                        const title = this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn;
                                        return (
                                            <Link key={item.path} to={item.path}
                                                  className={parentPaths.includes(item.path) ? "active" : ""}>
                                                <div style={{lineHeight: '60px'}}>
                                                    <Icon type={item.icon} style={{
                                                        fontSize: '28px'
                                                    }}/>
                                                </div>
                                                <div>{title}</div>
                                            </Link>)
                                    })
                                }
                            </div>
                        </div>
                        <div className="pull-right">
                            <PasswordChange/>
                            {this._renderAccount()}
                        </div>
                    </Header>
                    <Content className='page-content'>
                        {this.props.children}
                    </Content>
                    <Footer className="footer">
                        <div className="pull-left">
                            {APP_VERSION}
                        </div>
                        <div className="pull-right">
                            {APP_COPYRIGHT}
                        </div>
                    </Footer>
                </Layout>
            </Layout>
        );
    }

    _renderMenuItems(menusData, parentPath) {
        return menusData.map((item, i) => {
            const titleText = this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn;
            if (!item.hiddenFlag) {
                if (this._hasVisibleChildren(item.children)) {
                    const title = item.icon ? (
                            <span><Icon type={item.icon}/><span>{titleText}</span></span>) :
                        <span>{titleText}</span>;
                    return (
                        <Menu.SubMenu key={item.path} title={title}>
                            {this._renderChildrenMenuItems(item.children, item.path)}
                        </Menu.SubMenu>
                    );
                } else {
                    const newParentPath = (parentPath === undefined || parentPath === '') ? "" : (parentPath + "/");
                    const path = (item.path.indexOf('http') === -1) ? ("/app/" + newParentPath + item.path) : item.path;
                    //console.log(item.path);
                    const title = item.icon ? (
                            <span><Icon type={item.icon}/><span>{titleText}</span></span>) :
                        <span>{titleText}</span>;
                    return (
                        <Menu.Item key={item.path}>
                            <Link to={item.path} target={item.target}>
                                {title}
                                <span style={{float: "right"}}>{item.path === RoutePath.VMS_VISIT_APPROVE_LIST.path ?
                                    <Badge count={this.props.pendingApproves.length}
                                           showZero/> : ""}</span>
                            </Link>
                        </Menu.Item>
                    )
                }
            }
        })
    }

    _hasVisibleChildren(childrenMenus) {
        if (!childrenMenus || childrenMenus.length <= 0) {
            return false;
        }
        for (let childMenu of childrenMenus) {
            if (childMenu.hiddenFlag !== 1) {
                return true;
            }
        }
        return false;
    }

    _renderChildrenMenuItems(menusData, parentPath) {
        return menusData.map((item, i) => {
            const titleText = this.props.currentLanguage === Language.Enum.ZH ? item.nameZh : item.nameEn;
            if (!item.hiddenFlag) {
                const newParentPath = (parentPath === undefined || parentPath === '') ? "" : (parentPath + "/");
                const path = (item.path.indexOf('http') === -1) ? ("/app/" + newParentPath + item.path) : item.path;
                //console.log(item.path);
                const title = item.icon ? (
                        <span><Icon type={item.icon}/><span>{titleText}</span></span>) :
                    <span>{titleText}</span>;
                return (
                    <Menu.Item key={item.path}>
                        <Link to={item.path} target={item.target}>
                            {title}
                        </Link>
                    </Menu.Item>
                )
            }
        })
    }

    _renderAccount() {
        const sessionUser = this.props.sessionUser;
        return (
            <Menu className="account-menu" mode="horizontal" onClick={this.onMenuClick}>
                <Menu.SubMenu
                    title={<span><span className="spacing-h">{sessionUser.fullName}</span>
                        {/*<img className="avatar" style={{height: '60px'}}
                                 src={"data:image/jpeg;base64," + sessionUser.portrait}/>*/}</span>}>
                    <Menu.Item>
                        <Link to={RoutePath.ORG_EMPLOYEE_PROFILE.path}>
                            <Icon type="user"/><FormattedMessage id="ACCOUNT_USER_ROUTE_PROFILE"/>
                        </Link>
                    </Menu.Item>
                    {/*<Menu.Item><Icon type="setting"/>设置</Menu.Item>*/}
                    <Menu.Item key="modifyPassword">
                        <Link to={RoutePath.ACCOUNT_USER_PASSWORD.path}>
                            <Icon type="key"/><FormattedMessage id="ACCOUNT_USER_ROUTE_PASSWORD_MODIFY"/>
                        </Link>
                    </Menu.Item>
                    <Menu.Divider/>
                    <Menu.Item key="logout">
                        <Link to={RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path}>
                            <Icon type="logout"/><FormattedMessage id="ACCOUNT_USER_ROUTE_LOGOUT"/>
                        </Link>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        )
    }

    onMenuClick = ({key}) => {
        switch (key) {
            case'logout':
                this.props.dispatch(logout(this.props.router, this.state.formatMessage));
                break;
            // case "modifyPassword":
            //     this.props.dispatch(togglePasswordChangeModal(true));
            //     break;
        }
    };

    getCurrentMenuSelectedKeys() {
        let keys = [];
        const {location: {pathname}} = this.props;
        keys.push(pathname);
        // console.log(keys);
        return keys;
    }

    getCurrentOpenKeys() {
        let keys = [];
        const {location: {pathname}} = this.props;
        const splits = pathname.split('/').slice(1);
        let path = "";
        for (let i = 0; i < splits.length; i++) {
            path = (path === "") ? splits[i] : path + "/" + splits[i];
            keys.push("/" + path);
        }
        // console.log(keys);
        return keys;
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage,
        menuItems: store.AppReducer.menuItems,
        menuItemMap: store.AppReducer.menuItemMap,
        sessionUser: store.AccountUserReducer.sessionUser
    }
}

export default connect(mapStateToProps)(injectIntl(SiderLayout));