import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Button, Card, Col, Form, Input, Row} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {PaperStatus} from "../../../Enums";
import {refreshPaperSearchCondition, searchPapersWithDataModel} from "../../../actions/ExamDataModelAction";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        let searchCondition = Object.assign({},this.props.searchCondition);
        searchCondition.status = PaperStatus.Enum.Completed;
        this.props.dispatch(searchPapersWithDataModel(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value && value.target ? value.target.value : value;
        this.props.dispatch(refreshPaperSearchCondition(searchCondition));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_EXAMINEE_FIELD_NAME"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.name}
                                       onChange={(value) => this._handleChange("name", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_EXAMINATION_FIELD_CODE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.code}
                                       onChange={(value) => this._handleChange("code", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>

                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.ExamDataModelReducer.paperSearchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));