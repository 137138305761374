/**
 * 邮件发送
 */

import { MAIL_SERVER_HOST} from '../../constants/Profile';


// 邮件配置
export const searchProperties = MAIL_SERVER_HOST + "/mail/properties/query";
export const createProperties = MAIL_SERVER_HOST + "/mail/properties/create";
export const updateProperties = MAIL_SERVER_HOST + "/mail/properties/update";
export const deleteProperties = MAIL_SERVER_HOST + "/mail/properties/delete";
export const getPropertiesDetail = MAIL_SERVER_HOST + "/mail/properties/detail";

// 邮件模版
export const searchTemplates = MAIL_SERVER_HOST + "/mail/template/query";
export const createTemplate = MAIL_SERVER_HOST + "/mail/template/create";
export const updateTemplate = MAIL_SERVER_HOST + "/mail/template/update";
export const deleteTemplate = MAIL_SERVER_HOST + "/mail/template/delete";
export const getTemplateDetail = MAIL_SERVER_HOST + "/mail/template/detail";
// 发送邮箱验证码
export const sendMailVerifyCode = MAIL_SERVER_HOST + "/openAPI/mail/template/sendVerifyCode";

export const mailTemplateTranslate = MAIL_SERVER_HOST + "/mail/templates/translate";
export const updateMailTemplateTranslate = MAIL_SERVER_HOST + "/mail/templates/{id}";

// 邮件任务
export const sendEmailTask = MAIL_SERVER_HOST + "/mail/tasks";
export const searchEmailTasks = MAIL_SERVER_HOST + "/mail/tasks/queries";