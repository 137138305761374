import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Popconfirm, Card} from 'antd';

// import htmlToDraft from 'html-to-draftjs';
//
// import UpdateModal from "./UpdateModal";
// import AttachmentModal from "./AttachmentModal";

import {
    searchContents,
    getContent,
    deleteContent,
    toggleDetailModal,
    refreshContent
} from '../../../actions/ContentAction';
import {RoutePath} from "../../../../../constants/RoutePath";

import PaginationBar from "../../../../../components/PaginationBar";
import {CACHE_SITE_CODE} from "../../../../../constants/Profile";
import {getContentFiles, toggleFilesModal} from "../../../actions/ContentFileAction";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'CMS_CONTENT_FIELD_CODE'}), dataIndex: 'code', key: 'code'},
                {title: formatMessage({id: 'CMS_CONTENT_FIELD_TITLE'}), dataIndex: 'title', key: 'title'},
                {
                    title: formatMessage({id: 'CMS_CONTENT_FIELD_CHANNEL'}),
                    key: 'channel',
                    dataIndex: 'channel.title'
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleCopy.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_COPY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                <Divider type="vertical"/>
                <a onClick={this._handleShowAttachmentModal.bind(this, record)}><FormattedMessage
                    id="CMS_CONTENT_FIELD_FILES"/></a>
            </div>
        )
    }

    _handleCopy(record) {
        this.props.dispatch(getContent(record.id, true, this.state.formatMessage));
        this.props.router.push(RoutePath.CMS_CONTENT_CREATE.path);
    }

    _handleDelete(record) {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);
        const {searchCondition, dispatch} = this.props;
        const state = this.state;
        dispatch(deleteContent(siteCode, record, searchCondition, state.formatMessage));
    }

    _handleModify(record) {
        this.props.dispatch(getContent(record.id, false, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
        // this.props.router.push(RoutePath.CMS_CONTENT_UPDATE.path.replace(":contentId", record.id));
    }

    _handleShowAttachmentModal(record) {
        // 当前操作中的内容对象
        this.props.dispatch(refreshContent(record, this.state.formatMessage));

        this.props.dispatch(getContentFiles(record.id, this.state.formatMessage));
        this.props.dispatch(toggleFilesModal(true));
    }

    render() {
        return (
            <Card className="spacing-v">

                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>

                <Table rowKey="id" size="small" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.contents} pagination={false}/>

            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        const siteCode = localStorage.getItem(CACHE_SITE_CODE);

        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        searchCondition["pageSize"] = pageSize;
        this.props.dispatch(searchContents(siteCode, searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        contents: store.CmsContentReducer.contents,
        searchCondition: store.CmsContentReducer.searchCondition,
        pagination: store.CmsContentReducer.pagination,
        channelId: store.CmsChannelReducer.selectTreeKey
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));