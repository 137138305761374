/**
 * Created by Ethan on 17/3/7.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {injectIntl} from 'react-intl';

import {Checkbox} from 'antd';

class EnableCheckGroup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    _handleChange(checkedValues) {
        this.props.onChange && this.props.onChange.call(this, checkedValues);
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const options = [
            {label: formatMessage({id: "COMMON_FIELD_ENABLED"}), value: 1},
            {label: formatMessage({id: "COMMON_FIELD_DISABLED"}), value: 0}
        ];

        const defaultValues = [];
        if (this.props.defaultChecked){
            defaultValues.push(0);
            defaultValues.push(1);
        }

        return (
            <Checkbox.Group options={options} defaultValue={defaultValues} onChange={(values) => {
                this._handleChange(values)
            }}/>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(EnableCheckGroup));