import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {message} from 'antd';
import {ExaminationClassifyEnums} from "../Enums";

// import {refreshExaminationBanks} from "./ExaminationBankAction";

export function refreshLessonTemplate(lessonTemplate) {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_LESSON_TEMPLATE,
        lessonTemplate: lessonTemplate,
    }
}

export function initLessonTemplate() {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_INIT_LESSON
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}


export function togglePublishModal(isShow) {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_TOGGLE_PUBLISH_MODAL,
        showPublishModal: isShow
    }
}

export function searchLessonTemplates(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchLessonTemplates, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_LESSON_TEMPLATE_SEARCH_LESSON_TEMPLATE_LIST,
                lessonTemplateList: response.data,
                pagination: response.pagination
            });

            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getLessonTemplate(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getLessonTemplate, "id", id);

        HttpUtils.get(requestURL, (response) => {
            dispatch(refreshLessonTemplate(response.data));
            // dispatch(refreshExaminationBanks(response.data.banks));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function createLessonTemplate(lesson, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.createLessonTemplate, lesson, (response) => {
            dispatch(searchLessonTemplates(condition, formatMessage));
            // dispatch(refreshExaminationBanks([]));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updateLessonTemplate(lesson, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.updateLessonTemplate, lesson, (response) => {
            dispatch(searchLessonTemplates(condition, formatMessage));
            // dispatch(refreshExaminationBanks([]));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function deleteLessonTemplate(id, condition, formatMessage) {
    return dispatch => {
        let requestURL = HttpUtils.addQueryString(WebService.deleteLessonTemplate, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchLessonTemplates(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage);
    }
}

//查找所有课时一览，用于课程模版中的数据绑定
export function searchAllExaminationPeriod(formatMessage) {

    let condition = {paginationFlag: false, classify: ExaminationClassifyEnums.Enum.Training};
    return dispatch => {
        dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchExaminations, condition, (response) => {
            dispatch(refreshAllExaminationPeriod(response.data));
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_LESSON_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage)
    }

}

//查找所有课时一览，用于课程模版中的数据绑定
export function refreshAllExaminationPeriod(list) {

    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_LIST,
        allPeriodExaminationList: list
    }
}

export function toggleSelectExaminationModal(isShow) {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_TOGGLE_EXAMINATION_SELECT_MODAL,
        showSelectExaminationModal: isShow
    }
}

export function refreshExaminationSelectedIdList(selectedIdList) {
    return {
        type: ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_EXAMINATION_ID_SELECTED_LIST,
        examinationSelectedIdList: selectedIdList
    }
}

export function searchAllLessonTemplatesList(formatMessage) {
    let condition = {paginationFlag: false};

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: true});

        HttpUtils.post(WebService.searchLessonTemplates, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_LESSON_TEMPLATE_REFRESH_ALL_LIST,
                allLessonTemplateList: response.data,
            });

            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_EXAMINEE_LOADING, isLoading: false});
        }, formatMessage)
    }
}