/**
 * Created by wangyixian on 2018/6/19.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';

// 引入多语言Message
import {injectIntl, FormattedMessage} from 'react-intl';

import {Card, Table, Divider, Switch, Modal, Dropdown, Menu} from 'antd';
import PaginationBar from "../../../../../components/PaginationBar";
import {searchUsers, toggleDetailModal, getUser, deleteUser, updateUser} from '../../../actions/UserAction';

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_UID'}),
                    dataIndex: 'uid',
                    key: 'uid'
                },
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_FULL_NAME'}),
                    dataIndex: 'fullName',
                    key: 'fullName'
                },
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_EMAIL'}), dataIndex: 'email', key: 'email'
                },
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_MOBILE'}), dataIndex: 'mobile', key: 'mobile'
                },
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_ROLE'}), dataIndex: 'role', key: 'role',
                    render: (text, record, index) => this._renderRoles(text, record, index)
                },
                {
                    title: formatMessage({id: 'ACCOUNT_USER_FIELD_DISABLED'}), dataIndex: 'disabled', key: 'disabled',
                    render: (text, record, index) => <Switch checked={record.disabled === 1} onChange={(value) => {
                        this._switchUserDisabled(record, value);
                    }}/>
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _switchUserDisabled(record, value) {
        record["disabled"] = value ? 1 : 0;
        this.props.dispatch(updateUser(record, this.props.searchCondition, this.state.formatMessage));
    }

    _renderRoles(text, record, index) {
        let roles = "";
        if (record.roles !== undefined && record.roles.length > 0) {
            for (let i = 0; i < record.roles.length; i++) {
                const item = record.roles[i];
                if (i > 0) {
                    roles = roles + "," + item.name;
                } else {
                    roles = item.name;
                }
            }
        }
        return (
            <span>{roles}</span>
        );
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleDelete.bind(this, record)}><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
            </div>
        )
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getUser(record.id, this.state.formatMessage));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        Modal.confirm({
            title: formatMessage({id: "ACCOUNT_USER_TITLE_DELETE"}),
            content: formatMessage({id: "ACCOUNT_USER_MSG_DELETE_CONFIRM"}),
            okText: formatMessage({id: "COMMON_BUTTON_OK"}),
            cancelText: formatMessage({id: "COMMON_BUTTON_CANCEL"}),
            okType: 'danger',
            onOk() {
                dispatch(deleteUser(record, searchCondition, formatMessage));
            }
        })
    }

    _onRow(record, index) {
        const self = this;
        return {
            onDoubleClick: (event) => {
                self._handleShowUpdateModal(record);
            },
            onContextMenu: (event) => {
                self.setState({record: record});
            }
        }
    }

    render() {
        const menu = (
            <Menu>
                <Menu.Item key="click" onClick={() => this._handleShowUpdateModal(this.state.record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => this._handleDelete(this.state.record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </Menu.Item>
            </Menu>
        );
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Dropdown overlay={menu} trigger={['contextMenu']}>
                    <div>
                        <Table rowKey="id" size="middle" className="spacing-v"
                               columns={this.state.columns}
                               dataSource={this.props.users}
                               pagination={false}
                               onRow={(record, index) => this._onRow(record, index)}/>
                    </div>
                </Dropdown>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchUsers(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        users: store.AccountUserReducer.users,
        searchCondition: store.AccountUserReducer.searchCondition,
        pagination: store.AccountUserReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));