/**
 * Created by Ethan on 2017/11/24.
 */

const ParseUtils = {

    /**
     * Blobl转成Base64
     * @param blob 二进制格式数据
     * @param callback 转换好以后的回掉方法
     */
    blobToBase64: (blob, callback) => {
        if (!blob) {
            callback("");
            return;
        }

        var reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            callback(base64data);
        }
    },
}

export default ParseUtils;