import * as ActionTypes from '../ActionTypes';
import {EXAM_BANK_SUBJECT_SEARCH_SUBJECT_SORT_LIST} from "../ActionTypes";

const initialState = {
    bank: {},
    banks: [],
    searchCondition: {},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
    showSubjectSortModal: false,
    subjectSortList: [],
};

const BankReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_BANK_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_BANK_REFRESH_BANK:
            return Object.assign({}, state, {
                bank: action.bank
            });
        case ActionTypes.EXAM_BANK_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.EXAM_BANK_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.EXAM_BANK_TOGGLE_SUBJECT_SORT_MODAL:
            return Object.assign({}, state, {
                showSubjectSortModal: action.showSubjectSortModal
            });
        case ActionTypes.EXAM_BANK_SUBJECT_SEARCH_SUBJECT_SORT_LIST:
            return Object.assign({}, state, {
                subjectSortList: action.subjectSortList,
            });
        case ActionTypes.EXAM_BANK_SEARCH_BANK:
            return Object.assign({}, state, {
                banks: action.banks,
                pagination: action.pagination
            });
        default:
            return state
    }
};

export default BankReducer;