import * as ActionTypes from '../ActionTypes';
import {ExamDataModelGrade, ExamDataModelType} from "../Enums";

const initialState = {
    dataModel: {},
    dataModelList: [],
    searchCondition: {paginationFlag:false, type: ExamDataModelType.Enum.ANT_1, grade:ExamDataModelGrade.Enum.GRADE_1},
    pagination: {},
    isLoading: false,
    showDetailModal: false,
    showSubjectSortModal: false,
    subjectSortList: [],
    paperSearchCondition: {},
    paperPagination: {},
    paper: {},
};

const DataModelReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.EXAM_DATA_MODEL_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.EXAM_DATA_MODEL_REFRESH_DATA_MODEL:
            return Object.assign({}, state, {
                dataModel: action.dataModel
            });
        case ActionTypes.EXAM_DATA_MODEL_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        // case ActionTypes.EXAM_DATA_MODEL_TOGGLE_DETAIL_MODAL:
        //     return Object.assign({}, state, {
        //         showDetailModal: action.showDetailModal
        //     });
        case ActionTypes.EXAM_DATA_MODEL_TOGGLE_PAPER_DATA_MODEL_MODAL:
            return Object.assign({}, state, {
                showPaperDataModelModal: action.showPaperDataModelModal
            });
        case ActionTypes.EXAM_DATA_MODEL_SEARCH_LIST:
            return Object.assign({}, state, {
                dataModelList: action.dataModelList,
                pagination: action.pagination
            });
        case ActionTypes.EXAM_DATA_MODEL_SEARCH_PAPER_LIST:
            return Object.assign({}, state, {
                papers: action.papers,
                paperPagination: action.paperPagination
            });
        case ActionTypes.EXAM_DATA_MODEL_REFRESH_PAPER:
            return Object.assign({}, state, {
                paper: action.paper,
            });
        case ActionTypes.EXAM_DATA_MODEL_SEARCH_PAPER_SEARCH_CONDITION:
            return Object.assign({}, state, {
                paperSearchCondition: action.paperSearchCondition
            });
        default:
            return state
    }
};

export default DataModelReducer;