import React, {Component} from 'react';

import Simditor from 'simditor';
import {findDOMNode} from 'react-dom';
import 'simditor/styles/simditor.css';
import moment from "moment";
import {FORMAT_DATE_YEAR_MONTH_SIMPLE} from "../constants/DateTimeFormats";
import {CACHE_ACCESS_TOKEN, FTP_SERVER_HOST} from "../constants/Profile";

export default class TextEditorRichBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        let textbox = findDOMNode(this.componentRef);
        if (this.componentRef) {
            this.editor = new Simditor({
                textarea: textbox,
                toolbar: [
                    'title',
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'fontScale',
                    'color',
                    'ol',
                    'ul',
                    'blockquote',
                    'code',
                    'table',
                    'link',
                    'image',
                    'indent',
                    'outdent',
                    'alignment',
                    'hr',
                ],
                defaultImage: 'images/image.png',
                upload: {
                    url: FTP_SERVER_HOST + "/ftp/oss/file?editorType=Simditor&X-Auth-Token=" + localStorage.getItem(CACHE_ACCESS_TOKEN),
                    params: {
                        folder: "Upload/Files/" + moment().format(FORMAT_DATE_YEAR_MONTH_SIMPLE)
                    },
                    fileKey: 'file',
                    connectionCount: 1,
                    leaveConfirm: 'Uploading is in progress, are you sure to leave this page?'
                },
                pasteImage: true
            });
            // this.props.onLoadEditor && this.props.onLoadEditor.call(this, this.editor);
            // this.props.dispatch(refreshMailContentEditor(this.editor));
            this.editor.setValue(this.props.value);

            //监听改变
            this.editor.on("valuechanged", (e, src) => {
                if (this.props.active) {
                    const html = this.editor.getValue().trim();
                    this._handleChange(html);
                }
            });
        }
    }

    _handleChange(value) {
        this.props.onChange && this.props.onChange.call(this, value);
    }

    render() {

        // if (this.editor) {
        //     this.editor.setValue(this.props.value);
        // }
        return (
            <textarea id="editer" ref={el => {
                this.componentRef = el;
            }} placeholder="" autoFocus></textarea>
        );
    }
}