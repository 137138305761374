import React, {Component} from 'react';

import {FormOutlined} from '@ant-design/icons';
import {Rnd} from "react-rnd";

export default class RectangleParts extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }


    render() {

        let rectangleItem = this.props.rectangleItem;
        return (
            <div className={this.props.isCurrent?"answer-rectangle-select":"answer-rectangle"}
                 style={{
                width: rectangleItem.width + 'px',
                height: rectangleItem.height + 'px'
            }}>

            </div>
        );
    }
}

