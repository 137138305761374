/**
 * Created by Ethan on 17/1/13.
 */

import {FTP_SERVER_HOST} from '../../constants/Profile';

// 文件上传
export const uploadFile = FTP_SERVER_HOST + "/ftp/files/upload";
export const readFileById = FTP_SERVER_HOST + "/ftp/files/{id}/read";
export const uploadOSSFile = FTP_SERVER_HOST + "/ftp/oss/file";
export const getOSSPolicy = FTP_SERVER_HOST + "/ftp/oss/policy";
export const uploadOSSBase64 = FTP_SERVER_HOST +"/ftp/oss/base64";
export const uploadOSSFiles = FTP_SERVER_HOST + "/ftp/oss/files";

// 下载文件
export const downloadFile = FTP_SERVER_HOST + "/ftp/file";
export const downloadFileById = FTP_SERVER_HOST + "/ftp/files/{id}/download";
export const deleteFile = FTP_SERVER_HOST + "/ftp/files/{id}";
