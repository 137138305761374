import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {Button, Row, Card, Col, Table, Tag} from "antd";
import {handInPaper} from "../../../actions/PaperAction";
import {DateTimeFormat} from "../../../../../utils/DateUtil";
import {AttentionAssessmentSubjectType, getEnumsLabel, PaperStatus} from "../../../Enums";
import PaperSubjectListShowParts from "./PaperSubjectListShowParts";
import {getExamExamineeLesson} from "../../../actions/ExamineeLessonAction";

class PaperScoreShowParts extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            currentPaper: "",
            columns: [
                {
                    title: "课时",
                    dataIndex: 'title',
                    key: 'title',
                    width: 100,
                    render: (text, record) => {
                        if (record.examination) {
                            return record.examination.title;
                        }
                        return ""
                    }
                },

                {
                    title: "完成情况",
                    dataIndex: 'status',
                    width: 150,
                    key: 'status',
                    render: (text, record) => {
                        let paper = record.paper;
                        if (paper) {
                            if (paper.status === PaperStatus.Enum.Completed) {

                                return <span>已上课<br/> ({
                                    DateTimeFormat.dateToHyphenString(paper.createTime)})</span>

                            } else if (paper.status === PaperStatus.Enum.New) {
                                return <span>进行中<br/> ({
                                    DateTimeFormat.dateToHyphenString(paper.createTime)})</span>

                            }
                        }
                        return "-"
                    }
                },
                {
                    title: "训练情况",
                    dataIndex: 'scoreResult',
                    key: 'scoreResult',
                    render: (text, record) => {
                        if (record.paper) {
                            let scoreResultStr = record.paper.scoreResult;
                            const scoreResult = scoreResultStr && scoreResultStr !== "" ? JSON.parse(scoreResultStr) : {};
                            if (scoreResult) {

                                return <div>{
                                    Object.keys(scoreResult).map((type, index) => {
                                        let subjectResult = scoreResult[type];
                                        let totalCount= subjectResult.totalCount;

                                        if (type.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_VISION) ||
                                            type.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_HEARING) ||
                                            type.startsWith(AttentionAssessmentSubjectType.Enum.ATTENTION_TRAINING_SQUIRREL_CPT)) {
                                            totalCount = subjectResult.completeCount;
                                        }
                                        return <div key={type}>
                                            <Tag
                                                color={"blue"}>{getEnumsLabel(type, AttentionAssessmentSubjectType.Map, formatMessage)}
                                            </Tag>
                                            <br/>
                                            <div>
                                                {/*    得分:<span className="span-underline">{subjectResult.score*/}
                                                {/*}</span><br/>*/}
                                                正确率: <span className="span-underline">{totalCount ?
                                                Math.round(subjectResult.rightCount * 10000 /totalCount) / 100 : 0}% </span> ,
                                                总答题数: <span
                                                className="span-underline">{totalCount} </span> ,
                                                正确答题数: <span
                                                className="span-underline">{subjectResult.rightCount} </span>
                                            </div>
                                        </div>
                                    })
                                }</div>
                            }
                        }
                        return "-"
                    }
                },
                // {
                //     title: "上课时间",
                //     dataIndex: 'scoreInModel',
                //     key: 'scoreInModel',
                //     render: (text, record) => {
                //         if (record.paper) {
                //             return DateTimeFormat.dateToHyphenString(record.paper.createTime)
                //
                //         }
                //         return "-"
                //     }
                // },
                {
                    title: "操作",
                    dataIndex: 'action',
                    key: 'action',
                    width: 100,
                    render: (text, record) => {
                        if (record.paper) {
                            if (this.state.currentPaper === record.paper.id) {
                                return <Tag color="cyan">当前课时</Tag>
                            }
                            else {
                                return <Button size={"small"} type={'primary'}
                                               onClick={() => this.setState({currentPaper: record.paper.id})}>选择</Button>
                            }
                        }


                    }
                },

            ]
        }

    }

    componentWillMount() {

    }

    _handleSubmit() {
        const paper = Object.assign({}, this.props.paper);
        const subjects = Object.assign([], this.props.subjects);

        this.props.dispatch(handInPaper(paper, this.state.formatMessage));
    }
    _handleRefresh() {
        const examineeLesson = Object.assign({}, this.props.examineeLesson);


        this.props.dispatch(getExamExamineeLesson(examineeLesson.id, this.state.formatMessage, true));

    }
    render() {
        const formatMessage = this.state.formatMessage;
        const examineeLesson = this.props.examineeLesson ? this.props.examineeLesson : {};
        let examinationIds = examineeLesson.examinationIds;
        let examinee = examineeLesson.examinee ? examineeLesson.examinee : {};
        let examinationIdList = examinationIds ? examinationIds.split(",") : [];


        const relatedExaminationList = this.props.relatedExaminationList ? this.props.relatedExaminationList : [];
        let examinationMap = {};
        relatedExaminationList.forEach(item => examinationMap[item.id] = item);

        const relatedPaperList = this.props.relatedPaperList ? this.props.relatedPaperList : [];
        let paperMap = {};
        let currentPaper = null;
        let currentExamination = null;
        relatedPaperList.forEach(item => {
            paperMap[item.examinationId] = item;
            if (this.state.currentPaper === item.id) {
                currentPaper = item;
                currentExamination = examinationMap[item.examinationId];
            }
        });

        let dataList = [];
        examinationIdList.forEach(id => {
            if (examinationMap [id]) {
                let data = {};
                data.id = id;
                data.examination = examinationMap [id];
                data.paper = paperMap [id];
                dataList.push(data);
            }

        });

        let clientHeight = document.documentElement.clientHeight - 400;
        return (
            <Row><Col span={12}>
                <Card>
                    <h5>{formatMessage({id: 'EXAM_EXAMINEE_FIELD_NAME'})}: {examinee.lastName + examinee.firstName}</h5>
                    <h5>{formatMessage({id: 'EXAM_LESSON_FIELD_PERIOD_COUNT'})}: {examinationIdList.length}</h5>
                    <h5>{formatMessage({id: 'EXAM_LESSON_FIELD_PAPER_COUNT'})}: {relatedPaperList.length}</h5>
                    <Button size="small" type="green" onClick={()=>this._handleRefresh()}>刷新数据</Button>
                    {/*<h5>{formatMessage({id: 'EXAM_EXAMINEE_LESSON_FIELD_INFORMATION'})}</h5>*/}
                    <div style={{border: "#c8dbfd 1px solid", marginBottom: '10px'}}>
                        <Table rowKey={(record, index) => index} bordered={true}
                               size="middle" columns={this.state.columns}
                               scroll={{y: clientHeight}}
                               dataSource={dataList} pagination={false}/>
                    </div>

                </Card>
            </Col>
                <Col span={12}>

                    <PaperSubjectListShowParts currentPaper={currentPaper}
                                               currentExamination={currentExamination}/></Col>
            </Row>
        );
    }

}

function mapStateToProps(store) {
    return {
        examineeLesson: store.ExamineeLessonReducer.examineeLesson,
        relatedExaminationList: store.ExamineeLessonReducer.relatedExaminationList,
        relatedPaperList: store.ExamineeLessonReducer.relatedPaperList,

    }
}

export default connect(mapStateToProps)(injectIntl(PaperScoreShowParts));