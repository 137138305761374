import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Table, Divider, Popconfirm} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";

import {
    getResource,
    toggleDetailModal,
    deleteResource,
    searchResources
} from '../../../actions/ResourceAction';
import EnumItemLabel from "../../../../../components/EnumItemLabel";
import {ResourceTypes} from "../../../Enums";
import BoolItemLabel from "../../../../../components/BoolItemLabel";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                // {
                //     key: 'code',
                //     dataIndex: 'code',
                //     title: formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_CODE'})
                // },
                {key: 'nameZh', dataIndex: 'nameZh', title: formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_NAME_ZH'})},
                // {key: 'nameEn', dataIndex: 'nameEn', title: formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_NAME_EN'})},
                {
                    key: 'type',
                    dataIndex: 'type',
                    title: formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_TYPE'}),
                    render: (text, record, index) => <EnumItemLabel map={ResourceTypes.Map} code={text}/>
                },
                {key: 'path', dataIndex: 'path', title: formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_PATH'})},
                {key: 'sequence', dataIndex: 'sequence', title: formatMessage({id: 'ACCOUNT_RESOURCE_FIELD_SEQUENCE'})},
                {
                    key: 'action',
                    dataIndex: 'action',
                    // width: 100,
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        const {formatMessage} = this.state;
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <a onClick={this._handleCopy.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_COPY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                            cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleCopy(record) {
        this.props.dispatch(toggleDetailModal(true));
        this.props.dispatch(getResource(record.id, this.state.formatMessage, true));
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getResource(record.id, this.state));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        console.log(record);
        this.props.dispatch(deleteResource(record, this.props.searchCondition, this.state.formatMessage));
    }

    render() {
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v"
                       columns={this.state.columns}
                       dataSource={this.props.resources} pagination={false}/>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {

        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchResources(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        resources: store.AccountResourceReducer.resources,
        searchCondition: store.AccountResourceReducer.searchCondition,
        pagination: store.AccountResourceReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));