import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

import {toggleDetailModal, refreshBank, createBank, updateBank} from '../../../actions/BankAction';

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(bank) {
        let messageMap = {};
        if (bank.title === undefined || bank.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'EXAM_BANK_MSG_TITLE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {bank, searchCondition} = this.props;
        const {formatMessage} = this.state;
        if (this._validate(bank)) {
            if (bank.id === undefined || bank.id === "") {
                this.props.dispatch(createBank(bank, searchCondition, formatMessage));
            } else {
                this.props.dispatch(updateBank(bank, searchCondition, formatMessage));
            }

        }
    }

    _handleChange(name, value) {

        const bank = Object.assign({}, this.props.bank);
        bank[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshBank(bank));
    }

    render() {
        const {dispatch, bank} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = formatMessage({id: 'EXAM_BANK_TITLE_CREATE'});
        if (bank.id !== undefined && bank.id !== "") {
            title = formatMessage({id: 'EXAM_BANK_TITLE_UPDATE'});
        }
        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'EXAM_BANK_FIELD_TITLE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["title"] ? "error" : ""}
                               help={this.state.messageMap["title"]}>
                        <Input value={bank.title}
                               onChange={(data) => {
                                   this._handleChange("title", data)
                               }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        bank: store.ExamBankReducer.bank,
        showDetailModal: store.ExamBankReducer.showDetailModal,
        searchCondition: store.ExamBankReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));