import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    resources: [],
    resource: {},
    selectedResourceIds: [],
    searchCondition: {},
    showDetailModal: false,
    pagination: {},
    resourceTree: [],
    myResourceMenuTree: []
};

const ResourceReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_RESOURCE_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ACCOUNT_RESOURCE_REFRESH_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        case ActionTypes.ACCOUNT_RESOURCE_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ACCOUNT_RESOURCE_REFRESH_RESOURCE:
            return Object.assign({}, state, {
                resource: action.resource
            });
        case ActionTypes.ACCOUNT_RESOURCE_SEARCH:
            return Object.assign({}, state, {
                resources: action.resources,
                pagination: action.pagination
            });
        case ActionTypes.ACCOUNT_RESOURCE_REFRESH_SELECTED_RESOURCE_IDS:
            return Object.assign({}, state, {
                selectedResourceIds: action.selectedResourceIds
            });
        case ActionTypes.ACCOUNT_RESOURCE_TREE:
            return Object.assign({}, state, {
                resourceTree: action.resourceTree
            });
        case ActionTypes.ACCOUNT_RESOURCE_MY_MENU_TREE:
            return Object.assign({}, state, {
                myResourceMenuTree: action.myResourceMenuTree
            });
        default:
            return state
    }
};

export default ResourceReducer;