import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Alert, Card, InputNumber, DatePicker, Button} from "antd";

import {Label_6_Wrapper_18, Label_8_Wrapper_16} from "../../../../../constants/FormItemLayout";

import {
    createExamination, initExamination,
    refreshExamination,
    togglePublishModal,
    updateExamination
} from "../../../actions/ExaminationAction";

import {ExaminationStatus} from "../../../Enums";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import EnumItemSelect from "../../../../../components/EnumItemSelect";
import HttpUtils from "../../../../../utils/HttpUtils";
import {RoutePath} from "../../../../../constants/RoutePath";

import QRCode from 'qrcode.react';

class PublishModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _handleCancel() {
        this.props.dispatch(togglePublishModal(false));
    }

    render() {
        const {examination, pagination} = this.props;
        const formatMessage = this.state.formatMessage;
        let qrPath = window.location.origin + "/#" + RoutePath.EXAM_MOBILE_EXAMINATION.path;
        qrPath = HttpUtils.addQueryString(qrPath, "id", examination.id);
        const recordSum = pagination && pagination.recordSum ? pagination.recordSum : 0;

        return (
            <Modal
                maskClosable={false}
                title={formatMessage({id: 'EXAM_EXAMINATION_TITLE_PUBLISH'})}
                visible={this.props.showPublishModal}
                onCancel={() => this._handleCancel()}
                footer={[
                    <Button type="primary" key="id" onClick={() => this._handleCancel()}>
                        <FormattedMessage id="COMMON_BUTTON_CLOSE"/>
                    </Button>
                ]}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                {
                    recordSum > 0 ?
                        <div className="text-center">
                            <Alert message={formatMessage({id: "EXAM_EXAMINATION_MSG_PUBLISH_DESCRIPTION"})} type="info"
                                   showIcon/>
                            <br/>
                            <QRCode value={qrPath}/>
                            <p>{qrPath}</p>
                        </div> :
                        <div className="text-center">
                            {formatMessage({id: "EXAM_EXAMINATION_MSG_ADD_SUBJECT"})}
                        </div>
                }

            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        showPublishModal: store.ExamExaminationReducer.showPublishModal,
        examination: store.ExamExaminationReducer.examination,
        pagination: store.ExamExaminationSubjectReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(PublishModal));