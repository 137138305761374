import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, InputNumber} from "antd";

import {
    toggleDetailModal,
    refreshDataGroup,
    createDataGroup,
    updateDataGroup
} from '../../../actions/DataGroupAction';
import TreeSelector from "../../../../../components/TreeSelector";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(dataGroup) {
        let messageMap = {};
        if (dataGroup.code === undefined || dataGroup.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'ACCOUNT_DATA_GROUP_MSG_CODE_REQUIRED'});
        }
        if (dataGroup.name === undefined || dataGroup.name === "") {
            messageMap["name"] = this.state.formatMessage({id: 'ACCOUNT_DATA_GROUP_MSG_NAME_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {dataGroup, searchCondition} = this.props;
        if (this._validate(dataGroup)) {
            if (dataGroup.id === undefined || dataGroup.id === "") {
                this.props.dispatch(createDataGroup(dataGroup, searchCondition, this.state.formatMessage));
            } else {
                this.props.dispatch(updateDataGroup(dataGroup, searchCondition, this.state.formatMessage));
            }
        }
    }

    _handleChange(name, value) {

        const dataGroup = Object.assign({}, this.props.dataGroup);
        dataGroup[name] = (value && value.target) ? value.target.value : value;

        console.log(dataGroup);
        this.props.dispatch(refreshDataGroup(dataGroup));
    }

    render() {
        const {showDetailModal, dataGroup, dataGroupTree} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = "";
        if (dataGroup.id === undefined || dataGroup.id === "") {
            title = formatMessage({id: "ACCOUNT_DATA_GROUP_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "ACCOUNT_DATA_GROUP_TITLE_UPDATE"});
        }
        return (
            <Modal
                title={title} maskClosable={false}
                visible={showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_PARENT'})} {...Label_6_Wrapper_18}>
                        <TreeSelector treeData={dataGroupTree}
                                      value={dataGroup.parentId}
                                      onChange={(value) => this._handleChange("parentId", value)}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_CODE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={dataGroup.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_NAME'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={dataGroup.name}
                               onChange={(value) => {
                                   this._handleChange("name", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_SEQUENCE'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={dataGroup.sequence}
                                     onChange={(value) => {
                                         this._handleChange("sequence", value)
                                     }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        dataGroup: store.AccountGroupReducer.dataGroup,
        dataGroupTree: store.AccountGroupReducer.dataGroupTree,
        showDetailModal: store.AccountGroupReducer.showDetailModal,
        searchCondition: store.AccountGroupReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));