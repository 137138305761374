import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import PaperSubjectListShowParts from "./PaperSubjectListShowParts";
import {getPaperDetail, handInPaper} from "../../../actions/PaperAction";
import PaperScoreShowParts from "./PaperScoreShowParts";
import PaperReportShowParts from "./PaperReportShowParts";
import {Tabs} from "antd";
import {RoutePath} from "../../../../../constants/RoutePath";
import {AttentionAssessmentSubjectType, ExaminationStatus, PaperStatus} from "../../../Enums";
import PaperReportSingleShowParts from "./PaperReportSingleShowParts";

class Index extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
        }
    }

    componentWillMount() {
        const paperId = this.props.location.query.paperId;
        this.props.dispatch(getPaperDetail(paperId, this.state.formatMessage));


    }

    render() {
        const paper = Object.assign({}, this.props.paper);
        const hasResult = true;//paper.status === ExaminationStatus.Enum.Completed;
        return (
            <div>
                <Tabs defaultActiveKey="3">
                    <Tabs.TabPane tab={"答题详情"} key="1">
                        <PaperSubjectListShowParts/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={"测评结果"} key="2">
                        <PaperScoreShowParts/>
                    </Tabs.TabPane>

                    {hasResult ? <Tabs.TabPane tab={"测评报告"} key="3">
                        <PaperReportShowParts/>
                    </Tabs.TabPane> : null}
                    {hasResult ?
                        <Tabs.TabPane tab={"测评报告-GoNoGo"} key="4">
                            <PaperReportSingleShowParts subjectType={AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_GO_NO}/>
                        </Tabs.TabPane> : null
                    }
                    {hasResult ?
                        <Tabs.TabPane tab={"测评报告-注意网络"} key="5">
                            <PaperReportSingleShowParts subjectType={AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_PAY_ATTENTION}/>
                        </Tabs.TabPane> : null
                    }

                    {hasResult ?
                        <Tabs.TabPane tab={"测评报告-数字划销"} key="6">
                            <PaperReportSingleShowParts subjectType={AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_DIGITAL_WRITE_OFF}/>
                        </Tabs.TabPane> : null
                    }
                    {hasResult ?
                        <Tabs.TabPane tab={"测评报告-找一找"} key="7">
                            <PaperReportSingleShowParts subjectType={AttentionAssessmentSubjectType.Enum.ATTENTION_ASSESSMENT_FIND_IT}/>
                        </Tabs.TabPane> : null
                    }
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(Index));