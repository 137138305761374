import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, Select, InputNumber} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {createDictItem, refreshDictItem, toggleDetailModal, updateDictItem} from "../../../actions/DictItemAction";
import {Language} from "../../../../../constants/Enums";

class DictItemModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    _validate(dictItem) {
        let messageMap = {};
        if (dictItem.classId === undefined || dictItem.classId === "") {
            messageMap["classId"] = this.state.formatMessage({id: 'DICT_ITEM_MSG_CLASS_REQUIRED'});
        }
        if (dictItem.code === undefined || dictItem.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'DICT_ITEM_MSG_CODE_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.setState({messageMap: {}});
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const dictItem = this.props.dictItem;
        if (this._validate(this.props.dictItem)) {
            if (dictItem.id !== undefined && dictItem.id !== "") {
                this.props.dispatch(updateDictItem(this.props.dictItem, this.state.formatMessage));
            } else {
                this.props.dispatch(createDictItem(this.props.dictItem, this.state.formatMessage));
            }
        }
    }

    _handleChange(name, value) {

        const dictItem = Object.assign({}, this.props.dictItem);
        dictItem[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshDictItem(dictItem));
    }

    render() {
        const {dictItem} = this.props;
        const {formatMessage} = this.state;

        let title = formatMessage({id: 'DICT_ITEM_TITLE_CREATE'});
        if (dictItem.id !== undefined && dictItem.id !== "") {
            title = formatMessage({id: "DICT_ITEM_TITLE_UPDATE"});
        }

        return (
            <Modal
                title={title}
                maskClosable={false}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={this.state.formatMessage({id: 'COMMON_BUTTON_OK'})}
                cancelText={this.state.formatMessage({id: 'COMMON_BUTTON_CANCEL'})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_CLASS'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["classId"] ? "error" : ""}
                               help={this.state.messageMap["classId"]}>
                        <Select value={dictItem.classId}
                                onChange={(value) => this._handleChange("classId", value)}>
                            {
                                this.props.dictClasses.map((item, index) => {
                                    return <Select.Option key={index} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_CODE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={dictItem.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_NAME_ZH'})} {...Label_6_Wrapper_18}>
                        <Input value={dictItem.nameZh}
                               onChange={(value) => {
                                   this._handleChange("nameZh", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_NAME_EN'})} {...Label_6_Wrapper_18}>
                        <Input value={dictItem.nameEn}
                               onChange={(value) => {
                                   this._handleChange("nameEn", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_VALUE'})} {...Label_6_Wrapper_18}>
                        <Input value={dictItem.value}
                               onChange={(value) => {
                                   this._handleChange("value", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_SEQUENCE'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={dictItem.sequence} onChange={(value) => {
                            this._handleChange("sequence", value)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_REMARKS'})} {...Label_6_Wrapper_18}>
                        <Input.TextArea value={dictItem.remarks}
                               onChange={(value) => {
                                   this._handleChange("remarks", value)
                               }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentLanguage: store.AppReducer.currentLanguage,
        dictClasses: store.DictClassReducer.dictClasses,
        dictItem: store.DictItemReducer.dictItem,
        showDetailModal: store.DictItemReducer.showDetailModal
    }
}

export default connect(mapStateToProps)(injectIntl(DictItemModal));