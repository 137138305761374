import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Table, Divider, Dropdown, Menu, Modal, Popconfirm} from 'antd';

import PaginationBar from "../../../../../components/PaginationBar";

import {
    getDataGroupById,
    toggleDetailModal,
    deleteDataGroup,
    searchDataGroups,
    refreshSearchCondition
} from '../../../actions/DataGroupAction';

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {key: 'code', dataIndex: 'code', title: formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_CODE'})},
                {key: 'name', dataIndex: 'name', title: formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_NAME'})},
                {key: 'sequence', dataIndex: 'sequence', title: formatMessage({id: 'ACCOUNT_DATA_GROUP_FIELD_SEQUENCE'})},
                {
                    key: 'action',
                    dataIndex: 'action',
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        const {formatMessage} = this.state;
        return (
            <div>
                <a onClick={this._handleShowUpdateModal.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                            cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleShowUpdateModal(record) {
        this.props.dispatch(getDataGroupById(record.id, this.state));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleDelete(record) {
        const {dispatch, searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteDataGroup(record, searchCondition, formatMessage));
    }

    _onRow(record, index) {
        const self = this;
        return {
            onDoubleClick: (event) => {
                self._handleShowUpdateModal(record);
            },
            onContextMenu: (event) => {
                self.setState({record: record});
            }
        }
    }

    render() {
        const menu = (
            <Menu>
                <Menu.Item key="click" onClick={() => this._handleShowUpdateModal(this.state.record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </Menu.Item>
                <Menu.Item key="delete" onClick={() => this._handleDelete(this.state.record)}>
                    <FormattedMessage id="COMMON_BUTTON_DELETE"/>
                </Menu.Item>
            </Menu>
        );
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Dropdown overlay={menu} trigger={['contextMenu']}>
                    <div>
                        <Table rowKey="id" size="middle" className="spacing-v"
                               columns={this.state.columns}
                               dataSource={this.props.dataGroups} pagination={false}
                               onRow={(record, index) => this._onRow(record, index)}/>
                    </div>
                </Dropdown>
            </Card>
        )
    }

    _handlePagination(page, pageSize) {

        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;

        this.props.dispatch(searchDataGroups(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        dataGroups: store.AccountGroupReducer.dataGroups,
        searchCondition: store.AccountGroupReducer.searchCondition,
        pagination: store.AccountGroupReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));