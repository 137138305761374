import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {
    importExaminee,
    refreshExaminee, refreshImportResult,
    refreshSearchCondition,
    searchExaminees,
    toggleDetailModal, toggleImportModal
} from "../../../actions/ExamineeAction";
import ImportModal from "../../../components/ImportModal";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    key: 'lastName',
                    dataIndex: 'lastName',
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_LAST_NAME'})
                },
                // {
                //     key: 'firstName',
                //     dataIndex: 'firstName',
                //     title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_FIRST_NAME'})
                // },
                {
                    key: 'idNumber',
                    dataIndex: 'idNumber',
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_ID_NUMBER'})
                },
                {
                    key: 'mobile',
                    dataIndex: 'mobile',
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_MOBILE'})
                },
                {
                    key: 'email',
                    dataIndex: 'email',
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_EMAIL'})
                },
                {
                    key: 'result',
                    dataIndex: 'result',
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_RESULT'}),
                    render: (text, record, index) => <div className="overFlowEllipsis"
                                                          style={{width: 200}}>{record.result}</div>
                }
            ],
            dataList: []
        }
    }

    _validate(dataList) {
        let resultMap = {};
        let resultList = [];
        for (let i = 0; i < dataList.length; i++) {
            let data = dataList[i];
            if (data.lastName === undefined || data.lastName === "") {
                data.result = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_LAST_NAME_REQUIRED'});
                resultMap[data.idNumber] = data;
                resultList.push(data);
                continue;
            }
            if (data.firstName === undefined || data.firstName === "") {
                data.result = this.state.formatMessage({id: 'EXAM_EXAMINEE_MSG_FIRST_NAME_REQUIRED'});
                resultMap[data.idNumber] = data;
                resultList.push(data);
                continue;
            }

            resultList.push(data);
        }

        if (Object.keys(resultMap).length > 0) {
            this.props.dispatch(refreshImportResult(resultList));
        } else {
            this.props.dispatch(importExaminee(resultList, this.props.searchCondition, this.state.formatMessage, this.props.intl));
        }
    }

    componentWillMount() {
        this._handleSearch();
    }

    _handleSearch() {
        this.props.dispatch(searchExaminees(this.props.searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value && value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshExaminee({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    _handleShowImportModal() {
        this.props.dispatch(refreshImportResult([]));
        this.props.dispatch(toggleImportModal(true));
    }

    render() {
        const formatMessage = this.state.formatMessage;
        const searchCondition = this.props.searchCondition;
        return (
            <Card>
                <ImportModal showImportModal={this.props.showImportModal}
                             columns={this.state.columns}
                             dataList={this.props.importResult}
                             onOk={(dataList) => {
                                 console.log(dataList);
                                 this._validate(dataList);
                             }}
                             onCancel={() => {
                                 this.props.dispatch(toggleImportModal(false));
                             }}/>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_EXAMINEE_FIELD_NAME"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.name}
                                       onChange={(value) => this._handleChange("name", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_EXAMINEE_FIELD_MOBILE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.mobile}
                                       onChange={(value) => this._handleChange("mobile", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus" className="spacing-h"
                                        onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                                <Button icon="import" className="spacing-h"
                                        onClick={() => this._handleShowImportModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_IMPORT'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.ExamExamineeReducer.searchCondition,
        showImportModal: store.ExamExamineeReducer.showImportModal,
        pagination: store.ExamExamineeReducer.pagination,
        importResult: store.ExamExamineeReducer.importResult,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));