import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, InputNumber} from "antd";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import {createDictType, refreshDictType, toggleDetailModal, updateDictType} from "../../../actions/DictTypeAction";

class DictTypeModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    _validate(dictType) {
        let messageMap = {};
        if (dictType.code === undefined || dictType.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'DICT_TYPE_MSG_CODE_REQUIRED'});
        }
        if (dictType.name === undefined || dictType.name === "") {
            messageMap["name"] = this.state.formatMessage({id: 'DICT_TYPE_MSG_NAME_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.setState({messageMap: {}});
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const dictType = this.props.dictType;
        if (this._validate(this.props.dictType)) {
            if (dictType.id !== undefined && dictType.id !== "") {
                this.props.dispatch(updateDictType(this.props.dictType, this.state.formatMessage));
            } else {
                this.props.dispatch(createDictType(this.props.dictType, this.state.formatMessage));
            }
        }
    }

    _handleChange(name, value) {

        const dictType = Object.assign({}, this.props.dictType);
        dictType[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshDictType(dictType));
    }

    render() {
        const {dictType} = this.props;
        const {formatMessage} = this.state;

        let title = formatMessage({id: 'DICT_TYPE_TITLE_CREATE'});
        if (dictType.id !== undefined && dictType.id !== "") {
            title = formatMessage({id: "DICT_TYPE_TITLE_UPDATE"});
        }

        return (
            <Modal
                title={title}
                maskClosable={false}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={this.state.formatMessage({id: 'COMMON_BUTTON_OK'})}
                cancelText={this.state.formatMessage({id: 'COMMON_BUTTON_CANCEL'})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'DICT_TYPE_FIELD_CODE'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={dictType.code}
                               onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_TYPE_FIELD_NAME'})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={dictType.name}
                               onChange={(value) => {
                                   this._handleChange("name", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'DICT_ITEM_FIELD_SEQUENCE'})} {...Label_6_Wrapper_18}>
                        <InputNumber value={dictType.sequence} onChange={(value) => {
                            this._handleChange("sequence", value)
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        dictType: store.DictTypeReducer.dictType,
        showDetailModal: store.DictTypeReducer.showDetailModal
    }
}

export default connect(mapStateToProps)(injectIntl(DictTypeModal));