import * as ActionTypes from '../ActionTypes';

const initialState = {
    isLoading: false,
    dataGroups: [],
    dataGroupTree: [],
    dataGroup: {},
    pagination: {},
    showDetailModal: false,
    searchCondition: {}
};

const DataGroupReducer = (state = initialState, action = 'none') => {
    switch (action.type) {
        case ActionTypes.ACCOUNT_DATA_GROUP_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading
            });
        case ActionTypes.ACCOUNT_DATA_GROUP_REFRESH_DATA_GROUP:
            return Object.assign({}, state, {
                dataGroup: action.dataGroup
            });
        case ActionTypes.ACCOUNT_DATA_GROUP_SEARCH:
            return Object.assign({}, state, {
                dataGroups: action.dataGroups,
                pagination: action.pagination
            });
        case ActionTypes.ACCOUNT_DATA_GROUP_TREE:
            return Object.assign({}, state, {
                dataGroupTree: action.dataGroupTree
            });
        case ActionTypes.ACCOUNT_DATA_GROUP_TOGGLE_DETAIL_MODAL:
            return Object.assign({}, state, {
                showDetailModal: action.showDetailModal
            });
        case ActionTypes.ACCOUNT_DATA_GROUP_SEARCH_CONDITION:
            return Object.assign({}, state, {
                searchCondition: action.searchCondition
            });
        default:
            return state
    }
};

export default DataGroupReducer;