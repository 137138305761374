/**
 * Created by wangyixian on 2018/6/19.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Button} from 'antd';

import {refreshDictItem, toggleDetailModal} from "../../../actions/DictItemAction";
import DictItemModal from "./DictItemModal";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshDictItem({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        return (
            <div className="text-right">
                <DictItemModal/>
                <Button type="primary" size="small" icon="plus"
                        onClick={() => this._handleShowCreateModal()}>
                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                </Button>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {}
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));