import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, InputNumber} from "antd";
import {Label_4_Wrapper_20} from "../../../../../constants/FormItemLayout";
import {createContentFile, refreshFile, toggleDetailModal, updateContentFile} from "../../../actions/ContentFileAction";


class ContentFileModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(file) {
        let messageMap = {};
        if (file.name === undefined || file.name === "") {
            messageMap["name"] = this.state.formatMessage({id: 'CMS_CONTENT_FILE_MSG_NAME_REQUIRED'});
        }
        if (file.path === undefined || file.path === "") {
            messageMap["path"] = this.state.formatMessage({id: 'CMS_CONTENT_FILE_MSG_PATH_REQUIRED'});
        }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {file, content} = this.props;
        if (this._validate(file)) {
            if (file.id === undefined || file.id === "") {
                this.props.dispatch(createContentFile(file,  this.state.formatMessage));
            } else {
                this.props.dispatch(updateContentFile(file, this.state.formatMessage));
            }

        }
    }

    _handleChange(name, value) {

        const file = Object.assign({}, this.props.file);
        file[name] = value.target ? value.target.value : value;

        this.props.dispatch(refreshFile(file));
    }

    render() {
        const {file} = this.props;
        const formatMessage = this.state.formatMessage;

        return (
            <Modal
                maskClosable={false}
                title={formatMessage({id: 'CMS_CONTENT_FILE_TITLE'})}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'CMS_CONTENT_FILE_FIELD_NAME'})} {...Label_4_Wrapper_20} required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={file.name}
                               onChange={(data) => {
                                   this._handleChange("name", data)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CONTENT_FILE_FIELD_PATH'})} {...Label_4_Wrapper_20}
                               required={true}
                               validateStatus={this.state.messageMap["path"] ? "error" : ""}
                               help={this.state.messageMap["path"]}>
                        <Input value={file.path}
                               onChange={(data) => {
                                   this._handleChange("path", data)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CONTENT_FILE_FIELD_SEQUENCE'})} {...Label_4_Wrapper_20}>
                        <InputNumber value={file.sequence}
                                     onChange={(data) => {
                                         this._handleChange("sequence", data)
                                     }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'CMS_CONTENT_FILE_FIELD_FILE_VERSION'})} {...Label_4_Wrapper_20}>
                        <Input value={file.fileVersion}
                                        onChange={(data) => {
                                            this._handleChange("fileVersion", data)
                                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        file: store.CmsContentFileReducer.file,
        showDetailModal: store.CmsContentFileReducer.showDetailModal,
        content: store.CmsContentReducer.content,
    }
}

export default connect(mapStateToProps)(injectIntl(ContentFileModal));