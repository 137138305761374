import React, {Component} from 'react';
import {hashHistory, IndexRoute, Route, Router} from 'react-router';

import App from './App.js';

import {RoutePath} from './constants/RoutePath';

import SiderLayout from "./layout/SiderLayout";

import CommonException403 from "./views/403/Index";
import CommonException404 from "./views/404/Index";
import CommonException417 from "./views/417/Index";
import CommonException500 from "./views/500/Index";

import DictItemList from "./modules/Dict/views/Item/List/Index";
// 账号管理
import AccountUserList from './modules/Account/views/User/List/Index';
import AccountUserLogin from './modules/Account/views/User/Login/Index';
import AccountUserProfile from "./modules/Account/views/User/Profile/Index";
import AccountUserPasswordChange from "./modules/Account/views/User/PasswordChange/Index";
import AccountUserPasswordForgot from "./modules/Account/views/User/PasswordForgot/Index";
import AccountDataGroupList from "./modules/Account/views/DataGroup/List/Index";
import AccountRoleList from "./modules/Account/views/Role/List/Index";
import AccountResourceList from "./modules/Account/views/Resource/List/Index";
// 邮件管理
import MailPropertiesList from './modules/Mail/views/Properties/Index';
import MailTemplateList from './modules/Mail/views/Template/Index';
// 站点管理
import CmsSites from './modules/CMS/views/Sites/Index';
// 栏目管理
import CmsChannels from './modules/CMS/views/Channels/Index';
// 内容管理
import CmsContentList from './modules/CMS/views/Contents/List/Index';
import CmsContentDrafts from './modules/CMS/views/Contents/Drafts/Index';
import CmsContentCreate from "./modules/CMS/views/Contents/Create/Index";
import CmsContentUpdate from "./modules/CMS/views/Contents/Update/Index";
//训练的课时管理
import ExaminationPeriodList from "./modules/Exam/views/Examination/LessonPeriodList/Index"
//课程模版管理
import LessonTemplateList from "./modules/Exam/views/LessonTemplate/List/Index";

import ExamineeLessonList from "./modules/Exam/views/ExamineeLesson/List/Index"
import ExamineeLessonDetail from "./modules/Exam/views/ExamineeLesson/Detail/Index"
// 测评管理
import ExamBankList from "./modules/Exam/views/Bank/List/Index";
import ExamSubjectList from "./modules/Exam/views/Subject/List/Index";
import ExamExaminationList from "./modules/Exam/views/Examination/List/Index";
import ExamExamineeList from "./modules/Exam/views/Examinee/List/Index";
import ExamPaperList from "./modules/Exam/views/Paper/List/Index";

import ExamExaminationDetail from "./modules/Exam/views/Mobile/Examination/Index";
import ExamExamineeVerify from "./modules/Exam/views/Mobile/Examinee/Verify/Index";
import ExamExamineeVerifySuccess from "./modules/Exam/views/Mobile/Examinee/VerifySuccess/Index";
import ExamExamineeVerifyFailed from "./modules/Exam/views/Mobile/Examinee/VerifyFailed/Index";
import ExamPaperDetail from "./modules/Exam/views/Mobile/Paper/Detail/Index";
import ExamPaperResult from "./modules/Exam/views/Mobile/Paper/Result/Index";
// 卓励教育
import ZhuoLiVideoList from "./modules/Exam/views/Mobile/Video/List/Index";
import ZhuoLiVideoPlay from "./modules/Exam/views/Mobile/Video/Play/Index";
//常模
import ExamDataModelList from "./modules/Exam/views/DataModel/List/Index";
import ExamPaperDetailResult from "./modules/Exam/views/Paper/Detail/Index";
import ExamDataModelPaperUpdate from "./modules/Exam/views/DataModel/PaperList/Index";

export default class Root extends Component {
    render() {
        return (
            <Router history={hashHistory}>
                <Route path="/" component={App}>
                    <IndexRoute component={AccountUserLogin}/>
                    {/*共通的异常显示画面*/}
                    <Route path={RoutePath.COMMON_EXCEPTION_403.path} component={CommonException403}/>
                    <Route path={RoutePath.COMMON_EXCEPTION_404.path} component={CommonException404}/>
                    <Route path={RoutePath.COMMON_EXCEPTION_417.path} component={CommonException417}/>
                    <Route path={RoutePath.COMMON_EXCEPTION_500.path} component={CommonException500}/>

                    <Route path={RoutePath.ACCOUNT_USER_LOGIN_PASSWORD.path} component={AccountUserLogin}/>
                    <Route path={RoutePath.ACCOUNT_USER_PASSWORD_FORGOT.path} component={AccountUserPasswordForgot}/>
                    <Route component={SiderLayout}>
                        <Route path={RoutePath.CMS_CONTENT_LIST.path} component={CmsContentList}/>
                        {/*个人中心*/}
                        <Route path={RoutePath.ACCOUNT_USER_LIST.path} component={AccountUserList}/>
                        <Route path={RoutePath.ACCOUNT_USER_PROFILE.path} component={AccountUserProfile}/>
                        <Route path={RoutePath.ACCOUNT_USER_PASSWORD.path} component={AccountUserPasswordChange}/>
                        <Route path={RoutePath.ACCOUNT_ROLE_LIST.path} component={AccountRoleList}/>
                        <Route path={RoutePath.ACCOUNT_DATA_GROUP_LIST.path} component={AccountDataGroupList}/>
                        <Route path={RoutePath.ACCOUNT_RESOURCE_LIST.path} component={AccountResourceList}/>
                        {/*邮件*/}
                        <Route path={RoutePath.MAIL_PROPERTIES_LIST.path} component={MailPropertiesList}/>
                        <Route path={RoutePath.MAIL_TEMPLATE_LIST.path} component={MailTemplateList}/>
                        {/*字典*/}
                        <Route path={RoutePath.DICT_ITEM_LIST.path} component={DictItemList}/>
                        {/*内容管理*/}
                        <Route path={RoutePath.CMS_SITE_LIST.path} component={CmsSites}/>
                        <Route path={RoutePath.CMS_CHANNEL_LIST.path} component={CmsChannels}/>
                        <Route path={RoutePath.CMS_CONTENT_LIST.path} component={CmsContentList}/>
                        <Route path={RoutePath.CMS_CONTENT_DRAFTS.path} component={CmsContentDrafts}/>
                        <Route path={RoutePath.CMS_CONTENT_CREATE.path} component={CmsContentCreate}/>
                        <Route path={RoutePath.CMS_CONTENT_UPDATE.path} component={CmsContentUpdate}/>
                        {/*测评系统*/}
                        <Route path={RoutePath.EXAM_BANK_LIST.path} component={ExamBankList}/>
                        <Route path={RoutePath.EXAM_SUBJECT_LIST.path} component={ExamSubjectList}/>
                        <Route path={RoutePath.EXAM_EXAMINEE_LIST.path} component={ExamExamineeList}/>
                        <Route path={RoutePath.EXAM_EXAMINATION_LIST.path} component={ExamExaminationList}/>
                        <Route path={RoutePath.EXAM_PAPER_LIST.path} component={ExamPaperList}/>
                        <Route path={RoutePath.EXAM_DATA_MODEL_LIST.path} component={ExamDataModelList}/>
                        <Route path={RoutePath.EXAM_PAPER_RESULT_DETAIL.path} component={ExamPaperDetailResult}/>
                        <Route path={RoutePath.EXAM_DATA_MODEL_PAPER_UPDATE.path} component={ExamDataModelPaperUpdate}/>

                        {/*课程管理*/}
                        <Route path={RoutePath.EXAM_LESSON_TEMPLATE_LIST.path} component={LessonTemplateList}/>
                        <Route path={RoutePath.EXAM_EXAMINATION_PERIOD_LIST.path} component={ExaminationPeriodList}/>
                        <Route path={RoutePath.EXAM_EXAMINEE_LESSON_LIST.path} component={ExamineeLessonList}/>

                        <Route path={RoutePath.EXAM_EXAMINEE_LESSON_RESULT_DETAIL.path} component={ExamineeLessonDetail}/>

                    </Route>

                    <Route path={RoutePath.EXAM_MOBILE_EXAMINATION.path} component={ExamExaminationDetail}/>
                    <Route path={RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY.path} component={ExamExamineeVerify}/>
                    <Route path={RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY_SUCCESS.path} component={ExamExamineeVerifySuccess}/>
                    <Route path={RoutePath.EXAM_MOBILE_EXAMINEE_VERIFY_FAILED.path} component={ExamExamineeVerifyFailed}/>
                    <Route path={RoutePath.ZHUOLI_VIDEO_LIST.path} component={ZhuoLiVideoList}/>
                    <Route path={RoutePath.ZHUOLI_VIDEO_PLAY.path} component={ZhuoLiVideoPlay}/>
                    <Route path={RoutePath.EXAM_PAPER_DETAIL.path} component={ExamPaperDetail}/>
                    <Route path={RoutePath.EXAM_PAPER_RESULT.path} component={ExamPaperResult}/>
                </Route>
            </Router>
        )
    }
};
