import * as ActionTypes from '../ActionTypes';
import * as WebService from '../WebService';
import HttpUtils from '../../../utils/HttpUtils';
import {refreshSubjects, refreshCurrentIndex} from "./PaperSubjectAction";

import {CACHE_ACCESS_TOKEN} from "../../../constants/Profile";
import {message} from "antd";
import {searchExaminationSubjects} from "./ExaminationSubjectAction";
import {refreshExamination} from "./ExaminationAction";
import {ExaminationClassifyEnums} from "../Enums";

export function refreshPaper(paper) {
    return {
        type: ActionTypes.EXAM_PAPER_REFRESH_PAPER,
        paper: paper,
    }
}

export function refreshSearchCondition(condition) {
    return {
        type: ActionTypes.EXAM_PAPER_REFRESH_SEARCH_CONDITION,
        searchCondition: condition,
    }
}

export function toggleDetailModal(showDetailModal) {
    return {
        type: ActionTypes.EXAM_PAPER_TOGGLE_MODAL,
        showDetailModal: showDetailModal,
    }
}

export function searchPapers(condition, formatMessage) {

    return dispatch => {
        condition.classify=ExaminationClassifyEnums.Enum.Evaluation;
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        dispatch(refreshSearchCondition(condition))
        HttpUtils.post(WebService.searchPapers, condition, (response) => {
            dispatch({
                type: ActionTypes.EXAM_PAPER_SEARCH_PAPER,
                papers: response.data,
                pagination: response.pagination
            });
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getPaperDetail(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getPaper, "paperId", id);
        HttpUtils.get(requestURL, (response) => {

            let paper = response.data;
            let subjects = paper.subjects;
            if (subjects) {
                subjects.forEach(item => {
                    item.contentJson = item.content && item.content !== "" ? JSON.parse(item.content) : {};
                    if (item.status.startsWith("{")) {
                        item.statusObj = JSON.parse(item.status);
                    } else {
                        item.statusObj = {};
                    }
                });
                subjects.sort((a, b) => a.block - b.block);
                paper.subjects = subjects;
            }

            dispatch(refreshPaper(paper));
            dispatch(getExaminationSubjects(paper.examinationId, formatMessage));
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getPaper(id, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getPaper, "paperId", id);
        HttpUtils.get(requestURL, (response) => {

            let paper = response.data;
            let subjects = paper.subjects;
            if (subjects) {
                subjects.forEach(item => {
                    item.contentJson = item.content && item.content !== "" ? JSON.parse(item.content) : {};
                    if (item.status.startsWith("{")) {
                        item.statusObj = JSON.parse(item.status);
                    } else {
                        item.statusObj = {};
                    }
                });
                subjects.sort((a, b) => a.block - b.block);
                paper.subjects = subjects;
            }

            dispatch(refreshPaper(paper));
            // dispatch(getExaminationSubjects(paper.examinationId, formatMessage));
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function getMyExaminationPaper(examinationId, examineeId, formatMessage, router) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});

        let requestURL = HttpUtils.addQueryString(WebService.getMyExaminationPaper, "examinationId", examinationId);
        requestURL = HttpUtils.addQueryString(requestURL, "examineeId", examineeId);
        HttpUtils.get(requestURL, (response) => {
            console.log(response.data);
            dispatch(refreshPaper(response.data));
            dispatch(refreshSubjects(response.data.subjects));
            dispatch(refreshCurrentIndex(0));
            if (router) {
                console.log(response.data);
                // if (response.data.status === PaperStatus.Enum.New) {
                //     router.push(RoutePath.EXAM_PAPER_DETAIL.path.replace(":examinationId", examinationId));
                // } else {
                //     router.push(RoutePath.EXAM_PAPER_RESULT.path.replace(":examinationId", examinationId));
                // }
            }
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function handInPaper(paper, formatMessage, successCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        HttpUtils.post(WebService.handInPaper, paper, (response) => {
            dispatch(getPaperDetail(paper.id, formatMessage));
            // router.push(RoutePath.EXAM_PAPER_RESULT.path.replace(":examinationId", examinationId));
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
            successCallback && successCallback.call(this);
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}
export function handInPaperForLesson(paper, formatMessage, successCallback) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        HttpUtils.post(WebService.handInPaperForLesson, paper, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
            successCallback && successCallback.call(this);
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function deletePaper(id, condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        let requestURL = HttpUtils.addQueryString(WebService.deletePaper, "id", id);
        HttpUtils.post(requestURL, {}, (response) => {
            dispatch(searchPapers(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function updatePaper(paper, formatMessage, refreshFunction) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        // let requestURL = HttpUtils.addQueryString(WebService.updatePaper, "id", id);
        HttpUtils.post(WebService.updatePaper, paper, (response) => {
            if (refreshFunction) {
                refreshFunction.call();
            }
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
        }, formatMessage)
    }
}

export function exportPapers(condition, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: true});
        fetch(WebService.exportPaper, {
            method: "post",
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },

            body: JSON.stringify(condition)
        })
            .then((response) => response.arrayBuffer())
            .then((response) => {
                //console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', 'PaperExportFile.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                dispatch({type: ActionTypes.EXAM_PAPER_LOADING, isLoading: false});
            })
    }
}


//独立用于详情显示的，不和共通的混在一起用。
export function getExaminationSubjects(examinationId, formatMessage) {

    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});

        let requestURL = WebService.getSubjects.replace("{examinationId}", examinationId);
        const condition = {examinationId: examinationId};


        HttpUtils.post(WebService.searchExaminationSubjects, condition, (response) => {
            dispatch(refreshExaminationSubjectList(response.data));
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
        }, formatMessage)
    }
}


export function refreshExaminationSubjectList(relatedExaminationSubjectList) {
    return {
        type: ActionTypes.EXAM_PAPER_REFRESH_RELATED_EXAM_SUBJECT_LIST,
        relatedExaminationSubjectList: relatedExaminationSubjectList,
    }
}


export function refreshPaperSubject(paperSubject) {
    return {
        type: ActionTypes.EXAM_PAPER_REFRESH_SUBJECT,
        paperSubject: paperSubject,
    }
}

export function togglePaperSubjectModifyModal(showPaperSubjectModal) {
    return {
        type: ActionTypes.EXAM_PAPER_TOGGLE_PAPER_SUBJECT_MODIFY_MODAL,
        showPaperSubjectModal: showPaperSubjectModal,
    }
}


export function doExportPaperReport(exportPaperReport, formatMessage) {


    return dispatch => {
        dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: true});
        let requestUrl = WebService.exportPaperReport;
        fetch(requestUrl, {
            method: "post",
            headers: {
                'Accept': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'Content-Type': 'application/json',
                'X-Auth-Token': localStorage.getItem(CACHE_ACCESS_TOKEN),
            },
            body: JSON.stringify(exportPaperReport)
        })
            .then((response) => response.arrayBuffer())
            .then((response) => {
                //console.log(response)
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', '测评报告.docx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                dispatch({type: ActionTypes.EXAM_PAPER_SUBJECT_LOADING, isLoading: false});
            })
    }
}
