import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Table, Divider, Card, Popconfirm} from 'antd';

import {
    deleteProperties,
    getPropertiesDetail,
    searchPropertiesList,
    toggleDetailModal
} from "../../actions/PropertiesAction";
import PaginationBar from "../../../../components/PaginationBar";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {title: formatMessage({id: 'MAIL_PROPERTIES_FIELD_MAIL_HOST'}), dataIndex: 'mailHost', key: 'mailHost'},
                {title: formatMessage({id: 'MAIL_PROPERTIES_FIELD_MAIL_FROM'}), dataIndex: 'mailFrom', key: 'mailFrom'},
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}><FormattedMessage
                    id="COMMON_BUTTON_MODIFY"/></a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
            </div>
        )
    }

    _handleDelete(record) {
        const {dispatch, searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteProperties(record, searchCondition, formatMessage));
    }

    _handleModify(record) {
        this.props.dispatch(toggleDetailModal(true));
        this.props.dispatch(getPropertiesDetail(record.id, this.state.formatMessage));
    }

    render() {
        return (
            <Card className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey="id" size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.propertiesList} pagination={false}/>

            </Card>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchPropertiesList(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        propertiesList: store.MailPropertiesReducer.propertiesList,
        pagination: store.MailPropertiesReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));