import HttpUtils from "../../../utils/HttpUtils";
import {message} from 'antd';
import * as ActionTypes from "../ActionTypes";
import * as WebService from "../WebService";
import {searchEmployees} from "../../ORG/actions/EmployeeAction";

export function refreshTemplateEditor(editor) {
    return {
        type: ActionTypes.MAIL_TEMPLATE_REFRESH_TEMPLATE_EDITOR,
        editor: editor
    }
}

export function toggleDetailModal(isShow) {
    return {
        type: ActionTypes.MAIL_TEMPLATE_TOGGLE_DETAIL_MODAL,
        showDetailModal: isShow
    }
}

export function refreshSearchCondition(searchCondition) {
    return {
        type: ActionTypes.MAIL_TEMPLATE_REFRESH_SEARCH_CONDITION,
        searchCondition: searchCondition
    }
}

export function refreshTemplate(template) {
    return {
        type: ActionTypes.MAIL_TEMPLATE_REFRESH_TEMPLATE,
        template: template
    }
}

export function searchTemplates(condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: true});
        HttpUtils.post(WebService.searchTemplates, condition, (response) => {
            dispatch({
                type: ActionTypes.MAIL_TEMPLATE_SEARCH,
                templates: response.data,
                pagination: response.pagination
            });
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function getTemplateDetail(id, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: true});
        const requestUrl = HttpUtils.addQueryString(WebService.getTemplateDetail, "id", id);
        HttpUtils.get(requestUrl, (response) => {
            dispatch(refreshTemplate(response.data));
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)

    }
}

export function createTemplate(template, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: true});
        HttpUtils.post(WebService.createTemplate, template, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchTemplates(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_CREATE_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function updateTemplate(template, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: true});
        HttpUtils.post(WebService.updateTemplate, template, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchTemplates(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_UPDATE_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function deleteTemplate(template, condition, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: true});
        HttpUtils.post(WebService.deleteTemplate, template, (response) => {
            dispatch(toggleDetailModal(false));
            dispatch(searchTemplates(condition, formatMessage));
            message.success(formatMessage({id: "COMMON_MSG_DELETE_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
        }, formatMessage)
    }
}

export function sendMailVerifyCode(mail, formatMessage) {
    return dispatch => {
        dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: true});

        let requestUrl = WebService.sendMailVerifyCode + "?mailTo=" + mail + "&templateCode=MAIL_VERIFY_CODE";
        HttpUtils.get(requestUrl, (response) => {
            message.success(formatMessage({id: "MAIL_TEMPLATE_MSG_VERIFY_CODE_SEND_SUCCESS"}));
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, (response) => {
            dispatch({type: ActionTypes.MAIL_TEMPLATE_LOADING, isLoading: false});
        }, formatMessage);
    }
}