/**
 * Created by Ethan on 2018/4/21.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {injectIntl} from 'react-intl';

import {Modal, Form, Input, InputNumber, Checkbox} from "antd";

import {toggleDetailModal, createRole, updateRole, refreshRole} from "../../../actions/RoleAction";

import Validator from "../../../../../utils/Validator";
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentDidMount() {
    }

    _handleChange(name, value) {
        const role = Object.assign({}, this.props.role);
        if (name === "roles") {
            const roles = [];
            value.map((item, i) => {
                return (
                    roles.push({id: item})
                )
            });
            role[name] = roles;
        } else {
            role[name] = value.target ? value.target.value : value;
        }
        this.props.dispatch(refreshRole(role));
    }

    _handleOk() {
        const {dispatch, searchCondition, role} = this.props;
        if (this._validate(role)) {
            if (role.id === undefined || role.id === "") {
                dispatch(createRole(role, searchCondition, this.state.formatMessage));
            } else {
                dispatch(updateRole(role, searchCondition, this.state.formatMessage));
            }
        }
    }

    _validate(role) {
        const formatMessage = this.state.formatMessage;
        let messageMap = {};
        if (role.code === undefined || role.code === "") {
            messageMap["code"] = formatMessage({id: "ACCOUNT_ROLE_MSG_CODE_REQUIRED"});
        }
        if (role.name === undefined || role.name === "") {
            messageMap["name"] = formatMessage({id: "ACCOUNT_ROLE_MSG_NAME_REQUIRED"});
        }

        this.setState({messageMap: messageMap});

        return Object.keys(messageMap).length <= 0;
    }

    _handleCancel() {
        this.setState({messageMap: {}});
        this.props.dispatch(toggleDetailModal(false));
    }

    render() {
        const {role} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = "";
        if (role.id === undefined || role.id === "") {
            title = formatMessage({id: "ACCOUNT_ROLE_TITLE_CREATE"});
        }
        else {
            title = formatMessage({id: "ACCOUNT_ROLE_TITLE_UPDATE"});
        }

        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_SUBMIT"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: "ACCOUNT_ROLE_FIELD_CODE"})} {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={role.code} onChange={(value) => {
                                   this._handleChange("code", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_ROLE"})} {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["name"] ? "error" : ""}
                               help={this.state.messageMap["name"]}>
                        <Input value={role.name}
                               onChange={(value) => {
                                   this._handleChange("name", value)
                               }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: "ACCOUNT_ROLE_FIELD_SEQUENCE"})} {...Label_6_Wrapper_18}>
                        <InputNumber value={role.sequence}
                               onChange={(value) => {
                                   this._handleChange("sequence", value)
                               }}/>
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        role: store.AccountRoleReducer.role,
        searchCondition: store.AccountRoleReducer.searchCondition,
        showDetailModal: store.AccountRoleReducer.showDetailModal
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));