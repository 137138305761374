/**
 * Created by Ethan on 17/1/13.
 */

const Validator = {

    /*
     * 验证电话号码
     * */
    isMobileNumber: (mobile) => {

        const regex = /^(1[0-9]{10})$/;
        if (!regex.test(mobile)) {
            return false;
        }
        return true;
    },

    /**
     * 验证邮箱格式
     * @param email 邮箱地址
     * @returns {boolean} 是否是邮箱格式
     */
    isEmail: (email) => {
        const regex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        if (!regex.test(email)) {
            return false;
        }
        return true;
    },

    isNumber: (number) => {
        let regex = /^[0-9]*$/;
        if (!regex.test(number)) {
            return false;
        }
        return true;
    },

    /**
     * 数字、字母大/小写、以及特殊字符四者至少取其三
     */
    isPasswordStrength: (password) => {
        const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*\(\)_+`\-={}:";'<>?,.\/]).{8,20}$/;
        if (regex.test(password)) {
            return true;
        }
        return false;
    },

    containsSpecialCharacter: (str) => {
        const pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
        return pattern.test(str);
    },

    containsNumber: (str) => {
        const pattern = /\d/;
        return pattern.test(str);
    },
};
export default Validator;