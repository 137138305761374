/**
 * Created by wangyixian on 2018/6/19.
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
// 引入多语言Message
import {FormattedMessage, injectIntl} from 'react-intl';

import {Card, Form, Row, Col, Button, Input, Select} from 'antd';

import {refreshRole, refreshSearchCondition, searchRoles, toggleDetailModal} from '../../../actions/RoleAction';
import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this._handleSearch({});
        this.props.dispatch(refreshSearchCondition({}))
    }

    componentDidMount() {
    }

    _handleSearch(searchCondition) {
        this.props.dispatch(searchRoles(searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        let searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = value.target ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshRole({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {

        const formatMessage = this.state.formatMessage;

        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ACCOUNT_ROLE_FIELD_CODE"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.code}
                                       onChange={(value) => this._handleChange("code", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={formatMessage({id: "ACCOUNT_USER_FIELD_ROLE"})} {...Label_6_Wrapper_18}>
                                <Input value={this.props.searchCondition.name}
                                       onChange={(value) => this._handleChange("name", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch(this.props.searchCondition)}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus" onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.AccountRoleReducer.searchCondition
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));