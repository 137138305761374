import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';

import {Divider, Popconfirm, Table} from 'antd';


import PaginationBar from "../../../../../components/PaginationBar";
import {ExaminationStatus, ExamPaperType} from "../../../Enums";
import moment from "moment";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import {
    deleteExamExamineeLesson,
    getExamExamineeLesson,
    searchAllExaminationPeriod,
    searchAllExamineeList,
    searchExamineeLesson,
    toggleDetailModal
} from "../../../actions/ExamineeLessonAction";
import {DateTimeFormat} from "../../../../../utils/DateUtil";
import {RoutePath} from "../../../../../constants/RoutePath";

class SearchResult extends Component {

    constructor(props) {
        super(props);

        const formatMessage = this.props.intl['formatMessage'];
        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            columns: [
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_LESSON_FIELD_NUM'}),
                    dataIndex: 'number',
                    key: 'number'
                },
                // {
                //     title: formatMessage({id:'EXAM_EXAMINEE_FIELD_NAME'}),
                //     dataIndex: 'examineeId',
                //     key: 'examineeId'
                // },
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_NAME'}),
                    dataIndex: 'name',
                    key: 'name',
                    render: (text, record, index) => record.examinee.lastName + record.examinee.firstName
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINEE_FIELD_MOBILE'}),
                    dataIndex: 'mobile',
                    key: 'mobile',
                    render: (text, record, index) => record.examinee.mobile
                },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_TITLE'}),
                //     dataIndex: 'title',
                //     key: 'title'
                // },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_TYPE'}),
                //     dataIndex: 'classify',
                //     key: 'classify',
                //     render: (text, record, index) =>record.classify&& formatMessage({id: ExamPaperType.Map[record.classify]})
                // },
                // {
                //     title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_STATUS'}),
                //     dataIndex: 'status',
                //     key: 'status',
                //     render: (text, record, index) => formatMessage({id: ExaminationStatus.Map[record.status]})
                // },

                {
                    title: formatMessage({id: 'EXAM_LESSON_FIELD_PERIOD_COUNT'}),
                    dataIndex: 'examinationIds',
                    key: 'examinationIds',
                    render: (text, record, index) => {
                        let examinationIds = record.examinationIds;
                        let examinationIdList = examinationIds ? examinationIds.split(",") : [];
                        return examinationIdList.length
                    }
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_START_TIME'}),
                    key: 'startTime',
                    dataIndex: 'startTime',
                    render: (text, record, index) => DateTimeFormat.dateToHyphenString(record.startTime)
                },
                {
                    title: formatMessage({id: 'EXAM_EXAMINATION_FIELD_END_TIME'}),
                    key: 'endTime',
                    dataIndex: 'endTime',
                    render: (text, record, index) =>  DateTimeFormat.dateToHyphenString(record.endTime)
                },
                {
                    title: formatMessage({id: 'COMMON_FIELD_ACTION'}),
                    dataIndex: 'action',
                    key: 'action',
                    render: (text, record, index) => this._renderButtonGroup(text, record, index)
                }
            ]
        }
    }

    _renderButtonGroup(text, record, index) {
        return (
            <div>
                <a onClick={this._handleModify.bind(this, record)}>
                    <FormattedMessage id="COMMON_BUTTON_MODIFY"/>
                </a>
                <Divider type="vertical"/>
                <Popconfirm title={this.state.formatMessage({id: "COMMON_MSG_DELETE_CONFIRM"})}
                            onConfirm={() => this._handleDelete(record)}>
                    <a><FormattedMessage
                        id="COMMON_BUTTON_DELETE"/></a>
                </Popconfirm>
                {/*<Divider type="vertical"/>*/}
                {/*<Popover content={<QRCode value={qrPath}/>}>*/}
                {/*<a onClick={() => this.setState({showQrCode: true, selectedRecord: record})}> <Icon type="qrcode"/></a>*/}
                {/*</Popover>*/}
                {/*<Divider type="vertical"/>*/}
                {/*<a onClick={this._handlePublish.bind(this, record)}>*/}
                {/*<FormattedMessage id="EXAM_EXAMINATION_BUTTON_PUBLISH"/>*/}
                {/*</a>*/}
                <Divider type="vertical"/>
                <a onClick={this._handleShowDetail.bind(this, record)}>课程详情
                </a>
            </div>
        )
    }

_handleShowDetail(record){
    this.props.router.push({
        pathname: RoutePath.EXAM_EXAMINEE_LESSON_RESULT_DETAIL.path,
        query: {lessonId: record.id}
    })

}

    _handleDelete(record) {
        const {searchCondition, dispatch} = this.props;
        const formatMessage = this.state.formatMessage;
        dispatch(deleteExamExamineeLesson(record.id, searchCondition, formatMessage));
    }

    _handleModify(record) {
        const {searchCondition, dispatch} = this.props;
        const {formatMessage} = this.state;
        dispatch(getExamExamineeLesson(record.id, formatMessage));
        dispatch(toggleDetailModal(true));
        dispatch(searchAllExaminationPeriod(this.state.formatMessage));

    }

    render() {
        return (
            <div className="spacing-v">
                <PaginationBar pagination={this.props.pagination}
                               onChange={(page, pageSize) => this._handlePagination(page, pageSize)}/>
                <Table rowKey={(record) => record.id} size="middle" className="spacing-v" columns={this.state.columns}
                       dataSource={this.props.examineeLessonList} pagination={false}/>

            </div>
        )
    }

    _handlePagination(page, pageSize) {
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition["currentPage"] = page;
        this.props.dispatch(searchExamineeLesson(searchCondition, this.state.formatMessage));
    }
}

function mapStateToProps(store) {
    return {
        examineeLessonList: store.ExamineeLessonReducer.examineeLessonList,
        searchCondition: store.ExamineeLessonReducer.searchCondition,
        pagination: store.ExamineeLessonReducer.pagination
    }
}

export default connect(mapStateToProps)(injectIntl(SearchResult));