import React, {Component} from 'react';
import {connect} from 'react-redux';
import {injectIntl, FormattedMessage} from 'react-intl';

import {Modal, Form, Input, InputNumber, DatePicker} from "antd";

import {Label_6_Wrapper_18, Label_8_Wrapper_16} from "../../../../../constants/FormItemLayout";

import {
    createExamination, initExamination,
    refreshExamination,
    toggleDetailModal,
    updateExamination
} from "../../../actions/ExaminationAction";

import {ExaminationStatus, ExamPaperType} from "../../../Enums";
import * as DateTimeFormats from "../../../../../constants/DateTimeFormats";
import moment from "moment";
import EnumItemSelect from "../../../../../components/EnumItemSelect";

class DetailModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage'],
            messageMap: {}
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    _validate(examination) {
        let messageMap = {};
        if (!examination.title || examination.title === "") {
            messageMap["title"] = this.state.formatMessage({id: 'EXAM_EXAMINATION_MSG_TITLE_REQUIRED'});
        }
        if (!examination.code || examination.code === "") {
            messageMap["code"] = this.state.formatMessage({id: 'EXAM_EXAMINATION_MSG_CODE_REQUIRED'});
        }
        // if (!examination.status || examination.status === "") {
        //     messageMap["status"] = this.state.formatMessage({id: 'EXAM_EXAMINATION_MSG_STATUS_REQUIRED'});
        // }

        this.setState({messageMap: messageMap});

        return (Object.keys(messageMap).length <= 0);
    }

    _handleCancel() {
        this.props.dispatch(toggleDetailModal(false));
    }

    _handleOk() {
        const {examination, searchCondition} = this.props;
        const {formatMessage} = this.state;

        if (this._validate(examination)) {
            if (examination.id && examination.id !== "") {
                this.props.dispatch(updateExamination(examination, searchCondition, formatMessage));
            } else {
                this.props.dispatch(createExamination(examination, searchCondition, formatMessage));
            }
            this.props.dispatch(toggleDetailModal(false));
        }
    }

    _handleChange(name, value) {
        const examination = Object.assign({}, this.props.examination);
        examination[name] = (value && value.target) ? value.target.value : value;
        this.props.dispatch(refreshExamination(examination));
    }

    render() {
        const {examination} = this.props;
        const formatMessage = this.state.formatMessage;

        let title = formatMessage({id: 'EXAM_EXAMINATION_PERIOD_TITLE_CREATE'});
        if (examination.id && examination.id !== "") {
            title = formatMessage({id: 'EXAM_EXAMINATION_PERIOD_TITLE_UPDATE'});
        }
        return (
            <Modal
                maskClosable={false}
                title={title}
                visible={this.props.showDetailModal}
                onOk={() => this._handleOk()}
                onCancel={() => this._handleCancel()}
                okText={formatMessage({id: "COMMON_BUTTON_OK"})}
                cancelText={formatMessage({id: "COMMON_BUTTON_CANCEL"})}>
                <Form>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINATION_PERIOD_FIELD_CODE'})}
                               {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["code"] ? "error" : ""}
                               help={this.state.messageMap["code"]}>
                        <Input value={examination.code} onChange={(data) => {
                            this._handleChange("code", data)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINATION_PERIOD_FIELD_TITLE'})}
                               {...Label_6_Wrapper_18} required={true}
                               validateStatus={this.state.messageMap["title"] ? "error" : ""}
                               help={this.state.messageMap["title"]}>
                        <Input value={examination.title} onChange={(data) => {
                            this._handleChange("title", data)
                        }}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINATION_FIELD_TYPE'})}
                               {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["classify"] ? "error" : ""}
                               help={this.state.messageMap["classify"]}>
                        <EnumItemSelect
                            value={examination.classify} data={ExamPaperType.List}
                            onChange={(value) => this._handleChange("classify", value)}/>
                    </Form.Item>
                    <Form.Item label={formatMessage({id: 'EXAM_EXAMINATION_FIELD_STATUS'})}
                               {...Label_6_Wrapper_18}
                               required={true}
                               validateStatus={this.state.messageMap["status"] ? "error" : ""}
                               help={this.state.messageMap["status"]}>
                        <EnumItemSelect
                            value={examination.status} data={ExaminationStatus.List}
                            onChange={(value) => this._handleChange("status", value)}/>
                    </Form.Item>
                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_EXAMINATION_FIELD_START_TIME'})}>
                        <DatePicker
                            showTime
                            format={DateTimeFormats.FORMAT_DATE_TIME_HYPHEN}
                            value={(examination.startTime && examination.startTime !== "") ?
                                moment(examination.startTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE) : null}
                            onChange={(value, dateString) => this._handleChange("startTime", value && value.format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE))}
                        />
                    </Form.Item>
                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_EXAMINATION_FIELD_END_TIME'})}>
                        <DatePicker
                            showTime
                            format={DateTimeFormats.FORMAT_DATE_TIME_HYPHEN}
                            value={(examination.endTime && examination.endTime !== "") ?
                                moment(examination.endTime, DateTimeFormats.FORMAT_DATE_TIME_SIMPLE) : null}
                            onChange={(value, dateString) => this._handleChange("endTime", value && value.format(DateTimeFormats.FORMAT_DATE_TIME_SIMPLE))}
                        />
                    </Form.Item>
                    <Form.Item {...Label_6_Wrapper_18}
                               label={formatMessage({id: 'EXAM_EXAMINATION_PERIOD_FIELD_SUMMARY'})}>
                        <Input.TextArea value={examination.summary} onChange={(data) => {
                            this._handleChange("summary", data)
                        }}/>
                    </Form.Item>
                    {/*<Form.Item label={formatMessage({id: 'EXAM_EXAMINATION_FIELD_LIMIT_TIME'})}*/}
                               {/*{...Label_6_Wrapper_18}>*/}
                        {/*<InputNumber value={examination.limitTime}*/}
                                     {/*onChange={(data) => {*/}
                                         {/*this._handleChange("limitTime", data)*/}
                                     {/*}}/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label={formatMessage({id: 'EXAM_EXAMINATION_FIELD_PASS_SCORE'})}*/}
                               {/*{...Label_6_Wrapper_18}>*/}
                        {/*<InputNumber value={examination.passScore}*/}
                                     {/*onChange={(data) => {*/}
                                         {/*this._handleChange("passScore", data)*/}
                                     {/*}}/>*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        );
    }
}

function mapStateToProps(store) {
    return {
        searchCondition: store.ExamExaminationReducer.searchCondition,
        showDetailModal: store.ExamExaminationReducer.showDetailModal,
        examination: store.ExamExaminationReducer.examination
    }
}

export default connect(mapStateToProps)(injectIntl(DetailModal));