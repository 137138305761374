import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Card, Form, Input, Button, Row, Col, Select} from "antd";
import {
    refreshSearchCondition, refreshSubject,
    searchSubjects, toggleDetailModal
} from '../../../actions/SubjectAction';

import {searchBanks} from "../../../actions/BankAction";

import {Label_6_Wrapper_18} from "../../../../../constants/FormItemLayout";
import ListSelector from "../../../../../components/ListSelector";

class SearchCondition extends Component {

    constructor(props) {
        super(props);

        this.state = {
            formatMessage: this.props.intl['formatMessage']
        }
    }

    componentWillMount() {
        this.props.dispatch(searchBanks({paginationFlag: false}, this.state.formatMessage));
        this._handleSearch();
    }

    _handleSearch() {
        this.props.dispatch(searchSubjects(this.props.searchCondition, this.state.formatMessage));
    }

    _handleChange(name, value) {
        console.log(value);
        const searchCondition = Object.assign({}, this.props.searchCondition);
        searchCondition[name] = (value && value.target) ? value.target.value : value;
        this.props.dispatch(refreshSearchCondition(searchCondition));
    }

    _handleShowCreateModal() {
        this.props.dispatch(refreshSubject({}));
        this.props.dispatch(toggleDetailModal(true));
    }

    render() {
        const {banks, searchCondition} = this.props;
        const formatMessage = this.state.formatMessage;
        return (
            <Card>
                <Form>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_SUBJECT_FIELD_BANK"})} {...Label_6_Wrapper_18}>
                                <ListSelector allowClear={true} value={searchCondition.bankIds} data={banks}
                                              onChange={(value) => this._handleChange("bankIds", value ? [value] : [])}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={formatMessage({id: "EXAM_SUBJECT_FIELD_TITLE"})} {...Label_6_Wrapper_18}>
                                <Input value={searchCondition.title}
                                       onChange={(value) => this._handleChange("title", value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button type="primary" icon="search" className="spacing-h"
                                        onClick={() => this._handleSearch()}>
                                    <FormattedMessage id='COMMON_BUTTON_SEARCH'/>
                                </Button>
                                <Button icon="plus"
                                        onClick={() => this._handleShowCreateModal()}>
                                    <FormattedMessage id='COMMON_BUTTON_ADD'/>
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        );
    }
}

function mapStateToProps(store) {
    return {
        banks: store.ExamBankReducer.banks,
        searchCondition: store.ExamSubjectReducer.searchCondition,
        pagination: store.ExamSubjectReducer.pagination,
    }
}

export default connect(mapStateToProps)(injectIntl(SearchCondition));